import React,{useEffect} from 'react';
import styled from 'styled-components';

import Gif from './Mobility_Research_1.gif'
import Lidar from '../Lidar/lidar';
import bg2 from '../../../../we/subpages/ourStory/ourCulture/BG.svg'
import animation from './Lidar-Final.json';
import Lottie from 'react-lottie';
import { ReactComponent as Back } from '../../../../../assets/back-icon.svg'
function LidarResearch() {
    useEffect(()=>{
        document.body.scrollTop = 0; // For Safari
          document.documentElement.scrollTop = 0;
      },[])
      const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animation,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      };
      const handleBackBtn=()=>{
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        window.history.back();
    }
    return (
        <div style={{overflowX:'hidden'}}>
            
        <LidarResearchContainer>
        <div style={{'display':'flex','alignItems':'center','justifyContent':'flex-start',position:'relative',flexDirection:'row'}}>
           <Back className='back-btn' onClick={handleBackBtn} ></Back>
       
            </div>
            <div className='LidarResearch-header heading'>Mobility <div className='highlight'>Research</div></div>
            <p className='LidarResearch-topline highlight '>
            unveiling the Power of 3D LiDAR
            </p>
            {/* <p className='LidarResearch-top content'>Immerse yourself in the cutting-edge realm of 3D Lidar technology, where advanced laser scanning
and precise data capture enable unparalleled perception and tracking capabilities. </p> */}
            
          
             <p className='LidarResearch-top content'>Welcome to brigosha’s captivating world of mobility research! Immerse yourself in the revolutionary realm of 3D Lidar technology,
              and false Detections are history. With our groundbreaking standalone Lidar solution, level-4 autonomy is within reach, making driving safer and smarter.
               Experience the magic of our algorithm that detects ghost objects, ensuring no anomalies go unnoticed, setting a new perception standard. Precise navigation and collision avoidance are now realities,
                guaranteeing safer journeys for all. ADAS brilliance reshapes driving, offering inclusivity, efficiency, and a glimpse into the future of autonomous vehicles.
                 Join brigosha’s visionary drive and embrace the transformative journey towards a smarter, safer tomorrow!</p>
 <br/>
            
        </LidarResearchContainer>
       
         {/* <img src={Gif} style={{width:'100vw'}}>
           </img> */}
            <Lottie options={defaultOptions} isClickToPauseDisabled={true} />
         
           <div   style={{
    background:`url(${bg2}) center no-repeat`,backgroundSize:'140%',
    }}>
                  <Lidar/>
          

          </div>
           </div>
    )
}

const LidarResearchContainer = styled.div`
    height: auto;
    background-color: transparent;
    padding-top: 40px;
    padding-right: 8vw;
    padding-left: 8vw;
    text-align: center;
 
    .back-btn {
   
        left:0;
      
        cursor:pointer;
      
    }
    .back-btn svg{
        width:30px;
        height:30px;
    }

    .LidarResearch-header{
        font-size: calc(2vw + 15px);
        font-weight: 700;
        position:relative;
        z-index:2;
    }

    .LidarResearch-topline{
        padding-top: 10px;
        font-size: calc(1vw + 10px);
        font-weight: 400;
    }
    .LidarResearch-top{
       
        font-size: calc(1vw + 5px);
        font-weight: 400;
    }
    


    @media screen and (max-width: 767px){
        padding-right: 8vw;
        padding-left: 8vw;

        .LidarResearch-header{
            font-size: calc(2vw + 20px);
        }
        .LidarResearch-topline{
          
            font-size: calc(1vw + 15px);
           
        }
        .LidarResearch-top{
            font-size: calc(1vw + 10px);
        }
    }
`

export default LidarResearch;


