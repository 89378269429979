import styled from "styled-components";
import { Col } from 'antd'

export const PageNo = styled.div`
    font-weight: 400;
    font-size: calc(1vw + 6px);
    padding: 10px;
    text-align: center;
    cursor: pointer;
    margin-bottom: 4px;

    @media screen and (max-width: 767px){
        font-size: calc(1vw + 10px);
    }
`

export const ActivePageNo = styled.div`
    padding: 10px;
    font-size: calc(1vw + 6px);
    font-weight: 700;
    line-height: 23px;
    text-align: center;
    cursor: pointer;

    @media screen and (max-width: 767px){
        font-size: calc(1vw + 10px);
    }
`

export const PageHighlight = styled.div`
    width: 30px;
    height: 4px;
    left: 1034px;
    top: 1532px;

    background: #0086FF;
    border-radius: 10px 10px 0px 0px;
`

export const HeadCol = styled(Col)`
    font-size: calc(1vw + 6px); 
    font-weight: 400;

    @media screen and (max-width: 767px){
        font-size: calc(1vw + 10px);
        
    }
`