import { Col, Row } from 'antd';
import React from 'react';
import { PageNo, ActivePageNo, PageHighlight, HeadCol } from './styles';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const scrollToTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}

function Pagination({total = 0, pageSize = 0, currentPage = 1, showTotal = false, onPageChange, length = 0}) {
    const noOfPages = pageSize ?  Math.ceil(total / pageSize) : 1;
    let pageNos = [];
    for(let index = 1; index<= noOfPages; index ++){
        pageNos.push(index);
    }

    const incrementPage = e => {
        e.stopPropagation();
        if(currentPage > 1 ){
            onPageChange(currentPage - 1)
        }else{
            return
        }
    }

  return (
    <Row align='space-between' className='column-end'>
        <HeadCol  className='content grey' >
            {showTotal ? <>Showing {length} of {total}</> : ''}
        </HeadCol> 
        <Col span={16}>
            <Row  className='content grey' align='center' style={{justifyContent: 'flex-end'}}>
                <PageNo onClick={(e) => {incrementPage(e); scrollToTop()}}>{<FaChevronLeft />}</PageNo>
                {
                    pageNos.map((item, index) => 
                    currentPage === item ?  
                    <div key={index}>
                        <ActivePageNo className='primary' key={index}>{item}</ActivePageNo>
                        <PageHighlight />
                    </div>
                    :
                    <PageNo key={index} onClick={() => onPageChange(item)}>{item}</PageNo>
                )}
                <PageNo 
                onClick={() => {currentPage < noOfPages ? onPageChange(currentPage + 1) : onPageChange(noOfPages); scrollToTop()}}>{<FaChevronRight />}</PageNo>
            </Row>
        </Col>
    </Row>
  )
}

export default Pagination;
