
import React, { useState } from 'react';
import styled from 'styled-components';
import { Row, Col,Modal} from 'antd';
import {useNavigate} from 'react-router-dom';
import Img1 from './icons/smart-city.svg'
import Img2 from './icons/smart-mobility.svg'
import Img3 from './icons/iot.svg'
import Img4 from './icons/edu-tech.svg';
import Img5 from './icons/smart-health.svg'
import Img6 from './icons/fleet.svg'
import Img7 from './icons/smart-house.svg';
import Img8 from './icons/smart-energy.svg'
import {ReactComponent as DPE} from './tech-banner.svg';
import './styles.css'
import { useEffect } from 'react';
import Slider from 'react-slick';
function TechStack() {
    const [isVisible,setVisible]=useState(false);
    const [cardId ,setCardId]=useState(0);
    const [reset,setReset]=useState(false);
    const length=Obj.length;
    const handleClose = () => {
        setVisible(false);
    };
    const handleOpen = (index) => {
        console.log('hey',index)
        setVisible(true);
        setCardId(index);
    }
    // const settings = {
    //     dots: false,
     
    //    className:'slider-cards',
    //     slidesToShow: 4,
    //     slidesToScroll: 1,
    //     // nextArrow: <SampleNextArrow />,
    //     // prevArrow: <SamplePrevArrow />,
    //      autoplay:true,
    //      autoplaySpeed: 3000,
    //      infinite: true
        
      
    //   };
      const settings = {
        centerMode: true,
        centerPadding: '60px',
        className:'slider-cards',
        autoplay:true,
        autoplaySpeed: 3000,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              arrows: false,
              centerMode: true,
              centerPadding: '40px',
              slidesToShow: 3,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 480,
            settings: {
              arrows: false,
              centerMode: true,
              centerPadding: '100px',
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          }
        ]
      };
    useEffect(() => {
        if(!reset){
         const mount = setInterval(() =>{
            setCardId((prev) => {
                 if(prev == length-1){
                     return prev-(length-1);
                 }
                 else{
                     return prev+1;
                 }
             })
         }, 4000)
 
         return () => clearInterval(mount);
     }
     }, [reset])


    return (
        < TechStackContainer>


        <div className='TechStack-header heading'>Digital <div className='highlight'>Product</div> Engineering</div>
        <br/>
        <p className='content TechStack-top'>We are a team of digital product engineers that specialize in IoT and mobile development. We
deliver cutting-edge solutions that ensure data protection, security, and compliance. We also
provide firmware updates over the air and IoT sensor algorithms for seamless integration. No matter what
industry you're in, we can help you build digital domains of any kind. We're experts at
connecting physical objects to the internet, and we can help you create innovative solutions that
will transform your business.
</p>
        <br/>
        <div>
            <div  style={{}} className='tech-card-wrapper'>

            
        <div  className='tech-cards'>
{Obj?.map((ele,index)=>{
    return <Col  className="team-card">
    <Cards {...ele} index={index} selectedId={cardId} handleOpen={handleOpen} setReset={setReset} reset={reset} />
  </Col>
 
})}
  

</div>
<div>
    {/* <Slider  {...settings}>
    {Obj?.map((ele,index)=>{
    return <Col  className="team-card">
    <Cards {...ele} index={index} selectedId={cardId} handleOpen={handleOpen} setReset={setReset} reset={reset} />
  </Col>
 
})}
    </Slider > */}
      <Slider  
      {...settings}
     
      >
    {Obj?.map((ele,index)=>{
    return <Col  className="team-card">
    <Cards {...ele} index={index} selectedId={cardId} handleOpen={handleOpen} setReset={setReset} reset={reset} />
  </Col>
 
})}
    </Slider >
</div>

<div className='card-modal'  onMouseEnter={() => {setReset((reset)=>!reset)}}
        onMouseLeave={() => setReset((reset)=>!reset)}>
<p className='card-model-heading' >
                     {Obj[cardId].name}
                   </p > 
                   <p className='card-model-content content'>
                   {Obj[cardId].description}
                   </p>
</div>
</div>

            </div>

          
            {/* <Modal
                width={800}
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    translate: '-50% -50%',
                    display:'flex',
                    justifyContent:'center',
                    
                }}
                className='card-modal'
                footer={false} visible={isVisible}  onCancel={handleClose}>
          
                   <p className='card-model-heading' >
                     {Obj[index].name}
                   </p > 
                   <p className='card-model-content content' style={{}}>
                   {Obj[index].description}
                   </p>
        
                </Modal> */}
    
      
           
        </ TechStackContainer>
    )
}



const TechStackContainer = styled.div`
    height: auto;
    background-color: transaprent;
    padding-top: 80px;
    padding-right: 8vw;
    padding-left: 8vw;
    text-align: center;
    

    .TechStack-header{
        font-size: calc(2vw + 15px);
        font-weight: 700;
        position:relative;
        z-index:2;
    }

    .TechStack-topline{
        padding-top: 10px;
        font-size: calc(1vw + 10px);
        font-weight: 400;
    }
    .TechStack-top{
       
        font-size: calc(1vw + 5px);
        font-weight: 400;
    }
    .row2{
        gap:120px;
        display: flex;
  justify-content: center;
  place-items:center;
  margin-top:30px;
    }
  .tech-cards{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 2fr));
    justify-content: center;
    place-items:center;
    gap: 10px;
    width:50%;
  }
  .tech-card-wrapper{
    display:flex;
    flex-direction:row;
    justify-content:center;
    gap:16px;
  }
    
.card-modal{
    width:50%;
    display:flex;
    justify-content:center;
    flex-direction:column;
}
.slider-cards{
    display:none;
}
@media screen and (max-width:2000px){
    .tech-cards{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 2fr));
        justify-content: center;
        place-items:center;
        gap: 10px;
        width:60%;
        max-width:700px;
       
      }
}
@media screen and (max-width: 1212px){
    padding-right: 8vw;
    padding-left: 8vw;

 

    .slider-cards{
      display:block;
     
      height:100px !important;
     
       
    }
    .slick-list,.slick-track{
        height:100px !important;
    }
    .slick-track {
         display:flex;
         flex-direction:row;
         gap:10px;
          justify-content:center;
          align-items:center;
        height:92px !important;
        padding-top:10px;
    }

    .slick-slide{
        width:300px !important;
        height:92px !important;
    }
    .row2{
        margin-top:10px;
        gap:10px;
        display: flex;
        justify-content: center;
        place-items:center;
     
    }
    .tech-cards{
        display: none;
      
      }
      .tech-card-wrapper{
        display:flex;
        flex-direction:column;
        justify-content:center;
        gap:16px;
      }
      .card-modal{
        width:100%;
        display:flex;
        justify-content:center;
        flex-direction:column;
        align-items:center;
    }
}

    @media screen and (max-width: 767px){
        padding-right: 8vw;
        padding-left: 8vw;

        .TechStack-header{
            font-size: calc(2vw + 20px);
        }

        .TechStack-top{
            font-size: calc(1vw + 10px);
        }
        .slider-cards{
          display:block;
         
          height:100px !important;
         
           
        }
        .slick-list,.slick-track{
            height:100px !important;
        }
        .slick-track {
             display:flex;
             flex-direction:row;
             gap:10px;
              justify-content:flex-start;
              align-items:center;
            height:90px !important;
            padding-top:10px;
        }

        .slick-slide{
            width:300px !important;
            height:90px !important;
        }
        .row2{
            margin-top:10px;
            gap:10px;
            display: flex;
            justify-content: center;
            place-items:center;
         
        }
        .tech-cards{
            display: none;
          
          }
          .tech-card-wrapper{
            display:flex;
            flex-direction:column;
            justify-content:center;
            gap:16px;
          }
          .card-modal{
            width:100%;
            display:flex;
            justify-content:center;
            flex-direction:column;
        }
    }
`
export default TechStack;





const Cards = ({ name, img ,handleOpen,index,selectedId,setReset}) => {
    const navigate = useNavigate();
    const scrollToTop = () => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
       
    }
    
    return(
        <CardStyle   style={{backgroundColor:index==selectedId?'rgba(0, 134, 255, 1)':'white', boxShadow: index==selectedId?'0px 0px 12px 0px rgba(0, 0, 0, 0.11)':'0px 0px 6px 0px rgba(0, 0, 0, 0.11)',transform:index==selectedId? 'scale(1.03)':'scale(1)' }} >
           
            <div className='card' onClick={()=>{
            handleOpen(index)
        }}   onMouseEnter={() => {setReset((reset)=>!reset)}}
        onMouseLeave={() => setReset((reset)=>!reset)}>
            <img src={img} alt={name}  className='card-image' />
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',gap:'5px',width:'100%'}}>
            <h1 className='title heading' style={{color:index==selectedId?'white':'black' }}>{name} </h1>
           
            </div>
            
                
                </div>
        </CardStyle>
    )
}

const CardStyle = styled.div`
    width:300px;
    height:92px;
    margin-bottom: 10px;
    transition: all 0.5s ease-out;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:center;
    text-align:left;
  cursor:pointer;
  
    background-color:white;
    border-radius:10px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.11);
    padding:15px 15px;

    .card{
        width:100%;
        height:100%;
        display:flex;
        flex-direction:row;
        align-items: center;
        gap:10px;
      
      
    }
    &:hover  .title {
      color:#fff !important; 
    }
    &:hover  .arrow>path{
        fill:#0086FF;
    }
    
    &:hover{
        transform: scale(1.03); 
        transition: all 0.3s ease-out;
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.11);
        background-color: rgba(0, 134, 255, 1) !important;
    }

    // card-image:hover{
    //     transform: scale(1.02);
    // }

  
    .card-image{
 
        border-radius:10px;
        object-fit:fill;
        padding:10px;
        align-self:center;
        width:78px;
        height:78px;
        background-color:rgba(229, 245, 255, 1);
      
       
      
        transition: all 0.3s ease-out;
      }

    .title{
        text-align: left !important;
        font-size: 18px;
        font-weight: 700;
        float:left;
        width:100%;
     margin-bottom:0px;
    }
  

    .position{
        display: block;
        font-size: calc(1vw + 3px);
        margin-bottom: 10px;
        font-weight: 400;
    }
    .card-button{
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size:  calc(1vw + 3px);
        border-color: #0086FF;
        border-radius: 4px;
        font-weight: 700;
        padding: 2vw 4vw;
      }
   

    @media screen and (max-width: 728px){
        width:260px;
        height:72px;
        .title{
            font-size: calc(1vw + 10px);
        }

        .position{
            font-size: calc(1vw + 8px);
        }
        .card-button{
            font-size:12px;
        }
        .row{
            gap:40px;
        }
        .card-image{
 
         
            width:60px;
            height:60px;
           
          }
    }
`


const Obj = [
  
    {
        name: 'SMART CITY',
     
        img: Img1,
        description:"Transform urban landscapes with brigosha’s smart city solutions. Our comprehensive offerings leverage advanced technologies, data analytics, and connectivity to create sustainable and efficient cities. Experience intelligent infrastructure, optimized resource management, and enhanced citizen experiences with brigosha’s expertise."
      
    },
    {
        name: 'SMART MOBILITY',
     
        img: Img2,
        description:"Revolutionize transportation with brigosha’s smart mobility solutions. From connected vehicles to intelligent traffic management, our innovations optimize mobility, reduce congestion, and enhance safety. Embrace seamless connectivity and transformative mobility experiences with brigosha’s cutting-edge solutions."
     
    },
    {
        name:'IOT',
        img: Img3,
        description:"Connect, control, and unlock the potential of the Internet of Things (IoT) with brigosha. Our IoT solutions empower businesses with real-time data insights, remote monitoring, and predictive analytics. Harness the power of interconnected devices and drive digital transformation with brigosha’s IoT expertise."
       
    },{
        name:'EDU-TECH',
        img:Img4,
        description:"Transform education with brigosha’s edu-tech solutions. Our innovative platforms and tools enhance learning experiences, enable personalized education, and facilitate remote learning. Embrace digital classrooms, interactive content, and data-driven insights for empowering education with brigosha."
    },{
        name:'SMART-HEALTHCARE',
        img:Img5,
        description:"Elevate healthcare services with brigosha’s smart healthcare solutions. From telemedicine to connected medical devices, our technology-driven solutions enhance patient care, improve diagnostics, and streamline healthcare operations. Experience the future of healthcare with brigosha’s smart solutions."
    },{
        name:'FLEET-MANAGEMENT',
        img:Img6,
        description:"Optimize fleet operations with brigosha’s fleet management solutions. Our comprehensive platform offers real-time monitoring, route optimization, and predictive maintenance, enhancing efficiency, reducing costs, and improving overall fleet performance. Trust brigosha for seamless fleet management and smarter logistics."
    },{
        name:'SMART HOME',
        img:Img7,
        description:"Create the ultimate living experience with brigosha’s smart home solutions. Our  intelligent automation and connectivity solutions enable control of appliances, security systems, and  energy management, enhancing comfort, convenience, and energy efficiency. Transform your home   into a smart haven with brigosha."
    },{
        name:'SMART ENERGY',
        img:Img8,
        description:"Embrace sustainable energy practices with brigosha’s smart energy solutions. Our advanced technologies, smart grid solutions, and energy management systems optimize energy consumption, promote renewable energy integration, and empower efficient energy usage. Join the smart energy revolution with brigosha."
    }
    
]










