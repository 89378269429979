import { Button, Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
import styled from "styled-components";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

// export const ColorText1 = styled.div`
//     background: linear-gradient(90deg, #0086FF 17.71%, #0D0EFF 100%);
//     -webkit-background-clip: text;
//     -webkit-text-fill-color: transparent;
//     background-clip: text;
//     text-fill-color: transparent;
// `

export const ColorText2 = styled.span`
    display: block;
    font-weight: 600;
    font-size: calc(1vw + 2px);
    letter-spacing: 0.25rem;
    text-transform: uppercase;

    @media screen and (max-width: 767px){
        // margin-top: 50px;
        font-size: calc(1vw + 10px);
    }
`

// export const ColorText3 = styled.div`
//     background: linear-gradient(90deg, #0DFFEF 5.21%, #0086FF 83.33%);
//     -webkit-background-clip: text;
//     -webkit-text-fill-color: transparent;
//     background-clip: text;
//     text-fill-color: transparent;
//     line-height: 68px;
//     margin:0;
// `

export const Heading = styled.div`
    font-weight: 700;
    font-size: 24px;
    margin-top: 24px;
    width:max-content;

    @media screen and (max-width: 767px){
        font-size: 22px;
       
    }
     @media screen and (max-width: 490px){
        font-size: 20px;
       width:300px;
       word-break:break;
      
    }
`

export const SubHeading = styled.div`
    font-weight: 400;
    font-size: calc(1vw + 5px);
    margin-bottom:24px;

    @media screen and (max-width: 767px){
        font-size: calc(1vw + 10px);
    }
`

export const FormInput = styled(Input)`
    font-size: calc(1vw + 3px);
    padding: 1vw 1vw;
    width: 100%;
    height:38px;
    background:#f5f5f5;
    &:focus{
        background:#fff; 
    }

    @media screen and (max-width: 767px){
        font-size: calc(1vw + 8px);
        padding: 2vw 2vw;
    }
`

export const PhoneInputArea = styled(PhoneInput)`
&:focus{
    background: #fff;
}
    &.react-tel-input .form-control {
        position: relative;
        font-size: 16px;
        letter-spacing: .01rem;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        padding: 1vw 1vw !important;
        padding-left: 48px !important;
        margin-left: 0;
        background: #f5f5f5;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        /* line-height: 25px; */
        /* height: 4vw; */
        width: 100%;
        outline: none;
        height:50px;

        @media screen and (max-width: 767px){
            font-size: calc(1vw + 8px);
            padding: 2vw 2vw;
        }
    }
`

export const ContactImg = styled.img`
    width: 90%;
    @media screen and (min-width: 2000px){
        width: 80%;
    }
`

export const FormInputArea = styled(TextArea)`
    resize: none;
    font-size: calc(1vw + 3px); 
    padding: 1vw 1vw;
    background:#f5f5f5; 
    &:foucs{
        background:#fff;
    }
    
    
    @media screen and (max-width: 767px){
        font-size: calc(1vw + 8px);
        padding: 2vw 2vw;
    }
`

export const SubmitButton = styled(Button)`
    // width: 100%;   
    // padding: 2vw 6vw;
    // font-size: calc(1vw + 3px); 
    // background: transparent;
    // font-weight: 700;
    // border: 1px solid #0086FF;
    // display: flex;
    // justify-content: center;
    // align-items: center;

    // @media screen and (max-width: 767px){
    //     font-size: calc(1vw + 8px);
    // }
    width: 100%;    
    height: 40px;
    margin:0 auto;
    background-color: #0086FF !important;
    color:white !important;
    font-weight: 700;
    font-size: 16px; 
    padding: 10px;
    float:right;
    border: 1px solid #0086FF;
    display: flex;
    justify-content: center;
    align-items: center;
&:hover{
  transform: scale(1.02);
  transition: all 0.1s ease-out;
  background: #0086ff;
  color: #fff ;
  box-shadow: 0 20px 5px -10px rgba(0, 0, 0, 0.4);
  transform: translateY(1px);
}
    @media screen and (max-width: 767px){
        font-size: calc(1vw + 6px);
        padding: 2vw 2vw;
        width: 100%;
    }

    @media screen and (max-width: 575px){
        font-size: calc(1vw + 8px);
        padding: 2vw 2vw;
      
    }
`

export const AddressBody = styled.div`
font-size: 20px;
    font-weight: 600;
     width: 400px ;
    
    height:160px;
    color:#565656;
    padding: 20px 20px;
    // background-color:#F3F9FF;
    background-color:rgba(255, 255, 255, 1);
    border-radius:10px;
   box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.11);
   transition: all 0.3s ease-out;
   &:hover{
    transform: scale(1.02); 
    transition: all 0.3s ease-out;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.11);
   }
   img{
    width:20px;
    height:20px;
   } 
   p{
    font-size: 18px;
   }
    .text{
        display:block;
       margin-top:-10px;
        width:100%;
        font-size: 16px;
         word-wrap: break-word !important;
         font-weight: 600;
     }
    

    @media screen and (max-width: 767px){
        font-size: 16px;
        width: 100%;
        width: 280px;
        height:140px;
        p{
            font-size: 16px;
           }
        .text{
            display:block;
            margin-top:-10px;
            width:100%;
            font-size: 12px;
             word-wrap: break-word !important;
         }
    }
`