import React,{useEffect} from 'react';
import styled from 'styled-components';
import pen from './pen.svg'
import { Row, Col, Modal, Spin } from 'antd';
import {useNavigate} from 'react-router-dom';
import { useState } from 'react';
import Api from '../../../../../services/Api'
import NoBlog from './noBlogs.gif'
import { LoadingOutlined,UserOutlined  } from '@ant-design/icons';
import shareImg from './share.svg'
import linkedIn from './linkedIn.svg'
import twitter from './twitter.svg'
import   {   ViewMoreButton} from '../../joinUs/styles'
import bg2 from '../../ourStory/ourCulture/BG.svg'
import { ReactComponent as Back } from '../../../../../assets/back-icon.svg';
import { connect } from 'react-redux';
import { getAllBlogs } from '../../../redux/action';
import BrigoshaLogo from '../../../../../assets/logo.svg'

function Blogs({getAllBlogs,blogs}) {
  
    const [loader,setLoader]=useState(false);
    const [share,setShare]=useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);


    const handleBackBtn=()=>{
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        window.history.back();
       
    }
    useEffect(()=>{
    
       

        
    if(!blogs||blogs.length==0){
        setLoader(true)
        }
    getAllBlogs(()=>{
     
        setLoader(false)
    })
    },[])
    useEffect(()=>{
      share!==''?setIsModalOpen(true):setIsModalOpen(false)
    },[share])
    const navigate = useNavigate();


    const handleCancel = () => {
        setIsModalOpen(false);
        setShare('');
      };
    
   
          


         
     

const Obj=[];

let bannerBlog={};
blogs?.forEach((ele,index)=>{
   
    if(index<9){
   Obj.push( {
        id:ele.id,
        name:ele.title,
        tag:ele.tag,
        img:ele.locationPath,
           pic:ele?.userProfile,
           date:ele.updatedAt,
           user:ele.userName,
           views:ele.totalViews,
           index:index,
           privilege:ele?.privilege

    })
    }

    if(index==blogs.length-1){
        bannerBlog =Obj?.[  Math.floor(Math.random()*Obj?.length)];
    }
    
})




const copyLink=()=> {
    var copyText = document.getElementById("link");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyText.value);
    
    var tooltip = document.getElementById("Tooltip");
    tooltip.innerHTML = "Copied: " + copyText.value;
  }
  
  const removeLink=()=>  {
    var tooltip = document.getElementById("Tooltip");
    tooltip.innerHTML = "Copy to clipboard";
  } 





    return (
        <div   style={{
            background:`url(${bg2}) center no-repeat`,backgroundSize:'cover'
        
            }}>
        
        <BlogContainer>
   
        <div style={{'display':'flex',gap:'10px',justifyContent:'space-between',alignItems:'center' , height: '48px',marginBottom:'10px' ,width:'100%'}} >
            
       
       
           <Back className='back-btn' onClick={handleBackBtn} />
        
        <a href='https://login.brigosha.com' style={{width:'110px'}} target="_blank" rel="noopener noreferrer">
                    <img  alt="brigosha" src={pen}/>
                    <span style={{paddingLeft:'10px'}}>Write Blog</span></a>
                </div>
           
                    
            
          
           {loader?
           
           
           
           <div  style={{'minHeight':'280px','display':'flex',justifyContent:'center','alignItems':'center'}}>
           
           <LoadingOutlined style={{fontSize: "3em"}}/>
                </div> :
            Obj?.length==0?<div style={{'display':'flex','flexDirection':'column',justifyContent:'center',alignItems:'center',height:'-webkit-fill-available'}}> 
                <img alt='Data Management Solutions'  src={NoBlog} width={250} height={250}/>
                <span><b>Uh!Uh! No Blogs Found!</b></span>
                <span><b>But you can find your way back by clicking on the homepage</b></span>
            </div>:<>
            <div style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
< div className='bannerBlog'  style={{'height':'600px',width:'70vw',backgroundColor:'#fff',borderRadius:'12px',position:'relative',display:'flex',justifyContent:'center',cursor:'pointer'}}   onClick={()=>{
                    document.body.scrollTop = 0; // For Safari
                    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                    navigate(`/our-story/blog/blogId=${bannerBlog?.id}`)
                   }}> 
                   
                   <img src={bannerBlog?.img} alt='Video Transcoding' style={{'height':'100%',width:'100%',borderRadius:'5px',objectFit: 'cover'}}/>
                   <div style={{position:'absolute',left:0,bottom:0,display:'flex',flexDirection:'column',textAlign:'left',padding:'30px',  background: "linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .6) 100%)",width:'100%',borderRadius:'5px'}}  className='bannerblog-content'>
          
                    <div style={{display:'flex',flexDirection:'row',gap:'10px',overflow:'hidden',maxWidth:'500px',height:'32px',flexWrap:'wrap'}} className='blog-tags'>
                {
                    bannerBlog?.tag?.map((tag,index)=>{
                        return          index<3?  <span className='tag' style={{'color':'#fff',fontSize:'14px',lineHeight:'24px',backgroundColor:'rgba(75, 107, 251, 1)',width:'auto',height:'28px',borderRadius:'3px',textAlign:'center',marginBottom:'10px',fontWeight:'500',display:'inline',padding:'0px 5px'}}>{tag}</span>:null

                    })
                }
                 {
                   bannerBlog?.tag?.length>3?
                   <span style={{fontSize:'14px',lineHeight:'24px',display:'inline',padding:'0px 5px',height:'20px',textAlign:'center',marginBottom:'10px',fontWeight:'500',marginTop:'5px'}}>+{ bannerBlog?.tag?.length-3}</span>
                   : null
                }
           
           </div>
                    <span style={{'color':'#fff',fontSize:'36px',lineHeight:'40px',maxWidth:'720px',fontWeight:'600',cursor:'pointer'}}   className='blog-title'>{bannerBlog?.name}</span>
                   </div>
                   
                   
                   </div>
</div>
<div style={{'display':'flex','alignItems':'center','justifyContent':'center',position:'relative','marginBottom':'88px','marginTop':'103px'}}>
         
            {/* <div className='AllEvents-header heading'> <div className='gradient7' style={{'display':'flex','alignItems':'center'}}>Blogs<div className='polygon5' style={{'top':'auto'}}></div></div></div>
               */}
            <div className='AllEvents-header heading'> <div className='highlight' >Blogs</div></div>
          
            </div>


            <div style={{width:'100%',display:'flex',flexDirection:'column'}}>
            <span style={{'color':'rgba(24, 26, 42, 1)',fontSize:'24px',lineHeight:'28px',textAlign:'left',marginBottom:'32px',fontWeight:'700'}}>Our Latest Blogs</span>
          
          
            <Row  style={{'display':'grid','gridTemplateColumns':'repeat(auto-fill, minmax(396px, 1fr))',gap:'10px','gridRowGap':'30px',placeItems:'center'}}  className='blog-cards'>
           
            {Obj?.map((ele,key)=>{
       return key<9? <Col  className='team-card' id={key} >
       <Card {...Obj[key]} setShare={setShare}  />
      </Col>:null
         })}
                
            </Row>
            </div>
            {Obj?.length>=9?
            <Row align='center' style={{marginTop: 20, marginBottom: 80}}>
            <ViewMoreButton 
            className=' card-button' 
            onClick={() => {
                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                navigate('/our-story/allBlogs')
            }}
            >
                View More
            </ViewMoreButton>
        </Row>
            :null}
               
            </>
           }
        
        </BlogContainer>
        <Modal  visible={isModalOpen} footer={null} closable={true} centered onCancel={handleCancel} style={{'zIndex':'100'}}   bodyStyle={{
        backgroundColor: '#F5F5F5'
    }}  destroyOnClose={true} >
         
         <ModelDiv>
            <div   style={{ backgroundColor: '#FFF',padding:'20px'}} >
         <div style={{'display':'flex','alignItems':'center','justifyContent':'center',position:'relative','marginBottom':'30px',gap:'10px',}}>
           <div className='event-head' style={{'width':'60vw'}}>
             
       
            <h1 className='title heading'  style={{color:'#000',fontSize:'26px',lineHeight:'40px',fontWeight:'600'}}>{Obj?.[share]?.name}</h1>
          
          

            <div style={{'display':'flex',flexDirection:'column',justifyContent:'flex-start'}}>
               <div style={{'display':'flex',alignItems:'center',gap:'10px',flexDirection:'row',marginBottom:'10px'}}>
             

{Obj?.[share]?.pic && (Obj?.[share]?.privilege=='Blogger')?<img src={Obj?.[share]?.pic} alt='Product development process' style={{'width':'36px',height:'36px',borderRadius:'28px'}}/> :
               <img src={BrigoshaLogo} alt='IT Strategy Consulting' style={{'width':'36px',height:'36px',borderRadius:'28px'}}></img>
                }
                {/* <span>{Obj?.[share]?.user   }</span> */}
                <span>{Obj?.[share]?.privilege=='SuperAdmin' || Obj?.[share]?.privilege=='Admin'||!Obj?.[share]?.privilege ?"Brigosha Team":Obj?.[share]?.user}</span>
                <div>
                {Obj?.[share]?.date}
               </div>
               </div>
             
               <div style={{display:'flex',flexDirection:'row',gap:'10px',overflow:'scroll hidden'}} className='blog-tags'>
                {
                    Obj?.[share]?.tag?.map((tag,index)=>{
                        return       index<3 ? <span style={{'color':'#fff',fontSize:'14px',lineHeight:'24px',backgroundColor:'rgba(75, 107, 251, 1)',display:'inline',padding:'0px 5px',height:'28px',borderRadius:'3px',textAlign:'center',marginBottom:'10px',fontWeight:'500'}}>{tag}</span>:null

                    })
                }
                 {
                  Obj?.[share]?.tag?.length>3?
                   <span style={{fontSize:'12px',lineHeight:'20px',display:'inline',padding:'0px 5px',height:'20px',textAlign:'center',marginBottom:'10px',fontWeight:'500',marginTop:'5px'}}>+{Obj?.[share]?.tag?.length-3}</span>
                   : null
                }


           
           </div>
         </div>
            </div>
           </div>
           <div style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center',}}>
          
                   
           <div  style={{'height':'250px',width:'60vw',backgroundColor:'#fff',borderRadius:'12px',position:'relative',display:'flex',justifyContent:'center'}}> 
         
                    {/* <div style={{width:'26.5px',height:'26.5px', position:'absolute',right:10,top:20,background:'rgb(0,0,0,0.6)'}}>
                      <img  src={shareImg}  style={{width:'26.5px',height:'26.5px'}}/>
                    </div> */}
                    <img  alt='Outsourced IT Services' src={Obj?.[share]?.img} style={{'height':'250px',width:'100%',borderRadius:'5px',objectFit: 'contain'}}/>
                 
              
                </div>
                   
          </div>
          </div>
         <div style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center',textAlign:'center',flexDirection:'column'}}>
                    <h1>Share this with your social community</h1>
                    <div style={{width:'100%',display:'flex',justifyContent:'space-around',alignItems:'center'}}>
                  
                        <a href={` https://www.linkedin.com/shareArticle?mini=true&url=${encodeURI(`http://new.brigosha.com/our-story/blog/blogId=${Obj?.[share]?.id}`)}&title=${encodeURI(Obj?.[share]?.name)}&source=LinkedIn`}>      <img src={linkedIn} style={{width:'30px',height:'30px',cursor:'pointer'}}/></a>
                       
                        <a href={`https://twitter.com/intent/tweet?text=${encodeURI(Obj?.[share]?.name)}&url=${encodeURI(`http://new.brigosha.com/our-story/blog/blogId=${Obj?.[share]?.id}`)}`}  > <img src={twitter}   style={{width:'30px',height:'30px',cursor:'pointer'}}/></a>

                    </div>
                  
           </div>
        
          
        <div style={{width:'100%',padding:'10px',display:'flex',flexDirection:'column',justifyContent:'center ',alignItems:'center'}}>
                        
        <div style={{width:'95%',display:'flex',justifyContent:'space-between',alignItems:'center',height:"50px"}}>
        <span style={{color:'rgba(173, 173, 173, 1)',fontWeight:'700'}}>or copy link</span>      
                            </div>
                        <div style={{width:'95%',display:'flex',justifyContent:'space-between',alignItems:'center',height:"50px",background:'rgba(238, 238, 238, 1)',padding:'3px 16px'}}>
                             <input style={{'color':'rgba(173, 173, 173, 1)',border:'none',background:'rgba(238, 238, 238, 1)',width:'80%',padding:'3px', outline: 'none'}} id='link' value={`http://new.brigosha.com/our-story/blog/blogId=${ Obj?.[share]?.id}`} />
                           
                            <div class="tooltip">
<button onClick={copyLink} onMouseOut={removeLink} style={{'color':'#002546',border:'none',fontWeight:'700',cursor:'pointer'}}>
  <span class="tooltiptext" id="Tooltip">Copy to clipboard</span>
  Copy Link
  </button>
</div>
                        </div>
                    </div>
         </ModelDiv>
   </Modal>
        </div>
 


)
}

const Card = ({ name, img ,tag,user,pic,date,id,setShare,views,index,privilege=''}) => {

    const navigate = useNavigate();
    const handleShare=(event)=>{
        event.stopPropagation();
       
        const link=`http://new.brigosha.com/our-story/blog/blogId=${id}`;
       
       setShare(index);
    }
    return(
    
        <CardStyle  key={index} className='blog-card' >
            <div className='card-wrapper' onClick={()=>{  document.body.scrollTop = 0; // For Safari
       document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
       navigate(`/our-story/blog/blogId=${id}`)}} >
            
            <div style={{display:'flex',flexDirection:'column',width:'100%'}} >
                <div style={{width:'100%',maxHeight:'241px',position:'relative',display:'flex',justifyContent:'center'}}>
                  
                <img src={img} alt='img'  style={{objectFit: 'cover',minHeight:'100%'}} className='blogImg'/>
                {/* <div className="view_blog" style={{width:'50px',height:'26.5px',position:'absolute',right:10,bottom:20,display:'flex',alignItems:'center',gap:'5px',alignItems:'center',gap:'5px',background:'rgb(0,0,0,0.6)',justifyContent:'center'}}>
                      <img  src={eye}  style={{width:'17.22px',height:'11.47px'}}/>
                      <span style={{color:'#fff',fontSize:'11px'}}>{views}</span>
                    </div> */}
                </div>
         <div className='info'>
            
            <div className='event-head'>
                <div style={{display:'flex',flexDirection:'row',gap:'10px',overflow:'hidden',height:'30px',width:'100%',alignItems:'center',flexWrap:'wrap'}} className='blog-tags'>
                {
                    tag?.map((tag,index)=>{
                        if(index<3)
                       return <span style={{'color':'rgba(75, 107, 251, 1)',fontSize:'12px',lineHeight:'20px',backgroundColor:'rgba(75, 107, 251, 0.05)',display:'inline',padding:'0px 5px',height:'20px',borderRadius:'3px',textAlign:'center',marginBottom:'10px',fontWeight:'500',marginTop:'5px'}}>{tag}</span>
                  
                    })
                }
                {
                   tag?.length>3?
                   <span style={{fontSize:'12px',lineHeight:'20px',display:'inline',padding:'0px 5px',height:'20px',textAlign:'center',marginBottom:'10px',fontWeight:'500',marginTop:'5px'}}>+{tag?.length-2}</span>
                   : null
                }
                
           
           </div>
            <h1 className='title heading'  style={{color:'#000',cursor:'pointer',marginTop:'10px' }} >{name}</h1>
            </div>
          

            <div style={{'display':'flex',justifyContent:'space-between',alignItems:'center'}} className='blogcard-footer'>
               <div style={{'display':'flex',justifyContent:'flex-start',alignItems:'center',gap:'6px',flexDirection:'row',flex:1}}>
                
               {/* {pic?   <img src={pic} style={{'width':'36px',height:'36px',borderRadius:'28px'}}/>:
                 <UserOutlined/>
                } */}
                {pic && privilege=='Blogger'?<img alt='mapping solutions' src={pic} style={{'width':'36px',height:'36px',borderRadius:'28px'}}/> :
               <img src={BrigoshaLogo} alt='mobility' style={{'width':'36px',height:'36px',borderRadius:'28px'}}></img>}
                 
              <div style={{display:'flex',flexDirection:'column',alignItems:'flex-start'}}>
           
                <span>{privilege=='SuperAdmin' || privilege=='Admin' || !privilege?"Brigosha Team":user}</span>
                <div>
               
                <span>{date}</span>
                <span> | </span>
                <span>{views} Views</span>
                </div>
                </div>
              
            
               </div>
               <div className='share_blog' style={{display:'flex',justifyContent:'flex-end',}}>
                      <img  src={shareImg} alt='our culture' style={{width:'26.5px',height:'26.5px',cursor:'pointer'}} onClick={(e)=>handleShare(e)} />
                    </div>
               <div>
             
               </div>
            </div>
            </div>
            </div>
            </div>
        </CardStyle>
    )
}


const CardStyle = styled.div`
  
    margin-bottom: 10px;
    transition: transform 0.5s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: scale(1);
  

   
   .card-wrapper{
   
    
    cursor:pointer;
    background-color: #fff;
    justify-content:center;
    padding: 20px;
    position: relative;
    margin:0 auto 30px;
    display:flex;
    justify-content:center;
  
    border-radius:12px;
    border:1px solid #E8E8EA;
    max-height:420px;
   z-index:1;
   
  
   }
   .blog-tags::-webkit-scrollbar {
    height: 3px !important; /* width of the entire scrollbar */
    width:3px !important;
  }
  .blog-tags::-webkit-scrollbar-thumb {
    background-color: grey; /* color of the scroll thumb */
    border-radius: 20px; /*roundness of the scroll thumb*/
    /* border: 1px solid #B5DBE8;  creates padding around scroll thumb */
  }

   .glass-button {
    display: inline-block;
    padding: 12px 22px;
    height:50px;
    border: 0;
    text-decoration: none;
    border-radius: 8px;
    background-color: rgba(255,255,255,0.1);
    border: 1px solid rgba(255,255,255,0.1);
    backdrop-filter: blur(30px);
    color:#fff;
    font-size: 16px;
    letter-spacing: 2px;
    cursor: pointer;
    text-transform: uppercase;
  }
  
  .glass-button:hover {
    background-color: rgba(255,255,255,0.2);
  }
   .event-head {
    width:360px;
   display:flex;
   flex-direction:column;
   justify-content:center;
   align-items:flex-start;
    text-align: center;
    opacity:1;
    padding: 6px;
  
  }
    &:hover{
        transform: scale(1.02);
    }

    .blogImg{
        min-height:241px;
        height:241px;
        width:360px;
        border-radius:8px;
        
       
    }
   

    .title{
        max-height:50px;
        font-size: 16px;
        font-weight: 600;
        color: #FFFFFF;
        text-align:left;
        display:flex;
        align-items:center;
      
        margin-bottom:0px !important;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .position{
        display: block;
        font-size: calc(1vw + 3px);
        margin-bottom: 10px;
        font-weight: 400;
    }

    @media screen and (max-width: 770px){
        .card-wrapper{
            padding:5px;
        }
        .event-head{
            width:100%;
        }
        .title{
            font-size: calc(1vw + 6px);
            width:100%;
            max-height:40px;
        }

        .position{
            font-size: calc(1vw + 8px);
        }
        .info{
         margin-top:10px;
        }
        .blogImg{
         max-height:140px;
            min-height:117px;
            width:195px;
            margin-top:8px;
           
        }
        .blogcard-footer{
            font-size:12px;
            padding:5px;
        }
        .blogcard-footer img{
            width:20px !important;
            height:20px !important;
        }
    }
   
`



const BlogContainer = styled.div`
    height: auto;
    min-height:max-content;
    // background-color: #f3f9ff;
    background-color:transparent;
    padding-top: 40px;
    padding-right: 8vw;
    padding-left: 8vw;
    text-align: center;
    padding-bottom: 80px;
    .back-btn {
        position:relative;
     cursor:pointer;
      
       
    }
    .blog-tags::-webkit-scrollbar {
        height: 3px !important; /* width of the entire scrollbar */
        width:3px !important;
      }
      .blog-tags::-webkit-scrollbar-thumb {
        background-color: grey; /* color of the scroll thumb */
        border-radius: 20px; /*roundness of the scroll thumb*/
        /* border: 1px solid #B5DBE8;  creates padding around scroll thumb */
      }
    
    .back-btn svg{
        width:30px;
        height:30px;
    }
    .bold{
        font-weight: 700;
        color: black;
    }
    .team-image-topline{
        letter-spacing: 0.25rem;
        text-transform: uppercase;
        font-weight: 600;
        text-align:left;
        font-size: calc(1vw + 3px);
    }
    .AllEvents-header{
        font-size: calc(2vw + 15px);
        font-weight: 700;
        position:relative;
        z-index:2;
    }

    .expertise-top{
        padding-top: 20px;
        font-size: calc(1vw + 5px);
        font-weight: 400;
    }

    @media screen and (max-width: 767px){
        padding-right: 8vw;
        padding-left: 8vw;
       .blog-cards{
        grid-template-columns: repeat(auto-fill, minmax(220px, 1fr)) !important;
        gap: 0px 10px !important;
       }
       .back-btn{
        margin-top:0;
        margin-left:0;
       }
        .expertise-header{
            font-size: calc(2vw + 20px);
        }
        .bannerblog-content{
            padding:10px !important;
        }
        .tag{
            height:26px !important;
        }
        .expertise-top{
            font-size: calc(1vw + 10px);
        }
        .blog-title{
            font-size: calc(1vw + 10px);
            line-height:26px !important;
            overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        }
    }
`

const ModelDiv = styled.div`
   
   max-height:722px;
  min-height:300px;
  max-width:943px;


  background:#F5F5F5;
   
  .title{
    height:40px;
    font-size: 14px;
    font-weight: 600;
  
    margin-bottom:10px !important;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.blog-tags::-webkit-scrollbar {
    height: 5px !important; /* width of the entire scrollbar */
    width:3px !important;
  }
  .blog-tags::-webkit-scrollbar-thumb {
    background-color: grey; /* color of the scroll thumb */
    border-radius: 20px; /*roundness of the scroll thumb*/
    /* border: 1px solid #B5DBE8;  creates padding around scroll thumb */
  }


    
 @media  screen and (max-width: 950px){

    min-height:300px;
    max-height:max-content;
  
    

}
`
const mapStateToProps = state => {
    const {
        blogs
    }  = state.we;
  
    return {blogs}
  }
  
  const mapDispatchToProps = dispatch =>  ({
    getAllBlogs: (successCallback) => dispatch(getAllBlogs(successCallback)),
   
  })
  
  export default connect(mapStateToProps, mapDispatchToProps)(Blogs)
  



