import React, { useEffect } from 'react';
import { useState } from 'react';
import { Row, Col, Spin } from 'antd';
import 'antd/dist/antd.min.css';
import styled from 'styled-components';
import { Button, Modal } from 'antd';
import HTMLFlipBook from "react-pageflip";
import { pdfjs, Document, Page as ReactPdfPage } from "react-pdf";
import './emagazineStyle.css'
import { Carousel } from '3d-react-carousal';
import {  DownloadOutlined, EyeOutlined, FullscreenOutlined } from '@ant-design/icons';
import back from './back.svg'
import prev from './prev.svg'
import { connect } from 'react-redux';
import { LoadingOutlined ,CloudDownloadOutlined} from '@ant-design/icons';
import { getAllMagazines} from '../../../redux/action';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import Carousel1 from './carousel';




function Magazine({magazines,getAllMagazines}) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [btnModal, setbtnModal] = useState(false);
    const [fullscreen, setFullscreen] = useState(false);
    const [magazineId, setMagazineId] = useState();
    const [loader, setLoader] = useState(false);
    const [pageNum, setPageNum] = useState();
    const [num, setNum] = useState(1);

    const CARDS = 10;


    const Page = React.forwardRef(({ pageNumber }, ref) => {
        return (
            <div ref={ref}>
                <ReactPdfPage pageNumber={pageNumber} width={num == 1 ? 350 : 580} />
            </div>
        );
    });
    const PageCover = React.forwardRef((props, ref) => {
        return (
            <div className="page page-cover" ref={ref} data-density="hard">
                <div className="page-content">
                    <h2>{props.children}</h2>
                </div>
            </div>
        );
    });
    const handleFullScreenOpen = () => {
        setFullscreen(false);
    }
    const handleFullScreenCancel = () => {
        setFullscreen(false);
        console.log('close');
        setNum(1);
    }
    const openFullScreenModal = () => {
        console.log("hello");
        setFullscreen(true);
        setIsModalOpen(false);
        setNum(0);
      
    }
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const buttonOpen = () => {
        setbtnModal(true);
    };
    const handleOpen = () => {
        setbtnModal(false);
    }
    const handleCancelModal = () => {
        setbtnModal(false);
    }
  
    let slides = magazines?.map((ele, key) => {
        return <div>
            <div >
                <div onClick={() => {
                    setMagazineId(key);
                    setIsModalOpen(true);
                    console.log(magazines?.[key]?.emagazine)
                }} style={{ 'display': 'flex', flexDirection: 'row', overflow: 'hidden', justifyContent: 'center' }}>
                    <img src={ele.coverImage} alt='img' className='card-image' style={{ 'backgroundImage': `url(${ele.coverImage})` }} />
                </div>
            </div>
        </div>
    })
    const onDocumentLoadSuccess = ({ numPages }) => {
        // console.log(numPages)
        setPageNum(numPages)
    }
    useEffect(() => {
    
        if(!magazines||magazines?.length==0){
            setLoader(true)
            }
        getAllMagazines(()=>{
            setLoader(false)
        })
    }, [])
    return (
        <MagazineContainer>
            {/* <div className='magazine-header heading'>E- <div className='gradient4'>Magazine<div className='polygon4' ></div></div></div> */}

            <div className='magazine-header heading'>E- <span className='highlight' > Magazine</span> </div>
            <br />
      
    
             <CardStyle>
             <div className='magazine-preview'>
                    {loader ? <div  style={{'minHeight':'280px','display':'flex',justifyContent:'center','alignItems':'center',flexDirection:'column'}}>
                    <LoadingOutlined style={{fontSize: "3em"}}/> 
                     
                    </div> : slides?.length==0? 
                    <div style={{'minHeight':'100px','display':'flex',justifyContent:'center','alignItems':'center',flexDirection:'column',fontSize:'16px'}}> 
                   
                    <span><b>Uh!Uh! No Magazines Found!</b></span>
                    
                </div>:
                <>
                  <Carousel1>
                      {
                        magazines?.map((ele,key)=>{
                         return <Card ele={ele} id={key} setIsModalOpen={setIsModalOpen} setMagazineId={setMagazineId} />
    
                        
                        })
                      }
                       
                      
                   </Carousel1>
    
                        {/* <Carousel slides={slides} autoplay={true} interval={3000} /> */}
                       {/* <ThreeDCarousel /> */}
                        </>
                    }
                </div>
            <div  style={{minWidth:'300px',maxWidth:'700px',display:'flex',flexDirection:'column',textAlign:'left',flex:1}}>
                <h1 className='card-heading heading' >{Obj.headline}</h1>
                <p className='card-content content'>{Obj.description}</p>
                {slides?.length!==0?
                 <div
                 className='button-holder'
                 style={{ justifyContent: 'flex-start' }}
             >
                 <Button
                     type={Obj.btnType}
                     className='card-button'
                     onClick={buttonOpen}
                     style={{
                         color: '#0086FF',
                         backgroundColor:  'transparent'
                     }}
                 >
                     {Obj.buttonLabel}
                     <CloudDownloadOutlined  className='card-download' style={{height:'20px',width:'20px',alignItems:'center',display:'flex'}}/>
                 </Button>
                 {/* </Link> */}
             </div>:null
                }
               
            </div>
           
            <Modal className='emagazineModal'
                footer={false}
                width={750}
                style={{
                    position: 'fixed',
                    top: '50vh',
                    height: "530px",
                    left: '50vw',
                    translate: '-50% -50%',
                }}
                visible={isModalOpen} onOk={handleOk} onCancel={handleCancel}
            >
                <div>
                    <div className="frontmagazine" style={{ position: 'fixed', top: '200px', left: '80px', font: 'normal normal bold 20px/25px Poppins',color:"white" }}>Click on the page <p>to open the magazine</p></div>
                    {/* <div className="backmagazine" style={{position:'fixed', top:'200px',left:'480px', font:'normal normal bold 30px/35px Poppins'}}>Thank You<p>for reading<p>our magazine</p></p></div> */}
                  
                    <div className="viewFullScreen" style={{ position: 'fixed', right:'50%', bottom:'-15px', fontFamily: 'poppins', fontWeight: '600',fontSize:'20px !important' }} onClick={openFullScreenModal} ><FullscreenOutlined /> </div>
                    <div className='flipbookConatiner' >
                        <Document
                            file={{
                                url: magazines?.[magazineId]?.emagazine
                            }}
                            onLoadSuccess={onDocumentLoadSuccess}
                        >
                            <HTMLFlipBook
                                className="Flipbookcontent"
                                width={num == 1 ? 350 : 580} height={num == 1 ? 500 : 650}
                                showCover={true}>
                                {Array.from(Array(pageNum).keys()).map(i => {
                                    if (i === 0) {
                                        return <PageCover><Page pageNumber={i + 1} /> </PageCover>
                                    } else if (i == pageNum - 1) {
                                        return <PageCover>
                                            <Page pageNumber={i + 1} />
                                        </PageCover>
                                    } else {
                                        return <Page pageNumber={i + 1} />
                                    }
                                })}
                            </HTMLFlipBook>
                        </Document>
                    </div>
                </div>
            </Modal>
            <div>
                <Modal className='ModalFullview'
                    maskClosable={false}
                    width={'100vw'}
                    bodyStyle={{ height: '100vh',overflowY:"auto",overflowX:'auto' ,background: 'linear-gradient(45deg, #0086FF, #fff) ' }}
                    style={{
                       top: "1%",
                    }}
                    footer={false}
                    visible={fullscreen} onOk={handleFullScreenOpen} onCancel={handleFullScreenCancel}>
                    <div>
                        <div className="frontmagazine" style={{ position: "absolute", top:"300px",left: '200px', font: ' bold 25px/30px Poppins',color:"white", }}>Click on the page <p>to open the magazine</p></div>
                        <Document
                            file={{
                                url: magazines?.[magazineId]?.emagazine
                            }}
                            onLoadSuccess={onDocumentLoadSuccess}
                        >
                            <HTMLFlipBook  className="FlipbookcontentFullView"
                                style={{ right:0,marginTop:'-20px',height:"fit-content !important", background: 'linear-gradient(45deg, #0086FF, #fff) '}}
                               
                                width={num == 1 ? 350 : (window.innerWidth-100)/2} 
                                height={num == 1 ? 500 : (window.innerHeight)}
                                showCover={true}>
                                {Array.from(Array(pageNum).keys()).map(i => {
                                    if (i === 0) {
                                        return <PageCover><Page pageNumber={i + 1} /> </PageCover>
                                    } else if (i == pageNum - 1) {
                                        return <PageCover>
                                            <Page pageNumber={i + 1} />
                                        </PageCover>
                                    } else {
                                        return <Page pageNumber={i + 1} />
                                    }
                                })}
                            </HTMLFlipBook>
                        </Document>
                    </div>
                </Modal>
            </div>
            <Modal
                width={800}
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    translate: '-50% -50%',
                }}
                footer={false} visible={btnModal} onOk={handleOpen} onCancel={handleCancelModal}>
                <div className='emagazineMainContainer' >
                    <div>
                        <h2 style={{textAlign:'center'}}>Download Our E-Magazine</h2>
                        <div className="emagazineWraper">
                        {loader ? <div  style={{'minHeight':'280px','display':'flex',justifyContent:'center','alignItems':'center',flexDirection:'column'}}>
                <LoadingOutlined style={{fontSize: "3em"}}/> 
                 
                </div> :
                magazines?.length==0? 
                <div style={{'minHeight':'280px','display':'flex',justifyContent:'center','alignItems':'center',flexDirection:'column',fontSize:'16px'}}> 
               
                <span><b> No Magazines Found!!!</b></span>
                
            </div>:



                    magazines?.map((ele, key) => {
                                return (
                                    < div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:"center"}}>
                                    <div className='magazinecard'>
                                        <img  src={ele.coverImage} />
                                    
                                        <div className='info'>
                                           
                                            <div className='buttonContainer' style={{ display: "flex", justifyContent: "space-around", gap: "60px", borderRadius: "1rem", }}>

                                                <a href={magazines?.[key]?.emagazine} target="_blank" download="brigosha_magazine.pdf" className='view-pdf'>
                                                    <EyeOutlined style={{ color: "black", backgroundColor: "whitesmoke", borderRadius: "100%", width: "25px", height: "25px", paddingTop: "5px" }} />
                                                </a>
                                                <button style={{ color: "black", backgroundColor: "whitesmoke" }} onClick={() => {
                                                    fetch(magazines?.[key]?.emagazine).then(response => {
                                                        response.blob().then(blob => {
                                                            const fileURL = window.URL.createObjectURL(blob);
                                                            let alink = document.createElement('a');
                                                            alink.href = fileURL;
                                                            alink.download = ele.title + '.pdf';
                                                            alink.click();
                                                        })
                                                    })
                                                }}><DownloadOutlined /></button>
                                            </div>
                                        </div>
                                        
                                    </div>
                                        <h5 style={{ color: "black" ,fontSize:'16px'}}>{ele.title}</h5></div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </Modal>
        </CardStyle>
          
        </MagazineContainer>
    )
}


const CardStyle = styled(Row)`
  

    display:flex;
    flex-direction:row;
    justify-content:space-around;
    align-items:center;
 
    flex-wrap:wrap;
    .card-text-container{
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      
    }
    .slider-left i,.slider-right i{
        display:none;
    }
    .slider-left  div{
        width:50px;
        height:50px;
        background:url(${prev})  center no-repeat;
        background-size:17px;
      }
    .slider-right  div{
        width:50px;
        height:50px;
        background:url(${back})   center no-repeat;
        background-size:17px;
      }
    .card-image:hover{
      transform: scale(1.01);
      transition: all 0.3s ease-out;
    }
    .magazineImg{
        width:500px;
        height:600px;  
    }
    .card-button:hover{
        background-color: #0086FF !important;
        color:white !important;
     }
    caurosal-3d{
        width:400px !important;
        height:500px !important; 
        color: black,
        background: whitesmoke,   
    }
    .magazineImg img {
        flex: 1;
        height: 100%;
    }
    .card-download svg{
        width:20px;
        height:20px;
    }
    .left{
      text-align: left;
    }
    .right{
      text-align: right;
    }
    .card-image{
        width: 400px;
        height: 500px;
    }
    .button-holder{
      width: 100%;
      display: flex;
      align-items: center;
    }
    .card-content{
      font-size: calc(1vw + 5px);
      font-weight: 400;
      color:#111111;
      margin-left:10px
     
    }
    .card-heading {
      width: 100%;
      margin-bottom: 24px;
      font-size: calc(2vw + 10px);
      font-weight: 700;
      font-family: 'Sofia Sans', sans-serif;
    }
    .card-button{
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: calc(1vw + 3px);;
      border-color: #0086FF;
      border-radius: 4px;
      font-weight: 700;
      padding: 2vw 4vw;
      margin-left:10px;
      background-color: #0086FF !important;
      color:white !important;
      gap:10px;
      box-shadow: 0 20px 40px 0 rgba(35, 100, 210, 0.2);
    }
    .card-button:hover{
        box-shadow: 0 20px 5px -10px rgba(0, 0, 0, 0.4);
        transform: translateY(1px);
    }
    .content{
        padding-right:20px;
        font-family: 'Inter', sans-serif;
    }
    .magazine-preview{
     min-width:700px;
    }
    @media screen and (min-width: 2000px){
    //   .card-image{
    //     width: 400px;
    //     height:400px;
    //   }
    //   caurosal-3d{
    //     width:500px;
    //     height:600px;    
    // }
    
  
    }
    @media screen and (max-width: 1800px){
        .magazine-preview{
            min-width:600px;
           }
    }
    @media screen and (max-width: 1200px){
      
        display:flex;
        flex-direction:row;
        justify-content:center;
        align-items:center;
    }
    @media screen and (max-width: 900px){
      .card-content{
        font-size: calc(1vw + 10px);
        text-align: left !important;
        margin-left: 10px;
      }
      .card-heading{
        text-align: left !important;
        font-size: calc(1vw + 15px);
      }
      .button-holder{
        justify-content: flex-start !important;
      }
      .card-button{
        padding: 3vw 6vw;
        font-size: calc(1vw + 6px);
      }
      .card-image{
        width: 500px;
        height:400px;
      }
      caurosal-3d{
        width: 500px;
        height:400px;
    }
    }
    @media screen and (max-width: 600px){
        .magazine-preview{
            min-width:300px;
           }
           .card-download svg{
            width:16px;
            height:16px;
        }
    }
    @media screen and (max-width: 425px){
      .card-button{
        padding: 4vw 6vw;
      }
      .card-image{
        width: 250px;
        height:250px;
      }
      caurosal-3d{
        width:300px;
        height:300px;    
    }
    .slider-left div,.slider-right div{
       height:30px;
       width:30px;
       background-size:15px;
    }
    }
  `

  const MagazineContainer = styled.div`
 
    background-color: transparent;
    padding-top: 80px;
    padding-bottom: 40px;
  padding-left:10vw;
  padding-right:10vw;
    text-align: center;
    // margin-bottom:100px;
    font-size:18px;
    font-weight:400;
    color:#111111;
    min-height: max-content;
    position:relative;
    .magazine-header{
        font-size: calc(2vw + 15px);
        font-weight: 700;
        position:relative;
        z-index:2;
        font-family: 'Sofia Sans', sans-serif;
    }
    .magazine-top{
        padding-top: 20px;
        font-size: calc(1vw + 5px);
        font-weight: 400;
         font-family: 'Sofia Sans', sans-serif;
    }
    @media screen and (max-width: 767px){
        padding-right: 8vw;
        padding-left: 8vw;
        min-height: max-content;
     //   height:760px;
        .magazine-header{
            font-size: calc(2vw + 20px);
        }
        .magazine-top{
            font-size: calc(1vw + 10px);
        }
    }
    @media screen and (max-width: 420px){
     //   height:600px;
    }
`


const Card = ({ele,id,setMagazineId, setIsModalOpen}) => (
    <div className='Emagazine-card' style={{position:'relative'}} >
      <img src={ele.coverImage}  className='Emagazine-cover'  onClick={() => {
        setMagazineId(id);
        setIsModalOpen(true);
        console.log('aoidshgdb',id)
       
    }}></img>
    </div>
  );
  
  const Obj = {
    btnType: 'outline',
    headline: '',
    description: 'A periodic journal that details how our organization functions . Get a sneak-peek into our festivities & associate engagement programs on a quarterly basis.',
    buttonLabel: 'DOWNLOAD NOW',
  
    imgStart: 'start',
    
}
const mapStateToProps = state => {
    const {
       magazines
    }  = state.we;
  
    return { magazines }
  }
  
  const mapDispatchToProps = dispatch =>  ({
    getAllMagazines: (successCallback) => dispatch(getAllMagazines(successCallback)),
   
  })
  
  export default connect(mapStateToProps, mapDispatchToProps)(Magazine);
  
  

