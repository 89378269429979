
import React ,{useEffect,useState} from 'react';
import styled from 'styled-components';
import { ArrowLeftOutlined,LoadingOutlined } from '@ant-design/icons';
import { Row, Col, Button } from 'antd';
import Img from './icons/driverassistance-img.svg';
import { Link } from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import { ReactComponent as Back } from '../../../../../assets/back-icon.svg';
import bg2 from '../../../../we/subpages/ourStory/ourCulture/BG.svg'
import { ReactComponent as Arrow } from '../../../../we/subpages/ourStory/PotentialCard/Vector.svg';
import { getSuggestedBlogs } from '../../../../we/redux/action';
import { connect } from 'react-redux';
function DriverAssistance({suggestedBlogs,getSuggestedBlogs}) {
  const navigate = useNavigate();
    const handleBackBtn=()=>{
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        window.history.back();
    }
    useEffect(()=>{
      document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
    },[])

    const [loader,setLoader]=useState(false);
   
    useEffect(()=>{

    
        
      let params={
        tag:['DRIVER ASSISTANCE','DRIVER',"ADAS",'MOBILITY']
      }
    if(!suggestedBlogs||suggestedBlogs.length==0){
       
      setLoader(true)
}
  getSuggestedBlogs(params,()=>{
      setLoader(false)
  })
    },[])
    return (
        <React.Fragment>
             <div   style={{
      background:`url(${bg2}) center no-repeat`,backgroundSize:'cover'
  
      }}>   
        < DriverAssistanceContainer>
        
        <div style={{'display':'flex','alignItems':'center','justifyContent':'flex-start',position:'relative'}}>
           <Back className='back-btn' onClick={handleBackBtn} />
         
            </div>
            <div className='DriverAssistance-header heading'>Driver <div className='highlight'>Assistance</div></div>
          
            <BigCard {...Obj1}/>
            {loader ? <div  style={{'minHeight':'280px','display':'flex',justifyContent:'center','alignItems':'center',flexDirection:'column'}}>
                <LoadingOutlined style={{fontSize: "3em"}}/> 
                 
                </div> : suggestedBlogs?.length==0? 
                null:    
                <>   
                <Row gutter={10} style={{marginTop:'50px'}}  >
                <span style={{float:'left',fontSize:'23px',fontFamily:'Sofia Sans, sans-serif',paddingRight:'40px'}}>Also Read : </span>
              
                 
               
              </Row>
              <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'center',flexWrap:'wrap',gap:'18px', marginTop: '10px'}}>
                 {suggestedBlogs?.map(blog=>{
                    return  <Col >
                    <Card  {...blog}/>
                </Col>
                  })}
              
              </div></>
          
            } 
           
        </ DriverAssistanceContainer>
        </div>
        </React.Fragment>
    )
}

// const BigCard=({
//     btnType,
//     headline,
//     description,
//     buttonLabel,
//     img,
//     alt,
//     imgStart,
//     link
// })=>{
  
//     return(
//         <>
        
//       <CardStyle
//       wrap={true}
      
 
//     >
//         <Col 
//         xs={{span: 24, order: 2}} 
//         sm={{span: 24, order: 2}} 
//         md={{span: 12, order:  1}} 
//         lg={{span: 12, order:  1}} 
//         xl={{span: 12, order:  1}}
//         xxl={{span: 12, order:  1}}
//         className= 'card-text-container left'
//         gutter = {20}
//         >
         
               
//          <p className='card-content content'>
//          Driver assistance, also known as advanced driver-assistance systems (ADAS), comprises technologies
// that enhance vehicle safety by automating, improving, or modifying driving-related tasks.
//             </p>
//             <ul className='card-content content' style={{paddingLeft:'18px'}}>
//               <li><b>Safety and Convenience:</b><br/>    
// Driver assistance systems aim to enhance both safety and convenience during travel. They can assist
// with driving tasks and provide warnings for errors or dangers like lane departure and fatigue
// recognition.</li>
//               <li><b>Range of Systems:</b><br/>
           
// Driver assistance encompasses various systems, including basic ones like anti-lock brakes, as well as
// advanced technologies used in autonomous vehicles. These advanced systems utilize radar, lasers,
// machine vision, AI, and other position-detecting technologies to take control of vehicle operation.</li>
//               <li><b>Ancillary Activities:</b><br/>
              
// Driver assistance covers not only vehicle control but also ancillary driving activities like navigation,
// route planning, and obstacle detection.</li>
// <li><b>Data Collection:</b><br/>

// Driver assistance systems collect data on the driver's capabilities during daily driving. They can detect
// signs of drowsiness or sleepiness and provide alerts or signals to address the situation.</li>
//             </ul>
           
// <p className='card-content content'>
// Driver assistance technologies continuously evolve to improve overall road safety and enhance the
// driving experience.
// </p>
//             <div 
//               className='button-holder'
//               style={{justifyContent: 'flex-end',width:'172px'}}
//             >
//               <Link to={`/technology/${link}`} onClick={()=>{
//                   document.body.scrollTop = 0; // For Safari
//                   document.documentElement.scrollTop = 0;
//               }}> 
//                 <Button 
//                   type = {btnType} 
//                   className='card-button'
//                   style ={{
//                     color: '#0086FF',
//                     backgroundColor: 'transparent'
//                   }}
//                 >
//                   {buttonLabel}
//                 </Button>
//               </Link>
//             </div>
//         </Col>

//         <Col 
//         xs={{span: 24, order: 1}}  
//         sm={{span: 24, order: 1}}  
//         md={{span: 12, order:  2}}
//         lg={{span: 12, order:  2}}
//         xl={{span: 12, order:  2}}
//         xxl={{span: 12, order: 2}}
//         className='content'
//         style={{'display':'flex',justifyContent:'center',alignItems:'flex-start'}}
//         >
//           {
//             img ? <img src={img} alt={alt} className='card-image' /> : null
//           }  
         
//         </Col>
//     </CardStyle>
//     </>
//     )
// }

const BigCard=({
  btnType,
  headline,
  description,
  buttonLabel,
  img,
  alt,
  imgStart,
  link
})=>{
const scrollToTop=()=>{
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

}
  return(
      <>
      
    <CardStyle
    wrap={true}
  >
     <div style={{display:'flex',justifyContent:'center',width:'100%',flexDirection:'column',alignItems:'center',marginTop:'40px'}}>
     
      <p className='card-content content card-content-top'  style={{fontWeight:500,paddingBottom:'20px'}}>
          Driver assistance, also known as advanced driver-assistance systems (ADAS), comprises technologies
that enhance vehicle safety by automating, improving, or modifying driving-related tasks.
            </p>
            </div>
      <div className='wrapper-div'>
      <div className='image-div-mobile'>
        <img src={img} alt={alt} className='card-image' />
        </div>
        <div className='content-1'>
     
          <ul className='card-content content li-cards' style={{listStyle:'none'}}>
            <li >
            <span className='icon-text'>
            <b>Safety and Convenience</b> 
            Driver assistance systems aim to enhance both safety and convenience during travel. They can assist
with driving tasks and provide warnings for errors or dangers like lane departure and fatigue
recognition.
            </span>
            </li>
            <li> 
            <span className='icon-text'>
            <b>Range of Systems</b>
            Driver assistance encompasses various systems, including basic ones like anti-lock brakes, as well as
advanced technologies used in autonomous vehicles. These advanced systems utilize radar, lasers,
machine vision, AI, and other position-detecting technologies to take control of vehicle operation.
            </span>
            </li>
          
          </ul>
        </div>
        <div className='image-div'>
        <img src={img} alt={alt} className='card-image' />
        </div>
        <div className='content-2'>

        <ul className='card-content content li-cards' style={{textAlign:'right',listStyle:'none'}}>
            <li>  
               <span className='icon-text'>
              <b>Ancillary Activities</b>
              Driver assistance covers not only vehicle control but also ancillary driving activities like navigation,
route planning, and obstacle detection.
              </span>
           </li>
            <li> 
            <span className='icon-text'>
              <b>Data Collection</b>
            Driver assistance systems collect data on the driver's capabilities during daily driving. They can detect
signs of drowsiness or sleepiness and provide alerts or signals to address the situation.
              </span>
             </li>
         
          </ul>
        </div>
      </div>
      <div style={{display:'flex',justifyContent:'center',width:'100%',flexDirection:'column',textAlign:'center',alignItems:'center',marginTop:'40px'}}>
     
<p className='card-content content' style={{fontWeight:500}}>
Driver assistance technologies continuously evolve to improve overall road safety and enhance the
driving experience.
</p>
          
      </div>
          <div 
              className='button-holder'
              style={{justifyContent: 'center',width:'100%',display:'flex'}}
            >
              <Link to={`/technology/${link}`} onClick={()=>{
                  document.body.scrollTop = 0; // For Safari
                  document.documentElement.scrollTop = 0;
              }}> 
                <Button 
                  type = {btnType} 
                  className='card-button'
                  style ={{
                    color: '#0086FF',
                    backgroundColor: 'transparent'
                  }}
                >
                  {buttonLabel}
                </Button>
              </Link>
            </div>
  </CardStyle>
  </>
  )
}

const CardStyle = styled(Row)`

text-align:center;

.card-text-container{
  height: auto;
  display: flex;
  
  flex-direction: column;
 
}
.card-button:hover{
     
  background-color: #0086FF !important;
  color:white !important;
}





.card-image:hover{
  transform: scale(1.01);
  transition: all 0.3s ease-out;
}

.left{
  text-align: left;
}

.right{
  text-align: right;
}

.card-image{
  width: 100%;
  transition: all 0.3s ease-out;
  margin-bottom:30px;
}

.button-holder{
  width: 100%;

  
}

.card-content{
  font-size: calc(1vw + 3px);
  font-weight: 400;
  text-align:left;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  font-family: 'Inter', sans-serif;
}

.card-heading {
  width: 100%;
  margin-bottom: 24px;
  font-size: calc(1vw + 6px);
  font-weight: 700;
  font-family: 'Sofia Sans', sans-serif;
}

.card-button{
  margin-top: 20px;
  padding: 2vw 3vw;
  font-weight: 700;
  text-transform: uppercase;
  font-size: calc(1vw + 3px);
  border-color: #0086FF;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Sofia Sans', sans-serif;
  background-color: #0086FF !important;
  color:white !important;
  box-shadow: 0 20px 40px 0 rgba(35, 100, 210, 0.2);
}

.card-button:hover{
  transform: scale(1.02);
  transition: all 0.1s ease-out;
  background: #0086ff;
  color: #fff !important;
  box-shadow: 0 20px 5px -10px rgba(0, 0, 0, 0.4);
  transform: translateY(1px);
}

.wrapper-div{
display:flex;
flex-direction:row;
justify-content:space-between;

}
.content-1,.content-2{
max-width:33%;
}

.content-2{
text-align:right !important;
}

.image-div{
display:flex;
justify-content:center;
}
.image-div-mobile{
display:none;
}
.icon-box{
width:80px;
height:80px;
background:white;
padding:10px;
border-radius:4px;

box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.11);
}
.li-cards li{
display:flex;
flex-direction:row;
gap:20px;
margin-top:20px;
align-items:flex-start;
height:250px;
}
.icon-text{
display:flex;
flex-direction:column;
}
.li-card-head-text{
height:140px;
}
.card-content-top{
  text-align:center;
  
}
@media screen and (min-width: 2000px){
  .card-image{
    width: 75%;
  }
}

@media screen and (max-width: 767px){
  text-align:left;
  .card-content{
    font-size: calc(1vw + 10px);
    text-align: left !important;
  }
  .card-content-top{
    text-align:left;
  }
  .card-heading{
    text-align: left !important;
    font-size: calc(1vw + 15px);
  }

  .button-holder{
    justify-content: flex-start !important;
  }

  .card-button{
    padding: 3vw 6vw;
    font-size: calc(1vw + 6px);
  }
  .wrapper-div{
    flex-direction:column;
  }
  .image-div{
    display:none;
  }
  .image-div-mobile{
    display:flex;
    justify-content:center;
  }
  .content-1,.content-2{
    max-width:100%;
  }
  .content-2{
    margin-top:30px;
  }
  .content-2 .li-cards li{

    display:flex;
    flex-direction:row-reverse;
   justify-content:flex-end;
  }
  .li-card-head-text{
   height: auto;
  }
  .li-cards li{
    height: auto;
  }
}

@media screen and (max-width: 425px){
  .card-button{
    padding: 4vw 6vw;
  }

  .card-image{
    width: 90%;
  }
}
`
const  DriverAssistanceContainer = styled.div`
    height: auto;
    min-height:70vh;
    background-color: transparent;
    padding-top: 40px;
    padding-right: 8vw;
    padding-left: 8vw;
    text-align: center;
    padding-bottom: 80px;

    .back-btn {
       
        cursor:pointer;
       
    }
    .back-btn svg{
        width:30px;
        height:30px;
    }
    .DriverAssistance-header{
        font-size: calc(2vw + 15px);
        font-weight: 700;
        position:relative;
        z-index:2;
        display:flex;
        gap:7px;
        justify-content:center;
        align-items:center;
    }

    .DriverAssistance-top{
        padding-top: 20px;
        font-size: calc(1vw + 5px);
        font-weight: 400;
    }


    @media screen and (max-width: 767px){
        padding-right: 8vw;
        padding-left: 8vw;
.back-btn>svg{
  width:25px;
  height:25px;
}
        .DriverAssistance-header{
            font-size: calc(2vw + 20px);
        }

        .DriverAssistance-top{
            font-size: calc(1vw + 10px);
        }
    }
`



const Card = ({ title, locationPath,id }) => {
  return(
      <CardStyle1>
        



          <div class = "card">
          <Link to={`/our-story/blog/blogId=${id}`}>
  <img src={locationPath} alt="Event-Img" />
  <div class="card-content">
    <h2 >
    {title}
   
   
   </h2>
   <br/>
   <h3>  READ BLOG <Arrow></Arrow></h3>
  
    
  </div>
  </Link>
</div>

      </CardStyle1>
  )
}

  

const CardStyle1 = styled.div`
   max-width:350px;
  
   width:100%;
    margin-bottom: 10px;

    transition: transform 0.5s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
   max-height:270px;
   height:100%;
   

   .card {
    width: 100%;
    height: 100%;
    // border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    background-color: rgba(0,0,0,0.6);
    
    
  }
  .card img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.7;
    transition: opacity 0.2s ease-out;
  }
  .card h2 {
    position: absolute;
    inset: auto auto 50px 30px;
    margin: 0;
    transition: inset 0.3s 0.3s ease-out;
    font-family: 'Sofia Sans', sans-serif;
    font-weight: normal;
    text-transform: uppercase;
    color: #FFFFFF;
 
    text-align: left;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 240px;
 
  }
  .card h3 {
    position: absolute;
    inset: auto auto 20px 30px;
    margin: 0;
    transition: inset 0.3s 0.3s ease-out;
    font-family: 'Sofia Sans', sans-serif;
    font-weight: normal;
    text-transform: uppercase;
    color:#FFFFFF;
    width: 70%;
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis; 
    text-align:left;
    font-size:14px;
  }
 

    &:hover{
        transform: scale(1.02);
         box-shadow: 0 10px 20px 0  rgba(0,0,0,0.6);
    }

   



    .position{
        display: block;
        font-size: calc(1vw + 3px);
        margin-bottom: 10px;
        font-weight: 400;
    }
    @media screen and (max-width: 2400px){
    
    
      width:350px;
      height:250px;

      .card h2{
        width: 300px;
      }
    }
    @media screen and (max-width: 1850px){
    
      width:350px;
      height:250px;

      .card h2{
        width: 280px;
      }
      
    }
    @media screen and (max-width: 1230px){
      width:350px;
      height:250px;

      .card h2{
        width: 200px;
      }
    }
    @media screen and (max-width: 728px){

      width:80vw;
      height:280px;


        .title{
            font-size: calc(1vw + 10px);
        }

        .position{
            font-size: calc(1vw + 8px);
        }
        .card h2{
          width: 300px;
          font-size:14px;
        }
    }
    @media screen and (max-width: 425px){
     
      width:84vw;
      height:200px;
    img{
       
        height:250px;
        width:230px;
       
    }
    .event-head {
        
        width:230px;
        height:230px;
    }
    .card h2{
      width: 200px;
    }
    .title.heading{
        height:43px !important;
    }
    .glass-button {
        padding: 8px 20px;
        height:43px;
    }


    }
    @media screen and (max-width: 320px){
      width:260px;
      height:200px;
      .card h2{
        width: 200px;
      }
     img{
        
         height:230px;
         width:210px;
        
     }
     .event-head {
         
         width:210px;
         height:210px;
     }
     .glass-button {
        padding: 6px 18px;
        height:40px;
    }
    .title.heading{
        height:40px !important;
    }
 
     }
`



const mapStateToProps = state => {
  const {
      suggestedBlogs
  }  = state.we;

  return {suggestedBlogs}
}

const mapDispatchToProps =dispatch =>  ({
  getSuggestedBlogs:(params,successCallback)=>dispatch(getSuggestedBlogs(params,successCallback)),

})


export default connect(mapStateToProps, mapDispatchToProps)(DriverAssistance);




const Obj1 = {
    topLine: '',
    btnType: 'outline',
    headline: '',
    description: ` `,
    buttonLabel: 'EXPLORE THE TECHNOLOGY BEHIND',
    img: Img,
    alt: 'driver-image',
    imgStart: '',
    link:'mobility-solutions/ADAS'
}

