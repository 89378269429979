import React, { useEffect, useState } from 'react';
import { NavbarContents } from './style';


const SubNavbar = ({ label, data, NavLink, scrollToTop }) => {
   const [subMenu, setSubMenu] = useState(label);

    useEffect(() => {
      
        setSubMenu( window.location.pathname.includes('/technology') ?'technology' :'we'
     ) }, [subMenu])

   let path = label === 'we' ? '' : label + '/';
  

   return (
      <NavbarContents className='content'>
         <ul className='subnav-links'>
            {  
               data?.map((elem) => (
                  
                  <li className='tab' key={elem}>
                     <NavLink 
                     to={
                        elem === 'Our Story'  ? '/' : elem==='Mobility Solutions'? '/technology':
                        `/${path}${elem.toLowerCase().split(' ').join('-')}`
                     } 
                     className='tabs'
                     exact={
                        elem === 'Our Story' ?true:elem==='Connected Solutions' || elem==='Mobility Solutions'?  true :   
                        false
                     }
                     activeClassName='activetab'
                     inactiveClassName='inactivetab'
                     onClick={() => { 
                        setSubMenu(`${elem.toLowerCase().split(' ').join('-')}`)
                        scrollToTop()
                     }}
                     elem={elem}
                     >
                        <span>
                           {elem}
                        </span>
                        
                     </NavLink>
                  </li>
               ))
            }
         </ul>
      </NavbarContents>
   ) 
  
}

export default SubNavbar;
