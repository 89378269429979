import React from 'react';
import styled from 'styled-components';
import Card from '../../../card'
import IotImg from './iot-banner.svg';

function Iot() {
    return (
        <IotContainer>
            {/* <div className='iot-header heading'>Internet of <div className='gradient6'>Things<div className='polygon6'></div></div></div>
             */}
      
            <Card {...Obj1}/>
           
        </IotContainer>
    )
}

const IotContainer = styled.div`
    height: auto;
    background-color: transaprent;transaprent;
    padding-top: 80px;
    padding-right: 10vw;
    padding-left: 10vw;
    text-align: center;


    .iot-header{
        font-size: calc(2vw + 15px);
        font-weight: 700;
        position:relative;
        z-index:2;
    }

    .iot-top{
        padding-top: 20px;
        font-size: calc(1vw + 5px);
        font-weight: 400;
    }

    @media screen and (max-width: 767px){
        padding-right: 8vw;
        padding-left: 8vw;

        .iot-header{
            font-size: calc(2vw + 20px);
        }

        .iot-top{
            font-size: calc(1vw + 10px);
        }
    }
`

export default Iot;



const Obj1 = {
    topLine: '',
    btnType: 'outline',
    headline: `Internet of Things <div className='highlight'>(IoT)</div>`,
    description: 'Empowering Connectivity and Innovation in the Internet of Things (IoT),our expertise leverages electronic connectivity, sensors, and software, enabling data exchange and unlocking limitless possibilities for enhanced services.',
    buttonLabel: '',
    img: IotImg,
    alt: 'iot-image',
    imgStart: 'start',
    link:'',
    height:90
}
