import React from 'react';
import styled from 'styled-components';

function Belief() {
    return (
        
        <MessageWrapper>
            <div className='border-left'/>
            <span className='message-topline darkblue highlight'>
                belief
            </span><br/><br/>
            <span className='message-quote content grey'>
            Our core belief is that <span className='bold'>‘We are a Family’</span> with the same dream and vision and every  associate is a vital part of the brigosha family. brigosha consistently cultivates a sense of  'oneness' in and beyond the workplace.  
            </span><br/><br/>
            <span className='text content'>We proudly declare ourselves as:</span>
            <div className='tagline heading'>
                {/* <div style={{'display':'flex','gap':'10px'}}>
                   One<div className='gradient4' style={{'height':'62px'}}>Name,<div className='polygon4' style={{'width':'130px'}}></div></div>
                </div>
                <div  style={{'display':'flex','gap':'10px'}}> One <div className='gradient2' style={{'height':'62px'}}>Vision,<div className='polygon2'  style={{'width':'130px'}}></div></div> 
                </div>
                <div  style={{'display':'flex','gap':'10px'}}>One <div className='gradient5' style={{'height':'65px'}}>Family.<div className='polygon5'  style={{'width':'130px'}}></div>
                </div>
                </div> */}
                

                <div>One <span className='highlight'>Name</span>, One <span className='highlight'>Vision</span>, One <span className='highlight'>Family</span>.</div>
            </div>
        </MessageWrapper>
    )
}

const MessageWrapper = styled.div`
    padding-left: 10vw;
    padding-right: 10vw;
    padding-bottom: 80px;
    background-color: transparent;
    position: relative;
  
    top: 0;
    left: 0;

    .border-left{
        position: absolute;
        left: 8vw;
        top: -1vw;
        height: 8px;
        width: 320px;
        background: linear-gradient(-90deg, #0DFFEF 5.21%, #0086FF 83.33%);
        transform-origin: top left;
        transform: rotate(90deg);
    }

    .message-topline{
        letter-spacing: 0.25rem;
        font-size: calc(1vw + 3px);
        font-weight: 600;
        text-transform: uppercase;
        font-family: 'Sofia Sans', sans-serif;
    }

    .message-quote{
        font-size: calc(1vw + 6px);
        font-weight: 500;
        font-family: 'Inter', sans-serif;
    }

    .bold{
        font-weight: 700;
        color: black;
    }

    .text{
        font-size: calc(1vw + 8px);
        font-weight: 400;
        font-family: 'Inter', sans-serif;
    }

    .tagline{
        display: flex;
        margin-top: 20px;
        // margin-left: 20px;
        font-size: calc(2vw + 15px);
        font-weight: 700;
        position:relative;
        flex-wrap:wrap;
        z-index:2;
        font-family: 'Sofia Sans', sans-serif;
    }

    @media screen and (max-width: 1200px){
        .border-left{
            top: -2vw;
            width: 250px;
        }
    }

    @media screen and (min-width: 2000px){
        .border-left{
            width: 200px;
        }
    }

    @media screen and (max-width: 767px){
        .tagline{
            font-size: calc(2vw + 20px);
        }

     

        .message-topline{
            font-size: calc(1vw + 10px);
        }

        .message-quote{
            font-size: calc(1vw + 10px);
        }

        .text{
            font-size: calc(2vw + 8px);
        }
    }

    @media screen and (max-width: 765px){
        .border-left{
            top: 0;
            height: 5px;
            width:310px;
        }
    }  

 

    @media screen and (max-width: 460px){
        .border-left{
            top: 0;
            height: 5px;
            width:390px;
        }
    }
`

export default Belief;
