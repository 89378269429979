import React ,{useState,useEffect} from 'react';

import { Row, Col, Button } from 'antd';
import {ReactComponent as ArrowLeft} from '../../../../we/subpages/teams/left.svg'
import {ReactComponent as ArrowRight} from '../../../../we/subpages/teams/right.svg'
import styled from 'styled-components';
import Img1 from '../development/Analytic.svg'
import Img2 from '../development/Design.svg'
import Img3 from '../development/Testing.svg'
import Img4 from '../development/AI.svg'
function Development() {
    const [values, setValues] = useState(0);
    const [reset,setReset]=useState(false);
    const length=Obj.length;
    const changeValuesDown = () => {
        setValues((prev) => {
            if(prev == 0){
                return prev+length-1;
            }
            else{
                return prev-1;
            }
        })
    }

    const changeValuesUp = () => {
        setValues((prev) => {
            if(prev == length-1){
                return prev-(length-1);
            }
            else{
                return prev+1;
            }
        })
    }
    const setCustomValue=(value)=>{
        setValues(value);
    }

    useEffect(() => {
       if(!reset){
        const mount = setInterval(() =>{
            setValues((prev) => {
                if(prev == length-1){
                    return prev-(length-1);
                }
                else{
                    return prev+1;
                }
            })
        }, 5000)

        return () => clearInterval(mount);
    }
    }, [reset])
    return (
        <DevelopmentContainer>
            {/* <div className='development-header heading'>Research And <div className='gradient3'>Development<div className='polygon3'></div></div></div> */}
            <div className='development-header heading'> Research & <div className='highlight'>Development</div></div>
         
            <br/>
            <ValuesCarousal>
                <Row style={{display:'flex',justifyContent:'space-between'}}>
                    <Col 
                        xs={{span: 24, order: 2}} 
                        sm={{span: 24, order: 2}} 
                        md={{span: 12, order: 1}} 
                        lg={{span: 12, order: 1}} 
                        xl={{span: 12, order: 1}} 
                        xxl={{span: 12, order: 1}}
                        className='description'
                    >
                     
                        <div className='carousal'  onMouseEnter={() => {setCustomValue(values);setReset(!reset)}}
                    onMouseLeave={() => setReset(!reset)}>
                            <span className='header heading'>{Obj[values].heading}</span>
                            <p className='paragraph content grey'>{Obj[values].description}</p>
                        </div>
                    </Col>
                    <Col 
                        xs={{span: 24, order: 1}} 
                        sm={{span: 24, order: 1}} 
                        md={{span: 12, order: 2}} 
                        lg={{span: 12, order: 2}} 
                        xl={{span: 12, order: 2}} 
                        xxl={{span: 12, order: 2}}
                        className='image-container'
                    >
                        <img src={Obj[values].img} alt='Carousal Banner' className='carousal-image'/>
                    </Col>
                </Row>   
            
                <Row className='arrow-button '>
                    <div className='lines'>
                        <span className='left-outlined' onClick={() => changeValuesDown()}>
                            {/* <LeftOutlined/> */} <ArrowLeft/>
                        </span>
                      
                        {Obj.map((ele,index)=>{
                      
                      return <div className={values === index ? 'blueline' : 'greyline'} onClick={()=>{setCustomValue(index);setReset(!reset)}} 
                      onMouseLeave={() => setReset(!reset)}></div>
                    })}
                      
                        
                        <span className='right-outlined' onClick={() => changeValuesUp()}>
                            {/* <RightOutlined/> */}<ArrowRight/>
                        </span>
                    </div>
                </Row>
            </ValuesCarousal>
           
            
        </DevelopmentContainer>
    )
}

const DevelopmentContainer = styled.div`
    height: auto;
    background-color: transaprent;
   
    padding-right: 8vw;
    padding-left: 8vw;
    text-align: center;
    padding-bottom: 80px;

    .development-header{
        font-size: calc(2vw + 15px);
        font-weight: 700;
        position:relative;
        z-index:2;
    }

    .development-top{
        padding-top: 20px;
        font-size: calc(1vw + 5px);
        font-weight: 400;
    }

    @media screen and (max-width: 767px){
        padding-right: 8vw;
        padding-left: 8vw;

        .development-header{
            font-size: calc(2vw + 20px);
        }

        .development-top{
            font-size: calc(1vw + 10px);
        }
    }
`

export default Development;


const ValuesCarousal = styled.div`
text-align:left; 


    .title{
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 0.25rem;
        font-size: calc(1vw + 3px);
        font-family: 'Sofia Sans', sans-serif;
    }
    .lines{
        display:flex;
        flex-direction:row;
        align-items:center;
        gap:10px;
       
    }
    .blueline{
        width:60px;
        height:6px;
        background-color:#0086FF;
        border-radius:3px;
        cursor:pointer;
    }
    .greyline{
        width:20px;
        height:6px; 
        background-color:#9D9D9D;
        border-radius:3px;
        cursor:pointer;
    }

    .header{
        font-weight: 700;
        font-size: calc(2vw + 5px);
        display: block;
        margin-bottom: 20px;
        font-family: 'Sofia Sans', sans-serif;
    }

    .paragraph{
        font-weight: 400;
        font-size: calc(1vw + 5px);
        font-family: 'Inter', sans-serif;
    }

    .carousal-image{
        width: 400px;
        height:400px;
        padding-left:20px;
    }

    .carousal{
        margin-top: 50px
    }

    .image-container{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width:50%;
        height:50%;
    }

    .arrow-button{
        padding-bottom: 50px;
        font-size: calc(1vw + 8px);
        font-weight: 600;
        font-family: 'Sofia Sans', sans-serif;
        margin-top:20px;

       
    }

    .left-outlined>svg, .right-outlined>svg{
        display: flex;
        // margin-left: 10px;
        // margin-right: 30px;
        cursor: pointer;
        width:20px;
        height:20px;
        align-items:center;
    }

    .carousal-option{
        font-size: calc(2vw + 3px); 
        font-weight: 400;
        font-family: 'Sofia Sans', sans-serif;
    }

    .option{
        font-weight: 700;
      

    }
    .description{
      
    }



    @media screen and (min-width: 2000px){
        .carousal-image{
            width: 60%;
        }
    }

    @media screen and (max-width: 915px){
        .carousal-image{
            width: 80%;
            height: 300px;
    object-fit: fill;
        }
        .carousal-option, .description{
            // display: none;
        }
        .blueline{
            width:30px;
            height:5px;
            background-color:#0086FF;
            border-radius:3px;
            cursor:pointer;
        }
        .greyline{
            width:15px;
            height:5px; 
            background-color:#9D9D9D;
            border-radius:3px;
            cursor:pointer;
        }
        .mobile-description{
            display: flex;
            flex-direction: column;
            margin-top: 40px;
            font-size: calc(2vw + 8px);
            font-weight: 700;
            font-family: 'Sofia Sans', sans-serif;
        }

        .mobile-description .desc-header{
            display: flex;
            flex-direction: row;
            justify-content: flex-start
    
        }
        .desc-header span{
            font-family: 'Sofia Sans', sans-serif;
        }

        .mobile-description span { 
            margin-right: 10px;
        }

        .desc-body{
            font-size: calc(1vw + 10px);
            font-weight: 400;
            // padding-left: 46.38px;
            font-family: 'Sofia Sans', sans-serif;
        }
        .title{
            font-size: calc(1vw + 10px);
        }
        .image-container{
            justify-content:center;
        }
    }
    @media screen and (max-width: 770px){
        .paragraph{
          
            font-size: calc(1vw + 10px);
           
        }
        .header {
            
            font-size: calc(1vw + 15px);
        }
    }
    
`   



const Obj = [
    {
        heading: 'Analytics',
        description: 'We create analytics and do market research to identify the extent of problems and the most appropriate technology to deploy in order to address them. By doing so, we may introduce the problem’s answer and connect the company to the rest of the globe.',
        img:Img1
    },
    {
        heading: 'Design',
        description: 'At brigosha, we work on the design for the solution we come up connection. We also provide the product a demonstration so that with and on the prototype that enables us to grasp the connection. We also provide the product a demonstration so that it can comprehend how it is connected and how each screen is associated.',
        img:Img2
    },
    {
        heading: 'Development & Testing',
        description: "At brigosha, we fuel innovation through tireless development and meticulous testing, creating groundbreaking solutions that redefine technological boundaries, guaranteeing unparalleled quality and reliability for our esteemed clients' success.",
        img:Img3
    },
    {
        heading: 'AI & Machine Learning',
        description: "Unleash the power of AI and Machine Learning at brigosha! Experience our game-changing solutions that revolutionize industries. From advanced data analytics to predictive modeling and intelligent automation, we propel your business to new heights.",
        img:Img4
    },
]
