import  {createGlobalStyle} from "styled-components";

export const GlobalStyles = createGlobalStyle`
    /* *{
        background-color: white;
    } */

    html{
        scroll-behavior: smooth;
        background-color: #FFFFFF;
        margin: 0;
        padding: 0;
    }

    body{
         box-sizing: border-box;
        margin: 0;
        padding: 0;
        background-color: #FFFFFF;

         
         overflow-x:hidden !important;
       
         font-family: 'Sofia Sans', sans-serif; 
       
         
        
    }

    //font-styles
    .heading{
        font-family: 'Sofia Sans', sans-serif;
        z-index:1 !important; 
    }
    
    .content{
        font-family: 'Inter', sans-serif;
    }

    //text-color-schemas
    .primary{
        color: #0086FF !important;
    }

    .darkblue{
        color: #005EB3;
    }

    .secondary{
        color: #9A9A9A;
    }

    .tertiary{
        color: #005EB3;
    }

    .grey{
        color: #565656;
    }
 .highlight{
    color:#0086FF !important;
    display:inline-block;
    font-family: 'Sofia Sans', sans-serif;
   }
   .content{
    color:#002546 !important;
   }


    //linear-gradient

    .gradient1{
        background-color: #f3ec78;
        background-image: linear-gradient(90deg, #0086FF 17.71%, #0D0EFF 100%);
        background-size: 100%;
        background-clip: text;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent; 
        -moz-text-fill-color: transparent; 
        display:inline-box;
        position:relative;
        height:65px;
        min-width:max-content;
    }
    .polygon1{
     width:-webkit-fill-available;
        position:absolute;
        top:5%;
        z-index:-1;
        height:60px;
        background-color:  #E7E7FF ;
        clip-path:polygon(12% 0,100% 0%,88% 100%,0 100%);
    }
    .gradient2{
        background-color: #f3ec78;
        // background-image: linear-gradient(to right, #9A26FF 0% , #FF26DF 77.6%);
        background-image: linear-gradient(to right, #FF231A 0% , #FF26DF 87.27%);
        background-size: 100%;
        background-clip: text;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent; 
        -moz-text-fill-color: transparent; 
        display:inline-box;
        position:relative;
        height:65px;
        min-width:max-content;
    }
    .polygon2{
     width:-webkit-fill-available;
        position:absolute;
        top:5%;
        z-index:-1;
        height:60px;
        background-color: #FFE9FC;
        clip-path:polygon(12% 0,100% 0%,88% 100%,0 100%);
    }

    .gradient3{
        background-color: #f3ec78;
        background-image: linear-gradient(90deg, #00FF5E 0%, #0DFFEF 86.23%);
        background-size: 100%;
        background-clip: text;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent; 
        -moz-text-fill-color: transparent; 
        display:inline-box;
        position:relative;
        height:65px;
        min-width:max-content;
    }
    .polygon3{
     width:-webkit-fill-available;
        position:absolute;
        top:5%;
        z-index:-1;
        height:60px;
        background-color:#E7FFFD;
        clip-path:polygon(12% 0,100% 0%,88% 100%,0 100%);
    }

    .gradient4{
        background-color: #f3ec78;
        background-image: linear-gradient(90deg, #FF6719 0%, #FFD519 79.63%);
        background-size: 100%;
        background-clip: text;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent; 
        -moz-text-fill-color: transparent; 
     display:inline-box;
       position:relative;
       height:65px;
       min-width:max-content;
    }
    .polygon4{
     width:-webkit-fill-available;
        position:absolute;
        top:5%;
        z-index:-1;
        height:60px;
        background-color: #FFFBE8;  
        clip-path:polygon(12% 0,100% 0%,88% 100%,0 100%);
    }

    .gradient5{
        background-color: #f3ec78;
        background-image: linear-gradient(90deg, #FF512F 0%, #DD2476 100%);
        background-size: 100%;
        background-clip: text;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent; 
        -moz-text-fill-color: transparent; 
        display:inline-box;
        position:relative;
        height:65px;
        min-width:max-content;
    }
    .polygon5{
     width:-webkit-fill-available;
        position:absolute;
        top:5%;
        z-index:-1;
        height:60px;
        background-color: #FCE9F1;
        clip-path:polygon(12% 0,100% 0%,88% 100%,0 100%);
    }

    .gradient6{
        background-color: #f3ec78;
        background-image: linear-gradient(90deg, #0DFFEF 5.21%, #0086FF 83.33%);
        background-size: 100%;
        background-clip: text;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent; 
        -moz-text-fill-color: transparent; 
        display:inline-box;
        position:relative;
   
        height:65px;
        min-width:max-content;
    }
    .polygon6{
     width:-webkit-fill-available;
        position:absolute;
        top:5%;
        z-index:-1;
        height:60px;
        background-color: #E6F3FF;
        clip-path:polygon(12% 0,100% 0%,88% 100%,0 100%);
    }
    .gradient7{
        background-color: #f3ec78;
        background-image: linear-gradient(90deg, #9A26FF 0%, #FF26DF 77.6%);

        height:65px;
        background-size: 100%;
        background-clip: text;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent; 
        -moz-text-fill-color: transparent; 
        display:inline-box;
        position:relative;

        min-width:max-content;
    }

  .ant-modal-close-x .anticon{
    display: inline-block;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    font-weight: 800;
    background: rgba(0,0,0,0.6);
    border-radius: 100px;
    color: white;
    padding: 3px;

  }
  .ant-modal-close-x:hover .anticon{
    box-shadow:0 0 6px 0 rgba(0,0,0,.6);
  }
    #button {
        display: inline-block;
        background-color:#0086FF;
        width: 50px;
        height: 50px;
        text-align: center;
        border-radius: 100px;
        position: fixed;
        bottom: 30px;
        right: 30px;
        transition: background-color .3s, 
          opacity .5s, visibility .5s;
        opacity: 0;
        visibility: hidden;
        z-index: 3;
      }
      #button::after {
        content: "\f102";
        font-family: FontAwesome;
        font-weight: normal;
        font-style: normal;
        font-size: 1.8em;
        line-height: 50px;
        color: #fff;
        transition:0.3 ease;
   
      }
      #button::after{
        position:absolute;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%);
    
      }
      #button:hover::after{
        top:60%;
        animation:scroll-up 1.5s ease-in-out infinite;
      }
    
      #button:hover {
        cursor: pointer;
        // background-color: #333;
      }
      #button:active {
        background-color: #555;
      }
      #button.show {
        opacity: 1;
        visibility: visible;
      }

      
    
      @media (min-width: 500px) {
      
        #button {
          margin: 30px;
        }
      }

      @keyframes scroll-up{
        0%{
            opacity:0;
        }
        40%{
            opacity:1;
        }
        60%{
            opacity:1;
        }
        100%{
            opacity:0;
            top:40%;
        }
      }
      .back-btn{
      cursor:pointer;
       
        position:relative;
        
       
        }
    @media screen and (min-width: 1950px){
        .polygon1,.polygon2,.polygon3,.polygon4,.polygon5,.polygon6{
            height:90%;
        }
        .gradient1,.gradient2,.gradient3,.gradient4,.gradient5,.gradient6,.gradient7{
            height:100% !important;
        }
      
    }
    @media screen and (max-width: 1950px){
        .polygon1,.polygon2,.polygon3,.polygon4,.polygon5,.polygon6{
            height:70px;
        }
        .gradient1,.gradient2,.gradient3,.gradient4,.gradient5,.gradient6,.gradient7{
            height:80px !important;
        }
      
    }

    @media screen and (max-width: 1207px){
        .polygon1,.polygon2,.polygon3,.polygon4,.polygon5,.polygon6{
            height:50px;
        }
        .back-btn svg{
            width:24px !important; 
            height:24px !important;
        }
        .gradient1,.gradient2,.gradient3,.gradient4,.gradient5,.gradient6,.gradient7{
            height:60px !important;
        }
    }
    .column-end{
        display:flex;
        flex-direction:row;
        align-items:center;
       
       }


    @media screen and (max-width: 770px){
    .background-image,.background-image-1{
        background-size: 200% !important;
        background-position-y:-10vh !important;
       }
       .back-btn{
        margin-top:-26px;
        margin-left:-10px;
        padding-bottom:10px;
        height:30px;
        
        }
      
    }
    @media screen and (max-width: 500px){
      
           .column-end{
            display:flex;
            flex-direction:column;
            align-items:flex-start;
           
           }
          
        }
`