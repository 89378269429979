
import styled from 'styled-components';

export const MilestoneCard = styled.div`
    width: 85%;
    margin-top: 50px;
    display:flex;
    flex-direction:column;
    justify-content:center;
  

    img{
        // width: 20%;
        height:120px;
        width:120px;
        background-color:rgb(215 240 255);
        border-radius:50%;
        object-fit:scale-down;
        
    }

    @media screen and (max-width: 767px){
        width: 90%;

        img{
            height:80px;
        width:80px;
        }
    }

    @media screen and (max-width: 575px){
        width: 100%;

        img{
            // width: 20%;
        }
    }
`

export const MilestoneCardHeading = styled.div`  
    font-weight: 700;
    font-size: calc(1vw + 10px);    
    margin-top: 24px;
    font-family: 'Sofia Sans', sans-serif;
    text-align:left;

    @media screen and (max-width: 767px){
        font-size: calc(2vw + 10px);
    }
`

export const MilestoneCardBody = styled.div`
    font-weight: 400;
    font-size: calc(1vw + 5px);
    margin-top: 24px;
    font-family: 'Inter', sans-serif;
    text-align:left;

    @media screen and (max-width: 767px){
        font-size: calc(1vw + 10px);
    }
`
