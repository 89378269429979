import React ,{useState,useEffect} from 'react';
import styled from 'styled-components';
import { Row, Col, Button } from 'antd';
import { Link } from 'react-router-dom'
import {ReactComponent as Arrow} from '../PotentialCard/Vector.svg'
import { LoadingOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { getFeaturedBlogs} from '../../../redux/action';

const scrollToTop = () => {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}

function Blog({getFeaturedBlogs,featuredBlogs}) {
  const [loader,setLoader]=useState(false);
  useEffect(()=>{
 

    if(!featuredBlogs||featuredBlogs.length==0){
      setLoader(true)
      }
  getFeaturedBlogs(()=>{
      setLoader(false)
  })
  },[])
    return (
      <>
       
       <CardStyle>
       
  <div className='wrapper-div'>

 
      <Col 
        // xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}
                  className = 'card-text-container'
        order = {1}
        gutter = {10}
        style={{minWidth:featuredBlogs?.length==1?'450px':'300px'}}
        >
<div className='card-heading heading' >Our <span className='highlight' >Blogs</span> </div>
            
            <p 
            className='card-content grey content'
            >
Find insightful articles & ideas into our domains of expertise and beyond.</p> 
{
  featuredBlogs?.length!==0?
  <Link to='/our-story/blogs' onClick={scrollToTop}>
              <Button type = 'default' className='card-button primary'  >
                Read Our Blogs  <Arrow  style={{height:'20px',width:'20px'}}/>
              </Button>
            </Link>
            :null
}           

        </Col>
      

        <Col  
        // xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}
        flex={featuredBlogs?.length!=-0?'auto':''}
        order = {2}
        className='card-img-container'
        >
             {loader ? <div  style={{'minHeight':'280px','display':'flex',justifyContent:'center','alignItems':'center',flexDirection:'column'}}>
              <LoadingOutlined style={{fontSize: "3em"}}/> 
               
              </div> : featuredBlogs?.length==0? 
              <div style={{'minHeight':'100px','display':'flex',justifyContent:'center','alignItems':'center',flexDirection:'column',fontSize:'16px'}}> 
             
              <span><b>No featured blogs found!</b></span>
              
          </div>: 
          <div  className='image-wrapper' >
           {featuredBlogs?.map((blog,index)=>{
              return  <div  >
              <Card  {...blog}  featuredBlogs={featuredBlogs}    />
          </div>
            })}
           

          </div>
          
       }


        </Col>
      
        </div>
       
       
      
    </CardStyle>
      </>
    
    )
}
 
const Card = ({ title, locationPath,id,featuredBlogs }) => {
  return(
      <CardStyle1 style={{minWidth:featuredBlogs?.length==1?'500px':'300px',minHeight:featuredBlogs.length==1?'300px':'240px'}}>
        


          <div className = "card">
            <Link to={`/our-story/blog/blogId=${id}`}>
           
  <img src={locationPath} alt="Event-Img" />
  <div className="card-content">
    <h2 >
    {title}
   
   </h2>
   <br/>
   <h3>  READ BLOG <Arrow></Arrow></h3>
 
    
  </div>
  </Link>
</div>
      </CardStyle1>
  )
}

  
const CardStyle = styled(Row)`
  padding: 40px 10vw;
  background-color: transaprent;


.wrapper-div{
  display:flex;
  flex-direction:row;
  width:100%;
}

  // min-height:60vh;

  .card {
    color: #000D19;
  }

  .card-text-container{
    height: auto;
    min-width:600px;
    max-width:600px;
    font-size: 15px;
    

    z-index:2;
  }
  .card-button:hover{
    background-color: #0086FF !important;
    color:white !important;
 }
  
  .card-content{
    font-size: calc(1vw + 5px);
    font-weight: 400;
    margin-top:10px;
    font-family: 'Inter', sans-serif;
  }
  .blog-card{
    border:1px solid black;
   
    
  
 
  }
  
  .image-wrapper{
    gap:20px;
    justify-content:center;
    display:flex;
    flex-direction:row;
    width:100%;
    margin-top:40px;
  }


  .card-img-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    transition: all 0.3s ease-in;
    display:flex;
    flex-direction:row;

  }

  .card-img-container:hover{
    // transform: scale(1.01);
    // transition: all 0.3s ease-out;
  }

  .card-image{
    width: 100%;
    height:100%;
    object-fit:cover;
    opacity:0.6;

  }

  .card-top-line {
    letter-spacing: 0.25rem;
    text-transform: uppercase;
    font-weight: 600;
    font-size: calc(1vw + 3px);

  }

  .card-heading {
    font-size: calc(2vw + 15px);
    font-weight: 700;
    display: block;
    position:relative;
    z-index:2;
    font-family: 'Sofia Sans', sans-serif;
  }
  
  .card-button{
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: calc(1vw + 3px);
    border-color: #0086FF;
    border-radius: 4px;
    font-weight: 700;
    padding: 2vw 4vw;
    text-transform: uppercase;
    background-color: #0086FF !important;
    color:white !important;
    gap:10px;
    box-shadow: 0 20px 40px 0 rgba(35, 100, 210, 0.2);
  }

  .card-button:hover{
    box-shadow: 0 20px 5px -10px rgba(0, 0, 0, 0.4);
    transform: translateY(1px);
  }

  @media screen {
    .card-image{
      height: 90%;
    }

    .card-text-container{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
  }

  @media screen and (max-width: 2400px){
    
  .card-text-container{
    height: auto;
    min-width:500px;
    max-width:500px;
    font-size: 15px;
    

    z-index:2;
  }
  }
  @media screen and (max-width: 1850px){

    .wrapper-div{
      display:flex;
      flex-direction:row;
      align-items:center;
      width:100%;
    }
    .card-text-container{
      max-width:100%;
      min-width:400px;
      text-align:left;
      display:flex;
      flex-direction:column;
      align-items:flex-start;
    }  
  
  }
  @media screen and (max-width: 1000px){

    .wrapper-div{
      display:flex;
      flex-direction:column;
      align-items:center;
      width:100%;
    }
    .card-text-container{
      max-width:100%;
     
        min-width:100% !important;
      text-align:center;
      display:flex;
      flex-direction:column;
      align-items:center;
    }  
  
  }
  @media screen and (max-width:830px){
    .image-wrapper{
    margin-top:20px;
      justify-content:center;
      display:flex;
      flex-direction:column;
      height:100%;
      align-items:center;
    } 
  }

  @media screen and (max-width: 767px){
    padding-left: 8vw;
    padding-right: 8vw;
    .card-text-container{
      max-width:100%;
      min-width:300px;
    }
    .card-heading {
      font-size: calc(2vw + 20px);
    }

    .card-content{
      font-size: calc(1vw + 10px);
    }

    .card-top-line{
      font-size: calc(1vw + 10px);
    }

    .card-image{
      width: 90%;
      margin-bottom: 20px;
    }

    .card-button{
      padding: 3vw 6vw;
      font-size: calc(1vw + 8px);
    }

  }

  @media screen and (max-width: 425px){
    
  }
`
const CardStyle1 = styled.div`
   max-width:650px;
   min-width:650px;
   width:100%;
    margin-bottom: 10px;
    transition: transform 0.5s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
   min-height:450px;
   max-height:450px;
   height:100%;
   

   .card {
    width: 100%;
    height: 100%;
    // border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    background-color: rgba(0,0,0,0.6);
    
    
  }
  .card img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.7;
    transition: opacity 0.2s ease-out;
  }
  .card h2 {
    position: absolute;
    inset: auto auto 50px 30px;
    margin: 0;
    transition: inset 0.3s 0.3s ease-out;
    font-family: 'Sofia Sans', sans-serif;
    font-weight: normal;
    text-transform: uppercase;
    color: #FFFFFF;
 
    text-align: left;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 240px;
 
  }
  .card h3 {
    position: absolute;
    inset: auto auto 20px 30px;
    margin: 0;
    transition: inset 0.3s 0.3s ease-out;
    font-family: 'Sofia Sans', sans-serif;
    font-weight: normal;
    text-transform: uppercase;
    color:#FFFFFF;
    width: 70%;
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis; 
    text-align:left;
    font-size:14px;
  }
 

  
  



  
 

    &:hover{
        transform: scale(1.02);
         box-shadow: 0 10px 20px 0  rgba(0,0,0,0.6);
    }

   



    .position{
        display: block;
        font-size: calc(1vw + 3px);
        margin-bottom: 10px;
        font-weight: 400;
    }
    @media screen and (max-width: 2400px){
    
      max-width:550px;
      min-width:500px;
      width:100%;
    
      min-height:350px;
      max-height:350px;
      height:100%;

      .card h2{
        width: 240px;
      }
    }
    @media screen and (max-width: 1850px){
    
      max-width:500px;
      min-width:350px;
      width:100%;
    
      min-height:240px;
      max-height:300px;
      height:100%;
      
      
    }
    @media screen and (max-width: 1230px){
      max-width:380px;
      min-width:380px;
      align-self:flex-start;
      width:100%;
    
      min-height:300px;
      max-height:300px;
      height:100%;
      .card h2{
        width: 200px;
      }
    }
    @media screen and (max-width: 728px){

      max-width:360px;
      min-width:380px !important;
      align-self:flex-start;
      width:100%;
    
      min-height:230px !important;
      max-height:230px;
      height:100%;

        .title{
            font-size: calc(1vw + 10px);
        }

        .position{
            font-size: calc(1vw + 8px);
        }
        .card h2{
          width: 300px;
          font-size:14px;
        }
    }
    @media screen and (max-width: 425px){
     
      max-width:280px;
      min-width:280px !important;
      align-self:flex-start;
      width:100%;
    
      min-height:200px !important;
      max-height:200px;
      height:100%;
    img{
       
        height:250px;
        width:230px;
       
    }
    .event-head {
        
        width:230px;
        height:230px;
    }
    .card h2{
      width: 200px;
    }
    .title.heading{
        height:43px !important;
    }
    .glass-button {
        padding: 8px 20px;
        height:43px;
    }


    }
    @media screen and (max-width: 320px){
      max-width:260px;
      min-width:260px !important;
      align-self:flex-start;
      width:100%;
    
      min-height:180px !important;
      max-height:180px;
      height:100%;
      .card h2{
        width: 200px;
      }
     img{
        
         height:230px;
         width:210px;
        
     }
     .event-head {
         
         width:210px;
         height:210px;
     }
     .glass-button {
        padding: 6px 18px;
        height:40px;
    }
    .title.heading{
        height:40px !important;
    }
 
     }
`

const mapStateToProps = state => {
  const {
      featuredBlogs
  }  = state.we;

  return { featuredBlogs }
}

const mapDispatchToProps = dispatch =>  ({
  getFeaturedBlogs: (successCallback) => dispatch(getFeaturedBlogs(successCallback)),
 
})

export default connect(mapStateToProps, mapDispatchToProps)(Blog);


