import React from 'react';
import styled from 'styled-components';
import Card from '../../../card';
import Img from './research-img.svg';
import Gif from './Mobility_Research_1.gif'
import {ReactComponent as Arrow} from '../Automotive/svgs/Vector.svg'
import { Row, Col, Button } from 'antd';
import { Link } from 'react-router-dom';
import animation from './mobility-animation.json';
import Lottie from 'react-lottie';
function Research() {
    const scrollToTop = () => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
       
    }
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };
    return (
        <>
        <ResearchContainer>
            {/* <div className='Research-header heading'>Mobility <div className=' gradient3'>Research<div className='polygon3'></div></div></div> */}
            <div className='Research-header heading'>Mobility <div className='highlight'>Research</div></div>
           
            {/* <p className='Research-top content'>Welcome to brigosha’s captivating world of mobility research! Immerse yourself in
 the revolutionary realm of 3D Lidar technology, where blind spots vanish, and false
 detections are history. With our groundbreaking standalone Lidar solution, level-4
 autonomy is within reach, making driving safer and smarter. Experience the magic of
 our algorithm that detects ghost objects, ensuring no anomalies go unnoticed, setting a
 new perception standard. Precise navigation and collision avoidance are now realities,
 guaranteeing safer journeys for all. ADAS brilliance reshapes driving, offering
 inclusivity, efficiency, and a glimpse into the future of autonomous vehicles. Join
 brigosha’s visionary drive and embrace the transformative journey towards a smarter,
 safer tomorrow! </p> */}
  <p className='Research-top content'>Immerse yourself in the cutting-edge realm of 3D LiDAR technology, where advanced laser scanning
and precise data capture enable unparalleled perception and tracking capabilities. </p>
            <br/>
            <Lottie options={defaultOptions} isClickToPauseDisabled={true} />
         
          
            <Row justify='center' style={{marginTop:'20px'}}>
                <Link to={`/technology/mobility-solutions/LiDAR`} onClick={
                    scrollToTop()
                  }> 
                <Button 
             
             className='card-button'
             style ={{
               color:  '#0086FF',
               backgroundColor: 'transparent'
             }}
          
           >
             KNOW MORE<Arrow className='arrow'/>
           </Button>
           </Link>
                </Row>
        </ResearchContainer>
       
         {/* <img src={Gif} style={{width:'100vw'}}>
           </img> */}
      
           
           </>
    )
}

const ResearchContainer = styled.div`
    height: auto;
    background-color: transparent;
    padding-top: 80px;
    padding-bottom :80px;
    // padding-right: 8vw;
    // padding-left: 8vw;
    text-align: center;
    overflow-x:hidden;
    

    .Research-header{
        font-size: calc(2vw + 15px);
        font-weight: 700;
        position:relative;
        z-index:2;
    }

    .Research-topline{
        padding-top: 10px;
        font-size: calc(1vw + 10px);
        font-weight: 400;
    }
    .Research-top{
        padding-top: 10px;
        font-size: calc(1vw + 5px);
        font-weight: 400;
         padding-right: 8vw;
     padding-left: 8vw;
    }
    .card-button{
        margin-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
       
        font-size: calc(1vw + 3px);;
        border-color: #0086FF;
        border-radius: 4px;
        font-weight: 700;
        gap:10px;
        padding: 2vw 4vw;
        background-color: #0086FF !important;
        color:white !important;
        box-shadow: 0 20px 40px 0 rgba(35, 100, 210, 0.2);
      }
      .card-button .arrow>path{
         fill:white;
      }
      .card-button .arrow{
        width:20px;
        height:20px;
     }
    
      .card-button:hover{
         
        background-color: #0086FF !important;
        color:white !important;
        box-shadow: 0 20px 5px -10px rgba(0, 0, 0, 0.4);
         transform: translateY(1px);
     }



    @media screen and (max-width: 767px){
        // padding-right: 8vw;
        // padding-left: 8vw;

        .Research-header{
            font-size: calc(2vw + 20px);
        }

        .Research-top{
            font-size: calc(1vw + 10px);
        }
        .card-button{
            padding: 3vw 6vw;
            font-size: calc(1vw + 6px);
          }
          .card-button .arrow{
            width:15px;
            height:15px;
         }
    }
`

export default Research;


