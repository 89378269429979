import React,{useEffect} from 'react';
import styled from 'styled-components';
import { Row, Col, Modal} from 'antd';
import {useNavigate} from 'react-router-dom';
import { useState } from 'react';
import Api from '../../../../../services/Api'
import NoEvent from './no-event.gif'
import LazyLoad from 'react-lazyload';
import { ReactComponent as Back } from '../../../../../assets/back-icon.svg';
import { LoadingOutlined } from '@ant-design/icons';
import bg2 from '../../ourStory/ourCulture/BG.svg'
import { connect } from 'react-redux';
import { getAllEvents } from '../../../redux/action';


function AllEvents({
    getAllEvents,
    events


}) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loader,setLoader]=useState(false);
    const [eventDetails, setEventDetails]=useState([]);
    useEffect(()=>{
     
        if(!events||events.length==0){
            setLoader(true)
            }
        getAllEvents(()=>{
            setLoader(false)
        })
    },[])
    const navigate = useNavigate();


    const handleCancel = () => {
        setIsModalOpen(false);
      };
    
      const handleBackBtn=()=>{
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        navigate('/')
    }
   
          
const Obj = events?.map(ele=>{
    return{
        key:ele.id,
        name:ele.title,
        img:ele.thumbnailPath
    }
})
      
    return (
        <div   style={{
            background:`url(${bg2}) center no-repeat`,backgroundSize:'cover'
        
            }}>
        <AllEventsContainer>
      
    
                    <div style={{'display':'flex','alignItems':'center','justifyContent':'flex-start',position:'relative',gap:'10px'}}>
           <Back className='back-btn' onClick={handleBackBtn} />

            
            </div>
            <div  className='AllEvents-header heading'><div className='highlight'>Events</div></div>
           {loader?<div  style={{'minHeight':'280px','display':'flex',justifyContent:'center','alignItems':'center'}}>
           <LoadingOutlined style={{fontSize: "3em"}}/>
                </div> :
            Obj?.length==0?<div style={{'display':'flex','flexDirection':'column',justifyContent:'center',alignItems:'center'}}> 
                <img alt='events' src={NoEvent} width={250} height={250}/>
                <span><b>Uh!Uh! No Events Found!</b></span>
                <span><b>But you can find your way back by clicking on the homepage</b></span>
            </div>:
            <Row  style={{'display':'grid','gridTemplateColumns':'repeat(auto-fill, minmax(300px, 1fr))',gap:'10px','placeItems':'center'}} >
            
            {Obj?.map((ele,key)=>{
       return    <Col  className='team-card' id={ele.key} onClick={()=>{ setIsModalOpen(true);setEventDetails(events[key])}}>
       <Card {...Obj[key]} id={ele.key}/>
      </Col>
         })}
                
            </Row>
            
           }
          
        </AllEventsContainer>
      
        <Modal  visible={isModalOpen} footer={null} closable={false} centered onCancel={handleCancel} style={{'zIndex':'100'}} width={window.innerWidth>950?850:350}>
         
            <ModelDiv>
                <div className='div1'>
                    <img alt='Bike On The Go (BOTG)' src={eventDetails.thumbnailPath} width={463} height={285} />
                    
                    <div>
                        <h1>{eventDetails.title}</h1>
                        <p className='text'>{eventDetails.description} </p>
                    </div>

                </div>
                <div className='div2'>
                    <h1>Related Images</h1>
                    {eventDetails?.galleryImages?.length===0?<h4>No gallery found!!!</h4>:
                    <div className='div3'>
                   { eventDetails?.galleryImages?.map(ele=>{
                         return   <img alt='BOTG' src={ele.locationPath} id={ele.id} width={120} height={120}/>
                    })
                   }     
                    </div>}
                   <u><a onClick={()=>{
                    document.body.scrollTop = 0; // For Safari
                    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                    navigate(`/our-story/eventGallery/eventId=${eventDetails.id}`)
                   }}> <b>Go to Gallery Page </b></a></u>

                </div>
            </ModelDiv>
      </Modal>
      </div>
    )
}
const Card = ({ name, img }) => {
    return(
    
        <CardStyle  >
            <div className='card-wrapper'>
            <img src={img} alt={name}  style={{objectFit: 'cover'}}/>
            <div className='event-head'>
                <div style={{'display':'table','tableLayout':'fixed','width':'100%'}}>
            <h1 className='title heading'  style={{'textOverflow':'ellipsis',overflow:'hidden',display:'table-cell',verticalAlign:'middle'}}>{name}</h1>
            </div>
            <button class="glass-button">Explore</button>
            </div>
            </div>
        </CardStyle>
    )
}

// const CardStyle = styled.div`
//     width: 100%;
  
//     transition: all 0.5s ease-out;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     flex-gap:30px;
//     .card-wrapper{
//         width: 100%;
//         max-width: 360px;
//         cursor:pointer;
//         background-color: #f5f5f5;
//         padding: 20px;
//         position: relative;
//         margin:0 auto 30px;
//         border-radius: 4px;
       
//        }
   
//     &:hover{
//         transform: scale(1.02);
//     }

//     img{
//         width: 100%;
//         margin-bottom: 20px;
//         height:200px;
//         width:200px;
       
//     }

//     .title{
//         text-align: center;
//         font-size: calc(1vw + 8px);
//         font-weight: 700;
//     }

//     .position{
//         display: block;
//         font-size: calc(1vw + 3px);
//         margin-bottom: 10px;
//         font-weight: 400;
//     }

//     @media screen and (max-width: 728px){
//         .title{
//             font-size: calc(1vw + 10px);
//         }

//         .position{
//             font-size: calc(1vw + 8px);
//         }
//     }
// `
const CardStyle = styled.div`
   width:300px;
    margin-bottom: 10px;
    transition: transform 0.5s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: scale(1);
   height:400px;


   .card-wrapper{
    width: 100%;
    max-width: 300px;
    cursor:pointer;
    background-color: #fff;
    justify-content:center;
    padding: 20px;
    position: relative;
    margin:0 auto 30px;
    display:flex;
    justify-content:center;
  
    border-radius:8px;
    height:360px;
   
   
  
   }
   .card-wrapper:hover{
    box-shadow: 0 10px 20px rgba(	0, 94, 179, 0.12), 0 14px 20px rgba(	0, 94, 179, 0.26);
    border-radius:8px;
   }
   .glass-button {
    display: inline-block;
    padding: 12px 22px;
    height:50px;
    border: 0;
    text-decoration: none;
    border-radius: 8px;
    background-color: rgba(255,255,255,0.1);
    border: 1px solid rgba(255,255,255,0.1);
    backdrop-filter: blur(30px);
    color:#fff;
    font-size: 16px;
    letter-spacing: 2px;
    cursor: pointer;
    text-transform: uppercase;
  }
  
  .glass-button:hover {
    background-color: rgba(255,255,255,0.2);
  }
   .event-head {
    width:260px;
   display:flex;
   gap:5px;
   justify-content:space-between;
   align-items:flex-end;
    position:absolute;
    bottom:20px;
    height:320px;
    border-bottom-left-radius:8px;
    border-bottom-right-radius:8px;
    background: linear-gradient(to bottom, #9b9b980f 56%, black 100%);
    text-align: center;
    opacity:1;
    padding: 10px;
    transition: all 0.5s ease;
  }
    &:hover{
        transform: scale(1.02);
    }

    img{
       
        height:320px;
        width:260px;
        border-radius:8px;
       
    }
   

    .title{
        // height:50px;
        font-size: 16px;
        font-weight: 700;
        color: #FFFFFF;
        // text-align:left;
        // display:flex;
        // align-items:center;
    
        white-space: nowrap;
        line-height: 1.5em;
        height: 3em;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
        margin-bottom:0px !important;
    }

    .position{
        display: block;
        font-size: calc(1vw + 3px);
        margin-bottom: 10px;
        font-weight: 400;
    }

    @media screen and (max-width: 728px){
        .title{
            font-size: calc(1vw + 10px);
        }

        .position{
            font-size: calc(1vw + 8px);
        }
    }
    @media screen and (max-width: 425px){
       width:280px;
       height:320px;
    img{
       
        height:250px;
        width:230px;
       
    }
    .event-head {
        
        width:230px;
        height:230px;
    }
    .glass-button {
        padding: 8px 20px;
        height:43px;
    }
    .title.heading{
        height:43px !important;
    }


    }
    @media screen and (max-width: 320px){
        width:260px;
        height:300px;
     img{
        
         height:230px;
         width:210px;
        
     }
     .event-head {
         
         width:210px;
         height:210px;
     }
     .glass-button {
        padding: 6px 18px;
        height:40px;
    }
    .title.heading{
        height:40px !important;
    }
 
     }
`



const AllEventsContainer = styled.div`
    height: auto;
    min-height:70vh;
    // background-color: #f3f9ff;
    background-color:transparent;
    padding-top: 40px;
    padding-right: 8vw;
    padding-left: 8vw;
    text-align: center;
    padding-bottom: 80px;
    .back-btn {
      
        cursor:pointer;
       
    }
    .back-btn svg{
        width:30px;
        height:30px;
    }
    .bold{
        font-weight: 700;
        color: black;
    }
    .team-image-topline{
        letter-spacing: 0.25rem;
        text-transform: uppercase;
        font-weight: 600;
        text-align:left;
        font-size: calc(1vw + 3px);
    }
    .AllEvents-header{
        font-size: calc(2vw + 15px);
        font-weight: 700;
        position:relative;
        z-index:2;
    }

    .expertise-top{
        padding-top: 20px;
        font-size: calc(1vw + 5px);
        font-weight: 400;
    }

    @media screen and (max-width: 767px){
        padding-right: 8vw;
        padding-left: 8vw;
        padding-top: 40px;
        .back-btn {
         padding-top:0px;
         margin-top:0px;
         margin-left:0px;
            cursor:pointer;
           
        }
        .AllEvents-header{
            font-size: calc(2vw + 20px);
            font-weight: 700;
            position:relative;
            z-index:2;
        }
        .expertise-header{
            font-size: calc(2vw + 20px);
        }

        .expertise-top{
            font-size: calc(1vw + 10px);
        }
    }
`
const ModelDiv = styled.div`
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 50px;
   max-height:722px;
  min-height:300px;
  max-width:943px;

  
   .div1>img {
    max-width:463px; 
    max-height:258px;
   }
   .div2{
    display:flex;
    flex-direction:column;
    justify-content:space-evenly;
    gap:10px;
  
  
   }
  .div3{
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  
  }
  .div3 >img{
    width:120px;
    height:120px ;
  }
  .text{
    display:block;
    width:100%;
    max-height:235px;
     white-space: pre-wrap !important;
     white-space: -moz-pre-wrap;
     white-space: -pre-wrap;
     white-space: -o-pre-wrap;
     word-wrap: break-word !important;
     min-height:40px;
  
     overflow-y:auto;
     padding :5px 5px;
     font-size:18px;
    //  background:#F3F9FF;
  
 }




    
 @media  screen and (max-width: 950px){
    max-height:800px;
    display:grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
    place-items: center
    min-height:300px;

   overflow-y:scroll;
  
 
   
    .div1>img{
       min-height:150px;
       max-height:258px;
    width:100%;

    }
    .div3{
        grid-template-columns:  repeat(2, 100px);
        gap: 20px;
        place-items: center;
    }
    .div3>img{
        height:100px;
        width:100px;
        

    }
    .div2{
        align-items:space-around;
    }
    .text{
        max-height:max-content;
        font-size: 16px;
    }
}
`


const mapStateToProps = state => {
    const {
        events
    }  = state.we;

    return { events }
}

const mapDispatchToProps = dispatch =>  ({
    getAllEvents: (successCallback) => dispatch(getAllEvents(successCallback)),
   
})

export default connect(mapStateToProps, mapDispatchToProps)(AllEvents);






