import React, { useState, useEffect } from 'react';
import { ReactComponent as Prev } from './prev.svg';
import { ReactComponent as Next } from './back.svg';

import './carousel-style.css';

const MAX_VISIBILITY = 2;
const AUTO_SCROLL_INTERVAL = 3000; // 3 seconds (adjust as needed)

const Carousel1 = ({ children }) => {
  const [active, setActive] = useState(0);
  const [reset,setReset]=useState(false)
  const count = React.Children.count(children);
 
const offSet=(i)=>{
  if(i==active){
    return 0;
  }
  else if(active==0){
    if(i==1){
      return -1/3;
    }
    else if(i==count-1){
   return 1/3;
    }
  
  }else if( active==count-1){
    if(i==0){
      return -1/3;
    }else if(i==count-2){
     return 1/3
    }
  }
  
  else{
  return (active - i) / 3
  }

  }

  const  setSign=(i)=>{
  if(i==active){
    return 0;
  }
  else if(active==0){
    if(i==1){
      return -1;
    }
    else if(i==count-1){
   return 1;
    }
  
  }else if( active==count-1){
    if(i==0){
      return -1;
    }else if(i==count-2){
     return 1
    }
  }
  
  else{
  return Math.sign(active - i)
  }
  }
  const setAbsOffSet=(i)=>{
    if(i==active){
      return 0;
    }
    else if((active==0 && (i==1 ||i==count-1)) ||( active==count-1 && (i==0 || i==count-2)) ){
    
        return 1/3
     
    
    }
    else{
    return Math.abs(active - i) / 3
    }
  
  }

  const getVisible=(i)=>{
    if(i==active)
    return 0
    else if((active==0 && (i==1 ||i==count-1)) ||( active==count-1 && (i==0 || i==count-2))){
     return 1;
    }
   else{
      return Math.abs(active - i) 
    }
  }
 

  useEffect(() => {
    if(!reset){
    const mount = setInterval(() =>{
      
        setActive((prevActive) => (prevActive + 1) % count);
      
    }, AUTO_SCROLL_INTERVAL)
  
    return () => clearInterval(mount);
   }
   
  }, [reset]);

  return (
  
    <div className='Emagazine-carousel' >
    {count>1? <button className='Emagazine-nav left' onClick={() => setActive(i => i - 1)} disabled={active <= 0}><Prev/></button>:null}
    {React.Children.map(children, (child, i) => (

      <div className={i==active?"Emagazine-card-container":"Emagazine-card-container not-active"} style={{
          '--active': i === active ? 1 : 0,
          '--offset': offSet(i),// when active = 0 or (count-1)
          '--direction': setSign(i),
          '--abs-offset': setAbsOffSet(i),
          'pointerEvents': active === i ? 'auto' : 'none',
          'opacity': getVisible(i) >= MAX_VISIBILITY ? '0' : '1',
          'display': getVisible(i) > MAX_VISIBILITY ? 'none' : 'block',
        }}  onMouseEnter={() => {setActive(active);setReset(!reset)}}
        onMouseLeave={() => setReset(!reset)}>
        {child}
      </div>
    ))}
    {count>1?  <button className='Emagazine-nav right' onClick={() => setActive(i => i + 1)}  disabled={active >= count - 1} ><Next /></button>:null}
  </div>
    );
};

export default Carousel1;
