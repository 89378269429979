import ImageGallery from 'react-image-gallery';
import React, { lazy,useState,useEffect,useRef } from 'react';
import styled from 'styled-components';
import 'react-image-gallery/styles/css/image-gallery.css'
import { LoadingOutlined} from '@ant-design/icons';
import { connect } from 'react-redux';
import { getGallery} from '../../../redux/action';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import {ReactComponent as Ltop} from './L-top.svg';
import {ReactComponent as Lbottom} from './L-bottom.svg';
import './style.css'
const Gallery = ({getGallery,gallery}) => {


  const [loader ,setLoader]=useState(false);
  const [loadData ,setLoadData]=useState(false)
// useEffect(()=>{
//   var objScrollTop = 0;
//   var scroll = 0;
  
//   $(window).on('load', function()
//   {
//     parallax(document.getElementById('level1'), -1.5, -4800);
//     parallax(document.getElementById('level2'), -1, -2900);
//     parallax(document.getElementById('level3'), -1.3, 200);
//   });
  
//   $('body').on('mousewheel DOMMouseScroll MozMousePixelScroll wheel scroll',
//   // document.getElementById('images-container').addEventListener('scroll', 
//   function(e)
//   { 
  
    
  
//     if ( e.originalEvent.wheelDelta !== undefined )
//     {
//       parallax(document.getElementById('level1'), -1.5, e.originalEvent.wheelDelta);
//       parallax(document.getElementById('level2'), -1, e.originalEvent.wheelDelta);
//       parallax(document.getElementById('level3'), -1.3, e.originalEvent.wheelDelta);
  
//     }else if ( e.originalEvent.deltaY !== undefined )
//     {
//       parallax(document.getElementById('level1'), -1.5, (e.originalEvent.deltaY * (-30)));
//       parallax(document.getElementById('level2'), -1, (e.originalEvent.deltaY * (-30)));
//       parallax(document.getElementById('level3'), -1.3, (e.originalEvent.deltaY * (-30)));
//     }
//   });
  
//   function parallax(target, layer, scrollinit) 
//   {
//       var layer_coeff = 10 / layer;
//       scroll = scroll + ( parseInt(scrollinit) );
    
//       // var y = ($(window).height() - target.offsetHeight) / 2 - (scroll - ($(window).height() / 2)) / layer_coeff;
//       var y 		= ( $(window).height() / 2 ) - (scroll - ($(window).height() / 2)) / layer_coeff;
//       var scarto 	= target.offsetHeight - $(window).height();
  
//       // Aggiunge sotto la gallery quando si avvicina alla fine della colonna
//       if ( ( y < 0 ) && ( Math.abs(y) >= scarto ) )	append_gallery(target);
  
//       if ( ( parseInt(scrollinit) >= 0 ) && ( Math.abs(scroll) < 6000 ) )
//       {
//         scroll = scroll - 150;
//         return;
//       }	
  
//       $(target).css('marginTop', y);
//   }
  
//   function append_gallery(column)
//   {
//     var container 	= $(column).find('.gallery'),
//       items 		= container.children();
  
//     items.clone().appendTo(container);
//   }
// })

const [randomizedChunks, setRandomizedChunks] = useState([]);

const [bannerIndex,setBannerIndex]=useState(0);
const [reset,setReset]=useState(true);
const [mouseHover,setMouseHover]=useState(false);

function generateRandomIndices(n) {
  const indices = [];
  const maxIndex = n - 1;

  while (indices.length < 6) {
    const randomIndex = Math.floor(Math.random() * (maxIndex + 1));
    if (!indices.includes(randomIndex)) {
      indices.push(randomIndex);
    }
  }

  return indices;
}



useEffect(()=>{
  


  if(!gallery|| gallery?.length==0){
    setLoader(true)
    setLoadData(false)
    }
    getGallery(()=>{
    setLoader(false)
    
    setLoadData(true);
 })

},[])
useEffect(()=>{
  setReset(false)
  if(loadData)
  callShuffle();
},[loadData])
const callShuffle=()=>{
  setBannerIndex(0);
 
  let galleryLen=gallery?.length;
  const chunks =  generateRandomIndices(galleryLen);
  setRandomizedChunks(chunks);
}






useEffect(() => {
  if(!reset){
  const interval = setInterval(() => {
 
      
    
    setBannerIndex((prevIndex) =>{
      if(prevIndex==5){
        if(!mouseHover){
          callShuffle();
        }
      
        return 0;
      }else{
    return  (prevIndex + 1) % 6
      }
     
     
    
    })

  }, 3000);
 
  return () => clearInterval(interval);
}
}, [randomizedChunks,reset]);


console.log(randomizedChunks)
let bannerImg=gallery?.[randomizedChunks?.[bannerIndex]];
let bannerExt=bannerImg?.substring(bannerImg?.lastIndexOf('.') + 1, bannerImg?.length);





//  const images=gallery?.map((ele,index)=>{
//   let ext=ele.substring(ele.lastIndexOf('.') + 1, ele.length);
//  let isVideo=ext=='mp4'||ext=='MP4';
//   return   {
//     original: ele,
//     thumbnail: isVideo? 'https://t3.ftcdn.net/jpg/03/42/21/84/360_F_342218449_i0kAjwQV2iRUiifEAj9Pf4VWlwJ108KY.jpg':ele,
//     embedUrl:isVideo?ele:null,
//     index:index

//   }
//  })
//  const renderItem = (item) => {
//   if (item.embedUrl) {
//     return (
//         <video  controls   style={{maxHeight:'100vh',maxWidth:'100%',}} >
//           <source src={item.embedUrl} type="video/mp4" />
//         </video>
      
//     );
//   } else {
//     return (
     
//         <img
//         style={{maxHeight:'100vh',maxWidth:'100%',}}
//           src={item.original}
//           alt={item.originalAlt}
//         />
      
    
//     );
//   }
// };


  


    return(
<>

   {/* <GalleryStyle>
   <div className='gallery-heading title' ><span className='highlight' >Gallery</span> </div>
   {loader?<div  style={{'minHeight':'280px','display':'flex',justifyContent:'center','alignItems':'center',flexDirection:'column'}}>
       <LoadingOutlined style={{fontSize: "3em"}}/>
                </div>   : 
                 <>
                <div className='image-wrapper'>
          {gallery?.map(img=>{
            let ext=img.substring(img.lastIndexOf('.') + 1, img.length);
            let isVideo=ext=='mp4'||ext=='MP4';
            if(!isVideo){
              return <img src={img} className='images'></img>
            }else{
              return  <video   loop mutedd  style={{maxHeight:'100vh',maxWidth:'100%',}}  className='images'>
              <source src={img} type="video/mp4" />
            </video>
            }
          })}
            
          </div> 
          <div style={{width:'100vw',display:'flex',justifyContent:'center',marginTop:'40px'}}>
            <Link 
            to='/our-story/gallery' 
            onClick={() => {
              document.body.scrollTop = 0; // For Safari
              document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
            }}
          >
            <Button type = 'default' className='card-button primary'>
              View More
            </Button>
          </Link>
          </div>
         </>
        }    
   </GalleryStyle>
      */}

<ImageCard>
      <>
       {/* <div className='gallery-heading title'><div className='gradient7' style={{'height':'72px'}}>Gallery<div className='polygon2' style={{'top':'12%'}}></div></div></div> */}
       <div className='gallery-heading title'  ><span className='highlight' >Gallery</span> </div>
  
       {loader?<div  style={{'minHeight':'280px','display':'flex',justifyContent:'center','alignItems':'center',flexDirection:'column'}}>
       <LoadingOutlined style={{fontSize: "3em"}}/>
                </div>   :  
                <>
                {/* <div className='gallery-grid-wrapper'>
                <div class="grid">
             <div class="column">
              {gallery?.map((ele,index)=>{
                if(index<gallery.length/3){
                  let ext=ele.substring(ele.lastIndexOf('.') + 1, ele.length);
                let isVideo=ext=='mp4'||ext=='MP4';
                        if(isVideo){
                        return  <video  controls   style={{maxHeight:'100vh',maxWidth:'100%',}} >
                        <source src={ele} type="video/mp4" />
                        </video>
                        }
                        else         
                        return <img src={ele}></img>
                }else{
               return null
                }

             
              })}

                </div>
                <div class="column">
              {gallery?.map((ele,index)=>{
                if(index>gallery.length/3 && index <gallery.length*2/3){
                  let ext=ele.substring(ele.lastIndexOf('.') + 1, ele.length);
                  let isVideo=ext=='mp4'||ext=='MP4';
                          if(isVideo){
                          return  <video  controls   style={{maxHeight:'100vh',maxWidth:'100%',}} >
                          <source src={ele} type="video/mp4"  />
                          </video>
                          }
                          else         
                          return <img src={ele}></img>
                }else{
               return null
                }

             
              })}

                </div>
                <div class="column">
              {gallery?.map((ele,index)=>{
                if(index>gallery.length*2/3 && index <gallery.length){
                  let ext=ele.substring(ele.lastIndexOf('.') + 1, ele.length);
                  let isVideo=ext=='mp4'||ext=='MP4';
                          if(isVideo){
                          return  <video  controls   style={{maxHeight:'100vh',maxWidth:'100%',}} >
                          <source src={ele} type="video/mp4" />
                          </video>
                          }
                          else         
                          return <img src={ele}></img>
                }else{
               return null
                }

             
              })}

                </div>
                   </div>
                </div> */}
       {/* <div id='overlay-grid-1'></div> */}
        {/* <div class="container" id='images-container' onMouseEnter={() => {  document.body.style.overflowY = 'hidden';
    document.getElementById('images-container').style.overflowY = 'scroll'
    document.getElementById('images-container').style.overflowX = 'hidden';}} onMouseLeave={()=>{ document.body.style.overflowY = 'auto';
    document.getElementById('images-container').style.overflowY = 'hidden';}} >
 
 	
                    <div id="level1" class="row">
 
                            <div class="col1">
       
                                <div class="gallery">
                                {gallery?.map((ele,index)=>{
                if(index<gallery.length/3){
                  let ext=ele.substring(ele.lastIndexOf('.') + 1, ele.length);
                let isVideo=ext=='mp4'||ext=='MP4';
                        if(isVideo){
                        return 	<div class="gallery_item"> <video   muted={true}   loop  controls style={{maxHeight:'100vh',maxWidth:'100%',}} >
                        <source src={ele} type="video/mp4" />
                        </video></div>
                        }
                        else         
                        return 	<div class="gallery_item"> <img src={ele}></img></div>
                }else{
               return null
                }

             
              })}
                                 </div>
                            </div>
                    </div>
                    <div id="level2" class="row">
 
                  <div class="col2">

                      <div class="gallery">
                      {gallery?.map((ele,index)=>{
                if(index>gallery.length/3 && index <gallery.length*2/3){
                  let ext=ele.substring(ele.lastIndexOf('.') + 1, ele.length);
                  let isVideo=ext=='mp4'||ext=='MP4';
                  if(isVideo){
                  return 	<div class="gallery_item"> <video  muted={true} controls  loop   style={{maxHeight:'100vh',maxWidth:'100%',}} >
                  <source src={ele} type="video/mp4" />
                  </video></div>
                  }
                  else         
                  return 	<div class="gallery_item"> <img src={ele}></img></div>
                  }else{
                  return null
                  }


                  })}
                        </div>
                  </div>
                  </div>

                  <div id="level3" class="row">
 
                    <div class="col3">

                      <div class="gallery">
                      {gallery?.map((ele,index)=>{
                   if(index>gallery.length*2/3 && index <gallery.length){
                    let ext=ele.substring(ele.lastIndexOf('.') + 1, ele.length);
                    let isVideo=ext=='mp4'||ext=='MP4';
                    if(isVideo){
                    return 	<div class="gallery_item"> <video   muted={true} controls loop  style={{maxHeight:'100vh',maxWidth:'100%',}} >
                    <source src={ele} type="video/mp4" />
                    </video></div>
                    }
                    else         
                    return 	<div class="gallery_item"> <img src={ele}></img></div>
                    }else{
                    return null
                    }


                    })}
                        </div>
                    </div>
                    </div>
        </div> */}
        {/* <div id='overlay-grid-2'></div> */}

        {/* <div class="container">
          {gallery?.map(ele=>{
             
              return  <img src={ele} class="vertical"/>
          
          })}
      <a href="https://source.unsplash.com/600x600/?sig=1" data-lightbox="homePortfolio">
        <img src="https://source.unsplash.com/600x600/?sig=1"/>
      </a>
      
      <a href="https://source.unsplash.com/600x800/?sig=12" data-lightbox="homePortfolio" class="vertical">
        <img src="https://source.unsplash.com/600x800/?sig=12"/>
      </a>
      
      <a href="https://source.unsplash.com/800x600/?sig=71" data-lightbox="homePortfolio" class="horizontal">
        <img src="https://source.unsplash.com/800x600/?sig=71"/>
      </a>
      
      <a href="https://source.unsplash.com/600x600/?sig=40" data-lightbox="homePortfolio">
        <img src="https://source.unsplash.com/600x600/?sig=40"/>
      </a>
      
      <a href="https://source.unsplash.com/600x600/?sig=32" data-lightbox="homePortfolio">
        <img src="https://source.unsplash.com/600x600/?sig=32"/>
      </a>
      
      <a href="https://source.unsplash.com/800x800/?sig=7" data-lightbox="homePortfolio" class="big">
        <img src="https://source.unsplash.com/800x800/?sig=7"/>
      </a>
      
      <a href="https://source.unsplash.com/600x600/?sig=111" data-lightbox="homePortfolio">
        <img src="https://source.unsplash.com/600x600/?sig=111"/>
      </a>
      
      <a href="https://source.unsplash.com/600x800/?sig=94" data-lightbox="homePortfolio" class="vertical">
        <img src="https://source.unsplash.com/600x800/?sig=94"/>
      </a>
      
      <a href="https://source.unsplash.com/600x600/?sig=11" data-lightbox="homePortfolio">
        <img src="https://source.unsplash.com/600x600/?sig=11"/>
      </a>
      
      <a href="https://source.unsplash.com/800x600/?sig=68" data-lightbox="homePortfolio" class="horizontal">
        <img src="https://source.unsplash.com/800x600/?sig=68"/>
      </a>
      
      <a href="https://source.unsplash.com/600x600/?sig=24" data-lightbox="homePortfolio">
        <img src="https://source.unsplash.com/600x600/?sig=24"/>
      </a>
      
      <a href="https://source.unsplash.com/800x800/?sig=55" data-lightbox="homePortfolio" class="big">
        <img src="https://source.unsplash.com/800x800/?sig=55"/>
      </a>
      
      <a href="https://source.unsplash.com/600x600/?sig=56" data-lightbox="homePortfolio">
        <img src="https://source.unsplash.com/600x600/?sig=56"/>
      </a>
      
      <a href="https://source.unsplash.com/800x600/?sig=186" data-lightbox="homePortfolio" class="horizontal">
        <img src="https://source.unsplash.com/800x600/?sig=186"/>
      </a>
      
      <a href="https://source.unsplash.com/600x600/?sig=117" data-lightbox="homePortfolio">
        <img src="https://source.unsplash.com/600x600/?sig=117"/>
      </a>
      
      <a href="https://source.unsplash.com/800x800/?sig=157" data-lightbox="homePortfolio" class="big">
        <img src="https://source.unsplash.com/800x800/?sig=157"/>
      </a>
      
      <a href="https://source.unsplash.com/600x600/?sig=287" data-lightbox="homePortfolio">
        <img src="https://source.unsplash.com/600x600/?sig=287"/>
      </a>
      
      <a href="https://source.unsplash.com/600x800/?sig=307" data-lightbox="homePortfolio" class="vertical">
        <img src="https://source.unsplash.com/600x800/?sig=307"/>
      </a>
    </div> */}

    <div className='gallery-card'>
      <Ltop className='gallery-top'/>
      <Lbottom className='gallery-bottom'/>
      <div className='gallery-menu'  onMouseEnter={() => {setBannerIndex(bannerIndex);setReset(true);setMouseHover(true)}}
                    onMouseLeave={() =>{
                      setReset(false)
                      setMouseHover(false)
                    } }
                    >
        {
            bannerExt=='mp4'||bannerExt=='MP4'?
              <video   controls style={{height:'100%',width:'300px',cursor:'pointer'}} >
              <source src={bannerImg} type="video/mp4" />
              </video>
            :
            <img className='gallery-main-image' src={bannerImg } alt='banner-img'></img>
             
        }
      
      </div>
      <div className='gallery-sub-menu'>

        <div className='gallery-images-wrapper'>
        {randomizedChunks?.map((index,i) => {
          let image=gallery[index];
           let ext=image.substring(image.lastIndexOf('.') + 1, image.length);
           let isVideo=ext=='mp4'||ext=='MP4';
         return isVideo?
         <div className={`gallery-sub-image ${i==bannerIndex?'selected':'not-selected'}`}
         onClick={()=>{setBannerIndex(i)}} 
         onMouseEnter={() => {
          if(bannerIndex==i){
            setBannerIndex(i);
          }
          
          setReset(true);setMouseHover(true)}}
         onMouseLeave={() =>{
           setReset(false)
           setMouseHover(false)
         } } 
         >

         <video   muted={true}   loop  autoPlay playsInline style={{height:'100%',width:'100%',cursor:'pointer'}} >
         <source src={image} type="video/mp4" />
         </video>
         </div>:
          <img className={`gallery-sub-image ${i==bannerIndex?'selected':'not-selected'}`} src={image} alt={`image-${i}`} 
          onClick={()=>{setBannerIndex(i)}} 
          onMouseEnter={() => {
            if(bannerIndex==i){
              setBannerIndex(i);
            }
            setReset(true);setMouseHover(true)}}
          onMouseLeave={() =>{
            setReset(false)
            setMouseHover(false)
          } }
          ></img>
})}
        </div>
        <div className='gallery-view-btn'>
        <Link 
            to='/our-story/gallery' 
            onClick={() => {
              document.body.scrollTop = 0; // For Safari
              document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
            }}
          >
            <Button type = 'default' className='card-button primary' >
              View More
            </Button>
          </Link>
        </div>

      </div>
    </div>
                {/* <div style={{display:'flex',justifyContent:'center',marginTop:'40px',}}>
            <Link 
            to='/our-story/gallery' 
            onClick={() => {
              document.body.scrollTop = 0; // For Safari
              document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
            }}
          >
            <Button type = 'default' className='card-button primary' >
              View More
            </Button>
          </Link>
          </div> */}
              </>
        //         <ImageGallery 
        //   items={images} 
        //   showFullscreenButton={true} 
        //   loading={lazy}
        // //  ={true}
        //   showPlayButton={false}
        //   // slideInterval={3000}
        //   originalHeight={300}
        //   originalWidth={300}
        //   renderItem={renderItem}
        // infinite
         
                  
        // /> 
//  
        
    }
     </>
     </ImageCard> 
     </>
    
    )
}

const ImageCard = styled.div`

  padding-bottom: 80px;
  // padding-left: 1vw;
  // padding-right: 1vw;
  

 
//   .image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
//     height: 80px;
//     width: 80px;
// }
// .container{

//   height:120vh;
//   width:100vw;
//   position:relative;
//   padding-top:70vh;
//   background:white;

// }
// .container .row{

//   position:absolute;
  
// }
#overlay-grid-1{
  background:linear-gradient(transparent, rgba(233, 243, 253, 1),transparent);
  position:absolute;
  width:100vw;
  height:100px;
  top:-50%;
  left:0;
  right:0;
  bottom:0;
  z-index:200;
  
}
#overlay-grid-2{
  background:linear-gradient(transparent, rgba(233, 243, 253, 1),transparent);
  position:absolute;
  width:100vw;
  height:20%;
  top:-50%;
  left:0;
  right:0;
  bottom:0;



  z-index:200;
  
  
}
.grid-wrapper-gallery::-webkit-scrollbar{
  display:none;
}

// .image-gallery-thumbnail .image-gallery-thumbnail-image {
//   vertical-align: middle;
//   width: 100%;
//   line-height: 0;
//   height: 100px;
// }
// .image-gallery-icon{
//   z-index:3 !important;
// }
  .title{
    display: block;
    text-align: center;
    margin-bottom: 50px;
    font-size: calc(2vw + 18px);
    font-weight: 700;
  }
  .gallery-heading{
    font-size: calc(2vw + 10px);
    font-weight: 700;
    position:relative;
    z-index:1;
}
// .image-gallery-content .image-gallery-slide .image-gallery-image{

//   max-width:100%;
// object-fit:cover;
// }


// @import "https://unpkg.com/normalize.css";

.card-button{
  margin-top: 20px;
  padding: 2vw 3vw;
  font-weight: 700;
  text-transform: uppercase;
  font-size: calc(1vw + 3px);
  border-color: #0086FF;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Sofia Sans', sans-serif;
  background-color: #0086FF !important;
  color:white !important;
  box-shadow: 0 20px 40px 0 rgba(35, 100, 210, 0.2);
}

.card-button:hover{
  transform: scale(1.02);
  transition: all 0.1s ease-out;
  background: #0086ff;
  color: #fff !important;
  box-shadow: 0 20px 5px -10px rgba(0, 0, 0, 0.4);
  transform: translateY(1px);
}



// new gallery
 

  .gallery-card{
    display:flex;
    flex-direction:row;
    background-color:rgba(38, 50, 56, 1);
    width:1000px;
    height:522px;
    border-radius:10px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    margin:0 auto;
    justify-content:center;
    align-items:center;
    gap:30px;
    position:relative;


  }
  .gallery-top{
    position:absolute;
    top:-10%;
    left:-5%;

    width:200px;
    height:200px;
  }
  .gallery-bottom{
    position:absolute;
    bottom:-10%;
    right:-5%;
 
    width:200px;
    height:200px;
  }
  .gallery-menu{
    display:flex;
    justify-content:center;
    align-item:center;
  }
  .gallery-main-image{
    width:350px;
    height:350px;
    border-radius:5px;
    object-fit:cover;
  
  }
.gallery-sub-menu{
  display:flex;
  justify-content:center;
  flex-direction:column;
  height:350px;
}
.gallery-sub-image{
 width:150px;
 height:110px;
 background-color:rgba(38, 50, 56, 1);
 box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
 border-radius:5px;
 cursor:pointer;
 object-fit:contain;
}
.selected{
  border:2px solid white;
}
.not-selected{
  border:none;
}

.gallery-images-wrapper{
 display:grid;
 grid-template-columns: repeat(3, 150px);
 gap:10px;
}
.gallery-view-btn{
  display:flex;
  align-self:flex-end;
}

@media screen and (max-width:1020px){
  .gallery-card{
    width:90vw;
    max-width:600px;
    height:auto;
    padding:30px;
    flex-direction:column;
  }
  .gallery-view-btn{
    display:flex;
    align-self:center;
  }
}
  @media screen and (max-width: 767px){
    // padding-left: 8vw;
    // padding-right: 8vw;
    .card-button{
      font-size: calc(1vw + 6px);
    padding: 2vw 2vw;
  
    }
    .gallery-top{
      position:absolute;
      top:-7%;
      left:-6%;
  
      width:200px;
      height:200px;
    }
    .gallery-bottom{
      position:absolute;
      bottom:-7%;
      right:-6%;
   
      width:200px;
      height:200px;
    }

   
  .image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
    height: 60px;
    width: 60px;
}

  .title{
    font-size: calc(2vw + 20px);
  }
}


@media screen and (max-width: 570px){
  .gallery-card{
    width:88vw;
    max-width:450px;
    height:auto;
    padding:30px;
    flex-direction:column;
  }
  .gallery-main-image{
    width:260px;
    height:200px;
    border-radius:5px;
  }
.gallery-sub-menu{
  display:flex;
  justify-content:center;
  flex-direction:column;
  height:200px;
}
.gallery-sub-image{
 width:80px;
 height:80px;
}
.gallery-images-wrapper{
  display:grid;
  grid-template-columns: repeat(3, 80px);
  gap:10px;
 }
 .gallery-top{
  position:absolute;
  top:-7%;
  left:-8%;

  width:120px;
  height:120px;
}
.gallery-bottom{
  position:absolute;
  bottom:-7%;
  right:-8%;

  width:120px;
  height:120px;
}

}
`

// const GalleryStyle=styled.div`
// padding-bottom: 80px;
// // padding-left: 8vw;
// // padding-right: 8vw;

// :root {
//   --duration: 20s;
//   --delay: 20s;
// }
// * {
//   margin: 0;
//   padding: 0;
//   box-sizing: border-box;
// }
// .image-wrapper{
//   position: relative;
// height:150vh;
//   margin: 0 auto;
//   padding: 0 1rem;
//   margin-top:100px;
//   overflow: hidden;
//   transform-style: preserve-3d;
//   perspective: 25rem;
// }
// .images {
//   --duration: 40s;
//   --name: float-up-1;
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 33vw;
//   transform: translateY(200vh);
//   animation: var(--name) var(--duration) linear infinite;
//   box-shadow: 1px 3px 15px rgba(0, 0, 0, 0.5);
//   z-index: 1;
// }

// .card-button{
//   margin-top: 20px;
//   padding: 2vw 3vw;
//   font-weight: 700;
//   text-transform: uppercase;
//   font-size: calc(1vw + 3px);
//   border-color: #0086FF;
//   border-radius: 4px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   font-family: 'Sofia Sans', sans-serif;
//   background-color: #0086FF !important;
//   color:white !important;
//   box-shadow: 0 20px 40px 0 rgba(35, 100, 210, 0.2);
// }

// .card-button:hover{
//   transform: scale(1.02);
//   transition: all 0.1s ease-out;
//   background: #0086ff;
//   color: #fff !important;
//   box-shadow: 0 20px 5px -10px rgba(0, 0, 0, 0.4);
//   transform: translateY(1px);
// }


// .images:nth-child(1) {
//   animation-delay: 0s;
// }
// .images:nth-child(2) {
//   animation-delay: -3s;
// }
// .images:nth-child(3) {
//   animation-delay: -6s;
// }
// .images:nth-child(4) {
//   animation-delay: -15s;
// }
// .images:nth-child(5) {
//   animation-delay: -12s;
// }
// .images:nth-child(6) {
//   animation-delay: -15s;
// }
// .images:nth-child(7) {
//   animation-delay: -18s;
// }
// .images:nth-child(8) {
//   animation-delay: -21s;
// }
// .images:nth-child(9) {
//   animation-delay: -24s;
// }
// .images:nth-child(10) {
//   animation-delay: -27s;
// }
// .images:nth-child(11) {
//   animation-delay: -30s;
// }
// .images:nth-child(12) {
//   animation-delay: -33s;
// }
// .images:nth-child(13) {
//   animation-delay: -36s;
// }
// .images:nth-child(14) {
//   animation-delay: -39s;
// }
// // .images:nth-child(15) {
// //   animation-delay: -41;
// // }
// // .images:nth-child(16) {
// //   animation-delay: -22s;
// // }
// // .images:nth-child(17) {
// //   animation-delay: -30s;
// // }
// // .images:nth-child(18) {
// //   animation-delay: -10s;
// // }
// // .images:nth-child(19) {
// //   animation-delay: -29s;
// // }
// // .images:nth-child(20) {
// //   animation-delay: -32s;
// // }
// .images:nth-child(1) {
//   --name: float-up-1;
//   --duration: calc(20s * 1);
//   left: 0vw;
//   z-index: -1;
// }
// .images:nth-child(2) {
//   --name: float-up-3;
//   --duration: calc(20s * 3);
//   left: 10vw;
//   z-index: -3;
// }
// .images:nth-child(3) {
//   --name: float-up-2;
//   --duration: calc(20s * 2);
//   left: 90vw;
//   z-index: -2;
// }
// .images:nth-child(4) {
//   --name: float-up-1;
//   --duration: calc(20s * 1);
//   left: 36vw;
//   z-index: -1;
// }
// .images:nth-child(5) {
//   --name: float-up-3;
//   --duration: calc(20s * 3);
//   left: 62vw;
//   z-index: -3;
// }
// .images:nth-child(6) {
//   --name: float-up-2;
//   --duration: calc(20s * 2);
//   left: 5vw;
//   z-index: -2;
// }
// .images:nth-child(7) {
//   --name: float-up-2;
//   --duration: calc(20s * 2);
//   left: 55vw;
//   z-index: -2;
// }
// .images:nth-child(8) {
//   --name: float-up-3;
//   --duration: calc(20s * 3);
//   left: 20vw;
//   z-index: -3;
// }
// .images:nth-child(9) {
//   --name: float-up-1;
//   --duration: calc(20s * 1);
//   left: 88vw;
//   z-index: -1;
// }
// .images:nth-child(10) {
//   --name: float-up-2;
//   --duration: calc(20s * 2);
//   left: 6vw;
//   z-index: -2;
// }
// ///next
// .images:nth-child(11) {
//   --name: float-up-1;
//   --duration: calc(20s * 3);
//   left: 20vw;
//   z-index: -2;
// }
// .images:nth-child(12) {
//   --name: float-up-3;
//   --duration: calc(20s * 1);
//   left: 97vw;
//   z-index: 1;
// }
// .images:nth-child(13) {
//   --name: float-up-2;
//   --duration: calc(20s * 2);
//   left: 81vw;
//   z-index: -2;
// }
// .images:nth-child(14) {
//   --name: float-up-3;
//   --duration: calc(20s * 3);
//   left: -22vw;
//   z-index: 1;
// }
// .images:nth-child(15) {
//   --name: float-up-2;
//   --duration: calc(20s * 1);
//   left: 45vw;
//   z-index: -3;
// }
// // .images:nth-child(16) {
// //   --name: float-up-1;
// //   --duration: calc(20s * 3);
// //   left: 58vw;
// //   z-index: -2;
// // }
// // .images:nth-child(17) {
// //   --name: float-up-1;
// //   --duration: calc(20s * 2);
// //   left: 5vw;
// //   z-index: 2;
// // }
// // .images:nth-child(18) {
// //   --name: float-up-2;
// //   --duration: calc(20s * 1);
// //   left: 80vw;
// //   z-index: -3;
// // }
// // .images:nth-child(19) {
// //   --name: float-up-3;
// //   --duration: calc(20s * 1);
// //   left: 68vw;
// //   z-index: 1;
// // }
// // .images:nth-child(20) {
// //   --name: float-up-2;
// //   --duration: calc(20s * 2);
// //   left: 0vw;
// //   z-index: -2;
// // }
// @keyframes float-up-3 {
//   from {
//     transform: translateY(200vh) translateZ(-50vh);
//   }
//   to {
//     transform: translateY(-200vh) translateZ(-50vh);
//   }
// }
// @keyframes float-up-2 {
//   from {
//     transform: translateY(150vh) translateZ(-25vh);
//   }
//   to {
//     transform: translateY(-150vh) translateZ(-25vh);
//   }
// }
// @keyframes float-up-1 {
//   from {
//     transform: translateY(100vh);
//   }
//   to {
//     transform: translateY(-100vh);
//   }
// }

// .gallery-heading{
//   font-size: calc(2vw + 10px);
//   font-weight: 700;
//   position:relative;
//   z-index:1;
// }

// .title{
//   display: block;
//   text-align: center;
//   margin-bottom: 50px;
//   font-size: calc(2vw + 18px);
//   font-weight: 700;
// }
// @media screen and (min-width: 2000px){
//   padding-left: 15vw;
//   padding-right: 15vw;
// }

// @media screen and (max-width: 767px){
//   // padding-left: 8vw;
//   // padding-right: 8vw;

// .title{
//   font-size: calc(2vw + 20px);
// }
// }

// `
const mapStateToProps = state => {
  const {
      gallery
  }  = state.we;

  return { gallery }
}

const mapDispatchToProps = dispatch =>  ({
  getGallery: (successCallback) => dispatch(getGallery(successCallback)),
 
})

export default connect(mapStateToProps, mapDispatchToProps)(Gallery);



