
import React,{useState,useEffect} from 'react';
import styled from 'styled-components';
import { ArrowLeftOutlined,LoadingOutlined } from '@ant-design/icons';
import { Row, Col, Button } from 'antd';
import Img1 from './safety-illustration.svg';
import { ReactComponent as Back } from '../../../../../assets/back-icon.svg';
import { ReactComponent as Arrow } from '../../../../we/subpages/ourStory/PotentialCard/Vector.svg';
import {Link, useNavigate} from 'react-router-dom';
import bg2 from '../../../../../assets/bg2.svg'
import { getSuggestedBlogs } from '../../../../we/redux/action';
import { connect } from 'react-redux';
import active1 from './icons/active1.svg';
import active2 from './icons/active2.svg'
import active3 from './icons/active3.svg'
import active4 from './icons/active4.svg'
import active5 from './icons/active5.svg'
import active6 from './icons/active6.svg'
import active7 from './icons/active7.svg'
import active8 from './icons/active8.svg'
import active9 from './icons/active9.svg';
import wave1 from './icons/wave1.svg';
import wave2 from './icons/wave2.svg';
import passive1 from './icons/passive1.svg';
import passive2 from './icons/passive2.svg';
import passive3 from './icons/passive3.svg';

function SafetySystem({suggestedBlogs,getSuggestedBlogs}) {
    const navigate = useNavigate();
    const handleBackBtn=(path)=>{
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        window.history.back();
    }
    const [loader,setLoader]=useState(false);

    useEffect(()=>{
   
      let params={
        tag:['SAFETY SYSTEM','MOBILITY','SAFETY']
      }
    if(!suggestedBlogs||suggestedBlogs.length==0){
       
      setLoader(true)
}
  getSuggestedBlogs(params,()=>{
      setLoader(false)
  })
    },[])
    
    return (
        <React.Fragment>
     
        < SafetySystemContainer>
        <div style={{'display':'flex','alignItems':'center','justifyContent':'flex-start',position:'relative',gap:'10px', paddingRight: '8vw',
      paddingLeft: '8vw'}}>
            <Back className='back-btn' onClick={handleBackBtn} />
       
            </div>
          
            <div className='SafetySystem-header heading' style={{flex:1,alignSelf:'center',display:'flex',justifyContent:'center'}}>Safety <div className='highlight'>System</div></div>
           
            <BigCard {...Obj1}/>
            {loader ? <div  style={{'minHeight':'280px','display':'flex',justifyContent:'center','alignItems':'center',flexDirection:'column'}}>
                <LoadingOutlined style={{fontSize: "3em"}}/> 
                 
                </div> : suggestedBlogs?.length==0? 
                null:      <>   
                <Row gutter={10} style={{marginTop:'50px'}}  >
                <span style={{float:'left',fontSize:'23px',fontFamily:'Sofia Sans, sans-serif',paddingRight:'40px'}}>Also Read : </span>
              
                 
               
              </Row>
              <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'center',flexWrap:'wrap',gap:'18px', marginTop: '10px'}}>
                 {suggestedBlogs?.map(blog=>{
                    return  <Col >
                    <Card  {...blog}/>
                </Col>
                  })}
              
              </div></>
            }
        </ SafetySystemContainer>
      
        </React.Fragment>
    )
}

const BigCard=({
    btnType,
    mainheadline,
    description,
    buttonLabel,
    img,
    alt,
    imgStart,
    description1,
    description2,
    description3,
    description4,
    subheadline1,
    subheadline2,
    description5,
    subhead1,
    subhead2,subhead3
})=>{
    
   const scrollToTop=()=>{
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    
    }
    return(
        <>
        <CardStyle
        wrap={true}
      >
          {/* <Col 
          xs={{span: 24, order: 2}} 
          sm={{span: 24, order: 2}} 
          md={{span: 12, order:  1}} 
          lg={{span: 12, order:  1}} 
          xl={{span: 12, order:  1}}
          xxl={{span: 12, order:  1}}
          className= 'card-text-container left'
          gutter = {20}
          >
             
             <h3 className='content card-heading'>Active Safety Systems</h3>
         
            <ul className='card-content content' style={{paddingLeft:'18px'}}>
              <li>Head-Up Displays (HUD): Project important information onto the windshield for drivers to see while
keeping their eyes on the road.</li>
              <li>Anti-Lock Braking Systems (ABS): Prevent wheels from locking up during emergency braking,
improving steering control.</li>
              <li>Electronic Stability Control (ESC): Help drivers maintain control in challenging driving conditions.</li>
              <li>Tire Pressure Monitoring Systems (TPMS): Continuously monitor tire pressure to alert drivers of
unsafe levels.</li>
<li>Lane Departure Warning Systems (LDWS): Alert drivers when they unintentionally drift out of their
lane.</li>
<li>Adaptive Cruise Control (ACC): Adjust vehicle speed to maintain a safe distance from the vehicle
ahead.</li>
<li>Driver Monitoring Systems (DMS): Track driver behavior to detect fatigue or distraction.</li>
<li>Blind Spot Detection (BSD): Warn drivers of vehicles in blind spots during lane changes.</li>
<li>Night Vision Systems (NVS): Enhance visibility in low-light conditions.</li>
            </ul>

                 
            <h3 className='content card-heading'>Wave Two of Active Safety</h3>
         
         <ul className='card-content content' style={{paddingLeft:'18px'}}>
           <li>Autonomous Emergency Braking (AEB): Automatically apply brakes to prevent or reduce collision
severity.</li>
           <li>Drowsiness and Attention Detection Systems: Monitor driver attentiveness and provide alerts for
breaks.</li>
<li>ABS (Anti-Lock Braking System): Prevents wheels from locking up during sudden braking, enhancing
steering control.</li>
<li>Cruise Control: Maintains a steady speed set by the driver, ensuring a safer and more relaxed driving
experience.</li>
         </ul>

         <h3 className='content card-heading'>Passive Safety Features</h3>
         
         <ul className='card-content content' style={{paddingLeft:'18px'}}>
           <li>Airbags: Rapidly deploy to provide crucial protection to occupants in case of a
collision.</li>
          
<li>EDR (Event Data Recorder): Captures crash data for analysis, aiding in accident
reconstruction and improving safety measures.</li>

<li>Seatbelts: Safely restrain occupants, minimizing the risk of injury during sudden
stops or accidents.</li>
        
         </ul>
          
<p className='card-content content'>
These safety systems and features aim to prevent accidents, enhance driver assistance, and minimize
injuries in the event of a crash.
</p>
            
            
          </Col> */}
        
          <div style={{display:'flex',flexDirection:'column'}}>
          <div   style={{
      background:`url(${bg2}) center no-repeat`,backgroundSize:'100%',
      paddingRight: '8vw',
      paddingLeft: '8vw'
  
      }}> 
          <h2 className='card-heading-top'><span className='highlight'>Active Safety</span> Systems</h2>
            <div className='icon-grid'>
              {obj1.map(ele=>{
            return <div className='icon-card'>
            <img src={ele.img} alt='mobility solutions' className='icon-image'/>
            <h3 className='card-heading'>{ele.title}</h3>
            <p className='card-content'>{ele.content}</p>
          </div>
              })}
            
            </div>
            </div>
       
            
            <div   style={{
      background:`url(${bg2}) center no-repeat`,backgroundSize:'100%',
      backgroundPositionY:'20%',
      paddingRight: '8vw',
      paddingLeft: '8vw'
  
      }}> 
      <br/>
      <h2 className='card-heading-top'>Wave Two of <span className='highlight'>Active Safety</span></h2>
            <div className='icon-grid'>
              {obj2.map(ele=>{
            return <div className='icon-card'>
            <img src={ele.img} alt="application development"  className='icon-image'/>
            <h3 className='card-heading'>{ele.title}</h3>
            <p className='card-content'>{ele.content}</p>
          </div>
              })}
            
            </div>
            <br/>
            <h2 className='card-heading-top'><span className='highlight'>Passive Safety</span> Features</h2>
            <div className='icon-grid'>
              {obj3.map(ele=>{
            return <div className='icon-card'>
            <img src={ele.img} alt='app development'  className='icon-image'/>
            <h3 className='card-heading'>{ele.title}</h3>
            <p className='card-content'>{ele.content}</p>
          </div>
              })}
            
            </div>
            </div>
          </div>
  
          {/* <Col 
          xs={{span: 24, order: 1}}  
          sm={{span: 24, order: 1}}  
          md={{span: 12, order:  2}}
          lg={{span: 12, order:  2}}
          xl={{span: 12, order:  2}}
          xxl={{span: 12, order: 2}}
          className='content'
          style={{'display':'flex',justifyContent:'center',alignItems:'flex-start'}}
          >
            {
            <img src={Img1} alt={alt} className='card-image' /> 
            }  
           
          </Col> */}
      </CardStyle>
   
    </>
    )
}
const  SafetySystemContainer = styled.div`
    height: auto;
    background-image: linear-gradient(rgba(233, 243, 253, 1),white );
    padding-top: 40px;
    // padding-right: 8vw;
    // padding-left: 8vw;
    text-align: center;
    padding-bottom: 80px;
    min-height:70vh;
    .back-btn {
        // position:absolute;
        // left:0;
        cursor:pointer;
      
    }
  
    .SafetySystem-header{
        font-size: calc(2vw + 15px);
        font-weight: 700;
        position:relative;
        z-index:2;
        display:flex;
        gap:7px;
        align-items:center;
    }

    .SafetySystem-top{
        padding-top: 20px;
        font-size: calc(1vw + 5px);
        font-weight: 400;
    }

    @media screen and (max-width: 767px){
        // padding-right: 8vw;
        // padding-left: 8vw;
     
        .back-btn>svg{
          width:25px;
          height:25px;
        }
      .SafetySystem-header{
          font-size: calc(2vw + 20px);
                }

        .SafetySystem-top{
            font-size: calc(1vw + 10px);
        }
    }
`
const CardStyle = styled(Row)`
  padding-top: 20px;
  text-align:left;

  .card-text-container{
    height: auto;
    display: flex;
    
    flex-direction: column;
   
  }
  
  .card-button:hover{
       
    background-color: #0086FF !important;
    color:white !important;
 }
  

  .card-image:hover{
    transform: scale(1.01);
    transition: all 0.3s ease-out;
  }

  .left{
    text-align: left;
  }

  .right{
    text-align: right;
  }

  .card-image{
    width: 80%;
    transition: all 0.3s ease-out;
    margin-bottom:30px;
  }

  .button-holder{
    width: 100%;

    
  }

  .card-content{
    font-size: calc(1vw + 3px);
    font-weight: 400;
    text-align:left;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
    font-family: 'Inter', sans-serif;
  }

  .card-heading {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: calc(1vw + 6px);
    font-weight: 700;
    font-family: 'Sofia Sans', sans-serif;
  }
  .card-heading-top{
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: calc(1vw + 8px);
    font-weight: 700;
    font-family: 'Sofia Sans', sans-serif;
    margin-bottom:30px;
  }
  
  .card-button{
    margin-top: 20px;
    display:flex;
    justify-content:flex-start;
    align-items: center;
    font-size: calc(1vw + 3px);;
    border-color: #0086FF;
    border-radius: 4px;
    font-weight: 700;
    padding: 2vw 4vw;
  }

  .card-button:hover{
    transform: scale(1.02);
    transition: all 0.3s ease-out;
  }
  .icon-grid{
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    width:100%;
     gap:6px;

  }
  .icon-card{
    display:flex;
    flex-direction:column;
    gap:5px;
    width:33%;
    height:280px;
    padding:15px;
 
  }
  .icon-card:hover{
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.11);
    border-radius:4px;
    background:white;
   
  }
.icon-image{
  width:80px;
  height:80px;
  background:white;
  padding:10px;
  border-radius:4px;

  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.11);
}
 

  @media screen and (min-width: 2000px){
    .card-image{
      width: 75%;
    }
  }
  @media screen and (max-width: 1400px){
    .icon-card{
      display:flex;
      flex-direction:column;
      gap:5px;
      width:45%;
      height:280px;
      padding:15px;
   
    }
  }

  @media screen and (max-width: 767px){

    .card-content{
      font-size: calc(1vw + 10px);
      text-align: left !important;
    }
    .card-heading-top{
      font-size: calc(1vw + 18px);
    }
    .card-heading{
      text-align: left !important;
      font-size: calc(1vw + 15px);
    }

    .button-holder{
      justify-content: flex-start !important;
    }

    .card-button{
      padding: 3vw 6vw;
      font-size: calc(1vw + 6px);
    }
    .icon-card{
      display:flex;
      flex-direction:column;
      gap:5px;
      width:100%;
      height:230px;
      padding:15px;
   
    }
  }

  @media screen and (max-width: 425px){
    .card-button{
      padding: 4vw 6vw;
    }

    .card-image{
      width: 90%;
    }
  }
`

const Card = ({ title, locationPath,id }) => {
  return(
      <CardStyle1>
        



          <div class = "card">
          <Link to={`/our-story/blog/blogId=${id}`}>
  <img src={locationPath} alt="Event-Img" />
  <div class="card-content">
    <h2 >
    {title}
   
   
   </h2>
   <br/>
   <h3>  READ BLOG <Arrow></Arrow></h3>
  
    
  </div>
  </Link>
</div>

      </CardStyle1>
  )
}

  

const CardStyle1 = styled.div`
   max-width:350px;
  
   width:100%;
    margin-bottom: 10px;

    transition: transform 0.5s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
   max-height:270px;
   height:100%;
   

   .card {
    width: 100%;
    height: 100%;
    // border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    background-color: rgba(0,0,0,0.6);
    
    
  }
  .card img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.7;
    transition: opacity 0.2s ease-out;
  }
  .card h2 {
    position: absolute;
    inset: auto auto 50px 30px;
    margin: 0;
    transition: inset 0.3s 0.3s ease-out;
    font-family: 'Sofia Sans', sans-serif;
    font-weight: normal;
    text-transform: uppercase;
    color: #FFFFFF;
 
    text-align: left;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 240px;
 
  }
  .card h3 {
    position: absolute;
    inset: auto auto 20px 30px;
    margin: 0;
    transition: inset 0.3s 0.3s ease-out;
    font-family: 'Sofia Sans', sans-serif;
    font-weight: normal;
    text-transform: uppercase;
    color:#FFFFFF;
    width: 70%;
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis; 
    text-align:left;
    font-size:14px;
  }
 

    &:hover{
        transform: scale(1.02);
         box-shadow: 0 10px 20px 0  rgba(0,0,0,0.6);
    }

   



    .position{
        display: block;
        font-size: calc(1vw + 3px);
        margin-bottom: 10px;
        font-weight: 400;
    }
    @media screen and (max-width: 2400px){
    
    
      width:350px;
      height:250px;

      .card h2{
        width: 300px;
      }
    }
    @media screen and (max-width: 1850px){
    
      width:350px;
      height:250px;

      .card h2{
        width: 280px;
      }
      
    }
    @media screen and (max-width: 1230px){
      width:350px;
      height:250px;

      .card h2{
        width: 200px;
      }
    }
    @media screen and (max-width: 728px){

      width:80vw;
      height:280px;


        .title{
            font-size: calc(1vw + 10px);
        }

        .position{
            font-size: calc(1vw + 8px);
        }
        .card h2{
          width: 300px;
          font-size:14px;
        }
    }
    @media screen and (max-width: 425px){
     
      width:84vw;
      height:200px;
    img{
       
        height:250px;
        width:230px;
       
    }
    .event-head {
        
        width:230px;
        height:230px;
    }
    .card h2{
      width: 200px;
    }
    .title.heading{
        height:43px !important;
    }
    .glass-button {
        padding: 8px 20px;
        height:43px;
    }


    }
    @media screen and (max-width: 320px){
      width:260px;
      height:200px;
      .card h2{
        width: 200px;
      }
     img{
        
         height:230px;
         width:210px;
        
     }
     .event-head {
         
         width:210px;
         height:210px;
     }
     .glass-button {
        padding: 6px 18px;
        height:40px;
    }
    .title.heading{
        height:40px !important;
    }
 
     }
`


// let obj1={
//   name:'The Rise of Predictive Maintenance',
//   img:'https://images.unsplash.com/photo-1616759648335-e0d3b7834685?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTh8fHZsb2d8ZW58MHx8MHx8fDA%3D&w=1000&q=80'

// }
// let obj2={
//   name:'Automotive Safety',
//   img:'https://www.ryrob.com/wp-content/uploads/2022/02/Best-Practices-for-using-images-on-blog-2.png'
// }
// let obj3={
//   name:'Passive Safety',
//   img:'https://bizproworld.com/bizpro/blog/image/103/_1520836570_807459765.jpg'
// }

let obj1=[
  {
    title:'Head-Up Displays (HUD)',
    content:'Project important information onto the windshield for drivers to see while keeping their eyes on the road.',
    img:active1
  },
  {
    title:'Anti-Lock Braking Systems (ABS)',
    content:'Prevent wheels from locking up during emergency braking, improving steering control.',
    img:active2
  },
  {
    title:'Electronic Stability Control (ESC)',
    content:'Help drivers maintain control in challenging driving conditions.',
    img:active3
  },
  {
    title:'Tire Pressure Monitoring Systems (TPMS)',
    content:'Continuously monitor tire pressure to alert drivers of unsafe levels.',
    img:active4
  },
  {
    title:'Lane Departure Warning Systems (LDWS)',
    content:'Alert drivers when they unintentionally drift out of their lane.',
    img:active5
  },
  {
    title:'Adaptive Cruise Control (ACC)',
    content:'Adjust vehicle speed to maintain a safe distance from the vehicle ahead.',
    img:active6
  },
  {
    title:'Driver Monitoring Systems (DMS)',
    content:'Track driver behavior to detect fatigue or distraction.',
    img:active7
  },
  {
    title:'Blind Spot Detection (BSD)',
    content:'Warn drivers of vehicles in blind spots during lane changes.',
    img:active8
  },
  {
    title:'Night Vision Systems (NVS)',
    content:'Enhance visibility in low-light conditions.',
    img:active9
  }

]
let obj2=[
  {
    title:'Autonomous Emergency Braking (AEB)',
    content:'Automatically apply brakes to prevent or reduce collision severity.',
    img:wave1
  },
  {
    title:'Drowsiness and Attention Detection Systems',
    content:'Monitor driver attentiveness and provide alerts for breaks.',
    img:wave2
  }

]
let obj3=[
  {
    title:'Airbags',
    content:'Rapidly deploy to provide crucial protection to occupants in case of a collision.',
    img:passive1
  },
  {
    title:'EDR (Event Data Recorder)',
    content:'Captures crash data for analysis, aiding in accident reconstruction and improving safety measures.',
    img:passive2
  },
  {
    title:'Seatbelts',
    content:'Safely restrain occupants, minimizing the risk of injury during sudden stops or accidents.',
    img:passive3
  }

]

const mapStateToProps = state => {
  const {
      suggestedBlogs
  }  = state.we;

  return {suggestedBlogs}
}

const mapDispatchToProps =dispatch =>  ({
  getSuggestedBlogs:(params,successCallback)=>dispatch(getSuggestedBlogs(params,successCallback)),

})


export default connect(mapStateToProps, mapDispatchToProps)( SafetySystem);




const Obj1 = {
    topLine: '',
    btnType: 'outline',
    headline: '',
    img: '',
    alt: 'iot-image',
    imgStart: '',
    buttonLabel: '',
}

