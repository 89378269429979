import {
    SET_CURRENT_JOBS_PAGE,
    SET_JOBS_SUMMARY,
    SET_JOBS,
    SET_SEARCH,
    SET_SELECTED_JOB,
    SET_FEATURED_EVENTS,
    SET_EVENTS,
    SET_SELECTED_EVENT,
    SET_LOCATIONS,
    SET_FEATURED_BLOGS,
    SET_MAGAZINES,
    SET_BLOGS,
    SET_SELECTED_BLOG,
    SET_ALL_BLOGS,
    SET_SUGGESTED_BLOGS,
    SET_GALLERY
    
} from './action'


const initialState = {
    currentPage: 1,
    activeJobs: 0,
    allJobs: [],
    jobsMeta: {
        totalItems: 0
    },
    search: '',
    featuredEvents:[],
    events:[],
    currentJob: {},
    selectedEvent:{},
    locations:[],
    featuredBlogsData:[],
    magazines:[],
    blogs:[],
    allBlogs:[],
    selectedBlog:{},
    suggestedBlogs:[],
    gallery:[]
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_SELECTED_JOB: 
            return {
                ...state,
                currentJob: action.data
            }
        case SET_SEARCH: 
            return {
                ...state,
                search: action.val
            }
        case SET_JOBS_SUMMARY: 
            return {
                ...state,
                activeJobs: action.data.ActiveJobs,
                allJobs: [...action.data.AllJobs]
            }
        case  SET_JOBS: 
            return {
                ...state,
                allJobs: [...action.data.jobs.items],
                jobsMeta: {...action.data.jobs.meta}
            }
        case SET_CURRENT_JOBS_PAGE: 
            return {
                ...state,
                currentPage: action.page
            }
       case SET_FEATURED_EVENTS:
        return {
            ...state,
            featuredEvents:action.data
        }
        case SET_EVENTS:
        return {
          ...state,
          events:action.data
        }
        case SET_SELECTED_EVENT:
            return {
                ...state,
                selectedEvent:action.data
            }
        case SET_LOCATIONS:
                return {
                    ...state,
                    locations:action.data
                }
        case SET_FEATURED_BLOGS:
            return {
                ...state,
                featuredBlogs:action.data
            }
        case SET_MAGAZINES:
             return {
                ...state,
                magazines:action.data
             }
        case SET_BLOGS:
            return {
                ...state,
                blogs:action.data
            }
       case SET_SELECTED_BLOG:
        return {
            ...state,
            selectedBlog:action.data
        }
        case SET_ALL_BLOGS:
            return {
                ...state,
                allBlogs:action.data
            }
        case SET_SUGGESTED_BLOGS:
            return {
                ...state,
                suggestedBlogs:action.data
            }
        case SET_GALLERY:
            return {
                ...state,
                gallery:action.data
            }
        default: {
            return {...state};
        }
    }
}


export default reducer;