import {  LoadingOutlined, PaperClipOutlined } from '@ant-design/icons';
import { Form, message } from 'antd'
import React, { useState,useEffect} from 'react'
import { GoLocation } from 'react-icons/go'
import { connect } from 'react-redux';
import { useNavigate,useParams } from 'react-router-dom';
import { submitApplicationForm ,getJob} from '../../redux/action';
import { JdCardHeading, JdAddress, FormInput, SubmitButton, FileUpload, PhoneInputArea } from './styles'
import './styles.css';
import {phone} from 'phone';
import NotFound from '../../../technology/pagenotfound';
import bg2 from '../ourStory/ourCulture/BG.svg'
import { ReactComponent as Back } from '../../../../assets/back-icon.svg';

const scrollToTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}

const normFile = (e) => {
    if (Array.isArray(e)) {
        return e;
    }
    return e && e.fileList;
};

const props = {
    name: 'file',
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
  
  };

 const ApplyNow=({submitApplicationForm,currentJob,getJob}) =>{
    const navigate = useNavigate();
    const params=useParams();
    const [messageApi, contextHolder] = message.useMessage();
    useEffect(()=>{
    
      let param={
        jobId:params?.jobId?.split('=')[1]
       }
          if(!currentJob||currentJob.length==0){
            setLoader(true);
            }
            getJob(param,() => setLoader(false))
            return () => {   
            };
   
    },[])
    const handleBackBtn=()=>{
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        window.history.back();
       
    }
    const beforeUpload = (file) => {
        const isPDF = file.type === 'application/pdf';
        if (!isPDF) {
            message.error('You can only upload PDF file!');
        //   messageApi.open({
        //     type: 'error',
        //     content: 'You can only upload PDF file!',
        //     className: 'custom-class',
        //     style: {
        //     position:'absolute',
        //     left:'50%',
        //     top:'100px',
        //     transform:'translate(-50%,-50%)',
        //       zIndex:100,
        //       width:'300px'
        //     },
        //   });
          return false;
      
        }
        const isLt5M = file.size / 1024 / 1024 < 2;
        if (!isLt5M) {
            message.error('Resume must smaller than 2MB!');
            // messageApi.open({
            //     type: 'error',
            //     content: 'Resume must smaller than 2MB!',
            //     className: 'custom-class',
            //     style: {
            //         position:'absolute',
            //         left:'50%',
            //         top:'100px',
            //        transform:'translate(-50%,-50%)',
            //           zIndex:100,
            //           width:'300px'
            //     },
            //   });
              return false;
         
           

        }
        return isPDF && isLt5M;
      }

    const uploadFile = info => {
        const isLt5M = ((info.file.size) / (1024 * 1024)) < 2;
        if (info?.file?.type === 'application/pdf' && isLt5M) {
            console.log(info)
            setFileObj(info.file);
        }
        else{
            setFileObj(null);
        }
        setFileLoader(false);
    };

    const [fileLoader, setFileLoader] = useState(false);
    const [fileObj, setFileObj] = useState(null);
    const [formInput, updateFormInput] = useState({
        name: '',
        email: '',
        phone: '',
        currentOrg: '',
    });
    const [submitLoader, setSubmitLoader] = useState(false)
    const [loader,setLoader]=useState(false);
    const successCallback = () => {
        updateFormInput({
            name: '',
            email: '',
            phone: '',
            currentOrg: ''
        })
        scrollToTop();
        navigate('/join-us/application-sent')
    }

    const handleSubmit = () => {
        setSubmitLoader(true);
        console.log(fileObj)
        const params = {
            name: formInput.name,
            email:  formInput.email,
            phone: formInput.phone,
            currentOrg: formInput.currentOrg,
            files: fileObj?.originFileObj,
            jobId:currentJob.jobId
        }
        submitApplicationForm(params, () => setSubmitLoader(false), successCallback)

    }
    const [isActive, setActive] = useState({name: false, phone: false, email: false});

    
  return (
    <div   style={{
        background:`url(${bg2}) center no-repeat`,backgroundSize:'cover'
    
        }}>
            {contextHolder} 
         
          
   {

    loader? <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'100vh'}}>

           <LoadingOutlined style={{fontSize: "3em"}}/> 
            </div>
            
           : currentJob!=undefined?
    <div style={{padding: '20px 8vw',display:'flex',flexDirection:'column',alignItems:'center'}} className='jd-wrapper'>
      
      <div style={{'display':'flex','alignItems':'center','justifyContent':'flex-start',position:'relative',gap:'10px',width:'100%',maxWidth:'100vw'}}>
           <Back className='back-btn' onClick={handleBackBtn} />

            
            </div>
         
         <div style={{'display':'flex','alignItems':'flex-start','justifyContent':'flex-start',width:'100%'}}>
          
        <JdCardHeading className='heading-top' style={{paddingBottom:6,textAlign:'center'}}>
        {currentJob?.title}
    </JdCardHeading>
    </div >
        <JdAddress className='content grey'  style={{width:'100%',display:'flex',justifyContent:'center'}} >
        
     <div>
            <GoLocation style={{marginRight: 10}}/> </div>
            <span >{currentJob?.location?.map(location=>location.name)?.join(', ').toString()}</span>
    </JdAddress >
        <div style={{marginTop: 60, width: "80vw" ,display:'flex',justifyContent:'center'}}>
        <Form
        className='applyform'
    
            name="basic"
            labelCol={{
                span: 8,
            }}
            wrapperCol={{
                span: 16,
            }}
            initialValues={{
                remember: true,
            }}
            onFinish={() => {}}
            autoComplete="off"
            layout='vertical'
        >
             <span style={{width:'100%',textAlign:'end',float:'right'}}><span style={{color:'red'}}>*</span>All fields are mandatory !</span>
                      <br/>
            <Form.Item
                name="name"
                label="Name"
                rules={[
                    {
                        required: true,
                        message: 'Please enter your name!',
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value ||/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '-]+$/.test(value)) {
                                setActive({...isActive, name: true})
                                return Promise.resolve();
                            }
                            setActive({...isActive, name: false})
                            return Promise.reject(new Error('Please enter a valid name!'));
                        },
                    })
                ]}
            >
                <FormInput 
                placeholder='Name' 
                value={formInput.name} 
                onChange={
                    e => {
                        updateFormInput({...formInput, name: e.target.value.trim()})
                    }}
                />
            </Form.Item>

           <Form.Item
                name="email"
                label="E-mail"
                rules={[
                    {
                        required: true,
                        message: 'Please enter your email Id!',
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
                                setActive({...isActive, email: true})
                                return Promise.resolve();
                            }
                            setActive({...isActive, email: false})
                            return Promise.reject(new Error('Please enter a valid email!'));
                        },
                    })
                ]}
            >
                <FormInput placeholder='Email ID' value={formInput.email} onChange={e => {updateFormInput({...formInput, email: e.target.value.trim()})}}/>
            </Form.Item>


           <Form.Item
                name="phone"
                label="Phone Number"
                rules={[
                    {
                        required: true,
                        message: 'Please enter your Mobile Number (Eg: +91 *****-*****)!',
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if(value.length!==0){
        
                                if(phone('+'+value).isValid){
                                   
                                    setActive({...isActive, phone: true})
                                  
                                    return Promise.resolve();  
                                }else{
                                  
                                setActive({...isActive, phone: false});
                                return Promise.reject(new Error('Please enter a valid Mobile number!'));
                                }
                                
                            }
                            setActive({...isActive, phone: true})
                            return Promise.resolve();
                        }
                    }),
                ]}
            >
                <PhoneInputArea
                    country={'in'}
                    value={formInput?.phone}
                    onChange={phone => updateFormInput({...formInput, phone: phone})}
                    placeholder="Mobile Number (Eg: +91 *****-*****)"
                />
            </Form.Item>

            <Form.Item
                name="currentOrg"
                label="Current Organisation"
                rules={[
                {
                    required: true,
                    message: 'Please enter your Current Organisation Name!',
                },
                ]}
            >
                <FormInput placeholder='Current Organisation' value={formInput.currentOrg} onChange={e => {updateFormInput({...formInput, currentOrg: e.target.value.trim()})}}/>
            </Form.Item>

            <Form.Item
                name="upload"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                label="Resume"
                rules={[
                {
                    required: true,
                    message: 'Please upload your resume!',
                },
                ]}
            >
             
                <FileUpload {...props}
                    beforeUpload={beforeUpload}
                    onChange={uploadFile}
                    showUploadList={fileObj ? true : false}
                    fileList={[fileObj]}
                    action='https://www.mocky.io/v2/5cc8019d300000980a055e76'
                    maxCount={1}
                >
                    {fileLoader ? 
                        <div>
                            <LoadingOutlined style={{fontSize: "5em"}} />
                        </div>
                    :
                    <p className="ant-upload-text" style={{color: "#565656", fontSize: 'calc(1vw + 5px)'}}><PaperClipOutlined rotate={135} style={{marginRight: 10, color: "#0086FF"}}/>
                    Drop your resume here or <span style={{fontWeight: 700, color: "#0086FF"}}>Browse</span> </p>
                    }
                   
                </FileUpload>
              
            </Form.Item>
          
            
            <Form.Item
                wrapperCol={{
                offset: 0,
                span: 16,
                }}
            >
                <SubmitButton 
                type="primary" 
                htmlType="submit" 
                onClick={() => handleSubmit()} 
                loading={submitLoader}
                disabled={!formInput.name || !formInput.currentOrg || !formInput.email || !formInput.phone || !fileObj || !isActive.email || !isActive.name || !isActive.phone || fileObj.status==='removed' }
                >
                SUBMIT APPLICATION
                </SubmitButton>
            </Form.Item>
            </Form>
        </div>

    </div>:   
    <NotFound/>
    
            }
    </div>
        
   
  )
}

const mapStateToProps = state => {
    const {
        currentJob
    }  = state.we;

    return {currentJob}
}

const mapDispatchToProps =dispatch =>  ({
    getJob: (params,successCallback) => dispatch(getJob(params,successCallback)),
    submitApplicationForm: (params, callback, successCallback) => dispatch(submitApplicationForm(params, callback, successCallback))
})


export default connect(mapStateToProps, mapDispatchToProps)(ApplyNow);
