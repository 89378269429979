import Crd1 from '../../../../assets/joinUs/Group 978.png'
import Crd2 from '../../../../assets/joinUs/Group 976.png'
import Crd3 from '../../../../assets/joinUs/Group 977.png'
import Crd4 from '../../../../assets/joinUs/Group 980.png'
import Crd5 from '../../../../assets/joinUs/Group 981.png'
import Crd6 from '../../../../assets/joinUs/Group 979.png'

export const Policies = [
    {
        title: "Policies That Matter",
        icon: Crd1,
        body: "The Associate Security Program at brigosha is your safety net, supporting you through challenging situations. "
    },
    {
        title: "Flexible Working Hours",
        icon: Crd2,
        body: "Your work timings and number of hours mean little to nothing for us if you’re a man/woman with a plan and have the intention & ability to execute it. brigosha provides freedom to work as per your peak timings, if you deserve it. "
    },
    {
        title: "Comprehensive Health Plan",
        icon: Crd3,
        body: "Your well-being matters to us! brigosha provides comprehensive healthcare coverage for you and your loved ones, ensuring security and peace of mind during times of crisis."
    },
    {
        title: "Pay and Ownership: Salary & ESOP",
        icon: Crd4,
        body: "Join brigosha and unlock exciting rewards! We offer top-notch compensation packages, including ESOPS, as a token of appreciation for shaping our culture and ethics."
    },
    {
        title: "brigosha Centre of Excellence",
        icon: Crd5,
        body: "At brigosha, we believe in continuous growth. Elevate your expertise at the brigosha Centre of Excellence (BCE) through engaging, self- paced, and mentor-led learning experiences."
    },
    {
        title: "Associate Wellness Activities (Events)",
        icon: Crd6,
        body: "Our wellness events ignite joy and boost mental well-being at work."
    }
]