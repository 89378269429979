import React, { useEffect } from 'react';
import styled from 'styled-components';

import img1 from './imgs/broadcast1.svg';
import img2 from './imgs/broadcast2.svg';
import img3 from './imgs/broadcast3.svg';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

import { Row, Col, Button } from 'antd';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import { ReactComponent as Back } from '../../../../../assets/back-icon.svg';
import bg2 from '../../../../../assets/bg2.svg'
import { useState } from 'react';
function Broadcast() {
    const handleBackBtn=()=>{
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        window.history.back();
    }
    const [image,setImage]=useState(0);
useEffect(()=>{
  $(function() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
    setImage(0);
    document.getElementById('broadcast-wrapper').style.backgroundColor='#fff';
    setTimeout(()=>{
      document.getElementById('button').click();
    },1000)
})
},[])
useEffect(() => {
    
    
      // Ensure ScrollTrigger is enabled
      gsap.registerPlugin(ScrollTrigger);
  
  
      // Get a reference to the pinned image element
    
  
      // Create a function to update the pinned image source based on scroll position
      function updatePinnedImage() {
        // Calculate the index of the image based on scroll position
   
        const top1=document.getElementById('dtl-1').offsetTop;
        const top2=document.getElementById('dtl-2').offsetTop-300;
        const top3=document.getElementById('dtl-3').offsetTop-400;
         const top=window.scrollY;
          // Get the ScrollTrigger instance associated with the pinned element
      
            // Calculate the index of the image based on the scroll position
        
         console.log('data',top1,top2,top3,window.scrollY)
    if(top>=top1 && top<top2){
      setImage(0);
      document.getElementById('broadcast-wrapper').style.backgroundColor='#fff'

 
    }else if(top>top2&& top<top3){
      document.getElementById('broadcast-wrapper').style.backgroundColor='#F3F8FD'
     
      setImage(1);
     
    }else if(top>=top3){
      setImage(2);
      document.getElementById('broadcast-wrapper').style.backgroundColor='white'
    
    }
            // Update the pinned image source with a smooth fade transition
          
          
        
      }
   
      // Create your ScrollTrigger animation
      const animation = gsap.timeline({
        scrollTrigger: {
          trigger: '.gallery', // Element that triggers the animation
          start: 'top top', // Start point of the trigger
          end: 'bottom bottom', // End point of the trigger
          pin: '.right', // Element to pin
          scrub: true, // Smooth scrubbing effect
         
          onUpdate: () => {
            // Call the updatePinnedImage function on scroll
            updatePinnedImage();
          },
        },
      });
      updatePinnedImage();
      // Add animations or tweens to your timeline
      // For example, to animate the pinned element's opacity:
      // animation.to('.right', { opacity: 0, duration: 1 });
    
      // Add any other animations as needed

      
      // Don't forget to return a cleanup function for useEffect
      return () => {
        animation.kill(); // Kill the animation on unmount if necessary
      
      };
    }, []); // Empty dependency array ensures this effect runs only once
    

    useEffect(()=>{
      const imageSources = [
      img1,img2,img3
        // Add more image sources as needed
      ];
        const pinnedImage = document.querySelector('.pinned-image');
          gsap.to(pinnedImage, {
            opacity: 0.8, // Fade out the image
            transition:0.6 ,
            scale:0.9,
          
            duration: 0.1, // Duration of the fade animation
            onComplete: () => {
              // Change the image source
              pinnedImage.src = imageSources[image];
    
              // Fade the image back in
              gsap.to(pinnedImage, {
                transition:0.1 ,
                scale:1,
                opacity: 1, // Fade in the new image
                duration: 0.1, // Duration of the fade animation
              });
            },
          });
        
    },[image])

 

    return (
        <div   style={{
            background:`url(${bg2}) center no-repeat`,
            backgroundSize:'100%',
            backgroundPositionY:'-2%',
            backgroundColor:'#fff'
            }} id='broadcast-wrapper'>
        <BroadcastContainer>
            {/* <div className='Broadcast-header heading'>Internet of <div className='gradient6'>Things<div className='polygon6'></div></div></div>
             */}
               <div style={{'display':'flex','alignItems':'center','justifyContent':'flex-start',position:'relative',flexDirection:'row',width:'100%'}}>
           <Back className='back-btn' onClick={handleBackBtn} ></Back>
       
            </div>
   <div style={{height:'50vh',textAlign:'center',display:'flex',justifyContent:'center',alignItems:'center'}} className='content Broadcast-top'>
   <div className='BroadcastContainer-header heading'><div className='highlight'>In-House Broadcast :</div> Seamless scalability, geographically optimized content delivery, and personalized VOD experiences</div>
   </div>


   <div class="gallery" style={{marginTop:'-100px'}}>
	<div class="left">
		<div class="detailsWrapper">
		
      <CardStyle id='dtl-1' >
      <Col 
       className= 'card-text-container left' style={{width:'100%'}}
         >
              <div className='card-heading heading' >{parse(Obj1.headline)}</div>
              <p className='card-content content'>{Obj1.description}</p>
              {
                Obj1.link.length!==0? <div 
                className='button-holder'
                style={{justifyContent: 'flex-start' }}
              >
                <Link to={`/technology/${Obj1.link}`} onClick={
                    scrollToTop()
                  }> 
                    <Button 
                      type = {Obj1.btnType} 
                      className='card-button'
                      style ={{
                        color:  'white' ,
                        backgroundColor: 'transparent',
                        
                      }}
                    >
                      {Obj1.buttonLabel}
                    </Button>
                  </Link>
              
                
              </div>:null}
          </Col>
          </CardStyle>

          <CardStyle id='dtl-2'>
      <Col 
       className= 'card-text-container left' style={{width:'100%'}}
         >
              <div className='card-heading heading' >{parse(Obj2.headline)}</div>
              <p className='card-content content'>{Obj2.description}</p>
              {
                Obj2.link.length!==0? <div 
                className='button-holder'
                style={{justifyContent: 'flex-start' }}
              >
                <Link to={`/technology/${Obj2.link}`} onClick={
                    scrollToTop()
                  }> 
                    <Button 
                      type = {Obj2.btnType} 
                      className='card-button'
                      style ={{
                        color:  'white' ,
                        backgroundColor: 'transparent',
                        
                      }}
                    >
                      {Obj2.buttonLabel}
                    </Button>
                  </Link>
              
                
              </div>:null}
          </Col>
          </CardStyle>

          <CardStyle id='dtl-3'>
      <Col 
       className= 'card-text-container left' style={{width:'100%'}}
         >
              <div className='card-heading heading' >{parse(Obj3.headline)}</div>
              <p className='card-content content'>{Obj3.description}</p>
              {
                Obj3.link.length!==0? <div 
                className='button-holder'
                style={{justifyContent: 'flex-start' }}
              >
                <Link to={`/technology/${Obj3.link}`} onClick={
                    scrollToTop()
                  }> 
                    <Button 
                      type = {Obj3.btnType} 
                      className='card-button'
                      style ={{
                        color:  'white' ,
                        backgroundColor: 'transparent',
                        
                      }}
                    >
                      {Obj3.buttonLabel}
                    </Button>
                  </Link>
              
                
              </div>:null}
          </Col>
          </CardStyle>

		</div>
	</div>

	<div class="right">
    <div className='image-wrapper'>
    <img src={img1} alt="Pinned Image" className="pinned-image" />
    </div>

	</div>
</div>

 
      <div  className='mobile-view'>
      <Card {...Obj1}/>
      <br/>
      <br/>
           {/* <div className='bule-card'>
           <div className='Broadcast-header heading'>{parse(Obj2.headline)}</div>
    
        <p className='content Broadcast-top'>
         {Obj2.description}
         </p>
           </div> */}
            <Card {...Obj2}/>
           <br/>
           <br/>
            <Card {...Obj3}/>
      </div>
      <div style={{height:'10vh',width:'100%',color:'#FFF',textAlign:'center',display:'flex',justifyContent:'center',alignItems:'center'}}>

   </div>
           
           
        </BroadcastContainer>

    
        </div>
    )
}

const BroadcastContainer = styled.div`
   
  
    background-color: transparent;
    padding-top: 40px;
    padding-right: 10vw;
    padding-left: 10vw;
    text-align: center;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    .back-btn{
        display:flex;
        align-self:flex-start;
    }
    .mobile-view{
      display:none;
    }
    .image-wrapper{
      width:31vw;
      height:31vw;
      border-radius:11%;
     display:flex;
     justify-content:center;
     align-items:center;
     box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;

    }

    .pinned-image{
   
   width:70%;
      object-fit:contain;
      margin:0 auto;
      display:flex;
      justify-content:center;
    }
    .BroadcastContainer-header{
      font-size: calc(2vw + 15px);
      font-weight: 700;
      position:relative;
      z-index:2;
    }

.headline {
	background:#2D4E86;
	border-radius:6px;
	height:4em;
	width:100%;
}

.text {
	margin:0.8em 0 0 0;
	background:#2D4E86;
	border-radius:6px;
	height:1em;
	width:100%;
  
}




.gallery {
  display:flex;
  // outline:1px solid red;
  justify-content:space-between;
  gap:10px;
  width:80vw;
 
  // background:white;
  // box-shadow:0 0 10px 0 rgba(0,0,0,0.2);
  // border-radius:10px;
}

.left {
	width:600px !important;
}

.detailsWrapper {
	margin:auto;
	width:100%;
}

.details {
	height:100vh;
	// outline:1px solid green;
	display:flex;
	flex-direction:column;
	justify-content:center;
}


.right {
	// outline:1px solid purple;
	width:500px;
	height:100vh;
	display:flex;
	flex-direction:column;
	justify-content:center;
  align-items:center;
}

.photos {
	width:40vw;
	height:40vw;
	background:maroon;
}



    .Broadcast-header{
        font-size: calc(2vw + 15px);
        font-weight: 700;
        position:relative;
        z-index:2;
    }
    .bule-card{
        max-width:70vw;
        background-color: rgba(232, 246, 255, 1);
        height:auto;
        padding:20px;
        margin:0 auto;
    }

    .Broadcast-top{
        padding-top: 20px;
        font-size: calc(1vw + 5px);
        font-weight: 400;
       
    }
    @media screen and (max-width: 1240px){
      .right{
          width:400px!important;
      }
      .left{
        width:40vw!important;
      }
    }
 
    @media screen and (max-width: 767px){
        padding-right: 8vw;
        padding-left: 8vw;
        .gallery{
          display:none;
        }
        .mobile-view{
          display:block;
        }

        .Broadcast-header{
            font-size: calc(2vw + 20px);
        }

        .Broadcast-top{
            font-size: calc(1vw + 10px);
            height:auto !important;
        }
    }
`

export default Broadcast;



const Obj1 = {
    topLine: '',
    btnType: 'outline',
    headline: `<div className='highlight'>Broadcast Brilliance,</div> Any Scale:`,
    description: 'From small gatherings to global audiences, our streaming platform ensures quality remains unwavering. Discover the power of scalability without sacrificing viewer experience.',
    buttonLabel: '',
    img: img1,
    alt: 'Broadcast-image',
    imgStart: '',
    link:'',
    height:90
}
const Obj2={
    topLine: '',
    btnType: 'outline',
    headline: `<div className='highlight'>CDN:</div> Your Content's Express Lane`,
    description: 'Give your content wings with our Content Delivery Network (CDN). Lightning-fast delivery across the globe means reduced waiting times and an exceptional streaming experience.',
    buttonLabel: '',
    img:img2,
    alt: 'Broadcast-image',
    imgStart: 'start',
    link:'',
    height:90
}

const Obj3 = {
    topLine: '',
    btnType: 'outline',
    headline: `<div className='highlight'>On-Demand</div> Magic:`,
    description: 'Missed the live show? No worries. Our Video on Demand (VOD) feature lets you catch up at your own pace. Embrace the flexibility to enjoy content when and where you want it.',
    buttonLabel: '',
    img: img3,
    alt: 'Broadcast-image',
    imgStart: 'start',
    link:'',
    height:90
}




const Card = ({
    btnType,
    headline,
    description,
    buttonLabel,
    img,
    alt,
    imgStart,
    link,
    updateMainMenu ,
    height
  }) => {
    const scrollToTop = () => {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
     
  }
    return (
      <CardStyle
        wrap={true}
      >
          <Col 
          xs={{span: 24, order: 2}} 
          sm={{span: 24, order: 2}} 
          md={{span: 12, order: imgStart === 'start' ? 2 : 1}} 
          lg={{span: 12, order: imgStart === 'start' ? 2 : 1}} 
          xl={{span: 12, order: imgStart === 'start' ? 2 : 1}}
          xxl={{span: 12, order: imgStart === 'start' ? 2 : 1}}
          className={imgStart === 'start' ? 'card-text-container left' : 'card-text-container left'}
          gutter = {20}
        
          >
              <div className='card-heading heading' >{parse(headline)}</div>
              <p className='card-content content'>{description}</p>
              {
                link.length!==0? <div 
                className='button-holder'
                style={{justifyContent: 'flex-start' }}
              >
                <Link to={`/technology/${link}`} onClick={
                    scrollToTop()
                  }> 
                    <Button 
                      type = {btnType} 
                      className='card-button'
                      style ={{
                        color:  'white' ,
                        backgroundColor: 'transparent',
                        
                      }}
                    >
                      {buttonLabel}
                    </Button>
                  </Link>
              
                
              </div>:null}
          </Col>
     

          <Col
  
  xs={{ span: 24, order: 1 }}
  sm={{ span: 24, order: 1 }}
  md={{ span: 12, order: imgStart === 'start' ? 1 : 2 }}
  lg={{ span: 12, order: imgStart === 'start' ? 1 : 2 }}
  xl={{ span: 12, order: imgStart === 'start' ? 1 : 2 }}
  xxl={{ span: 12, order: imgStart === 'start' ? 1 : 2 }}
  className='content'
  style={{'display':'flex',justifyContent:imgStart === 'start'?'flex-start':'flex-end',alignItems:'center',}}
  >
  {
    img ? <><img src={img} alt={alt} className='card-image' style={{'height':`${height}%`}} />  </> :null
  

  
  
  }
  
       </Col>
      </CardStyle>
    )
  };
  
  
  const CardStyle = styled(Row)`
    padding-top: 10px;
  
    height:100vh;
    
    background-color:transparent;
    .card-text-container{
      
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .card-image{
     padding-bottom:10px;
     width:86% !important;

    object-fit:fill;
    transition: all 0.3s ease-out;
  
     
    
    }
    .card-image:hover{
      transform: scale(1.01);
      transition: all 0.3s ease-out;
    }
  

    .left{
      text-align: left;
    }
  
    .right{
      text-align: right;
    }
  
    .card-image{
      width: 70%;
    }
  
    .button-holder{
      width: 100%;
      display: flex;
      align-items: center;
    }
  
    .card-content{
      font-size: calc(1vw + 5px);
      font-weight: 400;
      color:#111111;
      word-wrap: break-word;
      text-align:left;
    
    }
  
    .card-heading {
      width: 100%;
      margin-bottom: 24px;
      font-size: calc(2vw + 10px);
      font-weight: 700;
    }
    
    .card-button{
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: calc(1vw + 3px);;
      border-color: #0086FF;
      border-radius: 4px;
      font-weight: 700;
      gap:10px;
      padding: 2vw 4vw;
      background-color: #0086FF !important;
      color:white !important;
      box-shadow: 0 20px 40px 0 rgba(35, 100, 210, 0.2);
    }
  
    .card-button:hover{
       
      background-color: #0086FF !important;
      color:white !important;
      box-shadow: 0 20px 5px -10px rgba(0, 0, 0, 0.4);
       transform: translateY(1px);
   }
    .card-button:hover{
      // transform: scale(1.02);
      // transition: all 0.3s ease-out;
    }
  
    @media screen and (min-width: 2000px){
      .card-image{
        width: 70%;
      }
    }
  
    @media screen and (max-width: 767px){
      height:100%;
      .card-content{
        font-size: calc(1vw + 10px);
        text-align: left !important;
      }
      .card-image{
        width: 65%;
      }
      .card-heading{
        text-align: left !important;
        font-size: calc(2vw + 20px);
      }
  
      .button-holder{
        justify-content: flex-start !important;
      }
      .content{
        justify-content:center !important;
      }
      .card-button{
        padding: 3vw 6vw;
        font-size: calc(1vw + 6px);
      }
    }
  
    @media screen and (max-width: 425px){
      .card-button{
        padding: 4vw 6vw;
      }
  
      .card-image{
        width: 70%;
      }
    }
  
   
  `
