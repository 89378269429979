import React from 'react';
import { Row, Col, Button } from 'antd';
import 'antd/dist/antd.min.css';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateMainMenu } from '../../../../../components/PrimaryNavbar/redux/action';
import {ReactComponent as Arrow} from '../PotentialCard/Vector.svg';

const scrollToTop = () => {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}

const Card = ({
  btnType,
  headline,
  description,
  buttonLabel,
  img,
  alt,
  imgStart,
  link,
  updateMainMenu 
}) => {

  return (
    <CardStyle
      wrap={true}
    >
        <Col 
        xs={{span: 24, order: 2}} 
        sm={{span: 24, order: 2}} 
        md={{span: 12, order: imgStart === 'start' ? 2 : 1}} 
        lg={{span: 12, order: imgStart === 'start' ? 2 : 1}} 
        xl={{span: 12, order: imgStart === 'start' ? 2 : 1}}
        xxl={{span: 12, order: imgStart === 'start' ? 2 : 1}}
        className={imgStart === 'start' ? 'card-text-container left' : 'card-text-container left'}
        gutter = {20}
        >
            <h1 className='card-heading heading' >{headline}</h1>
            <p className='card-content content'>{description}</p>
            <div 
              className='button-holder'
              style={{justifyContent: 'flex-start' }}
            >
              <Link to={`/${link.toLowerCase()}`} onClick={() => {
                scrollToTop()
                if(link.length!==0 )
                updateMainMenu(`${link.toLowerCase()}`)
              }}> 
                <Button 
                  type = {btnType} 
                  className='card-button'
                  style ={{
                    color: '#0086FF',
                    backgroundColor: 'transparent'
                  }}
                >
                  {buttonLabel}
                  <Arrow  style={{height:'20px',width:'20px'}}/>
                </Button>
              </Link>
            </div>
        </Col>
        <Col

xs={{ span: 24, order: 1 }}
sm={{ span: 24, order: 1 }}
md={{ span: 12, order: imgStart === 'start' ? 1 : 2 }}
lg={{ span: 12, order: imgStart === 'start' ? 1 : 2 }}
xl={{ span: 12, order: imgStart === 'start' ? 1 : 2 }}
xxl={{ span: 12, order: imgStart === 'start' ? 1 : 2 }}
className='content'
>
{
  img ? <img src={img} alt={alt} className='card-image' /> :null




}

</Col>
    </CardStyle>
  )
};


const CardStyle = styled(Row)`
  padding-top: 50px;

  .card-text-container{
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .card-button:hover{
    background-color: #0086FF !important;
    color:white !important;
 }
  .card-image:hover{
    transform: scale(1.01);
    transition: all 0.3s ease-out;
  }

  .left{
    text-align: left;
  }

  .right{
    text-align: right;
  }

  .card-image{
    width: 90%;
    transition: all 0.3s ease-in;
  }

  .button-holder{
    width: 100%;
    display: flex;
    align-items: center;
  }

  .card-content{
    font-size: calc(1vw + 5px);
    font-weight: 400;
    color:#111111;
    font-family: 'Inter', sans-serif;
  }

  .card-heading {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: calc(2vw + 10px);
    font-weight: 700;
    font-family: 'Sofia Sans', sans-serif;
    color: #1e375a;
  }
  
  .card-button{
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: calc(1vw + 3px);;
    border-color: #0086FF;
    border-radius: 4px;
    font-weight: 700;
    padding: 2vw 4vw;
    gap:10px;
    background-color: #0086FF !important;
    color:white !important;
    box-shadow: 0 20px 40px 0 rgba(35, 100, 210, 0.2);
  }

  .card-button:hover{
    // transform: scale(1.02);
    // transition: all 0.3s ease-out;
    // background: #0086FF !important;
    // color: white !important;
    box-shadow: 0 20px 5px -10px rgba(0, 0, 0, 0.4);
    transform: translateY(1px);
  }

  @media screen and (min-width: 2000px){
    .card-image{
      width: 75%;
    }
  }

  @media screen and (max-width: 767px){

    .card-content{
      font-size: calc(1vw + 10px);
      text-align: left !important;
    }

    .card-heading{
      text-align: left !important;
      font-size: calc(1vw + 15px);
    }

    .button-holder{
      justify-content: flex-start !important;
    }

    .card-button{
      padding: 3vw 6vw;
      font-size: calc(1vw + 6px);
    }
  }

  @media screen and (max-width: 425px){
    .card-button{
      padding: 4vw 6vw;
    }

    .card-image{
      width: 90%;
    }
  }
`

const mapStateToProps = state => {
  const {
      pathName
  }  = state.menu;

  return {pathName}
}

const mapDispatchToProps =dispatch =>  ({
  updateMainMenu: (val) => dispatch(updateMainMenu(val))
})

export default connect(mapStateToProps, mapDispatchToProps)(Card);



