import React from 'react';
import styled from 'styled-components';
import Card from '../../../card'

import Img from './cloud-banner.svg';

function ApplicationDevelopment() {
    return (
        <ApplicationDevelopmentContainer>
            {/* <div className='applicationDevelopment-header heading'>Cloud, Web & Mobile <div className='gradient3' >Application {window.innerWidth>=420?'Development':''}<div className='polygon3' ></div></div> {window.innerWidth<420?'Development':''}</div>
             */}
      
            <Card {...Obj1}/>
           
        </ApplicationDevelopmentContainer>
    )
}

const ApplicationDevelopmentContainer = styled.div`
    height: auto;
    background-color: transparent;
    padding-top: 80px;
    padding-right: 10vw;
    padding-left: 10vw;
    text-align: center;
    padding-bottom: 80px;

    .applicationDevelopment-header{
        font-size: calc(2vw + 15px);
        font-weight: 700;
        position:relative;
        z-index:2;
        
    }

    .applicationDevelopment-top{
        padding-top: 20px;
        font-size: calc(1vw + 5px);
        font-weight: 400;
    }

    @media screen and (max-width: 767px){
        padding-right: 8vw;
        padding-left: 8vw;

        .applicationDevelopment-header{
            font-size: calc(2vw + 20px);
        }

        .applicationDevelopment-top{
            font-size: calc(1vw + 10px);
        }
    }
`

export default ApplicationDevelopment;



const Obj1 = {
    topLine: '',
    btnType: 'outline',
    headline: `<div className='highlight'>Cloud, Web & Mobile</div> <br/>
     Application Development`,
    description: 'Empowering the Connected Future! Experience our range of mobile development applications, delivering top-notch data protection, identity management, and network security. Specializing in transformative loT technologies for batteries, infrastructure, ports, and healthcare. Stay ahead with our OTA firmware updates, AWS IoT integration, and innovative sensor algorithms for automotive and home appliances. Let’s shape the connected world together!',
    buttonLabel: '',
    img: Img,
    alt: 'application-image',
    imgStart: '',
    link:'',
    height:80
}
