import React from 'react';
import styled from 'styled-components';
import Card from '../../../card'
import BCSImg from './bcs.svg';
import bg2 from '../../../../../assets/bg2.svg'

function BCS() {
    return (
        <div   style={{
            background:`url(${bg2}) center no-repeat`,backgroundSize:'100%',
            backgroundPositionY:'-300%',
          
            backgroundColor:'#F3F8FD',
        
            }}>    
                 
              
            
        <BCSContainer>
            
      
            <Card {...Obj1}/>
        
           
        </BCSContainer>
        </div>
    )

}

const BCSContainer = styled.div`
    height: auto;
    background-color: transaprent;
    padding-top: 80px;
    padding-right: 10vw;
    padding-left: 10vw;
    text-align: center;


    .bc-header{
        font-size: calc(2vw + 15px);
        font-weight: 700;
        position:relative;
        z-index:2;
    }

    .bc-top{
        padding-top: 20px;
        font-size: calc(1vw + 5px);
        font-weight: 400;
    }

    @media screen and (max-width: 767px){
        padding-right: 8vw;
        padding-left: 8vw;

        .bcs-header{
            font-size: calc(2vw + 20px);
        }

        .bcs-top{
            font-size: calc(1vw + 10px);
        }
    }
`

export default BCS;




const Obj1 = {
    topLine: '',
    btnType: 'outline',
    headline: `<div className='highlight'>brigosha Connected Solutions :</div>
    Revolutionizing IoT Research and Development
     `,
     description: "brigosha Connected Solutions (BCS), the fearless ninja team of innovators, is on a relentless quest to conquer the world of IoT! With their lightning-fast delivery and innovative mindset, they have successfully revolutionized industries with their groundbreaking projects. BCS is armed with a powerful tech stack, including React.js, Angular.js, Node.js, Python, MongoDB, and more. Their projects have seamlessly integrated sensors, databases, and visualization boards, delivering real-time insights and custom reporting. With their cutting-edge solutions, BCS is transforming the way we live, work, and connect. Join them on their extraordinary journey and witness the power of IoT innovation firsthand!",
    buttonLabel: '',
    img: BCSImg,
    alt: 'bcs-image',
    imgStart: '',
    link:'',
    height:90
}
