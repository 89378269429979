import React, { useState ,useEffect} from 'react';
import styled from 'styled-components';
import { Button ,Spin} from 'antd';
import { Link } from 'react-router-dom';
// import Slider from "react-slick";
import Slider from './slider';
import './styles.css'
import {ReactComponent as Arrow} from './Vector.svg'
import Banner1 from './LidarBanner.svg'
import Banner2 from './CloudTech.svg'
import Banner3 from './MobilityBanner.svg' 
import Banner4 from './TelematicsBanner.svg'
import Banner5 from './DigitalBanner.svg'

import prev from './prev.svg'
import back from './back.svg'
import Api from '../../../../../services/Api'

const scrollToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0; 
}



function Potential() {

    const [slides,setSlides]=useState([]);
    const [loader,setLoader]=useState(false);
    useEffect(()=>{
        setLoader(true)
      
        Api.get("/public-apis/slides").send((res, err)=>{
        
            setSlides(res);
            setLoader(false)
        
        })
    },[]);
    const [sliderHeight,setSliderHeight]=useState();
    const[maxHeight,setMaxHeight]=useState();
        useEffect(() => {
        const handleResize = () => {
           
            if(window.innerWidth<425){
                setSliderHeight(window.innerHeight-60);
               
            }
          else {
            setSliderHeight(window.innerHeight-130);
            setMaxHeight(window.innerHeight-130);
        }

        if(window.innerHeight>600){
            setMaxHeight(600);
            if(window.innerWidth<767){
                setMaxHeight(800);
            }
        }
        if(window.innerWidth<767){
            if(window.innerHeight<600){
                setSliderHeight(700)
                setMaxHeight(700)
            }
        }
       
        };
    
        handleResize(); // Initial call to set the initial slidesToShow value
    
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      });
    
    const settings = {
        dots: true,
        adaptiveHeight:true,
       className:'banner',
       dotsClass:'slick-dots',
        slidesToShow: 1,
        slidesToScroll: 1,
        // nextArrow: <SampleNextArrow />,
        // prevArrow: <SamplePrevArrow />,
         autoplay:true,
         autoplaySpeed: 4000,
         infinite: true
        
      
      };
      function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
          <div
            className={className}
            style={{ ...style, display: "block",position:'absolute',  top: window.innerWidth>710?"93%":"6.5%",right:window.innerWidth>710?'43%':'2%',background:`url(${back})  no-repeat`,width:'30px',height:'30px' ,backgroundSize:window.innerWidth>710?'15px':'8px'}}
            onClick={onClick}
          />
        );
      }
      
      function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
          <div
            className={className}
            style={{ ...style, display: "block", position:'absolute',zIndex:2,  top: window.innerWidth>710?"93%":"6.5%",left:window.innerWidth>710?'44%':'80%',background:`url(${prev}) no-repeat`,width:'30px',height:'30px',backgroundSize:window.innerWidth>710?'15px':'8px' }}
            onClick={onClick}
          />
        );
      }
    //   const BannerData=slides?.map(ele=>{
    //     return{
    //         title:ele.title,
    //         heading:ele.heading,
    //        key:ele.id,
    //       context:ele.description,
    //     img:ele.gallerySlide[0].locationPath
    //     }
    // })
    return (
         <>
       <SliderContainer style={{height:sliderHeight,maxHeight:maxHeight}}>
       <Slider  >


       {BannerData.map((ele,key)=>{

return  <BannerContainer className='bannerContainer' key={key} >
   
     <div
      className='parent-container'
     >
         <div  className='child-div-1'>

     <div className='banner-heading heading'>{ele.title} <span className='highlight' >{ele.gradientTitle}</span>{ele.subTitle}</div>
     <div className='banner-content content grey'>
     {ele.context}
     </div>
     <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start',width:'100%',marginTop:'10px'}}>
     <Link to={ele.link} onClick={scrollToTop}  >
         <Button type = 'secondary' className='banner-button primary' >
             EXPLORE THE TECH 
         <Arrow  style={{height:'20px',width:'20px'}}/>
      </Button>
     </Link>
     </div>
     </div>
     <div 
  className='child-div-2'
     >
     <img 
     className='Banner-Img'
      src={ele.img} alt='big-banner' style={{width:ele.size,height:ele.size }}/>
     </div>
     </div>
     {/* <img className='mobile' src={ele.img} alt='mobile-banner'/> */}
   

 </BannerContainer>
       })}
       </Slider>
       </SliderContainer>
 


{/* ///////slider */}
       {/* <Slider {...settings}  >
        {BannerData.map((ele,key)=>{

              return  <div >
              <BannerContainer className='bannerContainer' key={key} >
                 
                   <div
                   
                    className='parent-container'
                   >
                       <div  className='child-div-1'>
           
                   <div className='banner-heading heading'>{ele.title} <span className='highlight' >{ele.gradientTitle}</span>{ele.subTitle}</div><br/>
                   <div className='banner-content content grey'>
                   {ele.context}
                   </div><br/>
                   <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start',width:'100%',marginTop:'10px'}}>
                   <Link to={ele.link} onClick={scrollToTop}  >
                       <Button type = 'secondary' className='banner-button primary' >
                           EXPLORE THE TECH 
                       <Arrow  style={{height:'20px',width:'20px'}}/>
                    </Button>
                   </Link>
                   </div>
                   </div>
                   <div 
           
                className='child-div-2'
                   >
                   <img 
                   className='Banner-Img'
                    src={ele.img} alt='big-banner' style={{width:ele.size}}/>
                   </div>
                   </div>
              
                 
              
               </BannerContainer>
               </div>
        })}
     
       
      </Slider> */}
      
    </>
    )
}
const SliderContainer=styled.div`

`
const BannerContainer = styled.div`
    width: 100vw;
  height:100%;
    padding-top: 40px;
    padding-right: 10vw;
    padding-left: 10vw;
    text-align: left;
    padding-bottom: 80px;
   max-height:max-content;
   min-height:auto;
   background:transparent;

   .parent-container {
    display: flex;
    justify-content:space-between;
    flex-direction:row;
    align-items:center;

    
  }
  
   .child-div-1 {
flex:1;

    width: 50%; /* Set the desired width */
  
 }
 .child-div-2{
    flex:1;
    //  height: 80vh; /* Set the desired height */
      width: 50%; /* Set the desired width */
      height: 100%; 
      display:flex;
      justify-content:center;
      align-items:center;
 }

 
    
    position:relative;

   .spinner{
    position:absolute;
    justify-content:center;
   }
  
    .spinner{
        position:absloute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .title{
        display: block;
        letter-spacing: 0.25rem;
        text-transform: uppercase;
        font-weight: 600;
        font-size: calc(1vw + 3px);
    }

    .banner-heading{
        font-family: 'Sofia Sans', sans-serif;
        font-size: calc(2vw + 15px);
        font-weight: 700;
        display: block;
        // width: 60vw;
        margin-top: 30px;
        color:#002546;
        line-height:50px;
        margin-bottom: 10px;
    }

    .banner-content{
        display: block;
        // width: 60vw;
        font-family: 'Inter', sans-serif;
        white-space: pre-wrap;
        white-space: -moz-pre-wrap;
        white-space: -pre-wrap;
        white-space: -o-pre-wrap;
        word-wrap: break-word;
        font-weight: 400;
        font-size: calc(1vw + 5px);
        padding-right:30px;
        color:#002546;
        margin-bottom: 20px;
       
       
    }
    .banner-button:hover{
     
        box-shadow: 0 20px 5px -10px rgba(0, 0, 0, 0.4);
       transform: translateY(1px);
    }

    .banner-button{
        // margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: calc(1vw + 3px);
        border-color: #0086FF;
        border-radius: 4px;
        font-weight: 700;
        background-color: transparent;
        padding: 2vw 4vw;
        gap:10px;
        background-color: #0086FF !important;
        color:white !important;
        box-shadow: 0 20px 40px 0 rgba(35, 100, 210, 0.2);
    }
  
    .mobile{
        display: none !important;
        width: stretch;
        margin-bottom: 30px;
    }
    @media screen and (max-width:1950px){
        .banner-heading{
          
            // width: 40vw  ;
        
        }
        .banner-content{
          
            // width: 40vw  ;
        
        }
    }

    @media screen and (max-width: 767px){
        padding-top: 0px;
    .parent-container{
        flex-direction:column;
        gap:30px;
    }
    .child-div-1,.child-div-2{
        width:100%;
    }
   

    .Banner-Img{
      width:90%;
      height:90%;
    }
        .title{
            font-size: calc(1vw + 10px);
        }

        .banner-content{
            font-size: calc(1vw + 10px);
        }

        .banner-heading{
            font-size: calc(2vw + 20px);
        }

        .banner-button{
            font-size: calc(1vw + 8px);
            padding: 3vw 6vw;
        }
       
    }

    @media screen and (max-width: 710px){
    
        padding-left: 8vw;
        padding-right: 8vw;
        

        .mobile{
            display: block;
          
            margin-left: -5vw;
            margin-right: -5vw;
        }

        .banner-content{
            width: 100%;
        }

        .banner-heading{
            width: 100%;
        }

        .banner-button{
            padding: 4vw 6vw;
        }
    }

`
const BannerData=[
    {
        title:'',
        gradientTitle:'Mobility',
        subTitle:' Solutions',
        context:"brigosha provides holistic mobility solutions focused on safety and security, ensuring a protected journey for all individuals.",
        img:Banner1,
        link:'technology',
        size:'75%'

    },
    {
        title:'Cloud ',
        gradientTitle:'Tech Stacks',
        subTitle:'',
        context:"brigosha provides niche solutions for cloud tech stacks and frameworks, empowering seamless integration and scalability for your digital infrastructure.",
        img:Banner2,
        link:'technology/connected-solutions',
        size:'70%'

    },
    {
        title:'',
        gradientTitle:'3D-Lidar',
        subTitle:' Perception and Tracking',
        context:"We conduct cutting-edge research and development for indigenous solutions using 3D-LiDARs, enabling advanced perception and tracking capabilities.",
        img:Banner3,
        link:'technology',
        size:'95%'

    },
       {
        title:'Telematics ',
        gradientTitle:'Platform Solutions',
        subTitle:'',
        context:"With brigosha unlock the power of telematics through our platform solutions, enabling real-time tracking, analytics, and optimization for your mobility ecosystem.",
        img:Banner4,
        link:'technology/connected-solutions',
        size:'80%'

    },
    {
        title:'',
        gradientTitle:'Digital',
        subTitle:' Product Engineering',
        context:'brigosha specializes in domain-agnostic digital product engineering, crafting customized solutions tailored to your unique business needs.',
        img:Banner5,
        link:'technology/connected-solutions',
        size:'80%'

    }
]


export default Potential



