
import React ,{useEffect} from 'react';
import styled from 'styled-components';
import { Row, Col, Button } from 'antd';
import parse from 'html-react-parser';
import Img from './icons/botg-banner.svg'
import { ReactComponent as Back } from '../../../../../assets/back-icon.svg';
import Img1 from './icons/Bluetooth.svg';
import Img2 from './icons/Navigation.svg';
import Img3 from './icons/Security.svg';
import Img4 from './icons/Data.svg';
import Img5 from './icons/Modular.svg';
import img from './icons/Botg-work.svg'
import { Link } from 'react-router-dom';
import bg2 from '../../../../we/subpages/ourStory/ourCulture/BG.svg'


function BOTG() {
  
    const handleBackBtn=()=>{
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        window.history.back();
    }
    useEffect(()=>{
      document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
    },[])
    return (
        <React.Fragment>
          
          <div   style={{
      background:`url(${bg2}) center no-repeat`,backgroundSize:'cover'
  
      }}>
          
        < BOTGContainer>
      


            <div style={{'display':'flex','alignItems':'center','justifyContent':'flex-start',position:'relative'}}>
           <Back className='back-btn' onClick={handleBackBtn} />
            </div>
       
            <BigCard {...Obj1}/>
            <br/>
            <br/>
            <br/>
            <br/>
            <div className='BOTG-header heading'>Future Of <div className='highlight'>Self Drive Vehicles</div> Is Here</div>
            <br/>
            <br/>
            <Row style={{ display: "grid",
             gridTemplateColumns: "repeat(auto-fit, minmax(400px, 1fr))",
             justifyContent: "center",
             placeItems:'center',
             gap: "10px"}}>

            <Col  className="team-card">
            <Cards {...Obj[0]} />
            </Col>
            <Col  className="team-card">
            <Cards {...Obj[1]} />
            </Col>
            <Col  className="team-card">
            <Cards {...Obj[2]} />
            </Col>
            <Col  className="team-card">
            <Cards {...Obj[3]} />
            </Col>
            <Col  className="team-card">
            <Cards {...Obj[4]} />
            </Col>
           </Row>
         
            <br/>
            <br/>
            <br/>
            <div className='BOTG-header heading'>How Does It <div className='highlight'>Work</div> ?</div>
            <br/>
            <br/>
           
            <img src={img} alt='botg' className='big-image' />
            
        </ BOTGContainer>
        </div>
        </React.Fragment>
    )
}

const BigCard=({
    btnType,
    buttonLabel,
    img,
    alt,
    headline,
    description,
    link,
    imgStart
 
})=>{

    const scrollToTop=()=>{
        // document.body.scrollTop = 0; // For Safari
        //   document.documentElement.scrollTop = 0;
    }  
    return(
        <>
        <CardStyle
        wrap={true}
      >
          <Col 
          xs={{span: 24, order: 2}} 
          sm={{span: 24, order: 2}} 
          md={{span: 12, order: imgStart === 'start' ? 2 : 1}} 
          lg={{span: 12, order: imgStart === 'start' ? 2 : 1}} 
          xl={{span: 12, order: imgStart === 'start' ? 2 : 1}}
          xxl={{span: 12, order: imgStart === 'start' ? 2 : 1}}
          className={imgStart === 'start' ? 'card-text-container left' : 'card-text-container left'}
          gutter = {20}
          >
            <h2 className='card-heading'>{parse(headline)}</h2>
         
            <p className='card-content content'>
          {description}
            </p>
            {
                link.length!==0? <div 
                className='button-holder'
                style={{justifyContent: 'flex-start' }}
              >
                <a href={`${link}`}  target="_blank" rel="noopener noreferrer"> 
                    <Button 
                      type = {btnType} 
                      className='card-button'
                      style ={{
                        color:  'white' ,
                        backgroundColor: 'transparent',
                        
                      }}
                    >
                      {buttonLabel}
                    </Button>
                  </a>
              
                
              </div>:null}

        
            
          </Col>
  
          <Col 
          xs={{span: 24, order: 1}}  
          sm={{span: 24, order: 1}}  
          md={{ span: 12, order: imgStart === 'start' ? 1 : 2 }}
          lg={{ span: 12, order: imgStart === 'start' ? 1 : 2 }}
          xl={{ span: 12, order: imgStart === 'start' ? 1 : 2 }}
          xxl={{ span: 12, order: imgStart === 'start' ? 1 : 2 }}
          className='content'
          style={{'display':'flex',justifyContent:'center'}}
          >
            {
              img ? <img src={img} alt={alt} className='card-image' /> : null
            } 
           
          </Col>
      </CardStyle>
     
    </>
    )
}
const  BOTGContainer = styled.div`
    height: auto;
    background-color: transaprent;
    padding-top: 40px;
    padding-right: 8vw;
    padding-left: 8vw;
    text-align: center;
    padding-bottom: 80px;
 
    .back-btn {
       
        cursor:pointer;
      
    }
    .back-btn svg{
        width:30px;
        height:30px;
    }
    .BOTG-header{
        font-size: calc(2vw + 15px);
        font-weight: 700;
        position:relative;
        z-index:2;
   
    }
    .big-image{
      width:70%;
      height:70%;
      
      padding:15px 15px;
      background-color:white;
      border-radius:10px;
      box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.11);
    }

    .BOTG-top{
        padding-top: 20px;
        font-size: calc(1vw + 5px);
        font-weight: 400;
    }
    .BOTG-icon{
      display:flex;
      flex-direction:column;
      align-items:center;
      background-color:rgba(229, 245, 255, 1);
      width:170px;
      height:154px;
      justify-content:center;
      border-radius:14px;
     
      
    }
    .BOTG-icon span{
      font-size: calc(1vw + 5px) ;
      font-family: 'Inter', sans-serif;
      font-weight:700 !important;
    }
    .BOTG-card{
      display:flex;
      flex-direction:row;
      align-items:center;
    
      max-width:600px;
   justify-content:flex-start;
      gap:30px;
    }
    .BOTG-card-info{
      width:400px;
      font-size: calc(1vw + 5px);
      font-family: 'Inter', sans-serif;
      font-weight:500;
      text-align:left;
    }

    @media screen and (max-width: 767px){
        padding-right: 8vw;
        padding-left: 8vw;
          .big-image{
            width:96%;
            height:96%;
          }
        .BOTG-header{
            font-size: calc(2vw + 20px);
        }

        .BOTG-top{
            font-size: calc(1vw + 10px);
        }
        .BOTG-card-info,  .BOTG-icon span{
          font-size: calc(1vw + 10px);
        }
        .BOTG-card{
          max-width:80vw;
        }
    }
`

const CardStyle = styled(Row)`
  padding-top: 20px;
  text-align:left;

  .card-text-container{
    height: auto;
    display: flex;
    
    flex-direction: column;
   
  }
  

  .card-image:hover{
    transform: scale(1.01);
    transition: all 0.3s ease-out;
  }

  .left{
    text-align: left;
  }

  .right{
    text-align: right;
  }

  .card-image{
    width: 80%;
    transition: all 0.3s ease-out;
  }

  .button-holder{
    width: 100%;

    
  }

  .card-content{
    font-size: calc(1vw + 5px);
    font-weight: 400;
    text-align:left;
    font-family: 'Inter', sans-serif;
    word-wrap: break-word;
  }

  .card-heading {
    width: 100%;
    margin-bottom: 24px;
    font-size: calc(2vw + 1px);
    font-weight: 700;
    font-family: 'Sofia Sans', sans-serif;
  }
  
  .card-button{
    margin-top: 20px;
    display:flex;
    justify-content:flex-start;
    align-items: center;
    font-size: calc(1vw + 3px);;
    border-color: #0086FF;
    border-radius: 4px;
    font-weight: 700;
    padding: 2vw 4vw;
    background-color: #0086FF !important;
    color:white !important;
    box-shadow: 0 20px 40px 0 rgba(35, 100, 210, 0.2);
  }

  .card-button:hover{
    background-color: #0086FF !important;
    color:white !important;
    box-shadow: 0 20px 5px -10px rgba(0, 0, 0, 0.4);
     transform: translateY(1px);
  }
 

  @media screen and (min-width: 2000px){
    .card-image{
      width: 75%;
    }
  }

  @media screen and (max-width: 767px){

    .card-content{
      font-size: calc(1vw + 10px);
      text-align: left !important;
    }

    .card-heading{
      text-align: left !important;
      font-size: calc(1vw + 15px);
    }

    .button-holder{
      justify-content: flex-start !important;
    }

    .card-button{
      padding: 3vw 6vw;
      font-size: calc(1vw + 6px);
    }
  }

  @media screen and (max-width: 425px){
    .card-button{
      padding: 4vw 6vw;
    }

    .card-image{
      width: 90%;
    }
  }
`


const Cards = ({ name, img ,description}) => {
 
    const scrollToTop = () => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
       
    }
    
    return(
        <CardStyle1>
           
            <div className='card'>
            <img src={img} alt={name}  className='card-image' />
            <div style={{display:'flex',flexDirection:'column',alignItems:'center',gap:'5px',justifyContent:'left'}}>
            <h1 className='title heading'>{name} </h1>
            <h3 className='card-content content'>{description}</h3>
           
            </div>
            
                
                </div>
        </CardStyle1>
    )
}

const CardStyle1 = styled.div`
    width:300px;
    height:304px;
    margin-bottom: 10px;
    transition: all 0.5s ease-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    text-align:left;

  
    background-color:white;
    border-radius:10px;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.11);
    padding:15px 15px;

    .card{
        width:100%;
        height:100%;
        display:flex;
        flex-direction:column;
        align-items: flex-start;
        justify-content: flex-start;
      
    }
    &:hover  .title {
      color:#faa444;
      transition: all 0.1s ease-out;
    }
   
    .card-content{
      font-size: calc(1vw + 2px);
    }
    // &:hover{
    //     transform: scale(1.03); 
    //     transition: all 0.3s ease-out;
    // }

    // card-image:hover{
    //     transform: scale(1.02);
    // }

  
    .card-image{
 
        border-radius:10px;
       
        object-fit:fill;
        margin-bottom:10px;
        align-self:flex-end;
        width:80px;
        height:80px;
      
       
      
        transition: all 0.3s ease-out;
      }

    .title{
        text-align: left;
        font-size: calc(1vw + 8px);
        font-weight: 700;
        // display:flex;
        // flex-direction:row;
        // align-items:center;
        // align-self: flex-start;
        // gap:10px;
        float:left;
        width:100%;
     margin-bottom:0px;
     transition: all 0.1s ease-out;
    }
  

    .position{
        display: block;
        font-size: calc(1vw + 3px);
        margin-bottom: 10px;
        font-weight: 400;
    }
    .card-button{
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size:  calc(1vw + 3px);
        border-color: #0086FF;
        border-radius: 4px;
        font-weight: 700;
        padding: 2vw 4vw;
      }
      @media screen and (min-width: 2000px){
        width:450px;
        height:450px;
      }
      @media screen and (max-width: 2000px){
        width:380px;
        height:380px;
      }

    @media screen and (max-width: 728px){
      width:230px;
      height:230px;
        .title{
            font-size: 14px;
        }

        .position{
            font-size: calc(1vw + 8px);
        }
        .card-button{
            font-size:12px;
        }
        .card-content{
          font-size: 12px;
        }
        .row{
            gap:40px;
        }
        .card-image{
          width:30px;
          height:30px;
        }
    }
`



const Obj = [
  
    {
        name: 'Bluetooth',
        description:'The solution has bluetooth connectivity to connect with smartphone and access the vehicle assigned to the rider.',
     
        img: Img1,
       
    },
    {
        name: 'Navigation',
        description:'The solution uses GNSS, GPS, GLONASS, IRNSS for navigation, asset monitoring and GeoFencing.',
        img: Img2,
      
    },
    {
        name:'Security',
        description:'OTP based authentication for communication between vehicle and mobile app at the cost of not more than 2 cents for 1000 operations.',
        img: Img3,
       
    },
    {
        name:'Data Analytics',
        description:'The vehicle information received in the cloud can  be logged and analyzed to monitor the health of each vehicle for predictive maintenance using AI.',
        img:Img4
    },
    {
        name:'Modular and Scalable',
        description:'The solution is easily scalable in thousands. Modularity of the solution enables the customization of all the features and its intelligence according to customer specifications.',
        img:Img5
    }
    
]
export default  BOTG;



const Obj1 = {
    topLine: '',
    btnType: '',
    headline:`<span className="BOTG-header"><div className='highlight' style="display:unset">BOTG</div></span>`,
    buttonLabel: 'REQUEST A DEMO NOW',
    img: Img,
    description:" Redefining the Self-Drive Experience! Enjoy keyless entry, cloud connectivity, and elite electric two-wheelers. Join our vibrant community of adventure seekers and embark on unforgettable journeys. Say hello to boundless excitement. Discover Bike On The Go—where convenience and exhilaration collide! Start your adventure today!",
    link:'mailto:info@brigosha.com',
    alt: 'BOTG-image',
    imgStart: ''
}


