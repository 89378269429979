import {
    UPDATE_MAIN_MENU
} from './action'


const initialState = {
    pathName: null
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case UPDATE_MAIN_MENU: 
            return {
                ...state,
                pathName: action.val
            }
        default: {
            return {...state};
        }
    }
}


export default reducer;