import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd'
import Image from './teamImagePic.svg';
import { Link } from 'react-router-dom';

import BG from './SVG.svg'


function TeamImage() {
    const scrollToTop = () => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
    return (
        <StyleTeamImage>
           {/* <span className='team-image-topline highlight'>Teams</span><br/> */}
           {/* <div className='team-image-heading heading'>An <div className='gradient2'>Amicable<div className='polygon2' ></div></div> Workforce</div><br/><br/> */}
           <div className='team-image-heading heading' >An <span className='highlight' >Amicable</span> Family </div>
           <br/>
           
           <img src={Image} alt='team' className='team-image'/><br/><br/>
           <span className='team-image-description content grey'>
                Our values and culture define us as 
                an amicable workforce. We are a workforce 
                that thrive under the umbrella of integrity 
                and belongingness irrespective of diverseness 
                working towards excellence.
            </span><br/><br/><br/>

            <Link to='/teams' onClick={scrollToTop}>
                <Button className='team-button primary'>
                    MEET THE TEAM
                </Button>
            </Link>
        </StyleTeamImage>
    )
}

const StyleTeamImage = styled.div`
    padding-right: 15vw;
    padding-left: 15vw;
    padding-top: 80px;
    background:url(${BG}) no-repeat;
    background-size:106%;
    

    width: 100%;
    height: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .team-image-topline{
        letter-spacing: 0.25rem;
        text-transform: uppercase;
        font-weight: 600;
        font-size: calc(1vw + 3px);
        font-family: 'Sofia Sans', sans-serif;
    }

    .team-image-heading{
        font-size: calc(2vw + 15px);
        font-weight: 700;
        display: block;
        margin-bottom: 20px;
        position:relative;
        z-index:2;
        font-family: 'Sofia Sans', sans-serif;
    }
    .team-button:hover{
        box-shadow: 0 20px 5px -10px rgba(0, 0, 0, 0.4);
       transform: translateY(1px);
     }
    .team-image{
        width: 100%;
        transition: all 0.3s ease-out;
    }

    .team-image:hover{
        transform: scale(1.01);
        transition: all 0.3s ease-out;
    }

    .team-button{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: calc(1vw + 3px);
        border-color: #0086FF;
        border-radius: 4px;
        font-weight: 700;
        padding: 2vw 4vw;
        background-color: #0086FF !important;
        color:white !important;
        box-shadow: 0 20px 40px 0 rgba(35, 100, 210, 0.2);
    }

    .team-image-description{
        font-weight: 400;
        font-family: 'Inter', sans-serif;
        font-size: calc(1vw + 5px);
    }

    .team-button:hover{
      
        background: #0086FF !important;
        color: white !important;
    }

    @media screen and (max-width: 767px){
        padding-left: 8vw;
        padding-right: 8vw;

        .team-image-description{
            font-size: calc(1vw + 10px);
        }

        .team-image-heading{
            font-size: calc(2vw + 20px);
        }

        .team-image-topline{
            font-size: calc(1vw + 10px);
        }

        .team-button{
            padding: 3vw 6vw;
            font-size: calc(1vw + 8px);
        }
    }

    @media screen and (max-width: 425px){
        
    }
`

export default TeamImage;
