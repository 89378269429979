import styled from 'styled-components';
import { Footer } from 'antd/lib/layout/layout';
import BG from './footerBG.svg'

export const FooterContents = styled(Footer)`
    padding-left: 5vw;
    padding-right: 5vw;
    // background-color: rgba(233, 243, 253, 1) ;
    background-image: linear-gradient(white, rgba(233, 243, 253, 1));
    height: auto;
    padding-top:100px;

    padding-bottom: 20px;
    font-weight: 400;
    font-size: calc(1vw + 5px);
    font-family: 'Sofia Sans', sans-serif;
    // background-image:url(${BG});
    // background-repeat: no-repeat; /* Control how the background image repeats */
    // background-position: center;
    // background-size: 130%; 
    .footer-footer-links{
        margin-bottom: 0px;
    }

    .footer-links{
        color: rgba(38, 50, 56, 1);
    }
    .footer-footer-links li,.footer-footer-links  p{
        font-size: calc(1vw + 3px);
        margin-top: 16px;
        color:rgba(38, 50, 56, 1);
        font-weight:600 !important;
    }
    .copy-right .footer-links, .copy-right .copy-right-text{
        font-size: calc(1vw + 3px);
        color:rgba(38, 50, 56, 1);
        font-weight:600 !important;
    }
    .footer-footer-links h3{
        font-size: calc(1vw + 6px);
        font-weight:800 !important;
    }
    li:hover .footer-links,.footer-links:hover, .footer-heading:hover , .hover-active{
        color: #0086FF !important;
        font-weight:800;
        transform: scale(1.02) ;
    }
    
.footer-heading{
    color: rgba(38, 50, 56, 1);
    font-weight:700;
}

.ant-dropdown-link{
    display:flex;
    justify-content:center;
    align-items:center;
 
}
    li:hover{
        color: #565656;
        transform: scale(1.02);
    }

    .footer-list{
        margin-top: 24px;
        list-style: none;
        padding: 0;
    }
    
    .footer-social-media{
        font-size: 30px;
        margin-top: -15px;
        padding: 0;
        display: flex;
        list-style: none;
    }

    .footer-media-tag{
        margin-right: 10px;
    }
    
    .footer-sign-in{
        border-radius: 4px;
        border: 1px solid #0086FF;
        font-weight: 700;
        font-size: calc(1vw + 1.8px);
        padding: 1.5vw 4vw;
        background-color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: -20px;
       
        background-color: #0086FF !important;
        color:white !important;
        box-shadow: 0 20px 40px 0 rgba(35, 100, 210, 0.2);
    }

    .footer-sign-in:hover{
        box-shadow: 0 20px 5px -10px rgba(0, 0, 0, 0.4);
        transform: translateY(1px);
    }

    .footer-footer{
        color: rgba(38, 50, 56, 1);
        border-top: 1px solid rgba(38, 50, 56, 1);;
        padding-top: 15px;
        display:flex;
        justify-content:space-between;
        
    }

    .ant-dropdown-link{
        color: rgba(38, 50, 56, 1);
    }
    .footer-company-info{
        display:flex;
        flex-direction:column;
        align-items:flex-start;
        gap:10px;
        width:80px;
    }
    .footer-company-logo{
        width: auto;
        height:75px;
       
    
      
    }
    .footer-company-logo path{
        fill:#0086FF;
    }
    .youtube-icon{
        width: 37.03px;
        height: 37.03px;
        margin-top: 3px;
    }
    .footer-address{
        margin-top: 24px;
        max-width: 15vw;
    }

    .footer-text{
        text-align: right;
        color: rgba(38, 50, 56, 1);
    }

    .footer-company-info{
        margin-bottom: 20px;
        
    }
    .associate-link{
        display:none;
      
    }
    .footer-links-wrapper{
        display:flex;
        flex-direction:row;
        max-width:1000px;
        flex-wrap:wrap;
        justify-content:space-around;
    }
    .copy-right{
        display:flex;
        flex-direction:row;
        flex-wrap:wrap;
    }
    .footer-end{
        display:flex;
        flex-direction:row;
        justify-content:space-between;
        width:100%;
        align-items: baseline;

    }
    .copy-right-top{
        display:none;
    }
    @media screen and (max-width:1440px){
        .associate-link{
            display:block;
          
        }
        .associate-link-info{
            display:none !important;
        }
        .footer-company-info{
            width: 100%;
            margin-bottom:80px;
        }
        .footer-sign-in{
        
            width:100%;
        }
    }

    @media screen and (max-width: 992px){
        .footer-text{
            text-align: center;
            margin-top: 20px;
        }

        .footer-footer{
            text-align: center;
        }
        .youtube-icon{
            width: 34.03px;
            height: 34.03px;
            margin-top: 3px;
        }

    }

    @media screen and (max-width: 767px){
        font-size: calc(1vw + 10px);
        padding-top:10px;
        .footer-sign-in{
            padding: 2vw 5vw;
            font-size: calc(1vw + 11px);
            width:100%;
        }
        .footer-links-wrapper{
            display:grid;
            grid-template-columns: repeat(2, 1fr);
          
            
        }
        .copy-right-top{
           display:block;
           padding-bottom:15px;
        }
        .footer-links-wrapper div{
            width:100% !important; 
        }

        .footer-address{
            margin-top: 24px;
            max-width: 250px;
        }
        .copy-right{
            display:flex;
            flex-direction:row;
            flex-wrap:wrap;
            align-items:center;
            height:10px;
        }
        .copy-right-text{
            display:none !important;
        }

        .weLinks, .automotiveLinks, .iotLinks{
            margin-top: 20px;
        }

        .footer-company-logo{
            width: auto;
        }

        .footer-company-info{
            // width:90vw;
            // display:flex;
            // justify-content:center;
            // align-items:center;
            // align-self:center;
            margin-bottom:60px;
           
         
        }
        .youtube-icon{
            width: 29.03px;
            height: 29.03px;
            margin-top: 8px;
        }
        .footer-text{
            text-align: left;
          
        }
    }
    @media screen and (max-width: 770px){
        .footer-footer-links li,.footer-footer-links  p,.copy-right-top{
          
            font-size: calc(1vw + 10px);
           
        }
        .footer-footer-links h3{
            
            font-size: calc(1vw + 15px);
        }
        .copy-right .footer-links, .copy-right .copy-right-text{
            font-size: calc(1vw + 10px);
          
        }
        
    }
    @media screen and (max-width: 400px){
    .footer-end{
        display:flex;
        flex-direction:column;
        align-items:flex-start;
        gap:20px;


   }
    }
    
`
