import React, { useEffect, useState } from 'react';

import PotentialMobility from './potential-mobility/potential';
import Automotive from './Automotive/automotive';
import Development from './development/Development';
import Overlay from './overlay';
import { Outlet } from 'react-router-dom';
import bg1 from '../../../we/subpages/ourStory/PotentialCard/potentialBG.svg'
import bg2 from '../../../../assets/bg2.svg'
import Research from './Mobility-research/research';



function Mobility() {
   const paths = [
      '/technology/mobility-solutions/safety-system', 
      '/technology/mobility-solutions/steering-solution',
      '/technology/mobility-solutions/driver-assistance',
      '/technology/mobility-solutions/infotainment'
    
  ]
  const [isNew,setNew]=useState(false)
  useEffect(()=>{
let sessionData=sessionStorage.getItem('new-window');
if(sessionData==null ){
sessionStorage.setItem('new-window','true');
setNew(true);
  }
  },[isNew])
   return (
  

 
     paths.includes(window.location.pathname) ? 
     <Outlet/> :
      <React.Fragment>
         {isNew?<Overlay/>:null}
         <div   style={{
       background: `url(${bg1}) top no-repeat`,
       backgroundSize: '100%',
       overflow: 'hidden',
     width:'100%',
     backgroundColor:'#F3F8FD',

      backgroundPositionY:'-20vh'
     
         }} className='background-image'>
        <PotentialMobility />
         <div   style={{
    background:`url(${bg2}) center no-repeat`,backgroundSize:'cover',
    backgroundColor:'#F3F8FD',
   
    }}> 
          <Automotive/>
       
          </div>
          </div>
          <div   style={{
     backgroundSize:'100%',
    background:'rgb(250,252,254,1)',
   
    }}> 
          <Research/>
      </div>
                   
        <div   style={{
    background:`url(${bg2}) center no-repeat`,backgroundSize:'cover',
    backgroundColor:'#F3F8FD',
    }}>
         
          <Development/>
          </div>
      </React.Fragment>
   
   )
}

export default Mobility;

