
import React ,{useState,useEffect} from 'react';
import styled from 'styled-components';
import { Row, Col, Button } from 'antd';
import {ReactComponent as ArrowLeft} from '../../../../we/subpages/teams/left.svg'
import {ReactComponent as ArrowRight} from '../../../../we/subpages/teams/right.svg'

import Img1 from './svgs/PA.svg';
import Img2 from './svgs/MR.svg';
import Img3 from './svgs/DesignIgnition.svg';
import Img4 from './svgs/Prototyping.svg';
import Img5 from './svgs/CloudTech.svg';
import Img6 from './svgs/DevOps.svg'
import Img7 from './svgs/Testing.svg';
import Img8 from './svgs/Production.svg';



function ProductDevelopment() {
    const [values, setValues] = useState(0);
    const [reset,setReset]=useState(false);
    const length=Obj.length;
    const changeValuesDown = (prev) => {
        setValues((prev) => {
            if(prev == 0){
                return prev+length-1;
            }
            else{
                return prev-1;
            }
        })
    }

    const changeValuesUp = () => {
        setValues((prev) => {
            if(prev == length-1){
                return prev-(length-1);
            }
            else{
                return prev+1;
            }
        })
    }
    const setCustomValue=(value)=>{
        setValues(value);
    }

    useEffect(() => {
       if(!reset){
        const mount = setInterval(() =>{
            setValues((prev) => {
                if(prev == length-1){
                    return prev-(length-1);
                }
                else{
                    return prev+1;
                }
            })
        }, 5000)

        return () => clearInterval(mount);
    }
    }, [reset])
    return (
        <ProductDevelopmentContainer>
          
            <div className='productDevelopment-header heading'><div className='highlight'>Product Development</div> Process </div>
      
            <br/>
            <p className='productDevelopment-top content'>The solution we have developed will be utilized in cloud/server applications and solutions for
diverse IoT scenarios. The process involves designing, prototyping, and developing the solution
with utmost precision and expertise. </p>
            <br/>
            <ValuesCarousal>
                <Row style={{display:'flex',justifyContent:'space-between'}}
              
                >
                    <Col 
                        xs={{span: 24, order: 2}} 
                        sm={{span: 24, order: 2}} 
                        md={{span: 12, order: 1}} 
                        lg={{span: 12, order: 1}} 
                        xl={{span: 12, order: 1}} 
                        xxl={{span: 12, order: 1}}
                        className='description'
                    >
                     
                        <div className='carousal'    onMouseEnter={() => {setCustomValue(values);setReset(!reset)}}
                onMouseLeave={() => setReset(!reset)} >
                            <span className='header heading'>{Obj[values].heading}</span>
                            <p className='paragraph content grey'>{Obj[values].description}</p>
                        </div>
                    </Col>
                    <Col 
                        xs={{span: 24, order: 1}} 
                        sm={{span: 24, order: 1}} 
                        md={{span: 12, order: 2}} 
                        lg={{span: 12, order: 2}} 
                        xl={{span: 12, order: 2}} 
                        xxl={{span: 12, order: 2}}
                        className='image-container'
                        onMouseEnter={() => {setCustomValue(values);setReset(!reset)}}
                        onMouseLeave={() => setReset(!reset)}
                    >
                        <img src={Obj[values].img} alt='Carousal Banner' className='carousal-image'/>
                    </Col>
                </Row>   
                <Row className='arrow-button '>
                    <Col  className='lines'>
                        <span className='left-outlined' onClick={() => changeValuesDown()}>
                           <ArrowLeft/>
                        </span>

                      {Obj.map((ele,index)=>{
                      
                        return <div className={values === index ? 'blueline' : 'greyline'} onClick={()=>{setCustomValue(index);setReset(!reset)}} 
                        onMouseLeave={() => setReset(!reset)}></div>
                      })}
                        
                        
                        
                        <span className='right-outlined' onClick={() => changeValuesUp()}>
                         <ArrowRight/>
                        </span>
                    </Col>
                </Row>
            </ValuesCarousal>
           
            
     
           
        </ProductDevelopmentContainer>
    )
}

const ProductDevelopmentContainer = styled.div`
    height: auto;
    // background-color: transaprent;
  
    padding-top: 80px;
    padding-right: 10vw;
    padding-left: 10vw;
    text-align: center;
   

    .productDevelopment-header{
        font-size: calc(2vw + 15px);
        font-weight: 700;
        position:relative;
        z-index:2;
    }

    .productDevelopment-top{
        padding-top: 20px;
        font-size: calc(1vw + 5px);
        font-weight: 400;
    }

    @media screen and (max-width: 767px){
        padding-right: 8vw;
        padding-left: 8vw;

        .productDevelopment-header{
            font-size: calc(2vw + 20px);
        }

        .productDevelopment-top{
            font-size: calc(1vw + 10px);
        }
    }
`
const ValuesCarousal = styled.div`
text-align:left;

    .title{
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 0.25rem;
        font-size: calc(1vw + 3px);
        font-family: 'Sofia Sans', sans-serif;
    }
    .lines{
        display:flex;
        flex-direction:row;
        align-items:center;
        gap:10px;
    }
.blueline{
    width:60px;
    height:5px;
    background-color:#0086FF;
    border-radius:3px;
    cursor:pointer;
}
.greyline{
    width:20px;
    height:5px; 
    background-color:#9D9D9D;
    border-radius:3px;
    cursor:pointer;
}
    .header{
        font-weight: 700;
        font-size: calc(2vw + 5px);
        display: block;
        margin-bottom: 20px;
        font-family: 'Sofia Sans', sans-serif;
    }

    .paragraph{
        font-weight: 400;
        font-size: calc(1vw + 5px);
        font-family: 'Inter', sans-serif;
    }

    .carousal-image{
        width: 400px;
        height:400px;
        padding-left:20px;
    }

    .carousal{
        margin-top: 50px
    }

    .image-container{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width:50%;
        height:50%;
    }

    .arrow-button{
        padding-bottom: 50px;
        font-size: calc(1vw + 8px);
        font-weight: 600;
        font-family: 'Sofia Sans', sans-serif;
        margin-top:20px;
       
    }

    .left-outlined>svg, .right-outlined>svg{
        display: flex;
        // margin-left: 10px;
        // margin-right: 30px;
        cursor: pointer;
        width:20px;
        height:20px;
        align-items:center;
    }

    .carousal-option{
        font-size: calc(2vw + 3px); 
        font-weight: 400;
        font-family: 'Sofia Sans', sans-serif;
    }

    .option{
        font-weight: 700;
      

    }
    .description{
      
    }



    @media screen and (min-width: 2000px){
        .carousal-image{
            width: 60%;
        }
    }

    @media screen and (max-width: 915px){
        .carousal-image{
            width: 80%;
        }
        .carousal-option, .description{
            // display: none;
        }
     
        .mobile-description{
            display: flex;
            flex-direction: column;
            margin-top: 40px;
            font-size: calc(2vw + 8px);
            font-weight: 700;
            font-family: 'Sofia Sans', sans-serif;
        }

        .mobile-description .desc-header{
            display: flex;
            flex-direction: row;
            justify-content: flex-start
    
        }
        .desc-header span{
            font-family: 'Sofia Sans', sans-serif;
        }

        .mobile-description span { 
            margin-right: 10px;
        }

        .desc-body{
            font-size: calc(1vw + 10px);
            font-weight: 400;
            // padding-left: 46.38px;
            font-family: 'Sofia Sans', sans-serif;
        }
        .title{
            font-size: calc(1vw + 10px);
        }
        .image-container{
            justify-content:center;
        }
        .blueline{
            width:30px;
            height:5px;
            background-color:#0086FF;
            border-radius:3px;
            cursor:pointer;
        }
        .greyline{
            width:10px;
            height:3px; 
            background-color:#9D9D9D;
            border-radius:3px;
            cursor:pointer;
        }
    }
    @media screen and (max-width: 770px){
        .paragraph{
          
            font-size: calc(1vw + 10px);
           
        }
        .header {
            
            font-size: calc(1vw + 15px);
        }
    }

    
`   



const Obj = [
    {
        heading: 'Product Analysis',
        description: 'Unveiling Insights, Crafting Success. We dive deep into market research, customer needs, and competition analysis to shape a winning product strategy.',
        img:Img1
    },
    {
        heading: 'Market Research',
        description: 'Unlocking Trends, Powering Innovation. Our thorough market research uncovers emerging trends, customer preferences, and market gaps, enabling us to create innovative solutions that stand out.',
        img:Img2
    },
    {
        heading: 'Design Ignition',
        description: 'From Vision to Reality, Igniting Innovation. With small-scale and large-scale design iterations, we transform ideas into tangible designs, pushing boundaries and fueling innovation every step of the way.',
        img:Img3
    },
    {
        heading: 'Prototyping',
        description: 'Shaping Ideas, Perfecting Perfection. Our skilled team crafts prototypes that bring concepts to life, allowing for thorough testing, user feedback, and fine-tuning to ensure an exceptional end product.',
        img:Img4
    },
    {
        heading: 'Development Process',
        description: 'Front-End Finesse, Back-End Brilliance. With meticulous attention to both front-end user experience and back-end functionality, we engineer seamless and robust solutions that exceed expectations.',
        img:Img5
    },
    {
        heading: 'DevOps Implementation',
        description: 'In the ever-evolving IT landscape, staying ahead of trends and meeting dynamic consumer demands is a challenge. Enter DevOps, the ultimate solution for high-speed product development, rapid application delivery, and timely marketing. With cutting-edge tools like Git & GitHub, Jenkins, Docker, and more, coupled with cloud services like AWS, we revolutionize your IT capabilities. Unleash the power of DevOps and witness the true potential of your organization.',
        img:Img6
    },
    {
        heading:'Testing',
        description:'Quality Unleashed, Performance Ensured. Rigorous testing at every stage ensures our products meet the highest standards of quality, performance, and user satisfaction.',
        img:Img7
    },
    {
        heading:'Production',
        description:'Turning Dreams into Reality, Unleashing Market Dominance. With precision and efficiency, we transition from development to production, delivering a market-ready product that captivates and dominates the industry.',
        img:Img8
    }
 
]

export default ProductDevelopment;



// const Obj1 = {
//     topLine: '',
//     btnType: 'outline',
//     headline: `Product <div className='highlight'>Development</div> Process`,
//     description: 'The solution we have developed will be utilized in cloud/server applications and solutions for diverse IoT use cases. The process involves designing, prototyping, and developing the solution with utmost precision and expertise.',
//     buttonLabel: '',
//     img: Img,
//     alt: 'product-image',
//     imgStart: 'start',
//     link:'',
//     height:90

// }
