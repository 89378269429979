import React from 'react';
import './style.css';
import bg2 from '../../../../assets/bg2.svg'
import parse from 'html-react-parser';
const Terms = () => {
  return (
   <>
    <div   style={{
      background:`url(${bg2}) center no-repeat`,backgroundSize:'100%',backgroundColor:'#F3F8FD',backgroundPositionY:''}}>
  
    <div className="terms-page"  style={{paddingTop:'60px'}}>
      <h1 className="terms-page__title">Terms & <div className='highlight'>Conditions</div></h1>
      <h3 className="terms-page__heading">General Terms</h3>
      <p className="terms-page__description" style={{marginBottom:0}} >
By accessing and placing an order with you, you confirm that you are in 
agreement with and bound by the terms of service contained in the Terms & 
Conditions outlined below. These terms apply to the entire website and any 
email or other type of communication between you and
Under no circumstances shall the team be liable for any direct, indirect, 
special, incidental, or consequential damages, including, but not limited to, 
loss of data or profit, arising out of the use, or the inability to use, the 
materials on this site, even if a team or an authorized representative has been
advised of the possibility of such damages. If your use of materials from this 
site results in the need for servicing repair or correction of equipment or data, 
you assume any costs thereof will not be responsible for any outcome that 
may occur during the course of usage of our resources. We reserve the right 
to change prices and revise the resource usage policy at any moment.</p>
</div>
</div>
<div   style={{
      background:`url(${bg2}) center no-repeat`,backgroundSize:'100%',backgroundColor:'#F3F8FD',backgroundPositionY:'100%'}}>
         <div className="terms-page"  style={{paddingTop:'10px'}} >
<h3 className="terms-page__heading">License</h3>
<p className="terms-page__description"> 
brigosha grants you a revocable, non-exclusive, non-transferable, limited 
license to download, install and use our service strictly by the terms of this 
Agreement.
These Terms & Conditions are a contract between you and brigosha (referred
to in these Terms & Conditions as "brigosha", "us", "we or "our"), the provider 
of the brigosha website and the services accessible from the brigosha website
(which are collectively referred to in these Terms & Conditions as the 
"brigosha Service")
You are agreeing to be bound by these Terms & Conditions. If you do not 
agree to these Terms & Conditions, please do not use the Service. In these 
Terms & Conditions, "you" refers both to you as an individual and to the entity 
you represent. If you violate any of these Terms & Conditions, we reserve the 
right to cancel your account or block access to your account without notice.
      </p>
     <h3 className="terms-page__heading">Definitions and key terms</h3>
     <p className="terms-page__description">
     {parse(`
For this Terms & Conditions
Cookie: a small amount of data generated by a website and saved by your web browser. It is used to identify your browser, provide analytics, remember information about you such as your language preference or login information Company: When this policy mentions "Company" "we" "us" or "our" it refers to brigosha Technologies Pvt Ltd.<b> Building #780 VCR Cornerstone 3rd Floor 19th Main, HSR Layout Sector 1, Bangalore 560102 </b> is responsible for your information.
Country: where brigosha or the owner's founders of brigosha are based, in this case, is India
Customer: refers to the company, organization, or person that signs up to usethe brigosha Service to manage the relationships with your consumers or service users Device: any internet-connected device such as a phone, tablet, computer, or any other device that can be used to visit brigosha and use the services.
IP address: Every device connected to the Internet is assigned a number known as an Internet protocol (IP) address: These numbers are usually assigned in geographic blocks. An IP address can often be used to identify the location from which a device is connecting to the Internet.
Personnel: refers to those individuals who are employed by Brigosha or are under contract to perform a service on behalf of one of the parties.
Personal Data: any information that directly, indirectly, or in connection with other information including a personal identification number-allows for the identification or identifiability of a natural person.
Service: refers to the service provided by Brigosha as described in the relative terms (if available) and on this platform. 
Third-party service: refers to advertisers, contest sponsors, promotional and marketing partners, and others who provide our content or whose products or services we think may interest you. 
• Website: brigosha's site, which can be accessed via this URL <a href='https://www.brigosha.com/'>https://www.brigosha.com/</a>. 
• You: a person or entity that is registered with brigosha to use the Services.`
     )}
     </p>
     <h3  className="terms-page__heading">Restrictions</h3>
     <p className="terms-page__description">You agree not to, and you will not permit others to license, sell, rent, lease, assign, distribute, transmit, host, outsource, disclose, or otherwise commercially exploit the service or make the platform available to any third party. Modify, make derivative works of, disassemble, decrypt, reverse compile, or reverse engineer any part of the service. Remove, alter, or obscure any proprietary notice (including any notice of copyright or trademark) of or its affiliates, partners, suppliers, or licensors of the service.</p>
     <h3  className="terms-page__heading">Your Suggetions</h3>
     <p className="terms-page__description">Any feedback, comments, ideas, improvements, or suggestions (collectively, “Suggestions”) provided by you to us concerning the service shall remain our sole and exclusive property. We shall be free to use, copy, modify, publish, or redistribute the
suggestions for any purpose and in any way without any credit or compensation to you.</p>
   <h3  className="terms-page__heading"> Your Consent</h3>
   <p className="terms-page__description" style={{marginBottom:0}}>We’ve updated our Terms and Conditions to provide you with complete transparency into what is being set when you visit our site and how it’s being used. By using our service, registering an account, or making a purchase, you hereby consent to our Terms and Conditions.
</p>
</div>
</div>
<div   style={{
      background:`url(${bg2}) center no-repeat`,backgroundSize:'100%',backgroundColor:'#F3F8FD',backgroundPositionY:'100%',paddingTop:'10px'}}>
         <div className="terms-page"  >
   <h3  className="terms-page__heading">Links to Other Websites</h3>
   <p className="terms-page__description">Our Service may contain links to other websites that are not operated by Us. If you click on a third-party link, You will be directed to that third-party’s site. We strongly advise You to review the Terms and Conditions of every site you visit. We have no control over and assume no responsibility for the content. Terms and Conditions or practices of any third-party sites or services.</p>
   <h3  className="terms-page__heading">Changes to our Terms & Conditions</h3>
   <p className="terms-page__description">You acknowledge and agree that we may stop (permanently or temporarily) providing the service (or any features within the service) to you or users generally at our sole discretion, without prior notice to you. You may stop using the service at any time. You do not need to specifically inform us when you stop using the service. You acknowledge and agree that if we disable access to your account, You may be prevented from accessing the service, your account details, or any files or other materials that are contained in your account. If we decide to change our Terms and Conditions, we will post those changes on this page and/or update the Terms and Conditions modification date below.</p>
   <h3  className="terms-page__heading">Modifications to our service</h3>
   <p className="terms-page__description">We reserve the right to modify, suspend, or discontinue, temporarily or permanently, the service or any service to which it connects, with or without notice and without liability to you.</p>
   <h3  className="terms-page__heading">Updates to our service</h3>
   <p className="terms-page__description">We may from time to time provide enhancements or improvements to the features or functionality of the service, which may include patches, bug fixes, updates, upgrades, and other modifications (Updates). Updates may modify or delete certain features and/or functionalities of the service. You agree that we have no obligation to (1) provide any updates, or ® continue to provide or enable any particular features and/or functionalities of the service to you. You further agree that all updates will be (1) deemed to constitute an integral part of the service and (ii) subject to the terms and conditions of this agreement.</p>
   <h3  className="terms-page__heading">Third-Party services</h3>
   <p className="terms-page__description">We may display, include, or make available third-party content (including data, information applications, and other product services) or provide links to third-party websites or services (“Third-Party Services”). You acknowledge and agree that we shall not be responsible for any Third-Party Services, including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quantity, or any other aspect thereof. We do not assume and shall not have any liability or responsibility to you or any other person or entity for any Third-Party Services. Third-Party Services and links thereof are provided solely as a convenience to you, and you access and use them entirely at your own risk and subject to such third parties’ terms and conditions.
</p>

   <h3  className="terms-page__heading">Term and Termination</h3>
   <p className="terms-page__description">This Agreement shall remain in effect until terminated by you or us. We may, in our sole discretion, at any time and for any or no reason, suspend or terminate this agreement with or without prior notice. This agreement will terminate immediately, without prior notice from us, if you fail to comply with any provision of it. You may also terminate this agreement by deleting the service and all copies thereof from your computer. Upon termination of this agreement, you shall cease all use of the service and delete all copies of the service from your computer. Termination of this agreement will not limit any of our rights or remedies at law or in equity in the event of a breach by you (during the term of this agreement) of any of your obligations under the present agreement.
</p>
   <h3  className="terms-page__heading">Copyright Terms</h3>
   <p className="terms-page__description">If you are a copyright owner or such owner’s agent and believe any material from us constitutes an infringement on your copyright, please contact us, setting forth the following information: (a) a physical or electronic signature of the copyright owner or a person authorised to act on his behalf; (b) identification of the material that is claimed to be infringing: (c) your contact information, including your address, telephone number, and email; (d) a statement by you that you have a good faith belief that use of the material is not authorized by the copyright owners and the statement that the information in the notification is accurate and, under penalty of perjury, you are authorized to act on behalf of the owner.
</p>
   <h3  className="terms-page__heading">Indemnification
</h3>
   <p className="terms-page__description">You agree to indemnify and hold us and our parent’s subsidiaries, affiliates, officers, associates, agents, partners, and licensors (if any) harmless from any claim or demand, including reasonable attorneys’ fees, due to or arising out of your (a) use of the service; (b) violation of this agreement or any law or regulation; or (c) violation of any right of a third party.
</p>
   <h3  className="terms-page__heading">No Warranties</h3>
   <p className="terms-page__description">
    {`The service is provided to you “AS IS,”  “AS AVAILABLE,” and with faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, we, on our behalf and on behalf of our affiliates and our respective licensors and service providers, expressly disclaim all warranties, whether express, implied, statutory, or otherwise, concerning the service, including all implied warranties of merchantability, fitness for a particular purpose, life, and non-infringement, and warranties that may arise out of a course of dealing, course of performance, usage, or trade practise. Without limitation to the foregoing, we provide no warranty or undertaking and make no representation of any kind that the service will meet your requirements, achieve any intended results, be compatible with or work with any other software, websites, systems, or services, operate without interruption, meet any performance or reliability standards, be error-free, or that any errors or defects can or will be corrected.
 
Without limiting the foregoing, neither we nor any provider makes any representation or warranty of any kind, express or implied: (1) as to the operation or availability of the service, or the information, content, and materials or products included thereon, that the service will be uninterrupted or error-free; (ii) as to the accuracy, reliability, or currency of any information or content provided through the service; or (iv) that the service, its servers, the content, or e-mails sent from or on behalf of us are free of viruses, scripts, trojan horses, worms, malware, timebombs, or other harmful components. Some jurisdictions do not allow the exclusion of or limitations on implied warranties or the limitations on the applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to you.

    `}
   </p>
   <h3  className="terms-page__heading">Limitation of Liability
</h3>
   <p className="terms-page__description">Not withstanding any damages that you might incur, the entire ability of us and any of our suppliers under any provision of this agreement, and your exclusive remedy for all of the foregoing, shall be limited to the amount paid by you for the service. To the maximum extent permitted by applicable law, in no event shall we or our suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, for loss of data or other information, for business interruption, for personal injury, for loss of privacy arising out of or in any way related to the use of or inability to use the service, third-party software and/or third-party hardware used with the service, or otherwise in connection with any provision of this agreement), even if we or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose. Some states and jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, so the above limitation or exclusion may not apply to you.
</p>

   <h3  className="terms-page__heading">Severability
</h3>
   <p className="terms-page__description" style={{marginBottom:0}} >
    {`If any provision of this agreement is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law, and the remaining provisions will continue in full force and effect.
 
This agreement, together with the privacy policy and any other legal notices published by us on the services, still constitutes the entire agreement between you and us concerning the services. If any provision of this agreement is deemed invalid by a court of competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of this agreement, which shall remain in full force and effect. No waiver of any term of this agreement shall be deemed a further or continuing waiver of such term or any other form, and our failure to assert any right or provision under this agreement shall not constitute a waiver of such right or provision. YOU AND US AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO THE SERVICES MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.`}
   </p>
   </div>
   </div>


   <div   style={{
      background:`url(${bg2}) center no-repeat`,backgroundSize:'100%',backgroundColor:'#F3F8FD',backgroundPositionY:'100%', backgroundClip: 'padding-box'}}>
         <div className="terms-page" style={{paddingTop:'10px'}} >
   <h3  className="terms-page__heading">Waiver</h3>
   <p className="terms-page__description">{`Except as provided herein, the failure to exercise a right or to require the performance of an obligation under this Agreement shall not affect a party’s ability to exercise such right or require such performance at any time thereafter, nor shall the waiver of a breach
constitute a waiver of any subsequent breach.
 
No failure to exercise or delay in exercising, on the part of either party, any right or power under this agreement shall operate as a waiver of that right or power. Nor shall any single or partial exercise of any right or power under this agreement preclude further exercise of that or any other right granted herein. In the event of a conflict between this agreement and any applicable purchase or other terms, the terms of this agreement shall govern.`}</p>
   <h3  className="terms-page__heading">Amendments to this Agreement</h3>
   <p className="terms-page__description">We reserve the right, at our sole discretion, to modify or replace this agreement at any time. If a revision is material, we will provide at least 30 days’ notice before any new terms take effect. What constitutes a material change will be determined at our sole discretion. By continuing to access or use our service after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use our service.
</p>
   <h3  className="terms-page__heading">Entire Agreement</h3>
   <p className="terms-page__description">The agreement constitutes the entire agreement between you and us regarding your use of the service and supersedes all prior and contemporaneous written or oral agreements between you and us. You may be subject to additional terms and conditions that apply when you use or purchase other services from us, which we will provide to you at the time of such use or purchase.
</p>
   <h3  className="terms-page__heading">Updates to Our Terms
</h3>
   <p className="terms-page__description">We may change our service and policies, and we may need to make changes to these terms so that they accurately reflect our Service and policies. Unless otherwise required by law, we will notify you (for example, through our service) before we make changes to these terms and allow you to review them before they go into effect. Then, if you continue to use the service, you will be bound by the updated terms. If you do not want to agree to these or any updated terms, you can delete your account.</p>
   <h3  className="terms-page__heading">Intellectual Property</h3>
   <p className="terms-page__description">Our platform and its entire contents, features, and functionality (including but not limited to all information, software, text, and displays) Images, video, and audio, as well as the design, selection, and arrangement such material,  are owned by us, its licensors, or other providers of such material and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights. The material may not be copied, reproduced, downloaded, or distributed in any way, in whole or in part, without the express prior written permission of us, unless and except as expressly provided in these Terms & Conditions. Any unauthorized use of the material is prohibited.
</p>
   <h3  className="terms-page__heading">Agreement to Arbitrate</h3>
   <p className="terms-page__description">{`This section applies to any dispute. EXCEPT DOESN’T INCLUDE DISAUTE RELATING TO CLAIMS FOR INJUNCTIVE OR EQUITABLE RELIEF REGARDING THE ENFORCEMENT OPMALIDITY OF YOUR OR INTELLECTUAL PROPERTY RIGHTS. The term “dispute” means any dispute action or other controversies between you and us concerning the Services or this agreement, whether in contract warranty, a statute, regulation, ordinance, or other legal or equitable ties in a dispute, given the broadest possible meaning allowed by law.`}</p>
   <h3  className="terms-page__heading">Notice of Dispute
</h3>
   <p className="terms-page__description">In the event of a dispute, you or we must give the other a Notice of Dispute, which is a written statement that sets forth the name, address, and contact information of the party giving it, the facts giving rise to the dispute, and the relief requested. You must send any Notice of Dispute via email to: We will send any Notice of Dispute to you by mail to your address if we have it, or otherwise to your email address. You and we will attempt to resolve any dispute through informal negotiation within sixty (60) days from the date the Notice of Dispute is sent. After sixty (60) days, you or we may commence arbitration.</p>
   <h3  className="terms-page__heading">Binding Arbitration
</h3>
   <p className="terms-page__description">If you and we don’t resolve any dispute by informal negotiation, any other effort to resolve the dispute will be conducted exclusively by binding arbitration, as described in this section: You are giving up the right to litigate (or participate in as a party or class member) all disputes in court before a judge or jury. The dispute shall be settled by binding arbitration under the commercial arbitration rules of the American Arbitration Association. Either party may seek any interim or preliminary injunctive relief from any court of competent jurisdiction as necessary to protect the party’s rights or property pending the completion of arbitration. Any legal, accounting, and other costs, fees, and expenses incurred by the prevailing party shall be borne by the non-prevailing party.
</p>
   <h3  className="terms-page__heading">Submissions and Privacy
</h3>
   <p className="terms-page__description">If you and we don’t resolve any dispute by informal negotiation, any other effort to resolve the dispute will be conducted exclusively by binding arbitration, as described in this section: You are giving up the right to litigate (or participate in as a party or class member) all disputes in court before a judge or jury. The dispute shall be settled by binding arbitration under the commercial arbitration rules of the American Arbitration Association. Either party may seek any interim or preliminary injunctive relief from any court of competent jurisdiction as necessary to protect the party’s rights or property pending the completion of arbitration. Any legal, accounting, and other costs, fees, and expenses incurred by the prevailing party shall be borne by the non-prevailing party.
</p>
   <h3  className="terms-page__heading">Promotions</h3>
   <p className="terms-page__description"> 
We may, from time to time, include contests, promotions, sweepstakes, or other activities (“Promotions”) that require you to submit material or information concerning yourself. Please note that all promotions may be governed by separate rules that may contain certain eligibility requirements, such as restrictions as to age and geographic location. You are responsible for reading all promotion rules to determine whether or not you are eligible to participate. If you enter any promotion, you agree to abide by and comply with it.</p>
   <h3  className="terms-page__heading">Typographical Errors
</h3>
   <p className="terms-page__description">In the event a product and/or service is listed at an incorrect price or with incorrect information due to a typographical error, we shall have the right to refuse or cancel any orders placed for the product and/or service listed at the incorrect price. We shall have the right to refuse or cancel any such order, whether or not the order has been confirmed and your credit card charged. If your credit card has already been charged for the purchase and your order is cancelled, we will immediately issue a credit to your credit card account or another payment account in the amount of the charge.
</p>
   <h3  className="terms-page__heading">Miscellaneous
</h3>
   <p className="terms-page__description">If, for any reason, a court of competent jurisdiction finds any provision or portion of these Terms and Conditions to be unenforceable, the remainder of these Terms and Conditions will continue in full force and effect. Any waiver of any provision of these Terms and Conditions will be effective only if in writing and signed by an authorised representative of us. We will be entitled to injunctive or other equitable relief (without the obligation of posting any bond or surety) in the event of any breach or anticipatory breach by you. We operate and control our service from our offices in the service is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation. Accordingly, those who choose to access our service from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable. These Terms and Conditions (which include and incorporate our Privacy Policy) contain the entire understanding, supersede all prior understandings between you and us concerning their subject matter, and cannot be changed or modified by you. The section headings used in this agreement are for convenience only and will not have any legal import.
</p>
   <h3  className="terms-page__heading">Disclaimer</h3>
   <p className="terms-page__description">{`We are not responsible for any content, code, or other imprecision. We do not provide warranties or guarantees. In no event shall
we are liable for any special, direct, indirect, consequential, or incidental damages or any damages whatsoever, whether in an action of contract negligence or other cause, arising out of or in connection with the use of the service or the contents of the service. We reserve the right to make additions, deletions, or modifications to the contents of the service at any time without prior notice.
 
Our Service and its contents are provided “as is” and “as available without any warranty or representation of any kind, whether express or implied. We are a distributor and not a publisher of the content supplied by third parties; as such, we exercise no editorial control over such content and make no warranty or representation as to the accuracy, reliability, or currency of any information, content, service, or merchandise provided through or accessible via our service, without limiting the foregoing. We specifically disclaim all warranties and representations in any content transmitted on or in connection with our service, on sites that may appear as links on our Service, or in the products provided as a part of, or otherwise in connection with, our service, including without limitation any warranties of merchantability, fitness for a particular purpose, or non-infringement of third party rights. No oral advice or written information given by us or any of its affiliates, associates, officers, directors, agents, or the like will create a warranty. Price and availability information is subject to change without notice. Without limiting the foregoing, we do not warrant that our service will be uninterrupted, uncorrupted, timely, or error-free.
`}</p>
   <h3  className="terms-page__heading">Contact Us</h3>
   <p className="terms-page__description">{`Don’t hesitate to contact us if you have any questions.
Via Email: info@brigosha.com
Via this address: brigosha HQ · 1023, Siri Shubham, 24th Main Rd, 1st Sector, HSR Layout, Bengaluru, Karnataka 560102, India.



`}</p>

    </div>
    </div>
  
    </>
  );
};

export default Terms;
