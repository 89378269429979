import styled from 'styled-components';
import { Header as AntHeader } from 'antd/lib/layout/layout';

export const NavbarContents = styled(AntHeader)`
   height: 49px;
   width: 100%;
   display: flex;
   align-items: end;
   padding: 0;
   font-weight: 400;

   .subnav-links{
      display: flex;
      list-style: none;
      height: 49px;
      color: #B3B3B3;
      width: 100%;
      padding: 0;
      margin-bottom: 0;
   }

   .tab{
      font-size: calc(1vw + 4px);
      height: 49px;
      width: 100%;
      font-family: 'Sofia Sans', sans-serif;
   }

   .tabs{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 49px;
      text-decoration: none;
      color: #B3B3B3;
      background-color: white;
      border-left: 0.5px solid #EEEEEE;
      border-right: 0.5px solid #EEEEEE;
   }

   .activetab{
      color: #0086FF;
      font-weight: 700;
      background-color: #F3F9FF;
   }

   @media screen and (max-width: 767px){
      .tab{
         font-size: calc(1vw + 7px);
      }
   }

   @media screen and (max-width: 425px){   
        display: none;
   }

`


