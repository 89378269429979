import React, { useEffect } from 'react';
import Achievement from './achievementCard/achievement';
import Potential from './PotentialCard/potential';
import Expertise from './expertiseCard/expertise';
import OurJourney from './ourJourneyCard/ourJourney';
import TeamImage from './teamImageCard/teamImage';
import Career from './careerCard/career';
import Message from './messageCard/message';
import Culture from './ourCulture/culture';
import Magazine from './E-magazine/eMagazine';
import ContactCard  from './contactCard/contact';
import Blog from './blogsCard/blogCrad';
import bg1 from './PotentialCard/potentialBG.svg'
import bg2 from './ourCulture/BG.svg'
import bg3 from '../../../../assets/bg-1.svg';
import bg4 from '../../../../assets/bg-2.svg';

function OurStory() {
   // useEffect(()=>{
   //    document.body.scrollTop = 0; // For Safari
   //    document.documentElement.scrollTop = 0;
   // },[])
   return (
      <React.Fragment >
          

   
         <div  style={{
       background: `url(${bg1}) top no-repeat`,
       backgroundSize: '100%',
       overflow: 'hidden',
     width:'100%',
     
      backgroundPositionY:'-20vh'
     
         }} className='background-image'>
         <Potential />
            
         <div   style={{
    background:`url(${bg2}) center no-repeat`,backgroundSize:'100%',

    }}>
         <Expertise />   </div>
         </div>
     
         
   
     
         
        
      
     
        <div   style={{
    background:`url(${bg2}) center no-repeat`,backgroundSize:'100%',
    backgroundPositionY:'100%'

    }}>
          <OurJourney />
          <Message />
      
        </div>
       
     
         <div   style={{
    background:`url(${bg2}) center no-repeat`,backgroundSize:'100%',

    }}>
    
         <Culture/>
        
         <Magazine/>
         <Blog/>
         </div>
       
        
         <TeamImage/>
         <div   style={{
    background:`url(${bg2}) center no-repeat`,backgroundSize:'cover',
  


    }}>
         <Achievement />
         </div>
         <div   style={{
    background:`url(${bg2}) center no-repeat`,backgroundSize:'cover',
  


    }}>
         <Career />
         <ContactCard />
         </div>
       
   
       
      </React.Fragment>
   )
}

export default OurStory;

