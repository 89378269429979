import { LoadingOutlined, SearchOutlined ,ShareAltOutlined,UserOutlined } from '@ant-design/icons';
import { Col, Input, Row,Modal  } from 'antd';
import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../../../../components/pagination';
import styled from 'styled-components';
import { Heading, AllJobsText, JobSearch } from './styles';
import NoBlog from './noBlogs.gif'
import './styles.css'
import eye from './eye.svg'
import { ArrowLeftOutlined } from '@ant-design/icons';
import prev from './prev.svg'
import back from './back.svg'
import Slider from "react-slick";
import { useParams } from 'react-router-dom';
import Api from '../../../../../services/Api';
import parse from 'html-react-parser';
import linkedIn from './linkedIn.svg'
import twitter from './twitter.svg'
import shareImg from './share.svg';
import { ReactComponent as Back } from '../../../../../assets/back-icon.svg';
import { getBlog } from '../../../redux/action';
import BrigoshaLogo from '../../../../../assets/logo.svg'
function BlogsDescription({getBlog,selectedBlog}) {


    const [pageLoader, setPageLoader] = useState(false);
    const params=useParams();
  const [share,setShare]=useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [shareBlog,setShareBlogData]=useState();
    useEffect(() => {
       
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
      
       

      
 let param={
  blogId:params?.blogId?.split('=')[1]
 }
        if(!selectedBlog||selectedBlog.length==0||selectedBlog?.blog?.id!=param.blogId){
          setPageLoader(true);
          }
      getBlog(param,()=>{
       
        setPageLoader(false);
      })

        return () => {
            
        };
    }, [params?.blogId]);
    const handleBackBtn=()=>{
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        window.history.back();
       
    }
    const [slidesToShow, setSlidesToShow] = useState(3);

    useEffect(() => {
      const handleResize = () => {
        const windowWidth = window.innerWidth;
        let count=selectedBlog?.suggestedBlogs?.length;
        if (windowWidth >= 1560) {
         
          setSlidesToShow(count<3?count:3);
        } else if (windowWidth >= 1100 && windowWidth<1560) {
          setSlidesToShow(count<2?count:2);
        } else if(windowWidth > 566 && windowWidth<723) {
          setSlidesToShow(count<2?count:2)
        }else{
          setSlidesToShow(count<1?count:1);
        }
      };
  
      handleResize(); // Initial call to set the initial slidesToShow value
  
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, [selectedBlog?.suggestedBlogs]);
    const settings = {
        dots: false,
        adaptiveHeight:true,
       className:'banner',
       dotsClass:'slick-dots',
        slidesToShow:slidesToShow,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
    
        //  autoplay:true,
        //  autoplaySpeed: 2000,
         
        
      
      };
      function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
          <div
            className={className}
            style={{ ...style, display: "block",position:'absolute',top:'50%',background:`url(${back})  no-repeat`,width:'30px',height:'50px' ,backgroundSize:window.innerWidth>710?'12px':'15px',backgroundPositionX:'right'}}
            onClick={onClick}
          />
        );
      }
      
      function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
          <div
            className={className}
            style={{ ...style, display: "block", position:'absolute',zIndex:2,  top: "50%",background:`url(${prev}) no-repeat`,width:'30px',height:'50px',backgroundSize:window.innerWidth>710?'12px':'15px' }}
            onClick={onClick}
          />
        );
      }
    const Obj =selectedBlog?.suggestedBlogs?.map((ele,index)=>{
    
        return  {
            id:ele.id,
            name:ele.title,
            tag:ele.tag,
            img:ele.locationPath,
               pic:ele?.userProfile,
               date:ele.updatedAt,
               user:ele.userName,
               views:ele.totalViews,
                index:index,
                privilege:ele?.priv
     
         }
          })
         
      
      
          const handleCancel = () => {
              setIsModalOpen(false);
              setShare('');
              setShareBlogData();
            };

          useEffect(()=>{
            if(share!==''){
              setIsModalOpen(true);
              if(share!==-1)
              setShareBlogData(Obj?.[share]);
              else
              setShareBlogData({
                id:selectedBlog?.blog?.id,
                name:selectedBlog?.blog?.title,
                tag:selectedBlog?.blog?.tag,
                img:selectedBlog?.blog?.locationPath,
                   pic:selectedBlog?.blog?.userProfile,
                   date:selectedBlog?.blog?.updatedAt,
                   user:selectedBlog?.blog?.userName,
                   views:selectedBlog?.blog?.totalViews,
                   privilege:selectedBlog?.blog?.privilege
              });
            }else{setIsModalOpen(false)}


          },[share])
        
    
        const copyLink=()=> {
            var copyText = document.getElementById("link");
            copyText.select();
            copyText.setSelectionRange(0, 99999);
            navigator.clipboard.writeText(copyText.value);
            
            var tooltip = document.getElementById("Tooltip");
            tooltip.innerHTML = "Copied: " + copyText.value;
          }
          
          const removeLink=()=>  {
            var tooltip = document.getElementById("Tooltip");
            tooltip.innerHTML = "Copy to clipboard";
          } 

  return (
    <BlogDescription>
    <div style={{minHeight:'70vh'}}>  
        
 
        {pageLoader?<div  style={{'minHeight':'280px','display':'flex',justifyContent:'center','alignItems':'center',width:'100%'}}>
           
           <LoadingOutlined style={{fontSize: "3em"}}/>
                </div> :
                
                <>
                 
            <div style={{'display':'flex','alignItems':'center','justifyContent':'center',position:'relative','marginBottom':'30px',gap:'10px'}}>
          
           <div className='event-head' style={{'width':'60vw'}}>
             
           <div style={{'display':'flex','alignItems':'center','justifyContent':'flex-start',position:'relative'}}>
           <Back className='back-btn' onClick={handleBackBtn} />
            </div>
          
            
          

            <div style={{'display':'flex',flexDirection:'column',justifyContent:'flex-start'}}>
               <div style={{'display':'flex',alignItems:'center',gap:'10px',flexDirection:'row',marginBottom:'10px',}}>
              
                <div>
                <h1 className='title heading'  style={{color:'#000',fontSize:'32px',lineHeight:'40px',fontWeight:'600'}}>{selectedBlog?.blog?.title}</h1>
          
                </div>
              
               </div>
               <div style={{'display':'flex',alignItems:'center',gap:'30px',flexDirection:'row',marginBottom:'10px'}} className='blog-header'>
                <div style={{'display':'flex',alignItems:'center',gap:'7px'}}>
                {/* {selectedBlog?.blog?.userProfile ?   <img src={selectedBlog?.blog?.userProfile } style={{'width':'36px',height:'36px',borderRadius:'28px'}}/>:
                 <UserOutlined/>
                } */}
                  {selectedBlog?.blog?.userProfile && (selectedBlog?.blog?.privilege=='Blogger')?<img src={selectedBlog?.blog?.userProfile} style={{'width':'36px',height:'36px',borderRadius:'28px'}}/> :
               <img alt=' IoT Analytics' src={BrigoshaLogo} style={{'width':'36px',height:'36px',borderRadius:'28px'}}></img>
                }
                
              
                <span>{selectedBlog?.blog?.privilege=='SuperAdmin' || selectedBlog?.blog?.privilege=='Admin'|| !selectedBlog?.blog?.privilege?"Brigosha Team":selectedBlog?.blog?.userName}</span>
                <div>
                {selectedBlog?.blog?.updatedAt}
               </div>  
               </div>
               <div  style={{'display':'flex',alignItems:'center',gap:'10px'}}>
                <div style={{width:'50px',height:'26.5px',display:'flex',alignItems:'center',gap:'5px',background:'rgb(80,80,80,.6)',justifyContent:'center',borderRadius:'50px',color:'#fff',textAlign:'center',alignItems:'center',paddingLeft:'3px',fontSize:'12px',fontWeight:600}}>
                     
                <img alt=' IoT devices' src={eye}  style={{width:'14.22px',height:'11.47px',fontWeight:'700'}}/>
                      <span style={{color:'#fff',fontSize:'12px',fontWeight:'700'}}>{selectedBlog?.blog?.totalViews}</span>       
                    </div>
                <div style={{width:'40px',height:'26.5px',display:'flex',gap:'1px',alignItems:'center',backgroundColor:'rgba(80,80,80,.6)',borderRadius:'50px',color:'#fff',justifyContent:'center',textAlign:'center',fontSize:'12px',fontWeight:600,cursor:'pointer'}} onClick={()=>{
                     setShare(-1);
                }}>
                      <ShareAltOutlined style={{cursor:'pointer',fontSize:'18px'}} />
                    </div>
                    </div>
                </div>
             
               <div style={{display:'flex',flexDirection:'row',gap:'10px',maxWidth:'600px',overflow:'scroll hidden'}} className='blog-tags'>
                {
                    selectedBlog?.blog?.tag?.map(tag=>{
                        return            <span style={{'color':'#fff',fontSize:'14px',lineHeight:'24px',backgroundColor:'rgba(75, 107, 251, 1)',width:'auto',height:'28px',borderRadius:'3px',textAlign:'center',marginBottom:'10px',fontWeight:'500',display:'inline',padding:'0px 5px'}}>{tag}</span>

                    })
                }
           
           </div>
                </div>
            </div>
           </div>
           <div style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
          
                   
           <div  style={{'height':'600px',width:'60vw',backgroundColor:'#fff',borderRadius:'12px',position:'relative',display:'flex',justifyContent:'center',marginBottom:'20px'}} className='blog-desc-img'> 
         
                    
                    <img alt='Smart Home Solutions' src={selectedBlog?.blog?.locationPath} style={{'height':'600px',borderRadius:'5px',objectFit: 'cover',width:'100%'}} />
                 
                
                </div>
                   
                   </div>

           <div style={{'display':'flex','alignItems':'center','justifyContent':'center',position:'relative','marginBottom':'30px',gap:'10px'}}>
           <div  style={{width:'60vw'}} className='blog-desc'>
            {
                parse(selectedBlog?.blog?.content||"")
            }
           </div>
           </div>
           <div style={{'display':'flex','alignItems':'center','justifyContent':'center',position:'relative','marginBottom':'30px',gap:'10px'}}>
          
          <Heading >
            {/* <div className='gradient2'>Suggested Blogs<div className='polygon2'></div></div> */}
           <div className='highlight also-read'  >Also read</div>
            </Heading>
            
            </div>
         
          <div className='blog' style={{width:'100%'}}>
         { Obj?.length==0? <div style={{'display':'flex','flexDirection':'column',justifyContent:'center',alignItems:'center',textAlign:'center',alignSelf:'center'}}> 
            
            <span style={{width:'100%',display:'flex',justifyContent:'center'}}><b>No  Suggested Blogs Found!</b></span><br/>
  
        </div>:
          <Slider {...settings}  centerMode={true} centerPadding='0' >
                   
           {Obj?.map((ele,key)=>{
      return  <Col  className='team-card' id={key}>
      <Card {...Obj[key]} setShare={setShare}  />
     </Col>
        })}
        
               
          
           
       </Slider>
      }
           </div>
           </>}
           

    
           <Modal  visible={isModalOpen} footer={null} closable={true} centered onCancel={handleCancel} style={{'zIndex':'100'}}   bodyStyle={{
        backgroundColor: '#F5F5F5'
    }}  destroyOnClose={true} >
         
         <ModelDiv>
            <div   style={{ backgroundColor: '#FFF',padding:'20px'}} >
         <div style={{'display':'flex','alignItems':'center','justifyContent':'center',position:'relative','marginBottom':'30px',gap:'10px',}}>
           <div className='event-head' style={{'width':'60vw'}}>
             
       
            <h1 className='title heading'  style={{color:'#000',fontSize:'26px',lineHeight:'40px',fontWeight:'600'}}>{shareBlog?.name}</h1>
          
          

            <div style={{'display':'flex',flexDirection:'column',justifyContent:'flex-start'}}>
               <div style={{'display':'flex',alignItems:'center',gap:'10px',flexDirection:'row',marginBottom:'10px'}}>
               
               {shareBlog?.pic && (shareBlog?.privilege=='Blogger')?<img alt='IoT Platform Solutions' src={shareBlog?.pic} style={{'width':'36px',height:'36px',borderRadius:'28px'}}/> :
               <img alt='IoT Platform ' src={BrigoshaLogo} style={{'width':'36px',height:'36px',borderRadius:'28px'}}></img>
                }
                {/* <span>{shareBlog?.user}</span> */}
                <span>{shareBlog?.privilege=='SuperAdmin' || shareBlog?.privilege=='Admin'||!shareBlog?.privilege?"Brigosha Team":shareBlog?.user}</span>
                
                <div>
                {shareBlog?.date}
               </div>
               </div>
             
               <div style={{display:'flex',flexDirection:'row',gap:'10px',overflow:'hidden',flexWrap:'wrap'}} className='blog-tags'>
                {
                    shareBlog?.tag?.map((tag,index)=>{
                        return         index<3 ?  <span style={{'color':'#fff',fontSize:'14px',lineHeight:'24px',backgroundColor:'rgba(75, 107, 251, 1)',display:'inline',padding:'0px 5px',height:'28px',borderRadius:'3px',textAlign:'center',marginBottom:'10px',fontWeight:'500'}}>{tag}</span>:null

                    })
                }
                 {
                   shareBlog?.tag?.length>3?
                   <span style={{fontSize:'12px',lineHeight:'20px',display:'inline',padding:'0px 5px',height:'20px',textAlign:'center',marginBottom:'10px',fontWeight:'500',marginTop:'5px'}}>+{ shareBlog?.tag?.length-3}</span>
                   : null
                }
           
           </div>
         </div>
            </div>
           </div>
           <div style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center',}}>
          
                   
           <div  style={{'height':'250px',width:'60vw',backgroundColor:'#fff',borderRadius:'12px',position:'relative',display:'flex',justifyContent:'center'}}> 
         
                    {/* <div style={{width:'26.5px',height:'26.5px', position:'absolute',right:10,top:20,background:'rgb(0,0,0,0.6)'}}>
                      <img  src={shareImg}  style={{width:'26.5px',height:'26.5px'}}/>
                    </div> */}
                    <img src={shareBlog?.img} alt='LiDAR Data Processing' style={{'height':'250px',width:'100%',borderRadius:'5px',objectFit: 'contain'}}/>
                 
              
                </div>
                   
          </div>
          </div>
         <div style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center',textAlign:'center',flexDirection:'column'}}>
                    <h1>Share this with your social community</h1>
                    <div style={{width:'100%',display:'flex',justifyContent:'space-around',alignItems:'center'}}>
                  
                        <a href={` https://www.linkedin.com/shareArticle?mini=true&url=${encodeURI(`http://new.brigosha.com/our-story/blog/blogId=${shareBlog?.id}`)}&title=${encodeURI(shareBlog?.name)}&source=LinkedIn`}>      <img src={linkedIn} alt='LiDAR Remote Sensing' style={{width:'30px',height:'30px',cursor:'pointer'}}/></a>
                       
                        <a href={`https://twitter.com/intent/tweet?text=${encodeURI(shareBlog?.name)}&url=${encodeURI(`http://new.brigosha.com/our-story/blog/blogId=${shareBlog?.id}`)}`}  > <img src={twitter} alt=' LiDAR Software Development'  style={{width:'30px',height:'30px',cursor:'pointer'}}/></a>

                    </div>
                  
           </div>
        
          
        <div style={{width:'100%',padding:'10px',display:'flex',flexDirection:'column',justifyContent:'center ',alignItems:'center'}}>
                        
        <div style={{width:'95%',display:'flex',justifyContent:'space-between',alignItems:'center',height:"50px"}}>
        <span style={{color:'rgba(173, 173, 173, 1)',fontWeight:'700'}}>or copy link</span>      
                            </div>
                        <div style={{width:'95%',display:'flex',justifyContent:'space-between',alignItems:'center',height:"50px",background:'rgba(238, 238, 238, 1)',padding:'3px 16px'}}>
                             <input style={{'color':'#002546',border:'none',background:'rgba(238, 238, 238, 1)',width:'80%',padding:'3px', outline: 'none'}} id='link' value={`http://new.brigosha.com/our-story/blog/blogId=${ shareBlog?.id}`} />
                           
                            <div class="tooltip">
<button onClick={copyLink} onMouseOut={removeLink} style={{'color':'rgb(0, 37, 70)',border:'none',fontWeight:'700',cursor:'pointer'}}>
  <span class="tooltiptext" id="Tooltip" >Copy to clipboard</span>
  Copy Link
  </button>
</div>
                        </div>
                    </div>
         </ModelDiv>
   </Modal>       
        
        
    </div>
    </BlogDescription>
  )
}



const Card = ({ name, img ,tag,user,pic,date,id,setShare,views,index,privilege=''}) => {

  const navigate = useNavigate();
  const handleShare=(event)=>{
      event.stopPropagation();
     
      const link=`http://new.brigosha.com/our-story/blog/blogId=${id}`;
     
     setShare(index);
  }
  return(
  
      <CardStyle  key={index} className='blog-card' >
          <div className='card-wrapper' onClick={()=>{  document.body.scrollTop = 0; // For Safari
     document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
     navigate(`/our-story/blog/blogId=${id}`)}} >
          
          <div style={{display:'flex',flexDirection:'column',width:'100%'}} >
              <div style={{width:'100%',maxHeight:'241px',position:'relative',display:'flex',justifyContent:'center'}}>
                  {/* <div style={{width:'26.5px',height:'26.5px', position:'absolute',right:10,top:20,zIndex:'20'}} className='share_blog'>
                    <img  src={shareImg}  style={{width:'26.5px',height:'26.5px',cursor:'pointer'}} onClick={(e)=>handleShare(e)} />
                  </div> */}
              <img src={img} alt='img'  style={{objectFit: 'cover'}} className='blogImg'/>
              {/* <div className="view_blog" style={{width:'50px',height:'26.5px',position:'absolute',right:10,bottom:20,display:'flex',alignItems:'center',gap:'5px',alignItems:'center',gap:'5px',background:'rgb(0,0,0,0.6)',justifyContent:'center'}}>
                    <img  src={eye}  style={{width:'17.22px',height:'11.47px'}}/>
                    <span style={{color:'#fff',fontSize:'11px'}}>{views}</span>
                  </div> */}
              </div>
       <div className='info'>
          
          <div className='event-head'>
              <div style={{display:'flex',flexDirection:'row',gap:'10px',overflow:'hidden',height:'30px',width:'100%',overflowX:'auto',alignItems:'center'}} className='blog-tags'>
              {
                  tag?.map((tag,index)=>{
                      if(index<2)
                     return <span style={{'color':'rgba(75, 107, 251, 1)',fontSize:'12px',lineHeight:'20px',backgroundColor:'rgba(75, 107, 251, 0.05)',display:'inline',padding:'0px 5px',height:'20px',borderRadius:'3px',textAlign:'center',marginBottom:'10px',fontWeight:'500',marginTop:'5px'}}>{tag}</span>
                
                  })
              }
              {
                 tag?.length>2?
                 <span style={{fontSize:'12px',lineHeight:'20px',display:'inline',padding:'0px 5px',height:'20px',textAlign:'center',marginBottom:'10px',fontWeight:'500',marginTop:'5px'}}>+{tag?.length-2}</span>
                 : null
              }
              
         
         </div>
          <h1 className='title heading'  style={{color:'#000',cursor:'pointer',marginTop:'10px' }} >{name}</h1>
          </div>
        

          <div style={{'display':'flex',justifyContent:'space-between',alignItems:'center'}} className='blogcard-footer'>
               <div style={{'display':'flex',justifyContent:'flex-start',alignItems:'center',gap:'6px',flexDirection:'row',flex:1}}>
                
               {pic && ( privilege=='Blogger')?<img src={pic} alt='LiDAR Consulting Services' style={{'width':'36px',height:'36px',borderRadius:'28px'}}/> :
               <img src={BrigoshaLogo} alt='LiDAR Surveying' style={{'width':'36px',height:'36px',borderRadius:'28px'}}></img>}
                 
              <div style={{display:'flex',flexDirection:'column',alignItems:'flex-start'}}>
           
                {/* <span>{user}</span> */}
                <span>{privilege=='SuperAdmin' || privilege=='Admin'|| !privilege?"Brigosha Team":user}</span>
                
                <div>
               
                <span>{date}</span>
                <span> | </span>
                <span>{views} Views</span>
                </div>
                </div>
              
            
               </div>
               <div className='share_blog' style={{display:'flex',justifyContent:'flex-end',}}>
                      <img  src={shareImg} alt='Cloud Infrastructure'  style={{width:'26.5px',height:'26.5px',cursor:'pointer'}} onClick={(e)=>handleShare(e)} />
                    </div>
               <div>
             
               </div>
            </div>
          </div>
          </div>
          </div>
      </CardStyle>
  )
}


const CardStyle = styled.div`

  margin-bottom: 10px;
  transition: transform 0.5s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: scale(1);


 
 .card-wrapper{
 
  
  cursor:pointer;
  background-color: #fff;
  justify-content:center;
  padding: 20px;
  position: relative;
  margin:0 auto 30px;
  display:flex;
  justify-content:center;

  border-radius:12px;
  border:1px solid #E8E8EA;
  max-height:420px;
 z-index:1;
 

 }
 .blog-tags::-webkit-scrollbar {
  height: 3px !important; /* width of the entire scrollbar */
  width:3px !important;
}
.blog-tags::-webkit-scrollbar-thumb {
  background-color: grey; /* color of the scroll thumb */
  border-radius: 20px; /*roundness of the scroll thumb*/
  /* border: 1px solid #B5DBE8;  creates padding around scroll thumb */
}

 .glass-button {
  display: inline-block;
  padding: 12px 22px;
  height:50px;
  border: 0;
  text-decoration: none;
  border-radius: 8px;
  background-color: rgba(255,255,255,0.1);
  border: 1px solid rgba(255,255,255,0.1);
  backdrop-filter: blur(30px);
  color:#fff;
  font-size: 16px;
  letter-spacing: 2px;
  cursor: pointer;
  text-transform: uppercase;
}

.glass-button:hover {
  background-color: rgba(255,255,255,0.2);
}
 .event-head {
  width:360px;
 display:flex;
 flex-direction:column;
 justify-content:center;
 align-items:flex-start;
  text-align: center;
  opacity:1;
  padding: 6px;

}
  &:hover{
      transform: scale(1.02);
  }

  .blogImg{
    min-height:241px;
      height:241px;
      width:360px;
      border-radius:8px;
      
     
  }
 

 .title{
      max-height:50px;
      font-size: 16px;
      font-weight: 600;
      color: #FFFFFF;
      text-align:left;
      display:flex;
      align-items:center;
    
      margin-bottom:0px !important;

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
  }

  .position{
      display: block;
      font-size: calc(1vw + 3px);
      margin-bottom: 10px;
      font-weight: 400;
  }

  @media screen and (max-width: 770px){
     .card-wrapper{
          padding:5px;
      }
      .info .event-head{
          width:100% !important; 
      }
      .info .title{
          font-size: calc(1vw + 6px) !important;
          width:100%;
          max-height:40px;
      }

      .position{
          font-size: calc(1vw + 8px);
      }
      .info{
          // position:absolute;
          // bottom:10px;
          width:100%;
      }
      .blogImg{
       max-height:140px;
          min-height:117px;
          width:195px;
          margin-top:8px;
         
      }
      .info .blogcard-footer{
          font-size:12px;
          padding:5px;
      }
      .info .blogcard-footer img{
          width:20px !important;
          height:20px !important;
      }
  }
 
`
 const ModelDiv = styled.div`
   max-height:722px;
  min-height:300px;
  max-width:943px;


  background:#F5F5F5;
   
  .title{
    height:40px;
    font-size: 14px;
    font-weight: 600;
  
    margin-bottom:10px !important;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.blog-tags::-webkit-scrollbar {
    height: 5px !important; /* width of the entire scrollbar */
    width:3px !important;
  }
  .blog-tags::-webkit-scrollbar-thumb {
    background-color: grey; /* color of the scroll thumb */
    border-radius: 20px; /*roundness of the scroll thumb*/
    /* border: 1px solid #B5DBE8;  creates padding around scroll thumb */
  }


    
 @media  screen and (max-width: 950px){

    min-height:300px;
    max-height:max-content;
  
    

}
`

const BlogDescription = styled.div`
padding: 40px 8vw;
.blog-desc p, .blog-desc {
  font-weight: 400;
  font-size: calc(1vw + 4px);
  font-family: 'Inter', sans-serif;

}

.blog-tags::-webkit-scrollbar {
    height: 3px !important; /* width of the entire scrollbar */
    width:3px !important;
  }
  .blog-tags::-webkit-scrollbar-thumb {
    background-color: grey; /* color of the scroll thumb */
    border-radius: 20px; /*roundness of the scroll thumb*/
    /* border: 1px solid #B5DBE8;  creates padding around scroll thumb */
  }
  .back-btn{
  
    cursor:pointer;
    margin-bottom:15px;
 
  }
  .also-read{
   font-size: calc(2vw + 1px);
  }

.bold{
    font-weight: 700;
    color: black;
}
.team-image-topline{
    letter-spacing: 0.25rem;
    text-transform: uppercase;
    font-weight: 600;
    text-align:left;
    font-size: calc(1vw + 3px);
}
.AllEvents-header{
    font-size: calc(2vw + 15px);
    font-weight: 700;
    position:relative;
    z-index:2;
}

.expertise-top{
    padding-top: 20px;
    font-size: calc(1vw + 5px);
    font-weight: 400;
}
.blog-desc img{
  width:60vw !important;
  object-fit:contain;
   }

@media screen and (max-width: 767px){
  padding:20px 6vw;
  .blog-desc p, .blog-desc {
    font-weight: 400;
    font-size: calc(1vw + 10px);
    font-family: 'Inter', sans-serif;
  
  }
 
  .blog-desc ol{
    padding:0px 6vw;
 }
 .also-read{
  font-size: 20px;
 }
   .back-btn{
    margin-top: 0px;
    margin-left: 0px; 
     padding-bottom: 0px;
}
.blog-desc-img{
  width:90vw !important;
  
}
.blog-desc-img{
  height:450px !important;
}
    .heading{
      font-size: 20px !important;
    }
    .event-head,.blog-desc{
      width:90vw !important;
    }
    .blog-desc img{
   width:100% !important;
    }
    .blog-desc{
      width:80vw;
    }
  
   .blog-cards{
    grid-template-columns: repeat(auto-fill, minmax(197px, 1fr)) !important;
    gap: 0px 10px !important;
   }
   .blog-header{
    flex-direction:column !important;
    align-items:flex-start !important;
    gap:10px !important;
   }
    .expertise-header{
        font-size: calc(2vw + 20px);
    }
    .bannerblog-content{
        padding:10px !important;
    }
    .tag{
        height:26px !important;
    }
    .expertise-top{
        font-size: calc(1vw + 10px);
    }
    .blog-title{
        font-size: calc(1vw + 10px);
        line-height:26px !important;
        overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    }
}
`

const mapStateToProps = state => {
  const {
      selectedBlog
  }  = state.we;

  return {selectedBlog}
}

const mapDispatchToProps = dispatch =>  ({
  getBlog: (params,successCallback) => dispatch(getBlog(params,successCallback)),
 
})

export default connect(mapStateToProps, mapDispatchToProps)(BlogsDescription)



