import React from 'react';
import styled from 'styled-components';
import Img from '../potential-mobility/mobility-banner.svg';
import { Row, Col, Button } from 'antd';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
function PotentialMobility() {
    return (
        <PotentialMobilityContainer>
            {/* <div className='potentialMobility-header heading'>Explore The True {window.innerWidth>=360?null:'Potential Of '}<div className='gradient2' >{window.innerWidth>=360?'Potential Of Mobility':'Mobility'}<div className='polygon2' ></div></div></div>
             */}
            <br/>
            <Card {...Obj1}/>
           
        </PotentialMobilityContainer>
    )
}

const Card = ({
    btnType,
    headline,
    description,
    buttonLabel,
    img,
    alt,
    imgStart,
    link,
    updateMainMenu ,
    height
  }) => {
  
    return (
      <CardStyle
        wrap={true}
      >
          <Col 
          xs={{span: 24, order: 2}} 
          sm={{span: 24, order: 2}} 
          md={{span: 12, order: imgStart === 'start' ? 2 : 1}} 
          lg={{span: 12, order: imgStart === 'start' ? 2 : 1}} 
          xl={{span: 12, order: imgStart === 'start' ? 2 : 1}}
          xxl={{span: 12, order: imgStart === 'start' ? 2 : 1}}
          className={imgStart === 'start' ? 'card-text-container left' : 'card-text-container left'}
          gutter = {20}
          >
              <div className='card-heading heading' >{parse(headline)}</div>
              <p className='card-content content'>{description}</p>
              {
                link.length!==0? <div 
                className='button-holder'
                style={{justifyContent: 'flex-start' }}
              >
                <Link to={`/${link.toLowerCase()}`} onClick={() => {
                    scrollToTop()
                    if(link.length!==0 )
                    updateMainMenu(`${link.toLowerCase()}`)
                  }}> 
                    <Button 
                      type = {btnType} 
                      className='card-button'
                      style ={{
                        color:  'white' ,
                        backgroundColor: 'transparent'
                      }}
                    >
                      {buttonLabel}
                    </Button>
                  </Link>
              
                
              </div>:null}
          </Col>
          <Col
  
  xs={{ span: 24, order: 1 }}
  sm={{ span: 24, order: 1 }}
  md={{ span: 12, order: imgStart === 'start' ? 1 : 2 }}
  lg={{ span: 12, order: imgStart === 'start' ? 1 : 2 }}
  xl={{ span: 12, order: imgStart === 'start' ? 1 : 2 }}
  xxl={{ span: 12, order: imgStart === 'start' ? 1 : 2 }}
  className='content'
  style={{'display':'flex',justifyContent:'center',alignItems:'center'}}
  >
  {
    img ? <><img src={img} alt={alt} className='card-image'  />  </> :null
  

  
  
  }
  
  </Col>
      </CardStyle>
    )
  };
  
  
  const CardStyle = styled(Row)`
    padding-top: 10px;
   overflow:hidden;
  //  min-height:80vh;
    
    .card-text-container{
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .card-image{
     padding-bottom:10px;
     width:138% !important;

    object-fit:fill;
  
     
    
    }
    .card-image:hover{
      // transform: scale(1.01);
      // transition: all 0.3s ease-out;
    }
    . card-button:hover{
       
      background-color: #0086FF !important;
      color:white !important;
   }

    .left{
      text-align: left;
    }
  
    .right{
      text-align: right;
    }
  
    .card-image{
      width: 70%;
    }
  
    .button-holder{
      width: 100%;
      display: flex;
      align-items: center;
    }
  
    .card-content{
      font-size: calc(1vw + 5px);
      font-weight: 400;
      color:#111111;
      white-space: pre-wrap;
      white-space: -moz-pre-wrap;
      white-space: -pre-wrap;
      white-space: -o-pre-wrap;
      word-wrap: break-word;
    
    }
  
    .card-heading {
      width: 100%;
      margin-bottom: 24px;
      font-size: calc(2vw + 15px);
      font-weight: 700;
    }
    
    .card-button{
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: calc(1vw + 3px);;
      border-color: #0086FF;
      border-radius: 4px;
      font-weight: 700;
      padding: 2vw 4vw;
    }
  
    .card-button:hover{
      transform: scale(1.02);
      transition: all 0.3s ease-out;
    }
  
    @media screen and (min-width: 2000px){
      .card-image{
        width: 70%;
      }
    }
  
    @media screen and (max-width: 767px){
  
      .card-content{
        font-size: calc(1vw + 10px);
        text-align: left !important;
        padding-right:10vw;
      }
      .card-image{
        width: 65%;
      }
      .card-heading{
        text-align: left !important;
        font-size: calc(2vw + 20px);
      }
  
      .button-holder{
        justify-content: flex-start !important;
      }
  
      .card-button{
        padding: 3vw 6vw;
        font-size: calc(1vw + 6px);
      }
    }
  
    @media screen and (max-width: 425px){
      .card-button{
        padding: 4vw 6vw;
      }
  
      .card-image{
        width: 70%;
      }
    }
  
   
  `

const PotentialMobilityContainer = styled.div`
    height: max-content;
    background: transparent;
    padding-top: 40px;
    // padding-right: 8vw;
    padding-left: 8vw;
    text-align: center;
   

    .potentialMobility-header{
        font-size: calc(2vw + 15px);
        font-weight: 700;
        position:relative;
        z-index:2;
    }

    .potentialMobility-top{
        padding-top: 20px;
        font-size: calc(1vw + 5px);
        font-weight: 400;
    }

    @media screen and (max-width: 767px){
        // padding-right: 8vw;
        padding-left: 8vw;

        .potentialMobility-header{
            font-size: calc(2vw + 20px);
        }

        .potentialMobility-top{
            font-size: calc(1vw + 10px);
        }
    }
`

export default PotentialMobility;



const Obj1 = {
    topLine: '',
    btnType: 'outline',
    headline: `Revolutionize Your <div className='highlight'>Mobility Potential!</div>`,
    description: 'Embrace the freedom of mobility with brigosha! Our renowned gateway service and inventive indigenous technology solutions provide seamless connectivity and endless possibilities. Roam freely in the digital landscape and unlock new horizons with us!',
    buttonLabel: '',
    img: Img,
    alt: 'iot-image',
    imgStart: '',
    link:'',
    height:80
}
