import { Button, Col, Row } from 'antd';
import React, {useEffect, useState} from 'react';
import Careers from './Career-Banner.svg';
import { Policies } from './policies';
import { 
    VacancyText, 
    PolicyCardHeading, 
    PolicyCardBody, 
    Heading, 
    VacancyBody, 
    ExploreButton, 
    PerksText, 
    PerksBody, 
    PolicyCard, 
    JdCard,
    JdCardHeading,
    JdCardBody, 
    ViewMoreButton,
    CareerImg,
    ApplyButton
} from './styles';
import { ReactComponent as MapPin } from './icons/map-icon.svg';
import { ReactComponent as Clock } from './icons/time-icon.svg';
import { ReactComponent as Line } from './icons/line-icon.svg';
import { useNavigate } from "react-router-dom";
import { connect } from 'react-redux';
import { fetchJobsSummary} from '../../redux/action';
import { matchPath} from 'react-router';
import { LoadingOutlined } from '@ant-design/icons';

import nojob from './nojob.gif'
import './styles.css'
import {ReactComponent as Arrow} from '../ourStory/PotentialCard/Vector.svg'
import bg2 from '../ourStory/ourCulture/BG.svg';
import bg1 from '../ourStory/PotentialCard/potentialBG.svg';
import OurTeam from './our-team.svg';
import emp4 from './icons/associate-1.svg';
import emp3 from './icons/associate-2.svg';
import emp2 from './icons/associate-3.svg';
import emp1 from './icons/associate-4.svg';


const policyCard = (obj, key) => (
    <Col key={key} xs={24} sm={12} md={12} lg={8} xl={8} xxl={8} >
        <PolicyCard>   
            <img src={obj.icon} alt={key}/>
            <PolicyCardHeading className='heading'>
                {obj.title}
            </PolicyCardHeading>
            <PolicyCardBody className='content grey'>
                {obj.body}
            </PolicyCardBody>
        </PolicyCard>
    </Col>
)

export const jdCard = (obj, key, handleClick) => (
//     <Col 
//         key={key} 
        
//         onClick={() => handleClick(obj)} 
//         style={{
//             marginTop: '30px',
//             padding:'10px'
//         }}
//     >
//         <JdCard>   
          
           
//             <JdCardHeading >
               
//                 {obj.title}
         
//             </JdCardHeading>

          
//         <JdCardBody className='content grey' style={{marginTop:'10px',width:'100%'}} >
//           <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
//                 <GoLocation style={{marginRight: 10}} className='location-tag'/> </div>
//                 <span style={{'width':'100%',height:'100px', whiteSpace: "nowrap",
//         lineHeight: "1.5em",
//         height: "1.5em",
//         overflow: "hidden",
//         textOverflow: "ellipsis"}} className='location-tag'>{obj.location?.join(', ').toString()}</span>
//         </JdCardBody>
//         <ApplyButton 
//   style={{marginTop:'10px',  padding: '1.4vw 2vw',borderRadius:'4px',pointerEvents: 'none',fontSize:  '14px'}} 
//   onClick={() => {
//     document.body.scrollTop = 0; // For Safari
//     document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
//     // navigate(`/join-us/application-form/jobId=${currentJob.jobId}`)
//   }}
//   >
//     Apply Now <Arrow></Arrow>
//   </ApplyButton>
       
//         </JdCard>
//     </Col>
    
<div  className='jd-list-card' onClick={() => handleClick(obj)}  key={key}>
    <div className='jd-meta-data'>
    <span className='jd-meta-heading'>{obj.title}</span>
    <div  className='location-meta-data'>
    <span  className='location-more'><MapPin/>{obj.location.slice(0, 5)?.join(', ').toString()}{obj.location.length>5?'...':''}</span>
    <span  className='location-less'><MapPin/>{obj.location.slice(0, 2)?.join(', ').toString()}{obj.location.length>2?'...':''}</span>
    <Line className='line-icon'/>
    <span style={{display:'flex',alignItems:'center',gap:3}}><Clock/>Full Time</span>

    </div>
  
    </div>
    
        <ApplyButton 
  style={{marginTop:'10px',  padding: '1.5vw',borderRadius:'4px',pointerEvents: 'none',fontSize:  '16px'}} 
  onClick={() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    // navigate(`/join-us/application-form/jobId=${currentJob.jobId}`)
  }}
  className='jd-apply-button'
  >
    Apply Now <Arrow></Arrow>
  </ApplyButton>

</div>
)


function Joinus({
    allJobs,fetchJobsSummary
}) {

    const navigate = useNavigate();
    const [pageLoader, setPageLoader] = useState(false);



  

    const handleClick = (data) => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        window.scrollTo(0, 0);
        navigate(`/join-us/job-description/jobId=${data.id}`)
      
    }
    useEffect(() => {
        
        

        console.log('join us calling twice.....')
      if(!allJobs||allJobs.length==0){
        setPageLoader(true);
        }
        fetchJobsSummary(() => setPageLoader(false))
        return () => {   
        };

    }, []);
 
    
 
      return <div >
         
                <div   
   style={{
        background: `url(${bg1}) top no-repeat`,
     backgroundSize: '100%',
     overflow: 'hidden',
   width:'100%',

   
    backgroundPositionY:'-20vh'
   
       }} className='background-image'>
     
              
                <Row align='center' style={{  padding: '40px 8vw',}}>
                    <div  className='career-banner'>
                       <Heading className='heading'>Unlock Your <div className='highlight' style={{display:'inline-block'}}>True</div> Potential</Heading>
                       <VacancyBody className='content grey'>
                            Are you ready to embark on an exhilarating career journey in a dynamic and growth-oriented
                            organization? At brigosha, we nurture your true potential while fostering a vibrant work environment.
                            Join our team, unleash your talents, and let's create a remarkable professional adventure filled with
                            growth, teamwork, and endless possibilities!
                            </VacancyBody>
                        <ExploreButton  onClick={() => document.getElementById('jobs').scrollIntoView()}>EXPLORE JOBS</ExploreButton>
                          <img src={emp1} alt='automotive' className='emp1-img'></img>
                           <img src={emp2} alt=' Product development process'  className='emp2-img'></img>
                           <img src={emp3} alt=' Product development' className='emp3-img'></img>
                           <img src={emp4} alt=' Product management'  className='emp4-img'></img>
                    </div>
                </Row>
                <Row align='center' style={{  padding: '40px 8vw',}}>
                    <div style={{ 
                        display: 'flex',
                        justifyContent:'center',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        <PerksText className='highlight '>PERKS & BENEFITS</PerksText>
                        {/* <Heading className='heading' >Live Your <div className='gradient1'>Best Life<div className='polygon1'></div></div> At brigosha</Heading> */}
                        <Heading className='heading' style={{textAlign:'center'}}>Make Your <div className='highlight'>Dream</div> Come True At brigosha</Heading>
                        <PerksBody className='content grey'>
                        Life gives you back what you put into it and brigosha is no exception. The best reward for work well done is an opportunity to do more which in turn becomes an opportunity to grow more. The practice of rewarding excellence is of paramount importance to us.
                        </PerksBody>
                    </div>
                </Row>
            
                <div   style={{
            background:`url(${bg2}) center no-repeat`,backgroundSize:'cover',
            backgroundPositionY:'-25vh',
            padding: '0px 8vw',
        
            }}>  
                <Row style={{marginTop: 60}}>
                    {
                        Policies.map((policy, index) => policyCard(policy, index)) 
                    }
                </Row>
                <Row style={{width:'100vw',marginTop: 60,marginBottom:60}}>
                    <img alt='hiring' src={OurTeam} style={{width:'80vw',height:'auto'}}></img>
                </Row>
                <Row id="jobs" align='center' style={{marginTop: 60}}>
                    <div 
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <PerksText className='highlight'>OPEN POSITIONS</PerksText>
                        {/* <Heading className='heading' >Empower <div className='gradient6' >The World<div className='polygon6'></div></div> With What We Build</Heading> */}
                        <Heading className='heading' style={{textAlign:'center'}} >Empower <div className='highlight' >The World</div> With What We Build</Heading>
                    </div>
                </Row>
                {pageLoader ? 
               <div style={{height: "70vh", display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <LoadingOutlined style={{fontSize: "3em"}}/> 
                </div>
            :<>
            {allJobs.length==0?<div style={{'display':'flex',justifyContent:'center',alignItems:'center',height:'400px',flexDirection:'column'}}>  <img alt='it solutions' src={nojob} className='nojob'></img>
            <span><b>No Job Found! Hold Tight! </b></span>
                 <span><b>Your favorite job role is on its way soon.</b></span>
            </div>
          
            : <><Row style={{marginTop: 60,display: 'flex', justifyContent: 'center', alignItems: 'center'}} >
                
            <div className='jd-grid'>    {
            allJobs.map((jd, index) => index<6 ?jdCard(jd, index, handleClick):null) }
            </div>
        
    </Row>
    <Row align='center' style={{marginTop: 60, marginBottom: 80}}>
        <ViewMoreButton 
        className=' card-button' 
        onClick={() => {
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
            navigate('/join-us/jobs')
        }}
        >
            View More
        </ViewMoreButton>
    </Row>
           </>}
            </>
                  }
                </div>
                </div>
            
            

            
    
       
          </div>
    
}


const mapStateToProps = state => {
    const {
        allJobs
    }  = state.we;

    return {  allJobs }
}

const mapDispatchToProps = dispatch =>  ({
    fetchJobsSummary: (callback) => dispatch(fetchJobsSummary(callback)),
  
})


export default connect(mapStateToProps, mapDispatchToProps)(Joinus);

