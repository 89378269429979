import { Col, Row } from 'antd';
import React from 'react';
import styled from 'styled-components';
import Timeline from './out-story-timeline.svg'
import Icon1 from './ill-2008.svg';
import Icon2 from './ill-2011.svg';
import Icon3 from './ill-2016.svg';
import Icon4 from './ill-2019.svg';
import Icon5 from './ill-2023.svg';

function OurJourney() {
    return (
        <OurJourneyStyle>
            <Row>
                <Col span = {24} className='our-journey-content'>
                    {/* <div className='our-journey-heading heading'>Our <div className='gradient1'>Journey<div  className='polygon1' style={{'width':window.innerWidth>1300?'180px':'120px',}}> </div></div></div><br/> */}
                    <div className='our-journey-heading heading'>The <span className='highlight' >Story</span> thus far </div>
                    <p className='our-journey-text content grey'>
                        Destinations are important but so is the journey 
                        we undertake to reach those dream destinations. 
                        Our journey defines our excitement, dreams, efforts 
                        and aspirations. The fascinating aspect is sometimes 
                        we reach our destination and sometimes we fall short. 
                        The destinations are not under our control but we 
                        always believe in celebrating the journey along with 
                        the pool of wonderful people who are an integral part 
                        of it.
                    </p>
                </Col>
            </Row>
            <Row>
                <Col span = {24} className='timeline-image-container'>
                    <img src={Timeline} alt='timeline' className='timeline-image'/>
                </Col>
            </Row>
            <div className='timeline'>
                <Row className='timeline-card'>
                    <Col span={24}>
                        <span className='card-date heading primary'>2008</span>
                    </Col>
                    <Col 
                        span={12} 
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <img  src={Icon1} alt='2008'/>
                    </Col>
                    <Col className='timeline-card-desc content grey' span={12}>
                    One fine evening under the shade of the iconic Eiffel tower in Paris, two friends dared to dream. A dream of starting up. Their lives, since that moment, have never been the same.
                    </Col>
                </Row>
                <Row className='timeline-card'>
                    <Col span={24}>
                        <span className='card-date heading primary'>2011</span>
                    </Col>
                    <Col className='timeline-card-desc content grey' span={12}>
                        A team of three people got together in a small room to create something amazing.
                    </Col>
                    <Col 
                        span={12} 
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <img src={Icon2} alt='2011'/>
                    </Col>
                </Row>
                <Row className='timeline-card'>
                    <Col span={24}>
                        <span className='card-date heading primary'>2016</span>
                    </Col>
                    <Col 
                        span={12} 
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <img src={Icon3} alt='2016'/>
                    </Col>
                    <Col className='timeline-card-desc content grey' span={12}>
                    Five years later, brigosha became a family of 70+ members with a strong foothold in the mobility domain and investing to establish the upcoming connected domain.
                    </Col>
                </Row>
                <Row className='timeline-card'>
                    <Col span={24}>
                        <span className='card-date heading primary'>2019</span>
                    </Col>
                    <Col className='timeline-card-desc content grey' span={12}>
                    Dedication & commitment propelled us beyond the 200-associate mark in no more than just 3 years.
                    </Col>
                    <Col 
                        span={12} 
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <img src={Icon4} alt='2019'/>
                    </Col>
                </Row>
                <Row className='timeline-card'>
                    <Col span={24}>
                        <span className='card-date heading primary'>2023</span>
                    </Col>
                    <Col 
                        span={12} 
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <img  src={Icon5} alt='2022'/>
                    </Col>
                    <Col className='timeline-card-desc content grey' span={12}>
                    Today, brigosha pioneers end-to-end solutions, offers captivating e-mobility and revolutionizes autonomous driving with unprecedented innovation. We boast of a family size of over 500 now!
                    </Col>
                </Row>
            </div>
        </OurJourneyStyle>
    )
}

export default OurJourney;

const OurJourneyStyle = styled.div`
    padding-right: 10vw;
    padding-left: 10vw;
    padding-top: 60px;
    padding-bottom: 40px;
    height: auto;

    .our-journey-content{
        text-align: center;
        font-family: 'Inter', sans-serif;
    }

    .our-journey-heading{
       
        font-size: calc(2vw + 15px);
        font-weight: 700;
        position:relative;
        z-index:2;
        font-family: 'Sofia Sans', sans-serif;
    }

    .our-journey-text{
        font-size: calc(1vw + 5px);
        font-weight: 400;
        font-family: 'Inter', sans-serif;
        padding-top: 20px;
    }

    .timeline-image-container{
        text-align: center;
        margin-top: 50px;
        font-family: 'Inter', sans-serif;
    }

    .timeline-image{
        width: 80%;
    }

    .card-date{
        display: block;
        font-size: calc(1vw + 10px);
        font-family: 'Sofia Sans', sans-serif;
    }

    .timeline-card{
        margin-top: 50px;
        display: none;
    }

    .timeline-card span{
        display: block; 
        text-align: center;
    }

    .timeline-card-desc{
        font-weight: 400;
        font-size: calc(1vw + 8px);
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Inter', sans-serif;
    }

    @media screen and (max-width: 767px){
        padding-left: 8vw;
        padding-right: 8vw;

        .timeline-image-container{
            display: none;
        }

        .our-journey-text{
            font-size: calc(1vw + 10px);
        }

        .our-journey-heading{
            font-size: calc(2vw + 20px);
        }

        .timeline-card{
            display: flex;
        }

        .timeline-card img{
            width: 80%;
        }
    }

    @media screen and (max-width: 425px){

    }
`
