import React from 'react';
import styled from 'styled-components';
import Brijesh from './Brijesh.png';
import Gopal from './Gopal.png';
import Shakira from './Shakira.png';
import Raghavendra from './Raghavendra.png';
import Sudhir from './Sudhir.png';
import Boopathi from './Boopathi.png'
import { Row, Col } from 'antd';
import { LinkedinFilled } from '@ant-design/icons';


const Team = () => {
    return (
        <StyleTeam>
                     <Row style={{   marginLeft: '-10vw', marginRight: '-8vw'}}>
          {/* <Col span={24}>
            <div className='highlighted-text heading'>
              Our <div className='gradient2'>vision<div className='polygon2'></div></div> is to make<br/> the world a better place through<br/> <div className='gradient7' style={{'height':'72px'}}>technology {window.innerWidth>400?'innovations':null}<div className='polygon2' ></div></div>{window.innerWidth<=400?'innovations':null}</div>
          </Col> */}
        </Row>
             <div 
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                        
                    >
                   
            <span className='team-topline darkblue highlight'>Meet the team</span><br/></div>
            {/* <div className='team-heading heading'>Leadership <div className='gradient1'>Team<div className='polygon1'></div></div></div><br/><br/> */}
            <div className='team-heading heading' >Our <span className='highlight' >Leaders</span> </div><br/>
           
            <span className='team-description content grey'>
            A group of resolute, tall-standing, committed individuals who have, over a decade,
propelled us to set benchmarks we ourselves didn’t think possible a few years ago.
            </span><br/><br/><br/>
            <Row justify='space-between'>
                <Col xs={11} sm={11} md={10} lg={7} xl={7} className='team-card'>
                    <Card {...Obj[0]}/>
                </Col>
                <Col xs={11} sm={11} md={10} lg={7} xl={7} className='team-card'>
                    <Card {...Obj[1]}/>
                </Col>
                <Col xs={11} sm={11} md={10} lg={7} xl={7} className='team-card'>
                    <Card {...Obj[2]}/>
                </Col>
                <Col xs={11} sm={11} md={10} lg={7} xl={7} className='team-card'>
                    <Card {...Obj[3]}/>
                </Col>
                <Col xs={11} sm={11} md={10} lg={7} xl={7} className='team-card'>
                    <Card {...Obj[4]}/>
                </Col>
                <Col xs={11} sm={11} md={10} lg={7} xl={7} className='team-card'>
                    <Card {...Obj[5]}/>
                </Col>
            </Row>
        </StyleTeam>
    )
}

const Card = ({ name, position, linkedIn, img }) => {
    return(
        <CardStyle >
            <img src={img} alt={name}/>
            <h1 className='title heading'>{name}</h1>
            <span className='position content grey'>{position}</span>
            <a href = {linkedIn} target="_blank" rel="noopener noreferrer">
                <LinkedinFilled style = {{color: '#117BB8', fontSize: '32px'}}/>
            </a>
        </CardStyle>
    )
}

const CardStyle = styled.div`
    width: 100%;
    margin-bottom: 80px;
    transition: all 0.5s ease-out;
    display: flex;
    flex-direction: column;
    align-items: center;



    &:hover{
        transform: scale(1.02);
    }

    img{
        width: 100%;
        margin-bottom: 20px;
        border-radius:14px;
    }

    .title{
        text-align: center;
        font-size: calc(1vw + 8px);
        font-weight: 700;
        font-family: 'Sofia Sans', sans-serif;
    }

    .position{
        display: block;
        font-size: calc(1vw + 3px);
        margin-bottom: 10px;
        font-weight: 400;
        font-family: 'Sofia Sans', sans-serif;
        text-align:center;
        height:50px;
    }

    @media screen and (max-width: 728px){
        .title{
            font-size: calc(1vw + 10px);
        }

        .position{
            font-size: calc(1vw + 8px);
            height:35px;
        }
    }
`

const StyleTeam = styled.div`
    padding-right: 15vw;
    padding-left: 15vw;
 
    padding-bottom: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .team-topline{
        font-size: calc(1vw + 3px);
        font-weight: 600;
        letter-spacing: 0.25em;
        text-transform: uppercase;
        width:280px;
      max-width:max-content;
      font-family: 'Sofia Sans', sans-serif;
        display:inline;
  
    }

    .team-heading{
        font-size: calc(2vw + 10px);
        font-weight: 700;
        position:relative;
        z-index:2;
        font-family: 'Sofia Sans', sans-serif;
    }

    .team-description{
        text-align: center;
        font-weight: 400;
        font-size: calc(1vw + 5px);
        font-family: 'Inter', sans-serif;
    }
    .highlighted-text{
        padding: 80px 10vw;
        width: 100%;
        text-align: center;
        font-size: calc(2vw + 18px);
        font-weight: 700;
        text-transform: capitalize;
        position:relative;
        z-index:2;
        font-family: 'Sofia Sans', sans-serif;
      }

    @media screen and (max-width: 728px){
        padding-right: 8vw;
        padding-left: 8vw;

        .team-topline{
            font-size: calc(1vw + 10px);
        }

        .team-heading{
            font-size: calc(2vw + 20px);
        }

        .team-description{
            font-size: calc(1vw + 10px);
        }
    }

 

`

const Obj = [
    {
        name: 'Brijesh Singh',
        position: 'Chief Executive Officer',
        linkedIn: 'https://www.linkedin.com/in/brijeshsifar',
        img: Brijesh,
    },
    {
        name: 'Gopal Jatiya',
        position: 'Chief Technology Officer',
        linkedIn: 'https://www.linkedin.com/in/gopaljatiya/',
        img: Gopal,
    },
    {
        name: 'Shakira Benazir',
        position: 'Chief Operating Officer',
        linkedIn: 'https://www.linkedin.com/in/shakira-benazir/',
        img: Shakira,

    },
    {
        name: 'Raghavendra Puranik',
        position: 'Vice President, Mobility Solutions',
        linkedIn: 'https://www.linkedin.com/in/raghavendra-puranik-32006124/',
        img: Raghavendra
    },
    {
        name: 'Sudhir Kumar Sharma',
        position: 'Assistant Vice President, Mobility Solutions',
        linkedIn: 'https://www.linkedin.com/in/sudhir-kumar-sharma-9ab72094/',
        img: Sudhir
    },
    {
        name: 'Boopathi Raja',
        position: 'Head of Delivery, Mobility Solutions',
        linkedIn: 'https://in.linkedin.com/in/boopathi-raja-6b521721/',
        img: Boopathi
    }
]

export default Team;