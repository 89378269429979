import React, { useEffect, useState } from 'react';
import Team from './TeamCard/team'
import Belief from './Belief/belief';
import Gallery from './Gallery/gallery';
import Impressive from './ImpressiveCard/impressive'
import styled from 'styled-components';

import { Row, Col, Button } from 'antd';
import {ReactComponent as ArrowLeft} from './left.svg'
import {ReactComponent as ArrowRight} from './right.svg'

import bg2 from '../ourStory/ourCulture/BG.svg'
import bg1 from '../ourStory/PotentialCard/potentialBG.svg'
import img1 from './Integrity.svg';
import img2 from './Transparency.svg';
import img3 from './Teamwork.svg';
import img4 from './Innovation.svg'

function Teams() {
    const [values, setValues] = useState(0)
    const [reset,setReset]=useState(false);
    useEffect(()=>{
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
    },[])

   
    const length=Obj.length;
    const changeValuesDown = () => {
        setValues((prev) => {
            if(prev == 0){
                return prev+length-1;
            }
            else{
                return prev-1;
            }
        })
    }

    const changeValuesUp = () => {
        setValues((prev) => {
            if(prev == length-1){
                return prev-(length-1);
            }
            else{
                return prev+1;
            }
        })
    }
    const setCustomValue=(value)=>{
        setValues(value);
    }

    useEffect(() => {
       if(!reset){
        const mount = setInterval(() =>{
            setValues((prev) => {
                if(prev == length-1){
                    return prev-(length-1);
                }
                else{
                    return prev+1;
                }
            })
        }, 5000)

        return () => clearInterval(mount);
    }
    }, [reset])
    



    return (<React.Fragment>
                <div   style={{
     background: `url(${bg1}) top no-repeat`,
  backgroundSize: '100%',
  overflow: 'hidden',
width:'100%',

 backgroundPositionY:'-20vh'

    }} className='background-image'><Impressive />
           <div   style={{
    background:`url(${bg2}) center no-repeat`,backgroundSize:'100%',

    }}> <Team/></div>
     </div>
     
    
  
    
            <div   style={{
    background:`url(${bg2}) center no-repeat`,backgroundSize:'100%',

    }}>     
     
            <Belief/>
          
            <ValuesCarousal>
            <div className='value-heading heading' ><span className='highlight' >Values</span> </div><br/>
           
           <span className='value-description content grey'>
           Where Integrity Inspires Innovation, Excellence is Delivered, and Collaboration Fuels Extraordinary
Solutions.
           </span><br/><br/><br/>
                <Row>
                    <Col 
                        xs={{span: 24, order: 2}} 
                        sm={{span: 24, order: 2}} 
                        md={{span: 12, order: 1}} 
                        lg={{span: 12, order: 1}} 
                        xl={{span: 12, order: 1}} 
                        xxl={{span: 12, order: 1}}
                        className='description'
                    >
                        {/* <span className='title darkblue highlight'>Values</span> */}
                        <div className='carousal' onMouseEnter={() => {setCustomValue(values);setReset(!reset)}}
                    onMouseLeave={() => setReset(!reset)}>
                            <span className='header heading'>{Obj[values].heading}</span>
                            <p className='paragraph content grey'>{Obj[values].description}</p>
                        </div>
                    </Col>
                    <Col 
                        xs={{span: 24, order: 1}} 
                        sm={{span: 24, order: 1}} 
                        md={{span: 12, order: 2}} 
                        lg={{span: 12, order: 2}} 
                        xl={{span: 12, order: 2}} 
                        xxl={{span: 12, order: 2}}
                        className='image-container'
                    >
                        <img src={Obj[values].img} alt='Carousal Banner' className='carousal-image'/>
                    </Col>
                </Row>
              
                <Row className='arrow-button '>
                    <div className='lines'>
                        <span className='left-outlined' onClick={() => changeValuesDown()}>
                            {/* <LeftOutlined/> */} <ArrowLeft/>
                        </span>
                      
                        {Obj.map((ele,index)=>{
                      
                      return <div className={values === index ? 'blueline' : 'greyline'} onClick={()=>{setCustomValue(index);setReset(!reset)}} 
                      onMouseLeave={() => setReset(!reset)}></div>
                    })}
                      
                        
                        <span className='right-outlined' onClick={() => changeValuesUp()}>
                            {/* <RightOutlined/> */}<ArrowRight/>
                        </span>
                    </div>
                </Row>
            </ValuesCarousal>
           
            </div>
            <Gallery/>
            
        </React.Fragment>
    )
}



const ValuesCarousal = styled.div`
text-align:left; 
padding:80px 10vw;
.value-heading{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap:4px;
    font-size: calc(2vw + 10px);
    font-weight: 700;
    position:relative;
    z-index:2;
    font-family: 'Sofia Sans', sans-serif;
}
.value-description{
    width: 100%;
    display: flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 400;
    font-size: calc(1vw + 5px);
    font-family: 'Inter', sans-serif;
}
    .title{
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 0.25rem;
        font-size: calc(1vw + 3px);
        font-family: 'Sofia Sans', sans-serif;
    }
    .lines{
        display:flex;
        flex-direction:row;
        align-items:center;
        gap:10px;
       
    }
    .blueline{
        width:60px;
        height:6px;
        background-color:#0086FF;
        border-radius:3px;
        cursor:pointer;
    }
    .greyline{
        width:20px;
        height:6px; 
        background-color:#9D9D9D;
        border-radius:3px;
        cursor:pointer;
    }

    .header{
        font-weight: 700;
        font-size: calc(2vw + 5px);
        display: block;
        margin-bottom: 20px;
        font-family: 'Sofia Sans', sans-serif;
    }

    .paragraph{
        font-weight: 400;
        font-size: calc(1vw + 5px);
        font-family: 'Inter', sans-serif;
    }

    .carousal-image{
        width: 400px;
        height:400px;
        padding-left:20px;
    }

    .carousal{
        margin-top: 50px
    }

    .image-container{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width:50%;
        height:50%;
    }

    .arrow-button{
        padding-bottom: 50px;
        font-size: calc(1vw + 8px);
        font-weight: 600;
        font-family: 'Sofia Sans', sans-serif;
        margin-top:20px;

       
    }

    .left-outlined>svg, .right-outlined>svg{
        display: flex;
        // margin-left: 10px;
        // margin-right: 30px;
        cursor: pointer;
        width:20px;
        height:20px;
        align-items:center;
    }

    .carousal-option{
        font-size: calc(2vw + 3px); 
        font-weight: 400;
        font-family: 'Sofia Sans', sans-serif;
    }

    .option{
        font-weight: 700;
      

    }
    .description{
      
    }



    @media screen and (min-width: 2000px){
        .carousal-image{
            width: 60%;
        }
    }

    @media screen and (max-width: 915px){
        .carousal-image{
            width: 80%;
            height: 300px;
    object-fit: fill;
        }
        .carousal-option, .description{
            // display: none;
        }
        .blueline{
            width:30px;
            height:5px;
            background-color:#0086FF;
            border-radius:3px;
            cursor:pointer;
        }
        .greyline{
            width:15px;
            height:5px; 
            background-color:#9D9D9D;
            border-radius:3px;
            cursor:pointer;
        }
        .mobile-description{
            display: flex;
            flex-direction: column;
            margin-top: 40px;
            font-size: calc(2vw + 8px);
            font-weight: 700;
            font-family: 'Sofia Sans', sans-serif;
        }

        .mobile-description .desc-header{
            display: flex;
            flex-direction: row;
            justify-content: flex-start
    
        }
        .desc-header span{
            font-family: 'Sofia Sans', sans-serif;
        }

        .mobile-description span { 
            margin-right: 10px;
        }

        .desc-body{
            font-size: calc(1vw + 10px);
            font-weight: 400;
            // padding-left: 46.38px;
            font-family: 'Sofia Sans', sans-serif;
        }
        .title{
            font-size: calc(1vw + 10px);
        }
        .image-container{
            justify-content:center;
        }
    }
    @media screen and (max-width: 770px){
        padding:0px 10vw 40px 10vw;
        .paragraph{
          
            font-size: calc(1vw + 10px);
           
        }
        .header {
            
            font-size: calc(1vw + 15px);
        }
        .value-heading{
            font-size: calc(2vw + 20px);
        }
        .value-description{
            font-size: calc(1vw + 10px);
        }
    }

    
`   



const Obj = [
    {
        heading: 'Integrity',
        description: 'We pride ourselves on honesty, dependability and loyalty. We take our commitments seriously and are accountable for results.',
        img:img1
    },
    {
        heading: 'Transparency',
        description: 'We maintain absolute transparency in our relationships and whatever we do. We have a 24x7 open door policy at all levels of management.',
        img:img2
    },
    {
        heading: 'Team Work',
        description: 'brigosha excels in recognizing diverse talents and perspectives of team members, assigning the right job to the right member, establishing a clear shared objective, and effective collaboration among all.',
        img:img3
    },
    {
        heading: 'EQUALITY',
        description: 'An unbiased democratic environment where ideas are bigger than individuals and no individual is bigger than another.',
        img:img4
    }
]

export default Teams;
