import React from 'react';
import styled from 'styled-components';

import img1 from './imgs/confo1.svg';
import img2 from './imgs/confo2.svg';
import img3 from './imgs/confo3.svg';
import img4 from './imgs/confo4.svg';
import img5 from './imgs/confo5.svg';
import img6 from './imgs/confo6.svg';
import { useState,useEffect } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { Row, Col, Button } from 'antd';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import { ReactComponent as Back } from '../../../../../assets/back-icon.svg';
import bg2 from '../../../../../assets/bg2.svg'
function Conference() {
    const handleBackBtn=()=>{
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        window.history.back();
    }

    const [image,setImage]=useState(0);
    useEffect(()=>{
      $(function() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
        setImage(0);
        document.getElementById('broadcast-wrapper').style.backgroundColor='#fff';
        setTimeout(()=>{
          document.getElementById('button').click();
        },1000)
    })
    },[])
    useEffect(() => {
        
        
          // Ensure ScrollTrigger is enabled
          gsap.registerPlugin(ScrollTrigger);
      
      
          // Get a reference to the pinned image element
        
      
          // Create a function to update the pinned image source based on scroll position
          function updatePinnedImage() {
            // Calculate the index of the image based on scroll position
       
            const top1=document.getElementById('dtl-1').offsetTop;
            const top2=document.getElementById('dtl-2').offsetTop-300;
            const top3=document.getElementById('dtl-3').offsetTop-400;
            const top4=document.getElementById('dtl-4').offsetTop-400;
            const top5=document.getElementById('dtl-5').offsetTop-400;
            const top6=document.getElementById('dtl-6').offsetTop-400;
             const top=window.scrollY;
              // Get the ScrollTrigger instance associated with the pinned element
          
                // Calculate the index of the image based on the scroll position
            
             console.log('data',top1,top2,top3,window.scrollY)
        if(top>=top1 && top<top2){
          setImage(0);
          document.getElementById('broadcast-wrapper').style.backgroundColor='#fff'
    
     
        }else if(top>=top2&& top<top3){
          document.getElementById('broadcast-wrapper').style.backgroundColor='#F3F8FD'
         
          setImage(1);
         
        }else if(top>=top3 && top<top4 ){
          setImage(2);
          document.getElementById('broadcast-wrapper').style.backgroundColor='white'
        
        }else if(top>=top4 && top<top5){
          document.getElementById('broadcast-wrapper').style.backgroundColor='#F3F8FD'
         
          setImage(3);
        }else if(top>=top5 && top<top6){
          document.getElementById('broadcast-wrapper').style.backgroundColor='#FFF'
         
          setImage(4);
        }else if(top>=top6 ){
          document.getElementById('broadcast-wrapper').style.backgroundColor='#F3F8FD'
         
          setImage(5);
        }
                // Update the pinned image source with a smooth fade transition
              
              
            
          }
       
          // Create your ScrollTrigger animation
          const animation = gsap.timeline({
            scrollTrigger: {
              trigger: '.gallery', // Element that triggers the animation
              start: 'top top', // Start point of the trigger
              end: 'bottom bottom', // End point of the trigger
              pin: '.right', // Element to pin
              scrub: true, // Smooth scrubbing effect
             
              onUpdate: () => {
                // Call the updatePinnedImage function on scroll
                updatePinnedImage();
              },
            },
          });
          updatePinnedImage();
          // Add animations or tweens to your timeline
          // For example, to animate the pinned element's opacity:
          // animation.to('.right', { opacity: 0, duration: 1 });
        
          // Add any other animations as needed
    
          
          // Don't forget to return a cleanup function for useEffect
          return () => {
        
            animation.kill(); // Kill the animation on unmount if necessary
          
          };
        }, []); // Empty dependency array ensures this effect runs only once
        
       
    
        useEffect(()=>{
          const imageSources = [
          img1,img2,img3,img4,img5,img6
            // Add more image sources as needed
          ];
            const pinnedImage = document.querySelector('.pinned-image');
              gsap.to(pinnedImage, {
                opacity: 0.8, // Fade out the image
                transition:0.6 ,
                scale:0.9,
              
                duration: 0.1, // Duration of the fade animation
                onComplete: () => {
                  // Change the image source
                  pinnedImage.src = imageSources[image];
        
                  // Fade the image back in
                  gsap.to(pinnedImage, {
                    transition:0.1 ,
                    scale:1,
                    opacity: 1, // Fade in the new image
                    duration: 0.1, // Duration of the fade animation
                  });
                },
              });
            
        },[image])
    return (
        <div   style={{
          background:`url(${bg2}) center no-repeat`,
          backgroundSize:'100%',
          backgroundPositionY:'-2%',
          backgroundColor:'#fff'
          }} id='broadcast-wrapper'>
        <ConferenceContainer>
         
         <div style={{'display':'flex','alignItems':'center','justifyContent':'flex-start',position:'relative',flexDirection:'row',width:'100%'}}>
           <Back className='back-btn' onClick={handleBackBtn} ></Back>
       
            </div>
            <div style={{height:'50vh',textAlign:'center',display:'flex',justifyContent:'center',alignItems:'center'}} className='content  Conference-top'>
   <div className='Conference-header heading'><div className='highlight'>In-House Conference :</div> Dive into immersive 3D environments for meetings, with customizable avatars and spatial audio for lifelike interactions</div>
   </div>
   <div class="gallery" style={{marginTop:'-100px'}}>
	<div class="left">
		<div class="detailsWrapper">
	{Obj.map((ele,index)=>{
    return  <CardStyle id={`dtl-${index+1}` }>
    <Col 
     className= 'card-text-container left' style={{width:'100%'}}
       >
            <div className='card-heading heading' >{parse(ele.headline)}</div>
            <p className='card-content content'>{ele.description}</p>
            {
              ele.link.length!==0? <div 
              className='button-holder'
              style={{justifyContent: 'flex-start' }}
            >
              <Link to={`/technology/${ele.link}`} onClick={
                  scrollToTop()
                }> 
                  <Button 
                    type = {ele.btnType} 
                    className='card-button'
                    style ={{
                      color:  'white' ,
                      backgroundColor: 'transparent',
                      
                    }}
                  >
                    {ele.buttonLabel}
                  </Button>
                </Link>
            
              
            </div>:null}
        </Col>
        </CardStyle>
  })}
     

		</div>
	</div>

	<div class="right">
    <div className='image-wrapper'>
    <img src={img1} alt="Pinned Image" className="pinned-image" />
    </div>

	</div>
</div>
   
            
<div  className='mobile-view'>
{Obj.map((ele,index)=>(
  <>
  <Card {...ele}/>
      <br/>
      <br/></>

))}
      </div>
           
           
        </ConferenceContainer>
        </div>
    )
}

const ConferenceContainer = styled.div`
    // height: 100vh;
   
    // position:sticky;
   
    // overflow-y:scroll;
    // &::-webkit-scrollbar {
    //     width: 0px;

    //   }
    background-color: transaprent;transaprent;
    padding-top: 40px;
    padding-right: 10vw;
    padding-left: 10vw;
    text-align: center;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;

    .mobile-view{
      display:none;
    }
    .gallery {
      display:flex;
      // outline:1px solid red;
      justify-content:space-between;
      gap:10px;
      width:80vw;
     
      // background:white;
      // box-shadow:0 0 10px 0 rgba(0,0,0,0.2);
      // border-radius:10px;
    }
    .image-wrapper{
      width:31vw;
      height:31vw;
      border-radius:11%;
     display:flex;
     justify-content:center;
     align-items:center;
     box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;

    }
    .pinned-image{
   
      width:70%;
         object-fit:contain;
         margin:0 auto;
         display:flex;
         justify-content:center;
       }
    .left {
      width:600px !important;
    }
    
    .detailsWrapper {
      margin:auto;
      width:100%;
    }
    
    .details {
      height:100vh;
      // outline:1px solid green;
      display:flex;
      flex-direction:column;
      justify-content:center;
    }
    
    
    .right {
      // outline:1px solid purple;
      width:500px;
      height:100vh;
      display:flex;
      flex-direction:column;
      justify-content:center;
      align-items:center;
    }
    .Conference-header{
        font-size: calc(2vw + 15px);
        font-weight: 700;
        position:relative;
        z-index:2;
    }
    .bule-card{
        max-width:70vw;
        background-color: rgba(232, 246, 255, 1);
        height:auto;
        padding:20px;
        margin:0 auto;
    }

    .Conference-top{
        padding-top: 20px;
        font-size: calc(1vw + 5px);
        font-weight: 400;
       
    }

    @media screen and (max-width: 1240px){
      .right{
          width:400px!important;
      }
      .left{
        width:40vw!important;
      }
    }

    @media screen and (max-width: 767px){
        padding-right: 8vw;
        padding-left: 8vw;
        .gallery{
          display:none;
        }
        .mobile-view{
          display:block;
        }
        .Conference-header{
            font-size: calc(2vw + 20px);
        }

        .Conference-top{
            font-size: calc(1vw + 10px);
            height:auto !important;
        }
        
    }
`

export default Conference;


const Obj=[
{
    topLine: '',
    btnType: 'outline',
    headline: `Elevate Conversations with <div className='highlight'>WebRTC Magic:</div>`,
    description: 'Step into a world of real-time interaction powered by Web Real-Time Communications (WebRTC). Seamlessly connect voices, videos, and texts across devices with our innovative platform. Our ingenious Selective Forwarding Unit (SFU) ensures lightning-fast performance, scalability, and minimal latency, paving the way for conversations that feel truly in the moment.',
    buttonLabel: '',
    img: img1,
    alt: 'Conference-image',
    imgStart: '',
    link:'',
    height:90
}
,{
    topLine: '',
    btnType: 'outline',
    headline: `<div className='highlight'>Zero Latency</div> , Infinite Possibilities:`,
    description: 'Experience the thrill of conversations with a mere 0.5-second delay. Our conference solution bridges the gap between speakers and listeners, erasing lag and making interactions as instant as a heartbeat.',
    buttonLabel: '',
    img:img2,
    alt: 'Conference-image',
    imgStart: 'start',
    link:'',
    height:90
}

, {
    topLine: '',
    btnType: 'outline',
    headline: `<div className='highlight'>Whiteboard</div>, redefined for Creativity:`,
    description: "Unleash your imagination on our dynamic whiteboard platform. Brushes of various sizes and colors, precision erasing, and multiple canvases provide a playground for your creativity. Share your masterpieces effortlessly, whether it's brainstorming, education, or pure artistic expression.",
    buttonLabel: '',
    img: img3,
    alt: 'Conference-image',
    imgStart: '',
    link:'',
    height:90
}

,{
    topLine: '',
    btnType: 'outline',
    headline: `Raise your <div className='highlight'>Virtual Hand</div> High:`,
    description: 'Put the spotlight on your voice with our virtual hand raise. Capture the essence of a physical classroom or meeting by signaling your eagerness to contribute. Get your chance to shine without interruptions.',
    buttonLabel: '',
    img: img4,
    alt: 'Conference-image',
    imgStart: 'start',
    link:'',
    height:90
}
,{
    topLine: '',
    btnType: 'outline',
    headline: `<div className='highlight'>Speak</div> with a touch:`,
    description: "Control the conversation flow with our touch-to-talk feature. Mute background noise until you're ready to speak. Just a touch activates your microphone, ensuring orderly discussions and uninterrupted communication.",
    buttonLabel: '',
    img:img5,
    alt: 'Conference-image',
    imgStart: '',
    link:'',
    height:90
}

, {
    topLine: '',
    btnType: 'outline',
    headline: `Real Time, <div className='highlight'>Chat Cloud Powered:</div>`,
    description: 'Break free from delays with our real-time chat, fueled by Web Sockets. Messages flow instantly, no more refreshing required. Backed by cloud data storage, enjoy scalability, accessibility, and data integrity. ',
    buttonLabel: '',
    img: img6,
    alt: 'Conference-image',
    imgStart: 'start',
    link:'',
    height:90
}
]



const Card = ({
    btnType,
    headline,
    description,
    buttonLabel,
    img,
    alt,
    imgStart,
    link,
    updateMainMenu ,
    height
  }) => {
    const scrollToTop = () => {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
     
  }
    return (
      <CardStyle
        wrap={true}
      >
          <Col 
          xs={{span: 24, order: 2}} 
          sm={{span: 24, order: 2}} 
          md={{span: 12, order: imgStart === 'start' ? 2 : 1}} 
          lg={{span: 12, order: imgStart === 'start' ? 2 : 1}} 
          xl={{span: 12, order: imgStart === 'start' ? 2 : 1}}
          xxl={{span: 12, order: imgStart === 'start' ? 2 : 1}}
          className={imgStart === 'start' ? 'card-text-container left' : 'card-text-container left'}
          gutter = {20}
        
          >
              <div className='card-heading heading' >{parse(headline)}</div>
              <p className='card-content content'>{description}</p>
              {
                link.length!==0? <div 
                className='button-holder'
                style={{justifyContent: 'flex-start' }}
              >
                <Link to={`/technology/${link}`} onClick={
                    scrollToTop()
                  }> 
                    <Button 
                      type = {btnType} 
                      className='card-button'
                      style ={{
                        color:  'white' ,
                        backgroundColor: 'transparent',
                        
                      }}
                    >
                      {buttonLabel}
                    </Button>
                  </Link>
              
                
              </div>:null}
          </Col>
     

          <Col
  
  xs={{ span: 24, order: 1 }}
  sm={{ span: 24, order: 1 }}
  md={{ span: 12, order: imgStart === 'start' ? 1 : 2 }}
  lg={{ span: 12, order: imgStart === 'start' ? 1 : 2 }}
  xl={{ span: 12, order: imgStart === 'start' ? 1 : 2 }}
  xxl={{ span: 12, order: imgStart === 'start' ? 1 : 2 }}
  className='content'
  style={{'display':'flex',justifyContent:imgStart === 'start'?'flex-start':'flex-end',alignItems:'center',}}
  >
  {
    img ? <><img src={img} alt={alt} className='card-image' style={{'height':`${height}%`}} />  </> :null
  

  
  
  }
  
  </Col>
      </CardStyle>
    )
  };
  
  
  const CardStyle = styled(Row)`
    padding-top: 10px;
  
  height:100vh;
    
    background-color:transparent;
    .card-text-container{
      
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .card-image{
     padding-bottom:10px;
     width:86% !important;

    object-fit:fill;
    transition: all 0.3s ease-out;
  
     
    
    }
    .card-image:hover{
      transform: scale(1.01);
      transition: all 0.3s ease-out;
    }
  

    .left{
      text-align: left;
    }
  
    .right{
      text-align: right;
    }
  
    .card-image{
      width: 70%;
    }
  
    .button-holder{
      width: 100%;
      display: flex;
      align-items: center;
    }
  
    .card-content{
      font-size: calc(1vw + 5px);
      font-weight: 400;
      color:#111111;
      word-wrap: break-word;
      text-align:left;
    
    }
  
    .card-heading {
      width: 100%;
      margin-bottom: 24px;
      font-size: calc(2vw + 10px);
      font-weight: 700;
    }
    
    .card-button{
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: calc(1vw + 3px);;
      border-color: #0086FF;
      border-radius: 4px;
      font-weight: 700;
      gap:10px;
      padding: 2vw 4vw;
      background-color: #0086FF !important;
      color:white !important;
      box-shadow: 0 20px 40px 0 rgba(35, 100, 210, 0.2);
    
    }
  
    .card-button:hover{
       
      background-color: #0086FF !important;
      color:white !important;
      box-shadow: 0 20px 5px -10px rgba(0, 0, 0, 0.4);
       transform: translateY(1px);
   }
    .card-button:hover{
      // transform: scale(1.02);
      // transition: all 0.3s ease-out;
    }
  
    @media screen and (min-width: 2000px){
      .card-image{
        width: 70%;
      }
    }
  
    @media screen and (max-width: 767px){
      height:100%;
      .card-content{
        font-size: calc(1vw + 10px);
        text-align: left !important;
      }
      .content{
        justify-content:center !important;
      }
      .card-image{
        width: 65%;
      }
      .card-heading{
        text-align: left !important;
        font-size: calc(2vw + 20px);
      }
  
      .button-holder{
        justify-content: flex-start !important;
      }
  
      .card-button{
        padding: 3vw 6vw;
        font-size: calc(1vw + 6px);
      }
    }
  
    @media screen and (max-width: 425px){
      .card-button{
        padding: 4vw 6vw;
      }
  
      .card-image{
        width: 70%;
      }
    }
  
   
  `
