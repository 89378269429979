import React from 'react';
import styled from 'styled-components';
import { Row, Col, Button } from 'antd';
import TeamBanner from './TeamsBanner.svg'
import { Link } from 'react-router-dom'


function Impressive() {
    return (
      <CardStyle>
        <Row
          wrap = {true}
          className = 'card'
        >
          <Col 
            xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}
            className = 'card-text-container right'
            order = {1}
            gutter = {20}
          >
              <div className='card-top-line darkblue highlight'>Teams</div>
              {/* <div className='card-heading heading'>Bringing <div className='gradient3'>Impressive<div className='polygon3'></div></div> Individuals Together</div> */}

              <div className='card-heading heading' >Bringing <span className='highlight' >Impressive </span><br/> Individuals Together </div>
           
              <p className='card-content grey content'>
              There is a reason why the world took notice when Aristotle said - “The whole is greater than the sum of its parts”. Indeed, teamwork is one of the most elementary tools when it comes to organizational efficiency. Having the ability to bring together groups of able individuals and make them share a common goal is the key, not only to greater creativity and productivity at work, but also to healthier, happier individuals & teams.
              </p>
              <div style={{width:'172px'}}>
              <Link 
                to='/join-us' 
                onClick={() => {
                  document.body.scrollTop = 0; // For Safari
                  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                }}
              >
                <Button type = 'default' className='card-button primary'>
                  Join Us
                </Button>
              </Link>
              </div>
          </Col>
  
          <Col 
            xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}
            order = {2}
            className='card-img-container'
          >
            <img  src={TeamBanner} alt={'Career'} className='card-image' />
          </Col>
        </Row>
    
      </CardStyle>
    )
}


  
  
const CardStyle = styled.div`
padding-right:8vw;
padding-left: 8vw;
padding-top:60px;
padding-bottom:60px;
min-height:max-content;
  background-color:transparent;
  .card-content{
    font-size: calc(1vw + 5px);
    font-family: 'Inter', sans-serif;
  }

  .highlight{
    display:inline-block;
  }
  .card-img-container{
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in;
  }

  .card-button:hover{
    box-shadow: 0 20px 5px -10px rgba(0, 0, 0, 0.4);
    transform: translateY(1px);
 }
  .card-img-container:hover{
    transform: scale(1.01);
    transition: all 0.3s ease-out;
  }

  .card-image{
    width: 90%;
  }

  .card-top-line{
    font-size: calc(1vw + 3px);
    text-transform: uppercase;
    letter-spacing: 0.25rem;
    font-weight: 600;
    margin-bottom: 30px;
    font-family: 'Sofia Sans', sans-serif;
  }

  .card-heading{
    margin-bottom: 24px;
    font-size: calc(2vw + 15px);
    font-weight: 700;
    position:relative;
    z-index:2;
    display:block;
    font-family: 'Sofia Sans', sans-serif;
  }
  
  .card-button{
    margin-top: 20px;
    padding: 2vw 3vw;
    font-weight: 700;
    text-transform: uppercase;
    font-size: calc(1vw + 3px);
    border-color: #0086FF;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Sofia Sans', sans-serif;
    background-color: #0086FF !important;
    color:white !important;
    box-shadow: 0 20px 40px 0 rgba(35, 100, 210, 0.2);
  }

  .card-button:hover{
    transform: scale(1.02);
    transition: all 0.1s ease-out;
    background: #0086ff;
    color: #fff !important;
  }

  

  @media screen and (min-width: 2000px){
    .card-image{
      width: 70%;
    }
  }

  @media screen and (max-width: 767px){
   

    .card-image{
      width: 80%;
      margin-bottom: 20px;
    }

    .card-heading{
      font-size: calc(2vw + 20px);
    }

    .card-content{
      font-size: calc(1vw + 10px);
    }

    .card-top-line{
      font-size: calc(1vw + 10px);
    }

    .card-button{
      padding: 3vw 6vw;
      font-size: calc(1vw + 8px);
    }

    .highlighted-text{
      padding: 80px 8vw;
      font-size: calc(2vw + 18px);
    }
  }

  @media screen and (max-width: 425px){
    .card-image{
      width: 100%;
      margin-bottom: 20px;
    }
  }
`

export default Impressive;
