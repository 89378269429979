import React from 'react';
import styled from 'styled-components';
import { Row } from 'antd';
import notfound from './404.gif'
const NotFound = ({
   
  }) => {
  
    return (
      <NotFoundStyle
        wrap={true}
      >
        <div style={{'width':'100vw','height':'70vh',display:'flex',justifyContent:'center',alignItems:'center'}}>
        {/* <span className='title content darkblue'>404 ! NOT FOUND</span> */}
        <img alt='brigosha' src={notfound} width={280} height={280}/>
</div>
      </NotFoundStyle>
    )
  };
  
  
  const NotFoundStyle = styled(Row)`
    padding-top: 50px;
    .title{
        display: block;
        letter-spacing: 0.25rem;
        text-transform: uppercase;
        font-weight: 600;
        font-size: calc(1vw + 3px);
    }


  
   
  `
  export default NotFound;