import React from 'react';
import './style.css'
import bg2 from '../../../../assets/bg2.svg'
const PolicyPolicy = () => {
  return (
    <div   style={{
      background:`url(${bg2}) center no-repeat`,backgroundSize:'100%',backgroundColor:'#F3F8FD',backgroundPositionY:'100%'}}>
  
  
    <div className="policy-page" >
      <h1 className="policy-page__title">Privacy <div className='highlight'>Policy</div></h1>
      <p className="policy-page__description" >
brigosha Technologies Pvt Ltd. operates the website <a href='https://brigosha.com/'>www.brigosha.com</a> and provides services ("SERVICE"). By using our Service, you consent to the collection and utilization of Personal Information in accordance with this policy. We prioritize safeguarding your information solely for enhancing and providing our Service. Your trust is paramount, and we ensure secure handling of your Personal Information through robust measures.
When visitors engage by posting comments, we may request personally identifiable information (name, phone number, postal address) to enhance your experience and facilitate communication. The collected data is strictly for identification and contact purposes.
</p>
<p className="policy-page__heading">Security</p>
<p className="policy-page__description"> 
We value the trust you place in us with your Personal Information. Therefore, we employ strong security measures to protect it.
For our app's functionality, we collect necessary user information (email, phone number, location, calendar, device ID) without sharing it with any third party. This data is used solely for app features, such as enabling functionality or user authentication.
Data collected is encrypted during transmission. Users can deactivate or delete their accounts at any time, ensuring the removal of their personal data from our systems. However, please note that certain data might be retained for administrative, legal, or security reasons even after account deletion.
      </p>
     
<p className="policy-page__heading">Contact Information</p> 
<p className="policy-page__description"> 
For inquiries or concerns regarding our Privacy Policy, contact our Privacy Officer:
<div className='special_text'>
Email: <a href="mailto:info@brigosha.com">info@brigosha.com</a>
<br></br>
Phone: 9538946543
</div>
</p>
<p className="policy-page__heading">User Rights</p> 
<p className="policy-page__description"> 
You have the right to request an exported file containing your stored personal data if you have an account on our site or have posted comments. Additionally, you can request the erasure of your personal data, excluding information essential for administrative, legal, or security reasons.
This Privacy Policy governs our online activities and applies to information collected through our website and app. By using our Service, you agree to the terms outlined in this Privacy Policy.
<br/>
<br/>
For further details on our terms and conditions, visit <a href='https://brigosha.com/'>www.brigosha.com</a>.
</p>
    </div>
    </div>
  );
};

export default PolicyPolicy;
