import React from 'react';
import styled from 'styled-components';
import Card from '../../../card';
import Img from './lidar.svg'
import tick from './tick.svg'
import LidarImg from './market-lidar.svg'
import { Row, Col, Button } from 'antd';
import icon1 from './icons/eye-1.svg';
import icon2 from './icons/car-dash.svg';
import icon3 from './icons/detection.svg';
import icon4 from './icons/map.svg';
import icon5 from './icons/eye-2.svg';
import icon6 from './icons/key.svg';
import icon7 from './icons/target.svg'
import {
    MilestoneCard,
    MilestoneCardBody,
    MilestoneCardHeading
} from './styles'

function Lidar() {
    return (
    
        <ResearchContainer>

            <div className='Research-header heading'>Embrace Our Research: Shattering  <br/>Boundaries With <div className='highlight'>LiDAR Innovation</div>!</div>
       
            <br/>
           
         
              <p className='LidarResearch-top content' >Witness the revolution we ignite as we introduce a groundbreaking standalone Lidar solution that redefines perception. 
                Our cutting-edge algorithms generate unparalleled tracking and perception capabilities, powered by the transformative potential of Lidar technology.
                 Join our extraordinary journey towards a safer, more autonomous future.
                  Shape the future of the driver assistance landscape with Brigosha’s Lidar-driven solutions and be a part of the transformation.</p>
            {/* <Card {...Obj}></Card> */}
         
           
           
            <div className='Research-header heading' style={{      marginTop:'40px'}}>Why <div className='highlight'>LiDAR</div>?</div>
            <CardStyle
        wrap={true}
      >
          <Col 
          xs={{span: 24, order: 2}} 
          sm={{span: 24, order: 2}} 
          md={{span: 12, order:  1}} 
          lg={{span: 12, order:  1}} 
          xl={{span: 12, order:  1}}
          xxl={{span: 12, order:  1}}
          className={'card-text-container left'}
          gutter = {20}
          >
            <span className='tick-point'>
            <img src={tick} alt='img' className='tick-image' /> 
            <p className='card-content content'>
             
                Bid farewell to depth-deprived image detection; LiDAR breathes life into scenes.</p>
            </span>
            {/* <span className='tick-point'>
            <img src={tick} alt='img' className='tick-image' /> 
            <p className='card-content content'>
             
            No more blind spots with multiple cameras for 360-degree vision.</p>
            </span> */}
            <span className='tick-point'>
            <img src={tick} alt='img' className='tick-image' /> 
            <p className='card-content content'>
            Radar’s 3D puzzle remains unsolved, but LiDAR unravels object identification.</p>
            </span>
            <span className='tick-point'>
            <img src={tick} alt='img' className='tick-image' /> 
            <p className='card-content content'>
             
            Lidar strides in 3D, capturing scenes, tagging objects, and tracking every move. </p>
            </span>
            <span className='tick-point'>
            <img src={tick} alt='img' className='tick-image' /> 
            <p className='card-content content'>
             
            Step into a world of safer and smarter driving, where LiDAR reigns supreme.</p>
            </span>
            
              
              
          </Col>
          <Col
  
  xs={{ span: 24, order: 1 }}
  sm={{ span: 24, order: 1 }}
  md={{ span: 12, order:  2 }}
  lg={{ span: 12, order: 2 }}
  xl={{ span: 12, order:2 }}
  xxl={{ span: 12, order:  2 }}
  className='content'
  style={{'display':'flex',justifyContent:'center',alignItems:'center'}}
  >
  {
     <img src={Img} alt='lidar-img' className='card-image' />  
  

  
  
  }
  
  </Col>
      </CardStyle>
      <div className='Research-header heading' style={{      marginTop:'40px'}}><div className='highlight'>Milestones</div> Unlocked!</div>

      <Row style={{marginTop: 60}}>
                    {
                        milestones?.map((policy, index) => milestoneCard(policy, index)) 
                    }
                </Row>
    <div className='Research-header heading' style={{      marginTop:'40px'}}>Unveiling Our <div className='highlight'>Achievements!</div></div>

            <Row style={{marginTop: 60}}>
                    {
                        achievements?.map((policy, index) => milestoneCard(policy, index)) 
                    }
                </Row>
            
        </ResearchContainer>
       
        
    )
}

const ResearchContainer = styled.div`
    height: auto;
    background-color: transaprent;
    padding-top: 80px;
    padding-bottom: 80px;
    padding-right: 8vw;
    padding-left: 8vw;
    text-align: center;
    

    .Research-header{
        font-size: calc(2vw + 15px);
        font-weight: 700;
        position:relative;
        z-index:2;
  
    }
    .LidarResearch-top{
       
      font-size: calc(1vw + 5px);
      font-weight: 400;
  }

    .Research-topline{
        padding-top: 10px;
        font-size: calc(1vw + 10px);
        font-weight: 400;
    }
    .Research-top{
       
        font-size: calc(1vw + 6px);
        font-weight: 400;
    }
    .LidarResearch-top{
       
      font-size: calc(1vw + 5px);
      font-weight: 400;
  }
    


    @media screen and (max-width: 767px){
        padding-right: 8vw;
        padding-left: 8vw;

        .Research-header{
            font-size: calc(2vw + 20px);
        }

        .LidarResearch-top{
          font-size: calc(1vw + 10px);
      }

        .development-top{
            font-size: calc(1vw + 10px);
        }
        .LidarResearch-top{
          font-size: calc(1vw + 10px);
      }
    }
`
const CardStyle = styled(Row)`
padding-top: 50px;


.card-text-container{
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.card-image{
 padding-bottom:10px;
 width:86% !important;

object-fit:fill;
transition: all 0.3s ease-out;

}
.tick-image{
    margin-top:8px;
}
.tick-point{
    display:flex;
    flex-direction:row;
    align-items:flex-start;
    gap:20px;
}
.card-image:hover{
  transform: scale(1.01);
  transition: all 0.3s ease-out;
}


.left{
  text-align: left;
}

.right{
  text-align: right;
}

.card-image{
  width: 70%;
}

.button-holder{
  width: 100%;
  display: flex;
  align-items: center;
}

.card-content{
  font-size: calc(1vw + 5px);
  font-weight: 400;
  color:#111111;
  word-wrap: break-word;
  text-align:left;

}

.card-heading {
  width: 100%;
  margin-bottom: 24px;
  font-size: calc(2vw + 10px);
  font-weight: 700;
}

.card-button{
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calc(1vw + 3px);;
  border-color: #0086FF;
  border-radius: 4px;
  font-weight: 700;
  gap:10px;
  padding: 2vw 4vw;
  background-color: #0086FF !important;
  color:white !important;
  box-shadow: 0 20px 40px 0 rgba(35, 100, 210, 0.2);
}

.card-button:hover{
   
  background-color: #0086FF !important;
  color:white !important;
  box-shadow: 0 20px 5px -10px rgba(0, 0, 0, 0.4);
   transform: translateY(1px);
}
.card-button:hover{
  // transform: scale(1.02);
  // transition: all 0.3s ease-out;
}

@media screen and (min-width: 2000px){
  .card-image{
    width: 70%;
  }
}

@media screen and (max-width: 767px){

  .card-content{
    font-size: calc(1vw + 10px);
    text-align: left !important;
  }
  .card-image{
    width: 65%;
  }
  .card-heading{
    text-align: left !important;
    font-size: calc(1vw + 15px);
  }

  .button-holder{
    justify-content: flex-start !important;
  }

  .card-button{
    padding: 3vw 6vw;
    font-size: calc(1vw + 6px);
  }
}

@media screen and (max-width: 425px){
  .card-button{
    padding: 4vw 6vw;
  }

  .card-image{
    width: 70%;
  }
}


`
const Obj = {
    topLine: '',
    btnType: 'outline',
    headline: ``,
    description: 'While the market hesitates at level-3 driver assistance, brigosha ascends to new heights with level-4 autonomy. Witness the revolution we ignite as we introduce a groundbreaking standalone LiDAR solution that redefines perception. Our cutting-edge algorithms unleash unparalleled tracking and perception capabilities, powered by the transformative potential of LiDAR technology. Join our extraordinary journey towards a safer, more autonomous future, leaving level-3 in the rearview mirror. Shape the driver assistance landscape of tomorrow with brigosha’s LiDAR-driven solutions and be part of the visionary transformation.   ',
    buttonLabel: '',
    img: LidarImg,
    alt: 'lidar-image',
    imgStart: '',
    link:'',
    height:80
}


const milestones=[
    {
        title:'Empowering Vision: Detecting Ghost Objects',
        description:'Our algorithm reveals unseen anomalies, ensuring no object is overlooked, setting a new standard in perception.',
        icon:icon1

    },
    {
        title:'Safer Roads: Revolutionizing Collision Avoidance',
        description:'Our advanced LiDAR technology enables precise navigation, avoiding collisions and ensuring safer travel for all.',
        icon:icon2

    },
    {
        title:'Zero False Detections',
        description:'Bid farewell to false positives with our cutting- edge system, ensuring accurate object identification and reliable real-world results.',
        icon:icon3

    },
    {
        title:'Ensuring Privacy',
        description:'Lidar ensures privacy of the passengers, number plates.',
        icon:icon4

    }


]
const achievements=[
    {
        title:'Full Detection: Unveiling the World',
        description:'Experience complete object detection, revealing every detail within frames for a comprehensive understanding.',
        icon:icon5

    },
    {
        title:'Tagging Mastery: Identifying Key Objects',
        description:'Accurately identify vehicles, pedestrians, and bicyclists with advanced object tagging, enhancing situational awareness.',
        icon:icon6

    },
    {
        title:'Precision Tracking: Pin-Point Accuracy',
        description:'Achieve precise object tracking, enabling comprehensive analysis of positions and movements with cutting-edge technology.',
        icon:icon7

    }
]

const milestoneCard = (obj, key) => (
            <Col key={key} xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <MilestoneCard>   
                    <img src={obj.icon} alt={key}/>
                    <MilestoneCardHeading className='heading'>
                        {obj.title}
                    </MilestoneCardHeading>
                    <MilestoneCardBody className='content grey'>
                        {obj.description}
                    </MilestoneCardBody>
                </MilestoneCard>
            </Col>
        )




  

export default Lidar;


