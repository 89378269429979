
import React ,{useEffect} from 'react';
import styled from 'styled-components';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Row, Col, Button } from 'antd';
import ADASIMG from './adas1.svg';
import bg2 from '../../../../we/subpages/ourStory/ourCulture/BG.svg'
import {useNavigate} from 'react-router-dom';
import { ReactComponent as Back } from '../../../../../assets/back-icon.svg';
import Img1 from './svgs/safety-icon.svg';
import Img2 from './svgs/efficiency-icon.svg';
import Img3 from './svgs/accessibility-icon.svg';
import Img4 from './svgs/mobility-icon.svg';
import Img5 from './svgs/insurance-icon.svg'
function Adas() {
    const navigate = useNavigate();
    const handleBackBtn=()=>{
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        window.history.back();
    }
    useEffect(()=>{
      document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
    },[])
    return (
        <React.Fragment>
          
          
          <div   style={{
      background:`url(${bg2}) center no-repeat`,backgroundSize:'cover'
  
      }}>   
        < AdasContainer>
      

        <div style={{'display':'flex','alignItems':'center','justifyContent':'flex-start',position:'relative'}}>
           <Back className='back-btn' onClick={handleBackBtn} />
         
            </div>
        
            <div className='Adas-header heading'> <div className='highlight'>ADAS</div></div>
           
         
            <BigCard {...Obj1}/>
            <br/>
            <div className='Adas-header heading'>Transforming <div className='highlight'>Society's Drive</div></div>
            <br/>
            <br/>
            <Row style={{ display: "grid",
  gridTemplateColumns: "repeat(auto-fit, minmax(600px, 1fr))",
  justifyContent: "center",
  placeItems:'center',
  gap: "30px"}}>
              <Col className='adas-card content'>
              <div className='adas-icon'>
              <img alt='web development' src={Img1}></img>
              <span >Safety</span>
              </div>
           
              <span className='adas-card-info'> ADAS features such as forward collision warning and automatic emergency braking reduce the risk of
accidents, saving lives and preventing injuries.</span>
              </Col>
              <Col className='adas-card content'>
              <div className='adas-icon'>
              <img alt='software Efficiency' src={Img2}></img>
              <span>Efficiency</span>
              </div>
              <span className='adas-card-info'>Adaptive cruise control and traffic sign recognition optimize fuel consumption and improve traffic
flow, reducing congestion and emissions.</span>
              </Col>
              <Col className='adas-card content'>
              <div className='adas-icon'>
              <img alt='software Accessibility' src={Img3}></img>
              <span>Accessibility</span>
              </div>
           
              <span className='adas-card-info'>ADAS enhances the driving experience for all, including people with disabilities or limited mobility,
making transportation more inclusive.</span>
              </Col>
              <Col className='adas-card content'>
              <div className='adas-icon'>
              <img alt='Future of Mobility' src={Img4}></img>
              <span>Future of Mobility</span>
              </div>
              <span className='adas-card-info'>ADAS is a stepping stone towards autonomous vehicles, paving the way for a future where cars can
navigate independently, leading to increased efficiency and reduced congestion.</span>
              </Col>
              <Col className='adas-card content'>
              <div className='adas-icon'>
              <img alt='ADAS-equipped vehicles' src={Img5}></img>
              <span>Insurance Benefits</span>
              </div>
              <span className='adas-card-info'>ADAS-equipped vehicles often qualify for lower insurance premiums, incentivizing drivers to
embrace these technologies and prioritize safety.</span>
              </Col>
            </Row>
           
        </ AdasContainer>
        </div>
        </React.Fragment>
    )
}

const BigCard=({
    btnType,
    buttonLabel,
    img1,
    img2,
    alt,
 
})=>{
    return(
        <>
        <CardStyle
        wrap={true}
      >
          <Col 
          xs={{span: 24, order: 2}} 
          sm={{span: 24, order: 2}} 
          md={{span: 12, order:  1}} 
          lg={{span: 12, order:  1}} 
          xl={{span: 12, order:  1}}
          xxl={{span: 12, order:  1}}
          className= 'card-text-container left'
          gutter = {20}
          >
             <h3 className='content card-heading'>Unlocking the Future of Driving: Through ADAS</h3>
            <p className='card-content content'>
            ADAS (Advanced Driver Assistance Systems) revolutionize vehicle safety by incorporating cutting-edge
technologies. From adaptive cruise control to lane-keeping assist, ADAS utilizes sensors and also
enhance driver awareness and mitigate risks. Discover how ADAS is reshaping the way we drive,
ensuring a safer and smarter journey.
            </p>
            <h3 className='content card-heading'>Our ADAS Expertise: Pioneering Innovation</h3>
            <p className='card-content content'>
            Since 2016, our team of professionals at brigosha are committed to advancing ADAS technology,
delivering state-of-the-art solutions that redefine the driving experience. Our team of experts
combines deep industry knowledge with relentless research to develop groundbreaking ADAS
features. Experience the brigosha difference and embrace a new era of intelligent driving with our
innovative ADAS solutions.
            </p>
            
          </Col>
  
          <Col 
          xs={{span: 24, order: 1}}  
          sm={{span: 24, order: 1}}  
          md={{span: 12, order:  2}}
          lg={{span: 12, order:  2}}
          xl={{span: 12, order:  2}}
          xxl={{span: 12, order: 2}}
          className='content'
          style={{'display':'flex',justifyContent:'center'}}
          >
            {
              img1 ? <img src={img1} alt={alt} className='card-image' /> : null
            }  
           
          </Col>
      </CardStyle>
     
    </>
    )
}
const  AdasContainer = styled.div`
    height: auto;
    background-color: transparent;
    padding-top: 40px;
    padding-right: 8vw;
    padding-left: 8vw;
    text-align: center;
    padding-bottom: 80px;
 
    .back-btn {
        position:absolute;
        left:0;
        cursor:pointer;
      
    }
    .back-btn svg{
        width:30px;
        height:30px;
    }
    .Adas-header{
        font-size: calc(2vw + 15px);
        font-weight: 700;
        position:relative;
        z-index:2;
    }

    .Adas-top{
        padding-top: 20px;
        font-size: calc(1vw + 5px);
        font-weight: 400;
    }
    .adas-icon{
      display:flex;
      flex-direction:column;
      align-items:center;
      background-color:rgba(229, 245, 255, 1);
      width:170px;
      height:154px;
      justify-content:center;
      border-radius:14px;
      padding:10px;
     
      
    }
    .adas-icon span{
      font-size: calc(1vw + 3px) ;
      font-family: 'Inter', sans-serif;
      font-weight:700 !important;
      color: #0086FF !important;
    }
    .adas-card{
      display:flex;
      flex-direction:row;
      align-items:center;
    
      max-width:600px;
   justify-content:flex-start;
      gap:30px;
    }
    .adas-card-info{
      width:400px;
      font-size: calc(1vw + 5px);
      font-family: 'Inter', sans-serif;
      font-weight:400;
      text-align:left;
      color: #002546 !important;
    }

    @media screen and (max-width: 767px){
        padding-right: 8vw;
        padding-left: 8vw;

        .Adas-header{
            font-size: calc(2vw + 20px);
        }

        .Adas-top{
            font-size: calc(1vw + 10px);
        }
        .adas-card-info,  .adas-icon span{
          font-size: calc(1vw + 10px);
        }
        .adas-card{
          max-width:80vw;
        }
    }
`

const CardStyle = styled(Row)`
  padding-top: 50px;
  text-align:left;

  .card-text-container{
    height: auto;
    display: flex;
    
    flex-direction: column;
   
  }
  

  .card-image:hover{
    transform: scale(1.01);
    transition: all 0.3s ease-out;
  }

  .left{
    text-align: left;
  }

  .right{
    text-align: right;
  }

  .card-image{
    width: 80%;
  }

  .button-holder{
    width: 100%;

    
  }

  .card-content{
    font-size: calc(1vw + 5px);
    font-weight: 400;
    text-align:left;
    font-family: 'Inter', sans-serif;
    word-wrap: break-word;
  }

  .card-heading {
    width: 100%;
    margin-bottom: 24px;
    font-size: calc(2vw + 1px);
    font-weight: 700;
    font-family: 'Sofia Sans', sans-serif;
  }
  
  .card-button{
    margin-top: 20px;
    display:flex;
    justify-content:flex-start;
    align-items: center;
    font-size: calc(1vw + 3px);;
    border-color: #0086FF;
    border-radius: 4px;
    font-weight: 700;
    padding: 2vw 4vw;
  }

  .card-button:hover{
    transform: scale(1.02);
    transition: all 0.3s ease-out;
  }
 

  @media screen and (min-width: 2000px){
    .card-image{
      width: 75%;
    }
  }

  @media screen and (max-width: 767px){

    .card-content{
      font-size: calc(1vw + 10px);
      text-align: left !important;
    }

    .card-heading{
      text-align: left !important;
      font-size: calc(1vw + 15px);
    }

    .button-holder{
      justify-content: flex-start !important;
    }

    .card-button{
      padding: 3vw 6vw;
      font-size: calc(1vw + 6px);
    }
  }

  @media screen and (max-width: 425px){
    .card-button{
      padding: 4vw 6vw;
    }

    .card-image{
      width: 90%;
    }
  }
`
export default  Adas;



const Obj1 = {
    topLine: '',
    btnType: 'outline',
    headline:'adas',
    headline1: `LiDAR: LIGHT DETECTION AND RANGING`,
    description1: ` A 3D view of a vehicle's surrounds is produced using infrared light or eye-safe lasers thanks to this technical advancement.
    LiDAR helps cars recognise pedestrians and other objects on the road or close by, reducing crashes and life-threatening injuries.
    LiDAR sensors, RADAR, cameras, and a GPS receiver are the main components of a conventional LiDAR system.
    LiDAR consists of a laser wave light-emitting system, a light receiving system, and a target environment.
    
    Through the analysis of the Time-of-Flight (ToF) from the element, LiDAR employs laser light to determine how far away an external element is in its surroundings.
    At brigosha, novel LiDAR-based algorithms and applications are being created.`,
    subheadLine:'ADAS',
    headline2:`ADVANCED DRIVER ASSISTANCE SYSTEMS`,
    description2:`Allows the actuator to maintain the same speed while controlling the throttle a game-changer when traveling a long distance on a highway.`,
    headline3:`AUTOMATIC CROSS-TRAFFIC CONTROL`,
    description3:`A collection of electronic tools that help drivers with driving and parking tasks while enhancing vehicle security to lower the chance of collisions or lessen their negative impacts.
    With the help of computer chips, sensors, and cameras, ADAS achieves 360-degree vision around the vehicle, identifies nearby obstructions or driving errors, and responds properly.
    `,
    headline4:`ALERT FOR LANE DEPARTURE`,
    description4:`Strives to maintain the car in its lane and warns when it starts to veer off it.`,
    headline5:`DETECTION OF PEDESTRIANS`,
    description5:`Find any pedestrians or other objects on the road, and adjust the throttle as necessary.`,
    headline6:`Emerging Automatic Breaks`,
    description6:` Automatically deploys the emergency brakes in emergency scenarios.`,
    headline7:`ADAS Technology Stack `,
    description7:`Integrated circuits known as SoCs (systems on a chip) may connect sensors, cameras, actuators, and high-performance ECUs thanks to embedded software solutions (Electronic Control Units). ECUs are embedded systems that manage the mechanical and electrical components of a car.
    `,
    headline8:`LiDAR Technology Stack `,
    description8:` The sensor suite must contain a variety of sensors in order to reach increasing levels of autonomy. Having a sensor suite with several cameras, radar(s), and LiDAR is necessary for level-4 autonomy (s). The combined data from all
    to fully comprehend the scene, these sensors are combined and processed. Although each sensor has advantages and disadvantages, data fusion unquestionably aids in decreasing
    the uncertainty brought on by a single sensor.
    All environmental conditions, including fog, dust, snow, rain, low light, and nighttime scenarios, make cameras unreliable. Radar's long wavelength prevents it from identifying small objects. On the other hand, despite various limitations, the use of lidar renders the full scene in 3D flawlessly. Radar and LiDAR both function similarly, however
    instead of using radio waves, it transmits light pulses. The lasers' light pulses can be used for get redirected by the barriers, creating a 3D point cloud. The quantity of
    the number of points produced varies on the type of laser used.
    `,
    buttonLabel: '',
    img1: ADASIMG,
    img2:Img2,
    alt: 'iot-image',
    imgStart: ''
}

