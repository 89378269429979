import React from 'react';
import styled from 'styled-components';
import { Row, Col, Button } from 'antd';
import CareerImg from './career-banner.svg'
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { ReactComponent as Arrow } from '../PotentialCard/Vector.svg';


const scrollToTop = () => {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}

function Career() {
    return (
      // <CardStyle>
      //     <Col 
        
      //     className = 'card-text-container right'
       
      //     >
  
      //         <div 
      //         className='card-top-line darkblue highlight'
      //         >
      //           Career
      //         </div> <br/>
  
      //         {/* <div
      //         className='card-heading heading' 
      //         >
      //           brigosha <div className='gradient5'>Careers<div className='polygon5'></div></div>
                
      //         </div> */}
      //         <div className='card-heading heading'>brigosha <span className='highlight' >Careers</span> </div>
  
      //         <p 
      //         className='card-content grey content'
      //         >
      //          We have fostered, over a long period of time, a working environment that is conducive to enabling innovative working methodologies. At brigosha, there is simply no limit to the volume of work one can get involved with as long as there is a willingness to engage and if need be, fail to reach the target.</p>
      //         <Link to='/join-us' onClick={scrollToTop}>
      //           <Button type = 'default' className='card-button primary'>
      //             Join Us
      //           </Button>
      //         </Link>
      //     </Col>
  
      //     <Col  
        
      //     className='card-img-container'
      //     >
      //       <img src={CareerImg} alt={'Career'} className='card-image' />
      //     </Col>

        
      // </CardStyle>
      < CareerContainer>
        <Card {...Obj1}/>
      </CareerContainer>
    )
}
  
  
// const CardStyle = styled(Row)`
//   padding: 0 10vw;
//   min-height:100vh;
// display:flex;
// flex-direction:column;
// justify-content:center;
// align-items:center;
// gap:30px;

//   padding-top:80px;
 
//   .card {
//     color: #000D19;
//   }

//   .card-text-container{
//     height: auto;
//     font-size: 15px;
//     padding-right: 5px;
//     padding-left: 5px;
//     display:flex;
// flex-direction:column;
// justify-content:center;
// align-items: center;

    
//   }
//   .card-button:hover{
//     background-color: #0086FF !important;
//     color:white !important;
//  }
  
//   .card-content{
//     font-size: calc(1vw + 5px);
//     font-weight: 400;
//     font-family: 'Inter', sans-serif;
//     text-align:center;
//   }

//   .card-img-container{
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     width: 50%;
//     transition: all 0.3s ease-in;
//   }

//   .card-img-container:hover{
//     transform: scale(1.01);
//     transition: all 0.3s ease-out;
//   }

//   .card-image{
//     width: 100%;
   
//   }

//   .card-top-line {
//     letter-spacing: 0.25rem;
//     text-transform: uppercase;
//     font-weight: 600;
//     font-size: calc(1vw + 3px);
//     font-family: 'Sofia Sans', sans-serif;
//     align-slef:center;
//   }

//   .card-heading {
//     font-size: calc(2vw + 15px);
//     font-weight: 700;
//     display: block;
//     position:relative;
//     z-index:2;
//     font-family: 'Sofia Sans', sans-serif;
//     color:#002546 !important;
//     align-slef:center;
//   }
  
//   .card-button{
//     margin-top: 20px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     font-size: calc(1vw + 3px);
//     border-color: #0086FF;
//     border-radius: 4px;
//     font-weight: 700;
//     padding: 2vw 4vw;
//     text-transform: uppercase;
//     background-color: #0086FF !important;
//     color:white !important;
//     box-shadow: 0 20px 40px 0 rgba(35, 100, 210, 0.2);
//     align-slef:center;
//   }

//   .card-button:hover{
//     box-shadow: 0 20px 5px -10px rgba(0, 0, 0, 0.4);
//     transform: translateY(1px);
//   }



//   @media screen and (max-width: 767px){
//     padding-left: 8vw;
//     padding-right: 8vw;
//     min-height:0px;
//     .card-heading {
//       font-size: calc(2vw + 20px);
//     }

//     .card-content{
//       font-size: calc(1vw + 10px);
//     }

//     .card-top-line{
//       font-size: calc(1vw + 10px);
//     }

//     .card-image{
//       width: 90%;
//       margin-bottom: 20px;
//     }
//     .card-img-container{
//       width:100%;
//     }

//     .card-button{
//       padding: 3vw 6vw;
//       font-size: calc(1vw + 8px);
//     }

//   }

//   @media screen and (max-width: 425px){
    
//   }
// `

const CareerContainer = styled.div`
    height: max-content;
    background: transparent;
    padding-top: 80px;
    padding-bottom: 80px;
    padding-right: 8vw;
    padding-left: 8vw;
    text-align: center;
   
    @media screen and (max-width: 767px){
      padding-bottom:40px;
    }
`

const Obj1 = {
  topLine: '',
  btnType: 'primary',
  headline: `brigosha <span className='highlight' >Careers</span>`,
  description: 'We have fostered, over a long period of time, a working environment that is conducive to enabling innovative working methodologies. At brigosha, there is simply no limit to the volume of work one can get involved with as long as there is a willingness to engage and if need be, fail to reach the target.',
  buttonLabel: 'JOIN US',
  img:CareerImg,
  alt: 'career-image',
  imgStart: '',
  link:'join-us'
}
export default Career;



const Card = ({
  btnType,
  headline,
  description,
  buttonLabel,
  img,
  alt,
  imgStart,
  link,
  updateMainMenu ,
  height
}) => {
  const scrollToTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
   
}
  return (
    <CardStyle>
        <div
      className= 'card-text-container left' >
            <div className='card-heading heading' >{parse(headline)}</div>
            <p className='card-content content'>{description}</p>
            {
              link.length!==0? <div 
              className='button-holder'
              style={{justifyContent: 'flex-start' }}
            >
              <Link to={`/${link}`} onClick={
                  scrollToTop()
                }> 
                  <Button 
                    type = {btnType} 
                    className='card-button'
                    style ={{
                      color:  'white' ,
                      backgroundColor: 'transparent',
                      
                    }}
                  >
                    {buttonLabel}<Arrow></Arrow>
                  </Button>
                </Link>
            
              
            </div>:null}
        </div>
        <div className='content career-image'
style={{'display':'flex',justifyContent:'center',alignItems:'center'}}
>
{
  img ? <><img src={img} alt={alt} className='card-image' style={{'height':`${height}%`}} />  </> :null




}

</div>
    </CardStyle>
  )
};


const CardStyle = styled(Row)`
  padding-top: 10px;
  display:flex;
  flex-direction:row;
  width:100%;
 
  background-color:transparent;
  .card-text-container{
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width:50%;
  }
  .card-image{
   padding-bottom:10px;
   width:86% !important;

  object-fit:fill;
  transition: all 0.3s ease-out;

   
  
  }
  .career-image{
    width:50%;
  }
  .card-image:hover{
    transform: scale(1.01);
    transition: all 0.3s ease-out;
  }


  .left{
    text-align: left;
  }

  .right{
    text-align: right;
  }

  .card-image{
    width: 70%;
  }

  .button-holder{
    width: 100%;
    display: flex;
    align-items: center;
  }

  .card-content{
    font-size: calc(1vw + 5px);
    font-weight: 400;
    color:#111111;
    word-wrap: break-word;
    text-align:left;
  
  }

  .card-heading {
    width: 100%;
    margin-bottom: 24px;
    font-size: calc(2vw + 10px);
    font-weight: 700;
  }
  
  .card-button{
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: calc(1vw + 3px);;
    border-color: #0086FF;
    border-radius: 4px;
    font-weight: 700;
    gap:10px;
    padding: 2vw 4vw;
    background-color: #0086FF !important;
    color:white !important;
    box-shadow: 0 20px 40px 0 rgba(35, 100, 210, 0.2);
  }

  .card-button:hover{
     
    background-color: #0086FF !important;
    color:white !important;
    box-shadow: 0 20px 5px -10px rgba(0, 0, 0, 0.4);
     transform: translateY(1px);
 }
  .card-button:hover{
    // transform: scale(1.02);
    // transition: all 0.3s ease-out;
  }

  @media screen and (min-width: 2000px){
    .card-image{
      width: 70%;
    }
  }

  @media screen and (max-width: 767px){
    gap:10px;
    .card-content{
      font-size: calc(1vw + 10px);
      text-align: center !important;
    }
    .card-text-container{
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width:100%;
    }
  .career-image{
    padding-top:20px;
    width:100%;
    display:flex;
    justify-content:center;
  }
    .card-image{
      width: 65%;
    }
    .card-heading{
      text-align: center !important;
      font-size: calc(2vw + 20px);
    }

    .button-holder{
      display:flex;
      justify-content: center !important;
    }

    .card-button{
      padding: 3vw 6vw;
      font-size: calc(1vw + 6px);
    }
  }

  @media screen and (max-width: 425px){
    .card-button{
      padding: 4vw 6vw;
    }

    .card-image{
      width: 70%;
    }
  }

 
`