import 'antd/dist/antd.min.css';
import { Col, Row } from 'antd';
import React, { useEffect,useRef ,useState} from 'react';
import styled from 'styled-components';
import Techsvg from './Technologies.svg';
import Projectsvg from './Projects.svg';
import Specialistsvg from './Specialist.svg';
import OuterCircle from './Polygon.svg';
import Count from './counter';



function Achievement() {
    // const counterRef = useRef(null);
    const [isDivVisible, setIsDivVisible] = useState(false);
  
    useEffect(() => {
        const handleScroll = () => {
          const divElement = document.getElementById('targetDiv');
          const { offsetTop } = divElement;
     
          if (window.scrollY+360 >= offsetTop) {
            setIsDivVisible(true);
          } else {
            setIsDivVisible(false);
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

  
    return (
        <AchievementWrapper id='targetDiv'>
            <Row>
                <Col span = {24}>

                {/* {isDivVisible?
                <p>visible</p>:
                <p>not visible</p>} */}
                    {/* <p className='achievement-bold-text  darkblue highlight'>THE COMPANY AT A GLANCE</p> */}
                    {/* <div className='achievement-heading heading'>Our <div className='gradient3'>Achievements<div className='polygon3' style={{'width':'-webkit-available-space'}}></div></div> in Numbers </div> */}

                    <div className='achievement-heading heading' ><span className='highlight' >brigosha</span> in Numbers </div>
                    <p className='achievement-body content grey'>            
                    Our numbers not only compliment our current potential but also the incredible journey that has got us to where we are today.
                    </p> 
                </Col>
            </Row>
            <Row className='achievement-image'> 
          
                <Col 
                   
                    className='achievement-project content grey'
                >
                      {isDivVisible?
                      
                    <Count data={{number:'700',label:'Projects Delivered',isMore:true,duration:'1'}}></Count>
                  
                    : 
                    null}
                </Col>
              
                <Col 
                   
                    className='achievement-project content grey'
                >
                      {isDivVisible?
                      
                    <Count data={{number:'200',label:'Specialist',isMore:true,duration:'30'}}></Count>
                    :
                    null}
                  
                </Col>
               
                <Col 
                   
                    className='achievement-project content grey'
                >
                       {isDivVisible?
                    <Count data={{number:'200',label:'Technologies Used',isMore:true,duration:'30'}}></Count>
                    :
                    null}
                  
                </Col>
              
                <Col 
                   
                    className='achievement-project content grey'
                >
                        {isDivVisible?
                    <Count data={{number:'5',label:'Offices',isMore:false,duration:'18'}}></Count>
                    : 
                    null}
                  
                </Col>
                
                <Col 
                   
                    className='achievement-project content grey'
                >
                      {isDivVisible?
                    <Count data={{number:'400',label:'Team',isMore:true,duration:'25'}}></Count>
                    :
                    null}
                  
                </Col>
               
            

               
                <Col 
                   
                    className='achievement-project content grey'
                >
                      {isDivVisible? 
                    <Count data={{number:'12',label:'Years in Operation',isMore:false,duration:'20'}}></Count>
                    :
                    null}
                  
                </Col>
              
                  </Row>
          
         
            
        </AchievementWrapper>
    )
}

const AchievementWrapper = styled.div`
    padding-top: 80px;
    padding-bottom: 40px;
    padding-left: 15vw;
    padding-right: 15vw;

   min-height:max-content;
 
   
    .achievement-bold-text{
        letter-spacing: 0.25rem;
        text-transform: uppercase;
        font-weight: 600;
        font-size: calc(1vw + 3px);
        text-align: center;
        font-family: 'Sofia Sans', sans-serif;
    }
    .achievement-div{
        font-size:18px;
    }
    .content-div{
        position:absolute;
        left:50%;
        top:50%;
        transform:translate(-50%,-50%);
        position:relative;
       
    }

    .achievement-heading{
        text-align: center;
        font-size: calc(2vw + 15px);
        font-weight: 700;
        margin-top: 30px;
        position:relative;
        z-index:2;
        font-family: 'Sofia Sans', sans-serif;
    }

    .achievement-body{
        text-align: center;
        font-weight: 400;
        font-size: calc(1vw + 5px);
        font-family: 'Inter', sans-serif;
    }

    .achievement-image{
        padding-top: 50px;
        margin-left: 60px;
        margin-right: 60px;
        z-index:2;
       display:grid;
       grid-template-columns: repeat(3,1fr);
      gap: 50px;
      grid-template-rows: auto;
      justify-content: space-evenly;
  justify-items: center;
  align-content: space-evenly;
  align-items: center;
  min-height:300px;
  position:relative;
  

    }
    .achievement-image > div{
     
        height:320px;
        width:300px;
     margin:5px;
       background:url(${OuterCircle}) no-repeat center;
       background-size:300px;
      
  
     
    
    }


    .achievement-project, .achievement-specialist, .achievement-technology{
        text-align: center;
        padding-left: 20px;
        padding-right: 20px;
        font-size: calc(1vw + 5px);
        font-weight: 400;
        letter-spacing: 0.1em;
        font-family: 'Sofia Sans', sans-serif;
        transition: all 0.3s ease-in;
     
    }

    .achievement-project:hover, .achievement-specialist:hover, .achievement-technology:hover{
        transform: scale(1.02);
        transition: all 0.3s ease-out;
        

    }
    

 

    .achievement-count{
      
        font-weight: 700;
        font-size: calc(2vw + 8px);
        margin-bottom: 5px;
        color:#0086FF;
        font-family: 'Sofia Sans', sans-serif;
    }
    .content:hover .achievement-count{
        color:#1e375a;
    }

    @media screen and (max-width: 1100px){
        .achievement-image > div{
     
            height:280px;
            width:280px;
            background-size:230px;

        
        }
        .achievement-image{
            grid-template-columns: repeat(2,1fr);
            gap:50px;
        }
    }

    @media screen and (max-width: 767px){
        padding-left: 10vw;
        padding-right: 10vw;

        .achievement-bold-text{
            font-size: calc(1vw + 10px);
        }

        .achievement-heading{
            font-size: calc(2vw + 20px);
        }
        .achievement-image > div{
     
            height:260px;
            width:260px;
            background-size:200px;

        
        }
        .achievement-div {
            font-size: 12px;
        }

        .achievement-body{
            font-size: calc(1vw + 10px);
        }

        .achievement-count{
            font-size: calc(2vw + 15px);
        }

        img{
            width: 30%;
        } 

        .achievement-project, .achievement-specialist, .achievement-technology{
            margin-top: 10px;
            margin-bottom: 10px;
            font-size: calc(1vw + 10px);
        }

        .achievement-image{
            padding-top: 50px;
         
            grid-template-columns: repeat(2,1fr);
            gap:0px;
        }
    }

    @media screen and (max-width: 530px){
        padding-left: 8vw;
        padding-right: 8vw;
        .achievement-image > div{
     
            height:180px;
            width:180px;
            background-size:150px;

        
        }
        .achievement-project, .achievement-specialist, .achievement-technology{
            margin-top: 10px;
            margin-bottom: 10px;
            font-size: 6px;
        }
        .achievement-image{
            grid-template-columns: repeat(2,1fr);
            gap:0px;
        }
    }
    @media screen and (max-width: 530px){
      
    
        .achievement-image{
            grid-template-columns: repeat(1,1fr);
            gap:0px;
        }
    }

`

export default Achievement;
