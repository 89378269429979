import React from 'react';
import styled from 'styled-components';
import Img from './ceoImg.svg';

function Message() {
    return (
        <>
        {/* <Info>
            <div className='info'>
              
            At brigosha, we offer Open Door, where any associate can speak with the CEO directly to exchange fresh ideas.
             </div>
        </Info> */}
        <div style={{maxHeight:'600px','position':'relative'}}>
        <MessageWrapper>
            <div className='border-left'/>
            <span className='message-topline darkblue  highlight'>
                MESSAGE FROM THE CEO
            </span><br/><br/>
            <span className='message-quote content'>
                “brigosha will continue to innovate in response to the fast changing world. We strive to work on pioneering technologies and build a better future with the support of our customers, partners, shareholders and associates.”
            </span><br/><br/>
            <img src={Img} alt='Ceo'/>
        </MessageWrapper>
        </div>
     </>
    )
}

export default Message;

const MessageWrapper = styled.div`
    padding-left: 10vw;
    padding-right: 10vw;
    position: relative;
    top: 40px;
    left: 0;
    height: 350px;



    .border-left{
        position: absolute;
        left: 6vw;
        // top: -2vw;
        height: 8px;
        width: 25vw;
        background: linear-gradient(-90deg, #0DFFEF 5.21%, #0086FF 83.33%);
        transform-origin: top left;
        transform: rotate(90deg);
    }
    @media screen and (min-width: 2000px){
        .border-left{
            width: 15vw;
        }
    }
    @media screen and (max-width: 2000px){
        
        .border-left{
            width: 20vw;
        }
    }

    .message-topline{
        letter-spacing: 0.25rem;
        text-transform: uppercase;
        font-weight: 600;
        font-size: calc(1vw + 3px);
        font-family: 'Sofia Sans', sans-serif;
        
    }

    .message-quote{
        font-size: calc(1vw + 12px);
        font-weight: 400;
        font-family: 'Inter', sans-serif;
    }

    img{
        width: 20%;
    }

    @media screen and (max-width: 1300px){
        .border-left{
            // top: -2vw;
            width: 30vw;
        }
    }

    @media screen and (max-width: 767px){
        .message-topline{
            font-size: calc(1vw + 10px);
        }

        img{
            width: 30%;
        }

        .message-quote{
            font-size: calc(1vw + 15px);
        }

        .border-left{
            top: -1vw;
            width: 45vw;
        }
    }

    @media screen and (max-width: 600px){
        .border-left{
            top: -1vw;
            height: 5px;
            width: 60vw;
        }
    }    

    @media screen and (max-width: 460px){
        img{
            width: 40%;
        }

        .border-left{
            top: 0;
            height: 5px;
            width: 70vw;
        }
    }
    @media screen and (max-width: 400px){
      

        .border-left{
            top: 0;
            height: 5px;
            width: 80vw;
        }
    }
    @media screen and (max-width: 370px){
      

        .border-left{
            top: 0;
            height: 5px;
            width: 96vw;
        }
    }
`
const Info =styled.div`
padding-left: 10vw;
    padding-right: 10vw;
    position:relative;
    max-height:500px;
    .info{
      
        text-align:center;
        display: flex;
   justify-content: center;
        color:#029FFB;
        padding: 35px 15px;
        border: 3px solid #04AFF9;
        border-style:dashed;
        border-radius:5px;
       
        font-size:28px;
        font-weight:400;
        line-height:40px;
        font-family: 'Sofia Sans', sans-serif;
        transform-origin: center; /* Set the rotation origin to the center */
        transition: transform 0.3s;  
       

    }

   
    @media screen and (max-width: 600px){
        .info{
      
            text-align:center;
            display: flex;
    justify-content: center;
            color:#029FFB;
            padding: 30px 10px;
            border: 3px solid #04AFF9;
            border-style:dashed;
            border-radius:5px;
           
            font-size:20px;
            font-weight:700;
            line-height:40px;
        
         
           
    
        }
    }  
`;