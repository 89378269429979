import React, { useEffect } from 'react';
import './carstyle.css'
import car from './carImage.svg';
import tyre from './wheelImage.svg';
import Lottie from 'react-lottie';
import animationData from './animation.json'; 
const Overlay=()=>{


  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  useEffect(()=>{
    document.body.style.overflow='hidden';

    setTimeout(function(){
      document.getElementById('lottie-car').style.display='none';
    },3000)
    
    return()=>{
      document.body.style.overflow='scroll';
    }
  })
   


  
  
  const handleAnimi=()=>{
    var car=document.getElementById('carobj');
    document.getElementById('ov').classList.add('move')
    document.getElementById('wave').classList.remove('circle-1');
    document.getElementById('wifi1').style.display='none';
    document.getElementById('wifi2').style.display='none';
    if(window.outerWidth<=1000){
      car.classList.add('animimobile');
    }else{
    car.classList.add('animi');
    }
  
  
    setTimeout(function(){
    var modal=document.getElementById('modal1');
    modal.classList.remove('open')
      modal.parentElement.classList.remove('open')
      document.body.style.overflow='auto';
  },2000);	
 
 

  }
    return(
      <div className='animi-overlay open' id='ov'>
      <div class='animi-modal open' id='modal1'  onClick={handleAnimi}>
        <div id="lottie-car">
        <Lottie options={defaultOptions} isClickToPauseDisabled={true}  className='animation-file'/>
        </div>
     
    <div class="car" id="carobj">
        <div className='highway'>
      <img src={car} alt=""  style={{cursor:'pointer'}} className='car-image'/>
   
    <div class="wheel" id='wheelid'>
      <img src={tyre} alt="LiDAR Mapping Services" class="frontwheel"  style={{cursor:'pointer'}} />

      <img src={tyre} alt="cloud Services" class="backwheel"  style={{cursor:'pointer'}}/>
    
    </div>
    </div>
    <div class="circle-1" id='wave'></div>
    <div class="wifi-symbol" id='wifi1'>
  <div class="wifi-circle first"></div>
  <div class="wifi-circle second"></div>
  <div class="wifi-circle third"></div>
</div>
<div class="wifi-symbol2" id='wifi2'>
  <div class="wifi-circle first"></div>
  <div class="wifi-circle second"></div>
  <div class="wifi-circle third"></div>
</div>
   </div>
   </div>
   </div>           
      
    )
}

export default Overlay;