
import React,{useEffect,useState} from 'react';
import styled from 'styled-components';
import {useNavigate} from 'react-router-dom';
import { useParams } from 'react-router-dom';
import NoGallery from './no-gallery.gif'
import { ImageGroup, Image } from 'react-fullscreen-image'
import { LoadingOutlined,PlayCircleOutlined } from '@ant-design/icons';
import { Tabs } from 'antd';
import { Modal  } from 'antd';
import { ReactComponent as Back } from '../../../../../assets/back-icon.svg';

import { connect } from 'react-redux';
import { getEvent } from '../../../redux/action';

function EventGallery({
  getEvent,
  selectedEvent
}) {

    const { TabPane } = Tabs;
    
    const [loader,setLoader]=useState(false)
    const params=useParams();
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [size,setSize]=useState(0)

    useEffect(()=>{
     

        let param={eventId:params?.eventId?.split('=')[1]}

        if(!selectedEvent|| Object.keys(selectedEvent).length==0){
          setLoader(true)
          }
      getEvent(param,()=>{
          setLoader(false)
      })


    },[])

    useEffect(() => {
      const handleResize = () => {
        const windowWidth = window.innerWidth;
        if (windowWidth <500) {
          setSize(300)
        }else{
          setSize(Math.floor(windowWidth/3))
        }
      };
  
      handleResize(); // Initial call to set the initial slidesToShow value
  
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
    
    const openModal = (video) => {
      setSelectedVideo(video);
      setIsModalOpen(true);
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
      setSelectedVideo(null);
    };
    const handleBackBtn=()=>{
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      window.history.back();
  }
 
   
  
   
    return (
        <React.Fragment>
          
          
           < EventGalleryContainer>
                



                    <div style={{'display':'flex','alignItems':'center','justifyContent':'flex-start',position:'relative',gap:'10px'}}>
           <Back className='back-btn' onClick={handleBackBtn} />
        
            </div>
            <div  className='EventGallery-header heading'><div className='highlight'>Gallery</div></div>
                    <br/>
                  
          
                    {loader?<div  style={{'minHeight':'280px','display':'flex',justifyContent:'center','alignItems':'center'}}>
                    <LoadingOutlined style={{fontSize: "3em"}}/>
                </div> :  
                
                <Tabs defaultActiveKey="images">
                <TabPane tab="Images" key="images" >
                  
                 { selectedEvent===undefined ||selectedEvent?.imageArray?.length===0||selectedEvent?.imageArray==undefined?<div style={{'display':'flex','flexDirection':'column',justifyContent:'center',alignItems:'center'}}> 
                <img alt='gallery' src={NoGallery} width={250} height={250} />
                <span><b>Uh!Uh! No Gallery Found!</b></span>
                
            </div>:
             <ImageGroup>
             <ul className="images">
           {selectedEvent.imageArray.map((i,key) => (
            <li key={key}>
           <Image
             src={i}
             alt=""
             style={{
               position: 'absolute',
               top: 0,
               left: 0,
               right: 0,
               bottom: 0,
               height: '100%',
               width: '100%',
               
               objectFit: 'cover',
              
             }}
           />
         </li>
       ))}
     </ul>
             </ImageGroup>
            
            }
                </TabPane>
                <TabPane tab="Videos" key="videos">
                { selectedEvent===undefined ||selectedEvent?.videoArray?.length===0||selectedEvent?.videoArray==undefined?<div style={{'display':'flex','flexDirection':'column',justifyContent:'center',alignItems:'center'}}> 
                <img alt='gallery' src={NoGallery} width={250} height={250} />
                <span><b>Uh!Uh! No Videos Found!</b></span>
                
            </div>:
                 <div style={{display:'grid',  gridTemplateColumns: `repeat(auto-fit, minmax(${size}px, 1fr))`,rowGap:'20px',columnGap:'10px'}} className='video-grid'>
{selectedEvent.videoArray?.map((video,key) => (
  <div key={key} className="video-item" onClick={() => openModal(video)} style={{width:`${size}px`,display:'flex',justifyContent:'center',borderRadius:"3px",position:'relative',cursor:'pointer'}}>
   <PlayCircleOutlined  style={{position:'absolute',top:'50%',left:'50%',transform:"translate(-50%,-50%)",fontSize:'50px',color:'gray'}}/>
      <video style={{width:'98%',height:"350px",
    objectFit: 'none'}} >
          <source src={video} type="video/mp4" />

        </video>
  
  </div>

))}
</div>
}
                </TabPane>
              </Tabs>
                
               
            
        

                }

{selectedVideo && (

<Modal   
visible={isModalOpen} footer={null} closable={true} centered onCancel={closeModal}
width={"90vw"}
bodyStyle={{ height: 'auto', overflow: 'auto',display:'flex',justifyContent:'center' }}
       >
        
  <div className="modal-content" style={{alignSelf:'center'}}>
  
    <video   controls style={{width:'100%',height:"100%"}} >
        <source src={selectedVideo} type="video/mp4" />
      </video>

  </div>
</Modal>

)}
                    
                </ EventGalleryContainer>
            
    
        </React.Fragment>
        
    )
}


const  EventGalleryContainer = styled.div`
    height: auto;
    background-color: white;
    padding-top: 40px;
    padding-right: 10vw;
    padding-left: 10vw;
    text-align: center;
    padding-bottom: 80px;
    min-height:70vh;
    .div3{
      display:grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 10px;
    }
   
    .card-wrapper{
        width: 100%;
        max-width: 360px;
        cursor:pointer;
        background-color: #f5f5f5;
        padding: 20px;
        position: relative;
        margin:0 auto 30px;
        border-radius: 4px;
       }
    .back-btn {
     
        cursor:pointer;
  
    }
    .back-btn svg{
        width:30px;
        height:30px;
    }
    .EventGallery-header{
        font-size: calc(2vw + 15px);
        font-weight: 700;
        position:relative;
        z-index:1;
    }
    .EventGallery-subhead{
        font-size: calc(1vw + 15px);
        font-weight: 700;
    }
   
    .EventGallery-top{
        padding-top: 20px;
        font-size: calc(1vw + 5px);
        font-weight: 400;
    }
    * {
    box-sizing: border-box;
    margin: 0;
   
  }
  .container {
    padding: 15px;
  }
  
  ul.images {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    grid-gap: 15px;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  ul.images > li {
    position: relative;
    padding-top: 66%;
  }
  
  ul.images > li img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
   
  }
  div.fullscreen-group::before{
background:white !important;
z-index:6;
  }
 
  .fullscreen-group--expanded .fullscreen-image> img{
    
    height: 98%!important;
    width: 100% !important;
    position:absolute !important;
    top:50% !important;
    left:50% !important;
 
    transform:translate(-50%,-50%) !important;
    object-fit:contain !important;
 
 
  }

  @media screen and (max-width: 767px){
    padding-right: 8vw;
    padding-left: 8vw;

    ul.images {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
      grid-gap: 15px;
      list-style: none;
      margin: 0;
      padding: 0;
    }
    .EventGallery-header{
        font-size: calc(2vw + 20px);
    }

    .EventGallery-top{
        font-size: calc(1vw + 10px);
    }
}
  
  @media screen and (max-width: 567px) {
    ul.images {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
   .video-grid{
    place-items:center;
   }
    
  }
  
   
  

  // .container {
  //   display: block;
  //   width: 90%;
  //   height: 100vh;
  //   margin: 0px auto;
  //   overflow-y:scroll;
  //   position: relative;
  //   -ms-overflow-style: none;  /* IE and Edge */
  //   scrollbar-width: none;
  // }
  // .container::-webkit-scrollbar {
  //   display: none;
  // }
  // .row {
  //   position: absolute;
  //   width: 100%;
  //   -webkit-transition: all 0.5s;
  //   transition: all 0.5s;
  // }
  // .col1 {
  //   width: 33%;
  //   height: auto;
  // }
  // .col2 {
  //   width: 33%;
  //   height: auto;
  //   margin-left: calc(33% + 10px);
  // }
  // .col3 {
  //   width: 33%;
  //   height: auto;
  //   margin-left: calc(66% + 20px);
  // }
  // .gallery {
  //   position: relative;
  //   z-index: 4;
  // }
  // .gallery .gallery_item {
  //   padding-bottom: 10px;
  //   text-align: left;
  // }
  // .gallery .gallery_item img {
  //   width: 100%;
  //   height: auto;
  // }
  
  
  
  
// #images {
//     display:flex;
//     justify-content:center;
//     align-items:center;
//   }
  
//   .hero {
//     padding:0 20px 0 0 !important;

//     margin: 0 !important;
//   }
  
//   .hero img {
//     width: 100%;
//     margin-bottom: 20px;
//     position: relative;
//   }
  
//   .hero img:last-child {
//     margin: 0;
//   }
  
  
  
  
  
`

   
const mapStateToProps = state => {
  const {
      selectedEvent
  }  = state.we;

  return { selectedEvent }
}

const mapDispatchToProps = dispatch =>  ({
  getEvent: (params,successCallback) => dispatch(getEvent(params,successCallback)),
 
})

export default connect(mapStateToProps, mapDispatchToProps)(EventGallery);







