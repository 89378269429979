import React from 'react';
import styled from 'styled-components';
import { Row, Col, Button } from 'antd';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import { ReactComponent as Arrow } from '../we/subpages/ourStory/PotentialCard/Vector.svg';
const Card = ({
    btnType,
    headline,
    description,
    buttonLabel,
    img,
    alt,
    imgStart,
    link,
    updateMainMenu ,
    height
  }) => {
    const scrollToTop = () => {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
     
  }
    return (
      <CardStyle
        wrap={true}
      >
          <Col 
          xs={{span: 24, order: 2}} 
          sm={{span: 24, order: 2}} 
          md={{span: 12, order: imgStart === 'start' ? 2 : 1}} 
          lg={{span: 12, order: imgStart === 'start' ? 2 : 1}} 
          xl={{span: 12, order: imgStart === 'start' ? 2 : 1}}
          xxl={{span: 12, order: imgStart === 'start' ? 2 : 1}}
          className={imgStart === 'start' ? 'card-text-container left' : 'card-text-container left'}
          gutter = {20}
          >
              <div className='card-heading heading' >{parse(headline)}</div>
              <p className='card-content content'>{description}</p>
              {
                link.length!==0? <div 
                className='button-holder'
                style={{justifyContent: 'flex-start' }}
              >
                <Link to={`/technology/${link}`} onClick={
                    scrollToTop()
                  }> 
                    <Button 
                      type = {btnType} 
                      className='card-button'
                      style ={{
                        color:  'white' ,
                        backgroundColor: 'transparent',
                        
                      }}
                    >
                      {buttonLabel}<Arrow></Arrow>
                    </Button>
                  </Link>
              
                
              </div>:null}
          </Col>
          <Col
  
  xs={{ span: 24, order: 1 }}
  sm={{ span: 24, order: 1 }}
  md={{ span: 12, order: imgStart === 'start' ? 1 : 2 }}
  lg={{ span: 12, order: imgStart === 'start' ? 1 : 2 }}
  xl={{ span: 12, order: imgStart === 'start' ? 1 : 2 }}
  xxl={{ span: 12, order: imgStart === 'start' ? 1 : 2 }}
  className='content'
  style={{'display':'flex',justifyContent:'center',alignItems:'center'}}
  >
  {
    img ? <><img src={img} alt={alt} className='card-image' style={{'height':`${height}%`}} />  </> :null
  

  
  
  }
  
  </Col>
      </CardStyle>
    )
  };
  
  
  const CardStyle = styled(Row)`
    padding-top: 10px;
   
    background-color:transparent;
    .card-text-container{
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .card-image{
     padding-bottom:10px;
     width:86% !important;

    object-fit:fill;
    transition: all 0.3s ease-out;
  
     
    
    }
    .card-image:hover{
      transform: scale(1.01);
      transition: all 0.3s ease-out;
    }
  

    .left{
      text-align: left;
    }
  
    .right{
      text-align: right;
    }
  
    .card-image{
      width: 70%;
    }
  
    .button-holder{
      width: 100%;
      display: flex;
      align-items: center;
    }
  
    .card-content{
      font-size: calc(1vw + 5px);
      font-weight: 400;
      color:#111111;
      word-wrap: break-word;
      text-align:left;
    
    }
  
    .card-heading {
      width: 100%;
      margin-bottom: 24px;
      font-size: calc(2vw + 10px);
      font-weight: 700;
    }
    
    .card-button{
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: calc(1vw + 3px);;
      border-color: #0086FF;
      border-radius: 4px;
      font-weight: 700;
      gap:10px;
      padding: 2vw 4vw;
      background-color: #0086FF !important;
      color:white !important;
      box-shadow: 0 20px 40px 0 rgba(35, 100, 210, 0.2);
    }
  
    .card-button:hover{
       
      background-color: #0086FF !important;
      color:white !important;
      box-shadow: 0 20px 5px -10px rgba(0, 0, 0, 0.4);
       transform: translateY(1px);
   }
    .card-button:hover{
      // transform: scale(1.02);
      // transition: all 0.3s ease-out;
    }
  
    @media screen and (min-width: 2000px){
      .card-image{
        width: 70%;
      }
    }
  
    @media screen and (max-width: 767px){
  
      .card-content{
        font-size: calc(1vw + 10px);
        text-align: left !important;
      }
      .card-image{
        width: 65%;
      }
      .card-heading{
        text-align: left !important;
        font-size: calc(2vw + 20px);
      }
  
      .button-holder{
        justify-content: flex-start !important;
      }
  
      .card-button{
        padding: 3vw 6vw;
        font-size: calc(1vw + 6px);
      }
    }
  
    @media screen and (max-width: 425px){
      .card-button{
        padding: 4vw 6vw;
      }
  
      .card-image{
        width: 70%;
      }
    }
  
   
  `
  export default Card;