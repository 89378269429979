import React ,{useState,useEffect}from 'react';
import styled from 'styled-components';
import Img1 from './icons/botg.svg'
import Img2 from './icons/offway.svg'
import Img3 from './icons/yca.svg'
import { Row, Col} from 'antd';
import { Link } from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import Img4 from './icons/conference.svg';
import Img5 from './icons/broadcast.svg'
import Img6 from './icons/transcoding.svg'
import {ReactComponent as Arrow} from './icons/Vector.svg';

import bg2 from '../../../../../assets/bg2.svg'
import { ViewMoreButton } from '../../../../we/subpages/joinUs/styles';

function BCSResearch() {
    const navigate = useNavigate();
    const [values, setValues] = useState(0);
    const [reset,setReset]=useState(false);
    const length=ips.length;
    const changeValuesDown = (prev) => {
        setValues((prev) => {
            if(prev == 0){
                return prev+length-1;
            }
            else{
                return prev-1;
            }
        })
    }

    const changeValuesUp = () => {
        setValues((prev) => {
            if(prev == length-1){
                return prev-(length-1);
            }
            else{
                return prev+1;
            }
        })
    }
    const setCustomValue=(value)=>{
        setValues(value);
    }

    useEffect(() => {
       if(!reset){
        const mount = setInterval(() =>{
            setValues((prev) => {
                if(prev == length-1){
                    return prev-(length-1);
                }
                else{
                    return prev+1;
                }
            })
        }, 5000)

        return () => clearInterval(mount);
    }
    }, [reset])
    return (

        <div   style={{
            background:`url(${bg2}) center no-repeat`,backgroundSize:'cover',
            backgroundPositionY:'-300%',
        
            backgroundColor:'#F3F8FD',
        
            }}>
        <BCSResearchContainer>
        <div className='Research-header heading'>Industry focused <div className='highlight'>IoT Development</div></div>
        <br/>
        <br/>
        <p className='content Research-top'>
        BOTG: Redefine freedom on two wheels! Unleash exhilarating self-drives with keyless entry, cloud connectivity, and elite e-bikes. Join a vibrant community of adventure enthusiasts. Ignite boundless journeys and embrace convenience and excitement in perfect harmony.
        </p>
        <div>
        <Row style={{ display: "grid",
  gridTemplateColumns: "repeat(auto-fit, minmax(400px, 1fr))",
  justifyContent: "center",
  placeItems:'center',
  gap: "10px"}}>

  <Col  className="team-card">
    <Cards {...Obj[0]} />
  </Col>
  {/* <Col  className="team-card">
    <Cards {...Obj[1]} />
  </Col>
  <Col  className="team-card">
    <Cards {...Obj[2]} />
  </Col> */}
</Row>

            </div>

            <div>
            <br/>
           <br/>
            <div className='Research-header heading'>Research & <div className='highlight'>Development</div></div>
         
            {/* <Card {...Obj1}/>
            
            <Card {...Obj2}/>
            <Card {...Obj3}/> */}

            <ValuesCarousal >
                <Row style={{display:'flex',justifyContent:'space-between'}}
                >
                    <Col 
                        xs={{span: 24, order: 2}} 
                        sm={{span: 24, order: 2}} 
                        md={{span: 12, order: 1}} 
                        lg={{span: 12, order: 1}} 
                        xl={{span: 12, order: 1}} 
                        xxl={{span: 12, order: 1}}
                        className='description'
                    >
                     
                        <div className='carousal'    onMouseEnter={() => {setCustomValue(values);setReset(!reset)}}
                onMouseLeave={() => setReset(!reset)} >
                            <span className='header heading'>{ips[values].heading}</span>
                            <p className='paragraph content grey'>{ips[values].description}</p>
                            <div 
                className='button-holder'
                style={{justifyContent: 'flex-start',maxWidth:'200px' }}
              >
                            <Link to={`${ips[values].link}`} onClick={()=>{
                        document.body.scrollTop = 0; // For Safari
                        document.documentElement.scrollTop = 0;
                                                    }} style={{width:'100px'}}> 
                                <ViewMoreButton 
                                className=' card-button' 
                               
                                >
                                Know More
                                </ViewMoreButton>
                                </Link>
                        </div>
                        </div>
                    </Col>
                    <Col 
                        xs={{span: 24, order: 1}} 
                        sm={{span: 24, order: 1}} 
                        md={{span: 12, order: 2}} 
                        lg={{span: 12, order: 2}} 
                        xl={{span: 12, order: 2}} 
                        xxl={{span: 12, order: 2}}
                        className='image-container'
                        onMouseEnter={() => {setCustomValue(values);setReset(!reset)}}
                        onMouseLeave={() => setReset(!reset)}
                    >
                        <img src={ips[values].img} alt='Carousal Banner' className='carousal-image'/>
                    </Col>
                  
                </Row>   
                <Row className='arrow-button '>
                    <Col  className='lines'>
                        {/* <span className='left-outlined' onClick={() => changeValuesDown()}>
                           <ArrowLeft/>
                        </span> */}

                      {ips.map((ele,index)=>{
                      
                        return <div className={values === index ? 'blueline' : 'greyline'} onClick={()=>{setCustomValue(index);setReset(!reset)}} 
                        onMouseLeave={() => setReset(!reset)}></div>
                      })}
                        
                        
{/*                         
                        <span className='right-outlined' onClick={() => changeValuesUp()}>
                         <ArrowRight/>
                        </span> */}
                    </Col>
                </Row>
            </ValuesCarousal>
           
            </div>
            
        </BCSResearchContainer>
       </div>
       
    )
}

const BCSResearchContainer = styled.div`
    height: auto;
 
     background-color: transaprent;
    padding-top: 80px;
    padding-right: 8vw;
    padding-left: 8vw;
    text-align: center;
    

    .Research-header{
        font-size: calc(2vw + 15px);
        font-weight: 700;
        position:relative;
        z-index:2;
    }

    .Research-topline{
        padding-top: 10px;
        font-size: calc(1vw + 10px);
        font-weight: 400;
    }
    .Research-top{
       
        font-size: calc(1vw + 6px);
        font-weight: 400;
    }
    .row2{
        gap:120px;
        display: flex;
  justify-content: center;
  place-items:center;
  margin-top:30px;
    }
    


    @media screen and (max-width: 767px){
        padding-right: 8vw;
        padding-left: 8vw;

        .Research-header{
            font-size: calc(2vw + 20px);
        }

        .development-top{
            font-size: calc(1vw + 10px);
        }
        .row2{
            margin-top:10px;
            gap:10px;
            display: flex;
            justify-content: center;
            place-items:center;
         
        }
        .Research-top{
       
            font-size: calc(1vw + 10px);
            font-weight: 400;
        }
    }
`

const Cards = ({ name, img ,link,}) => {
    const navigate = useNavigate();
    const scrollToTop = () => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    
    }
    
    return(
        <CardStyle  >
           
            <Link to={`/technology/${link}`} onClick={scrollToTop} className='card'>
            <img src={img} alt={name}  className='card-image' />
            <div style={{display:'flex',flexDirection:'column',alignItems:'center',gap:'5px'}}>
            <h1 className='title heading'>{name} </h1>
            {/* <h2 className='explore heading'>Explore </h2> */}
           
            </div>
            
                
                </Link>
        </CardStyle>
    )
}

const CardStyle = styled.div`
    width:354px;
    height:92px;
    margin-bottom: 10px;
    transition: all 0.5s ease-out;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:center;
    text-align:left;
    
    &:hover{
        background-color:rgba(0, 134, 255, 1);
        
    }
  
    background-color:white;
    border-radius:10px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.11);
    padding:15px 15px;

    .card{
        width:100%;
        height:100%;
        display:flex;
        flex-direction:row;
        align-items: center;
        gap:10px;
      
    }
    &:hover  .title , &:hover .explore{
      color:#FFF;
    }
    &:hover  .arrow>path{
        fill:#0086FF;
    }
    
    &:hover{
        transform: scale(1.03); 
        transition: all 0.3s ease-out;
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.11);
    }

    // card-image:hover{
    //     transform: scale(1.02);
    // }

  
    .card-image{
 
        border-radius:10px;
        object-fit:fill;
        padding:10px;
        align-self:center;
        width:78px;
        height:78px;
        background-color:rgba(229, 245, 255, 1);
      
       
      
        transition: all 0.3s ease-out;
      }

    .title{
        text-align: left;
        font-size: 18px;
        font-weight: 700;
       line-height:23px;
        float:left;
        width:100%;
     margin-bottom:0px;
    }
    .explore{
        text-align: left;
        font-size: 16px;
        font-weight: 700;
        color:#0086FF;
        float:left;
        width:100%;
     margin-bottom:0px;
     text-decoration:underline;
    }
  

    .position{
        display: block;
        font-size: calc(1vw + 3px);
        margin-bottom: 10px;
        font-weight: 400;
    }
    .card-button{
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size:  calc(1vw + 3px);
        border-color: #0086FF;
        border-radius: 4px;
        font-weight: 700;
        padding: 2vw 4vw;
       
      }
   

    @media screen and (max-width: 728px){
        width:300px;
    height:92px;
        .title{
            font-size: calc(1vw + 10px);
        }

        .position{
            font-size: calc(1vw + 8px);
        }
        .card-button{
            font-size:12px;
        }
        .row{
            gap:40px;
        }
        .card-image{
            border-radius:10px;
            object-fit:fill;
            padding:10px;
            align-self:center;
            width:78px;
            height:78px;
            background-color:rgba(229, 245, 255, 1);
          
           
          
            transition: all 0.3s ease-out;
        }
    }
`

const ValuesCarousal = styled.div`
text-align:left;
display:flex;
flex-direction:row;
justify-content:center;
align-items:center;
padding-bottom:20px;

    .title{
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 0.25rem;
        font-size: calc(1vw + 3px);
        font-family: 'Sofia Sans', sans-serif;
    }
    
    .lines{
        display:flex;
        flex-direction:column;
        align-items:center;
        gap:10px;
    }
.blueline{
    width:26px;
    height:26px;
    border-radius:100px;
     border:2px solid #0086FF;
    cursor:pointer;
    position:relative;
}
.blueline::after{
    content:'';
    width:6px;
    height:6px;
    position:absolute;
    background-color: #0086FF;
    border-radius:100px;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);

}
.greyline{
    width:26px;
    height:26px;
    border-radius:100px;
    //  background-color:#9D9D9D;
    cursor:pointer;
    position:relative;

}
.greyline::after{
    content:'';
    width:6px;
    height:6px;
    position:absolute;
    background-color: #9D9D9D;
    border-radius:100px;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
}
    .header{
        font-weight: 700;
        font-size: calc(2vw + 5px);
        display: block;
        margin-bottom: 20px;
        font-family: 'Sofia Sans', sans-serif;
    }

    .paragraph{
        font-weight: 400;
        font-size: calc(1vw + 5px);
        font-family: 'Inter', sans-serif;
        height:200px;
        width:120%;
       
    }

    .carousal-image{
        width: 400px;
        height:400px;
        padding-left:20px;
    }

    .carousal{
        margin-top: 50px;
    
    }

    .image-container{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width:50%;
        height:50%;
    }

    .arrow-button{
        padding-bottom: 50px;
        font-size: calc(1vw + 8px);
        font-weight: 600;
        font-family: 'Sofia Sans', sans-serif;
        margin-top:20px;
        display:flex;
        justify-content:center;
        
        margin-left:40px;
        align-items:center;
       
    }

    .left-outlined>svg, .right-outlined>svg{
        display: flex;
        // margin-left: 10px;
        // margin-right: 30px;
        cursor: pointer;
        width:20px;
        height:20px;
        align-items:center;
    }

    .carousal-option{
        font-size: calc(2vw + 3px); 
        font-weight: 400;
        font-family: 'Sofia Sans', sans-serif;
    }

    .option{
        font-weight: 700;
      

    }
    .description{
      
    }
    .card-button{
    max-width:200px;
    }



    @media screen and (min-width: 2000px){
        .carousal-image{
            width: 60%;
        }
    }
    @media screen and (max-width: 1200px){
        .carousal-image{
            width: 70%;
        }
    }

    @media screen and (max-width: 915px){
        .carousal-image{
            width: 80%;
            height:100%;
        }
        .paragraph{
            width: 40vw;
        }
        .carousal-option, .description{
            // display: none;
        }
     
        .mobile-description{
            display: flex;
            flex-direction: column;
            margin-top: 40px;
            font-size: calc(2vw + 8px);
            font-weight: 700;
            font-family: 'Sofia Sans', sans-serif;
        }

        .mobile-description .desc-header{
            display: flex;
            flex-direction: row;
            justify-content: flex-start
    
        }
        .desc-header span{
            font-family: 'Sofia Sans', sans-serif;
        }

        .mobile-description span { 
            margin-right: 10px;
        }

        .desc-body{
            font-size: calc(1vw + 10px);
            font-weight: 400;
            // padding-left: 46.38px;
            font-family: 'Sofia Sans', sans-serif;
        }
        .title{
            font-size: calc(1vw + 10px);
        }
        .image-container{
            justify-content:center;
        }
        // .blueline{
        //     width:30px;
        //     height:5px;
        //     background-color:#0086FF;
        //     border-radius:3px;
        //     cursor:pointer;
        // }
        // .greyline{
        //     width:10px;
        //     height:3px; 
        //     background-color:#9D9D9D;
        //     border-radius:3px;
        //     cursor:pointer;
        // }

        
    }
    @media screen and (max-width: 770px){
        flex-direction:column;
        .paragraph{
          
            font-size: calc(1vw + 10px);
           
        }
        .lines{
            flex-direction:row;
        }
        .paragraph{
          
            height:auto;
            width:100%;
        }
        .header {
            
            font-size: calc(1vw + 15px);
        }
        .image-container {
            margin-top:20px;
        }
        .carousal .card-button{
            margin-top: 0px;
        }
    }

    
`   



const ips = [
    {
        heading: 'In-house Conference',
        description: 'Step into the future of seamless communication with our real-time audio/video conference platform powered by cutting-edge webRTC technology. Experience an astonishing 0.5-second latency, engage with our digital online whiteboard, effortlessly raise your hand, and enjoy the touch-to-talk feature. Stay connected through real-time chat and cloud-based data storage for ultimate convenience. Plus, track attendance with ease for enhanced productivity.',
        img:Img4,
        link:'in-house-conference'
    },
    {
        heading: 'In-house Broadcast',
        description: 'Enjoy a captivating broadcasting experience with our scalable audio/video streaming platform. Leverage the power of CDN for lightning-fast content delivery. Enhance your creativity on our digital online whiteboard, foster real-time interactions through chat and cloud-based data storage, and dive into an immersive video-on-demand (VOD) feature facilitated by our geographically distributed server network. Keep attendance organized effortlessly.',
        img:Img5,
        link:'in-house-broadcast'

    },
    {
        heading:'Video Transcoding',
        description:'Unlock the true potential of your videos with our scalable video transcoding solution. Seamlessly process multiple resolutions using our advanced batch processing technology. Enjoy the flexibility of storing your content on the cloud while our transcoding magic works behind the scenes. Prepare to dazzle your audience with flawless playback across devices and resolutions.',
        img:Img6,
        link:'video-transcoding'
    }
]


const Obj = [
  
    {
        name: 'Bike On The Go (BOTG)',
     
        img: Img1,
        link:'connected-solutions/BOTG'
    },
    {
        name: 'Off Highway Power Train Remote Diagnostics',
     
        img: Img2,
        link:'connected-solutions/Off-Highway-Power-Train-Remote-Diagnostics'
    },
    {
        name:'Your Company App',
        img: Img3,
        link:'connected-solutions/YCA'
    }
    
]
// const Obj1 = {
//     topLine: '',
//     btnType: 'outline',
//     headline: `In-house <div className='highlight'>Conference</div>`,
//     description: 'Step into the future of seamless communication with our real-time audio/video conference platform powered by cutting-edge webRTC technology. Experience an astonishing 0.5-second latency, engage with our digital online whiteboard, effortlessly raise your hand, and enjoy the touch-to-talk feature. Stay connected through real-time chat and cloud-based data storage for ultimate convenience. Plus, track attendance with ease for enhanced productivity.',
//     buttonLabel: '',
//     img: Img4,
//     alt: 'r&d-image',
//     imgStart: '',
//     link:'',
//     height:90

// }
// const Obj2 = {
//     topLine: '',
//     btnType: 'outline',
//     headline: `In-house <div className='highlight'>Broadcast</div>`,
//     description: 'Enjoy a captivating broadcasting experience with our scalable audio/video streaming platform. Leverage the power of CDN for lightning-fast content delivery. Enhance your creativity on our digital online whiteboard, foster real-time interactions through chat and cloud-based data storage, and dive into an immersive video-on-demand (VOD) feature facilitated by our geographically distributed server network. Keep attendance organized effortlessly.',
//     buttonLabel: '',
//     img: Img5,
//     alt: 'r&d-image',
//     imgStart: 'start',
//     link:'',
//     height:90

// }
// const Obj3 = {
//     topLine: '',
//     btnType: 'outline',
//     headline: `Video <div className='highlight'>Transcoding</div>`,
//     description: 'Unlock the true potential of your videos with our scalable video transcoding solution. Seamlessly process multiple resolutions using our advanced batch processing technology. Enjoy the flexibility of storing your content on the cloud while our transcoding magic works behind the scenes. Prepare to dazzle your audience with flawless playback across devices and resolutions.',
//     buttonLabel: '',
//     img: Img6,
//     alt: 'r&d-image',
//     imgStart: '',
//     link:'',
//     height:90

// }
export default BCSResearch;


