import React,{useEffect} from 'react';
import styled from 'styled-components';
import { Row, Col, Modal} from 'antd';
import { Link } from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import { useState } from 'react';

import bg from './BG.svg'
import { LoadingOutlined } from '@ant-design/icons';
import {ReactComponent as EyeLogo} from './eye-blink.svg'
import { connect } from 'react-redux';
import { getFeaturedEvents } from '../../../redux/action';

import './style.css'


function Culture({
    getFeaturedEvents,
    featuredEvents


}) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loader,setLoader]=useState(false);
    const [eventDetails, setEventDetails]=useState([]);
    useEffect(()=>{
     
        if(!featuredEvents||featuredEvents.length==0){
            setLoader(true)
            }
        getFeaturedEvents(()=>{
            setLoader(false)
        })
    
       
    },[])
    const navigate = useNavigate();

    const handleCancel = () => {
        setIsModalOpen(false);
      };
    
      const handleEventLink=()=>{
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
    
        navigate('/our-story/events')
      }
      
const Obj = featuredEvents?.map((ele,key)=>{
    return{
        key:ele.id,
        name:ele.title,
        img:ele.thumbnailPath
    }
})

    return (
        <>
        <CultureContainer>
            <div style={{'display':'flex','flexDirection':'column',marginBottom:'10px'}}>
            {/* <div className='culture-header heading'>Our <div className='gradient2'>Culture<div className='polygon2' ></div></div></div> */}
            <div className='culture-header heading'>Our <span className='highlight' >Culture</span> </div>
          
            {/* <span className='culture-image-topline darkblue highlight'>THE FESTIVITES</span> */}
            </div>
            {/* <p className='culture-top content grey'>
            At brigosha we embrace <span className='bold'>Diversity</span> and <span className='bold'>Equality</span>. 
            </p> */}
            <p className='culture-top content grey'>Our values and practices help bring our diverse team together to create a healthy and friendly work environment.</p>
           { Obj?.length==0?null:<a onClick={handleEventLink} className='viewAll'><b>View All Events</b><EyeLogo style={{width:"20px",height:"20px"}} /></a>}
            
            <br/>
            <br/>
            {loader? 
             <div  style={{'minHeight':'280px','display':'flex',justifyContent:'center','alignItems':'center',flexDirection:'column'}}>
            <LoadingOutlined style={{fontSize: "3em"}}/>
                   
                </div> :
               
            <Row style={{'display':'flex','gap':'20px',alignItems:'center',justifyContent:'space-evenly',marginTop:'10px'}}>
                
         { Obj?.length==0?<div style={{'display':'flex','flexDirection':'column',justifyContent:'center',alignItems:'center'}}> 
               
                <span><b> Events are coming soon...</b></span>
                
            </div>:
         
         Obj?.map((ele,key)=>{
       return  <Col  className='team-card' key={ele.key} onClick={()=>{ setIsModalOpen(true);setEventDetails(featuredEvents[key])}}>
       <Card {...Obj[key]} />
      </Col>
         })}
               
            
                
            </Row>
           }
        
        {/* <Webelief/> */}
        </CultureContainer>
       
         <Modal  visible={isModalOpen} footer={null} closable={true} centered onCancel={handleCancel} style={{'zIndex':'100'}} width={window.innerWidth>950?830:350}   >
            <ModelDiv>
                <div className='div1'>
                    <img src={eventDetails.thumbnailPath} alt="event-thumbnail" width={463} height={258} loading="lazy"/>
       
               
                    
                    <div style={{'paddingTop':'10px'}}>
                        <h1>{eventDetails.title}</h1>
                        <p className='text'>{eventDetails.description} </p>
                    </div>

                </div>
                <div className='div2'>
                    <h1>Related Images</h1>
                    {eventDetails?.galleryImages?.length===0?<h4>No gallery found!!!</h4>:
                    <div className='div3'>
                   { eventDetails?.galleryImages?.map(ele=>{
                         return   <img src={ele.locationPath} id={ele.id} width={120} height={120} alt='event-img'  loading="lazy" placeholder={<LoadingOutlined style={{fontSize: "3em"}}/>}/>
                    })
                   }     
                    </div>}
                   <u>
                   <Link to={`/our-story/eventGallery/eventId=${eventDetails.id}`} onClick={()=>{
                  document.body.scrollTop = 0; // For Safari
                  document.documentElement.scrollTop = 0;
                  document.documentElement.scrollTop=0;
              }}> 
               <b>Go to Gallery Page </b>
              </Link>
                   </u>

                </div>
            </ModelDiv>

      </Modal>
        </>
    )
}
const Card = ({ name, img }) => {
    return(
        <CardStyle>
            {/* <div className='card-wrapper'>
            <img src={img} alt={name} style={{objectFit: 'cover'}}/>
            <div className='event-head'>
            <div style={{'display':'table','tableLayout':'fixed','width':'100%'}}>
            <h1 className='title heading'  style={{'textOverflow':'ellipsis',overflow:'hidden',display:'table-cell',verticalAlign:'middle'}} >{name}</h1>
            </div> <button class="glass-button">Explore</button>
            </div>
            </div> */}


            <div className = "card">
    <img src={img} alt="Event-Img" />
    <div className="card-content">
      <h2 >
      {name}
      </h2>
     
    
      <button className="button">Explore</button>
      
    </div>
  </div>
        </CardStyle>
    )
}

const CardStyle = styled.div`
   width:300px;
    margin-bottom: 10px;
    transition: transform 0.5s ease;
    display: flex;
    flex-direction: column;
    align-items: center;

   height:400px;

   .card {
    width: 20rem;
    height: 36rem;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    background: black;
    box-shadow: 0 30px 60px 0 #aac3e1;
    
  }
  .card img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.7;
    transition: opacity 0.2s ease-out;
  }
  .card h2 {
    position: absolute;
    inset: auto auto 30px 30px;
    margin: 0;
    transition: inset 0.3s 0.3s ease-out;
    font-family: 'Sofia Sans', sans-serif;
    font-weight: normal;
    text-transform: uppercase;
    color:#FFFFFF;
    width: 16rem;
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis; 
    text-align:left;
  }
  .card p, .card .button {
    position: absolute;
    opacity: 0;
    max-width: 80%;
    transition: opacity 0.3s ease-out;
  }
  .card p {
    inset: auto auto 80px 30px;
  }
  .card .button {
    inset: auto auto 40px 30px;
    // color: inherit;
    // text-decoration: none;
    display: inline-block;
    padding: 12px 22px;
    height:50px;
    border: 0;
    text-decoration: none;
    border-radius: 8px;
    background-color: rgba(255,255,255,0.1);
    border: 1px solid rgba(255,255,255,0.1);
    backdrop-filter: blur(30px);
    color:#fff;
    font-size: 16px;
    letter-spacing: 2px;
    cursor: pointer;
    text-transform: uppercase;
  }
  .card:hover h2 {
    inset: auto auto 100px 30px;
    transition: inset 0.3s ease-out;
  }
  .card:hover p, .card:hover .button {
    opacity: 1;
    transition: opacity 0.5s 0.1s ease-in;
  }
  .card:hover img {
    transition: opacity 0.3s ease-in;
    opacity: 1;
  }
  
  .material-symbols-outlined {
    vertical-align: middle;
  }




   .card-wrapper{
    width: 100%;
    max-width: 300px;
    cursor:pointer;
    background-color: #fff;
    justify-content:center;
    padding: 20px;
    position: relative;
    margin:0 auto 30px;
    display:flex;
    justify-content:center;
  
    border-radius:8px;
    height:360px;
    box-shadow: 0 30px 60px 0 #aac3e1;
   
  
   }
   .card-wrapper:hover{
    box-shadow: box-shadow: 0 30px 60px 0 #aac3e1;
    border-radius:8px;
   }
   .glass-button {
    display: inline-block;
    padding: 12px 22px;
    height:50px;
    border: 0;
    text-decoration: none;
    border-radius: 8px;
    background-color: rgba(255,255,255,0.1);
    border: 1px solid rgba(255,255,255,0.1);
    backdrop-filter: blur(30px);
    color:#fff;
    font-size: 16px;
    letter-spacing: 2px;
    cursor: pointer;
    text-transform: uppercase;
  }
  
  .glass-button:hover {
    background-color: rgba(255,255,255,0.2);
  }
   .event-head {
    width:260px;
   display:flex;
   gap:5px;
   justify-content:space-between;
   align-items:flex-end;
    position:absolute;
    bottom:20px;
    height:320px;
    border-bottom-left-radius:8px;
    border-bottom-right-radius:8px;
    background: linear-gradient(to bottom, #9b9b980f 56%, black 100%);
    text-align: center;
    opacity:1;
    padding: 10px;
    transition: all 0.5s ease;
  }
    &:hover{
        // transform: scale(1.02);
    }

    img{
       
        height:320px;
        width:260px;
        border-radius:8px;
       
    }
   

    .title{
        // height:30px;
        // line-height:15px;
        font-size: 16px;
        font-weight: 700;
        color: #FFFFFF;
        // text-align:left;
        // display:flex;
        // align-items:center;
        margin-bottom:0px !important;
        white-space: nowrap;
        line-height: 1.5em;
        height: 3em;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
       
    }

    .position{
        display: block;
        font-size: calc(1vw + 3px);
        margin-bottom: 10px;
        font-weight: 400;
    }

    @media screen and (max-width: 728px){
        width:15rem;
        height:17rem;
        .card {
            width: 15rem;
            height: 15rem;
        }
        .title{
            font-size: calc(1vw + 10px);
        }

        .position{
            font-size: calc(1vw + 8px);
        }
    }
    @media screen and (max-width: 425px){
    //    width:280px;
    //    height:320px;
    img{
       
        // height:250px;
        // width:230px;
       
    }
    .event-head {
        
        width:230px;
        height:230px;
    }
    .title.heading{
        height:43px !important;
    }
    .glass-button {
        padding: 8px 20px;
        height:43px;
    }


    }
    @media screen and (max-width: 320px){
        // width:260px;
        // height:300px;
     img{
        
        //  height:230px;
        //  width:210px;
        
     }
     .event-head {
         
         width:210px;
         height:210px;
     }
     .glass-button {
        padding: 6px 18px;
        height:40px;
    }
    .title.heading{
        height:40px !important;
    }
 
     }
`






const CultureContainer = styled.div`




    min-height: max-content;
    // background-color: #F3F9FF;
    padding-top: 80px;
    padding-bottom:40px;
    padding-right: 10vw;
    padding-left: 10vw;
    text-align: center;
//   background:url(${bg}) center no-repeat;
// background-size:100%;
// background-position-y:100%;
    .bold{
        font-weight: 700;
        color: black;
    }
    .culture-image-topline{
        letter-spacing: 0.25rem;
        text-transform: uppercase;
        font-weight: 600;
        text-align:left;
        font-size: calc(1vw + 3px);
        padding-top:20px;
        font-family: 'Inter', sans-serif;
    }
    .culture-header{
        font-size: calc(2vw + 15px);
        font-weight: 700;
        position:relative;
        z-index:2;
        font-family: 'Sofia Sans', sans-serif;
    }
  

    .culture-top{
        padding-top: 5px;
        font-size: calc(1vw + 5px);
        font-weight: 400;
        margin:0;
        font-family: 'Inter', sans-serif;
    }
    .viewAll{
      margin-top:30px;
        text-align:center !important;
        font-family: 'Inter', sans-serif;
  
       margin-right:20px;
       display:flex;
       flex-direction:row;
       align-items:center;
       justify-content:flex-end;
       gap:5px;
    
        
      
       }
       
       
       .viewAll:hover {
       color:#005EB3;
        font-family: 'Inter', sans-serif;
        font-size:14px;


       }

    @media screen and (max-width: 767px){
        padding-right: 8vw;
        padding-left: 8vw;
       .viewAll{
   
        text-align:center !important;
        display:flex !important;
        justify-content:center;
      
       }
        .culture-header{
            font-size: calc(2vw + 20px);
        }

        .culture-top{
            font-size: calc(1vw + 10px);
        }
        .culture-image-topline{
            font-size: calc(1vw + 10px);
           
        }
    }
`
const ModelDiv = styled.div`
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 50px;
   max-height:722px;
  min-height:300px;
  max-width:943px;

  
   .div1>img {
    max-width:463px; 
    max-height:258px;
   }
   .div2{
    display:flex;
    flex-direction:column;
    justify-content:space-evenly;
    gap:10px;
  
  
   }
  .div3{
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    place-items:center;
  
  }
  .div3 >img{
    width:120px;
    height:120px ;
  }
  .text{
    display:block;
    width:100%;
    max-height:249px;
     white-space: pre-wrap !important;
     white-space: -moz-pre-wrap;
     white-space: -pre-wrap;
     white-space: -o-pre-wrap;
     word-wrap: break-word !important;
     min-height:max-content;
    
     overflow-y:auto;
     font-size:18px;
     padding :5px 5px;
    //  background:#F3F9FF;
  
 }




    
 @media  screen and (max-width: 950px){
   
    display:grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
    place-items: center
    min-height:300px;
    max-height:max-content;
  
    max-height:700px;
    overflow-y:scroll;
   
    .div1>img{
       min-height:150px;
       max-height:258px;
    width:100%;

    }
    .div3{
        grid-template-columns:  repeat(2, 100px);
        gap: 20px;
        place-items: center;
    }
    .div3>img{
        height:100px;
        width:100px;
        

    }
    .div2{
        align-items:space-around;
    }
    .text{
        max-height:max-content;
        font-size: 16px;
    }
}
`

const mapStateToProps = state => {
    const {
        featuredEvents
    }  = state.we;

    return { featuredEvents }
}

const mapDispatchToProps = dispatch =>  ({
    getFeaturedEvents: (successCallback) => dispatch(getFeaturedEvents(successCallback)),
   
})

export default connect(mapStateToProps, mapDispatchToProps)(Culture);




