import ContactBanner from '../ourStory/contactCard/contact.svg'
import { Form, Input, Row, Col, message } from 'antd'
import React, { useState, useEffect } from 'react'
import './styles.css';
import { ColorText2, FormInput, FormInputArea, SubmitButton, Heading, SubHeading, ColorText1, AddressBody, ContactImg, PhoneInputArea } from './styles';
import { submitContactForm, getLocations } from '../../redux/action';
import { connect } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { FastForwardOutlined, PhoneTwoTone } from '@ant-design/icons';
import { LoadingOutlined } from '@ant-design/icons';
import { phone } from 'phone';
import pin from './map-pin.svg'
import { ReactComponent as Arrow } from './Vector.svg'
import bg2 from '../ourStory/ourCulture/BG.svg';
import map from './world-map.svg'
import personMan from './personmaleblue.svg'
import bg1 from '../ourStory/PotentialCard/potentialBG.svg'
import twitter from './icons/xIcon.svg';
import linkedIn from './icons/linkedinIcon.svg';
import youtube from './icons/youtubeIcon.svg';
import call from './icons/callIcon.svg';
import email from './icons/emailIcon.svg';
import leftIcon from './icons/arrow-left-icon.svg';
import mapIcon from './icons/map-pin-icon.svg';
import callIcon from './icons/call-icon.svg';

const scrollToTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}


function ContactUs({ submitContactForm, getLocations, locations }) {

    const [location, setLocation] = useState(1);
    const length = 5;
    const [reset, setReset] = useState(false);
    const navigate = useNavigate();
    const links = [
        'https://goo.gl/maps/uQQRakkjFjRjdsT38',//corporate ofc
        'https://maps.app.goo.gl/DNAgjMaZPBdB8ZT27',// odc
        'https://goo.gl/maps/qoVmdBd8LELgEVTeA',//canada ofc
        'https://goo.gl/maps/VBHeT21V8xcTLkgHA',// newwark ofc
        'https://www.linkedin.com/in/tarun-srivastava-tarunsrivastava/',//sydney contact
    ]
    const [loader, setLoader] = useState(false);

    const initalValues = {
        name: '',
        email: '',
        phone: '',
        message: ''
    }
    const [formInput, updateFormInput] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });
    const [submitLoader, setSubmitLoader] = useState(false)
    const [isActive, setActive] = useState({ name: false, phone: false, email: false });

    const handleSubmit = () => {
        setSubmitLoader(true);
        submitContactForm(formInput, () => { scrollToTop(); setSubmitLoader(false) }, successCallback)
    }

    const clearForm = () => {
        const newState = { ...initalValues }
        updateFormInput(newState);
    }

    const successCallback = () => {

        clearForm()
        navigate('/get-in-touch/submit-success')
    }



    useEffect(() => {


        if (!locations || locations.length == 0) {

            setLoader(true)
        }
        getLocations(() => {
            setLoader(false)
        })


    }, []);

    useEffect(() => {

        if (window.location.pathname !== '/get-in-touch/submit-success') {
            let locationElement = document.getElementById(`location-${location}`);

            let selectedLocationElement = document.getElementById(`location-selected-${location}`);
            locationElement.addEventListener('mouseover', () => {
                selectedLocationElement.style.opacity = 1;
                locationElement.style.opacity = 0;
            })
            locationElement.addEventListener('mouseout', () => {
                selectedLocationElement.style.opacity = 0;
                locationElement.style.opacity = 1;
            })
        }
    })
    useEffect(() => {
        if (!reset) {
            const interval = setInterval(() => {
                setLocation((prev) => (prev === length - 1 ? 1 : prev + 1));
            }, 4000);

            return () => clearInterval(interval);
        }
    }, [length, reset]);

    const locationRedirect = (index) => {

        window.open(links[index], '_blank');

    }

    useEffect(() => {
        if (window.location.pathname !== '/get-in-touch/submit-success') {
            for (let i = 1; i < length; i++) {
                let locationCardElement = document.getElementById(`location-card-${i}`);
                let locationElement = document.getElementById(`location-${i}`);
                let selectedLocationElement = document.getElementById(`location-selected-${i}`);
                selectedLocationElement.style.opacity = 0;
                locationElement.style.opacity = 1;
                locationCardElement.style.display = 'none';
            }
            let locationElement = document.getElementById(`location-${location}`);
            let currentLocationElement = document.getElementById(`location-card-${location}`);
            let selectedLocationElement = document.getElementById(`location-selected-${location}`);
            selectedLocationElement.style.opacity = 1;
            locationElement.style.opacity = 0;
            currentLocationElement.style.display = 'flex';
        }


    }, [location]);

    return (window.location.pathname === '/get-in-touch/submit-success') ?
        <Outlet /> : (
            <div style={{
                background: `url(${bg2}) center no-repeat`, backgroundSize: 'cover',
                position: 'relative',
                backgroundPositionY: '200%', hight: 'auto',
                minHeight: '400px',

                paddingBottom: 50,
                width: '100%',



            }}>
                <div style={{
                    background: `url(${bg1}) top no-repeat`,
                    backgroundSize: '100%',
                    position: 'relative',
                    width: '100%',
                    padding: '40px 8vw',
                    backgroundPositionY: '-20vh',
                    paddingTop: 60,
                    paddingBottom: 60

                }} className='background-image-1'>


                    <Row style={{ alignItems: 'center' }}>
                        {/* <Col 
                style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}
            >
                <ContactImg width="90%" src={ContactBanner}  />
            </Col> */}

                        <div style={{ width: '100vw', display: 'flex', justifyContent: 'center' }}>
                            <div style={{
                            }} className='contact-wrapper-1'>
                                {/* <ColorText2 className='highlight'>CONTACT US</ColorText2> */}

                                {/* <Heading className='heading'>
                        How Can We <span className='polygon1'><span className='gradient1'>Help You?</span></span>
                    </Heading> */}
                                {/* <div 
              className='address-card-heading heading'
           
              >
                How Can We <div className='gradient1'  >Help You? <div className='polygon1' ></div></div>
              </div> */}
                                <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
                                    <div
                                        className='address-card-heading heading'
                                        style={{ width: '100%' }}

                                    >
                                        Get In <div className='highlight'  >Touch </div>
                                    </div>
                                    <SubHeading className='content grey' style={{ maxWidth: '500px' }}>Our dedicated team is ready to assist you with any inquiries or support you need. Reach out today for a seamless experience with brigosha!</SubHeading>
                                    <div style={{
                                        display: 'flex', flexDirection: 'column',
                                        alignItems: 'flex-start', color: 'rgba(38, 50, 56, 1)', justifyContent: 'center', gap: '10px'
                                    }} className='black-box'>


                                        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', color: 'rgba(38, 50, 56, 1)', gap: '10px' }}> <span><img src={call} alt='919538946543' style={{ cursor: 'default', marginRight: '10px' }} className='contact-icon'></img>+919538946543</span>
                                            <span><img alt='IT' src={email} style={{ cursor: 'default', marginRight: '10px' }} className='contact-icon'></img>info@brigosha.com</span>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', color: 'rgba(38, 50, 56, 1)', gap: '10px', alignItems: 'center' }}>
                                            <a href='https://twitter.com/Brigosha' target="_blank" rel="noopener noreferrer"><img src={twitter} alt='AI and mobility' style={{ cursor: 'pointer' }} className='contact-icon'></img></a>
                                            <a href='https://www.linkedin.com/company/brigosha/' target="_blank" rel="noopener noreferrer"><img alt='AI' src={linkedIn} style={{ cursor: 'pointer' }} className='contact-icon'></img></a>
                                            <a href='https://www.youtube.com/@BRIGOSHA' target="_blank" rel="noopener noreferrer"><img alt='technology' src={youtube} style={{ cursor: 'pointer' }} className='contact-icon'></img></a>
                                        </div>
                                    </div>
                                </div>

                                <div className='contact-wrapper'>
                                    <Form
                                        style={{ padding: '0 0', background: 'rgba(255,255,255,0.2)', textAlign: 'center' }}
                                        name="basic"
                                        className='contact-form'
                                        initialValues={{
                                            remember: true,
                                        }}
                                        layout='vertical'
                                        onFinish={() => handleSubmit()}
                                        autoComplete="off"
                                    >


                                        <br />
                                        <Form.Item
                                            name="name"
                                            label="Name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter your name!',
                                                },
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (!value || /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '-]+$/.test(value)) {
                                                            setActive({ ...isActive, name: true })
                                                            return Promise.resolve();
                                                        }
                                                        setActive({ ...isActive, name: false })
                                                        return Promise.reject(new Error('Please enter a valid name!'));
                                                    },
                                                })
                                            ]}
                                        >

                                            <FormInput placeholder='Name' value={formInput.name} onChange={e => { updateFormInput({ ...formInput, name: e.target.value.trim() }) }} />
                                        </Form.Item>

                                        <Form.Item
                                            name="email"
                                            label="E-mail"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter your E-mail!',
                                                },
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (!value || /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
                                                            setActive({ ...isActive, email: true })
                                                            return Promise.resolve();
                                                        }
                                                        setActive({ ...isActive, email: false })
                                                        return Promise.reject(new Error('Please enter a valid email!'));
                                                    },
                                                })
                                            ]}
                                        >

                                            <FormInput placeholder='Email ID' value={formInput.email} onChange={e => { updateFormInput({ ...formInput, email: e.target.value.trim() }) }} />
                                        </Form.Item>


                                        <Form.Item
                                            name="phone"
                                            label='Phone Number'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter your Mobile Number(Eg: +91 *****-*****)!',
                                                },
                                                () => (
                                                    {
                                                        validator(_, value) {



                                                            if (value.length !== 0) {

                                                                if (phone('+' + value).isValid) {

                                                                    setActive({ ...isActive, phone: true })

                                                                    return Promise.resolve();
                                                                } else {

                                                                    setActive({ ...isActive, phone: false });
                                                                    return Promise.reject(new Error('Please enter a valid Mobile number!'));
                                                                }

                                                            }


                                                        }
                                                    }),
                                            ]}
                                        >

                                            <PhoneInputArea
                                                country={'in'}
                                                value={formInput?.phone}
                                                autoFormat={FastForwardOutlined}

                                                onChange={phone => {


                                                    updateFormInput({ ...formInput, phone: phone })
                                                }}
                                                placeholder="Mobile Number (Eg: +91 *****-*****)"

                                            />
                                        </Form.Item>


                                        <Form.Item
                                            name="message"
                                            label="Message"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please type a message!'
                                                }
                                            ]}
                                        >

                                            <FormInputArea placeholder='Message(Max 250 Characters)' value={formInput?.message} onChange={e => { updateFormInput({ ...formInput, message: e.target.value }) }} maxLength={250} />
                                        </Form.Item>

                                        <Form.Item
                                            wrapperCol={{ offset: 0 }}
                                        >
                                            <SubmitButton

                                                type="primary"
                                                htmlType="submit"
                                                loading={submitLoader}

                                                disabled={!formInput.name || !formInput.email || !formInput.message || !formInput.phone || !isActive.email || !isActive.name || !isActive.phone}
                                            >
                                                SUBMIT
                                            </SubmitButton>
                                        </Form.Item>
                                        <span style={{ width: '100%', textAlign: 'end', float: 'right' }}><span style={{ color: 'red' }}>*</span>All fields are mandatory</span>
                                    </Form>

                                    {/* <div    style={{marginTop: 50, padding: '0 0',background:'rgba(43, 43, 43, 1)',width:'266px',textAlign:'center',height:"271px",position:'absolute',left:'60%',borderRadius:'4px',boxShadow:' 15px 16px 30px 0px rgba(0, 0, 0, 0.11)',display:'flex',flexDirection:'column',
alignItems:'center',color:'white',justifyContent:'space-around'}} className='black-box'> 
                    <div    style={{marginTop: 50, padding: '0 0',background:'rgba(43, 43, 43, 1)',width:'50px',textAlign:'center',height:"50px",position:'absolute',left:'-10%',top:'-18.5%',borderRadius:'100px',boxShadow:' 15px 16px 30px 0px rgba(0, 0, 0, 0.11)'}}> 
</div>
             <div> <span style={{fontWeight:700}}>Contact Us</span></div>             
           <div style={{display:'flex',justifyContent:'center',flexDirection:'column',color:'rgba(255, 255, 255, 1)',gap:'5px'}}> <span>+919538946543</span>
                 <span>info@brigosha.com</span>
                 <span>Bengaluru, Karnataka</span></div>
                <div style={{display:'flex',justifyContent:'center',flexDirection:'row',color:'rgba(255, 255, 255, 1)',gap:'10px'}}>
                    <a href = 'https://twitter.com/Brigosha' target="_blank" rel="noopener noreferrer"><img src={twitter} style={{cursor:'pointer'}}></img></a>
                    <a href = 'https://www.linkedin.com/company/brigosha-technologies-pvt-lmt/about/' target="_blank" rel="noopener noreferrer"><img src={linkedIn} style={{cursor:'pointer'}}></img></a>
                    <a href = 'https://www.youtube.com/@BRIGOSHA' target="_blank" rel="noopener noreferrer"><img src={youtube} style={{cursor:'pointer'}}></img></a> 
                </div>
                    </div> */}
                                </div>
                            </div>
                        </div>
                    </Row>
                    <div   >

                        {/* <Row>
      <span className='address-topline highlight'>OUR LOCATIONS</span><br/><br/>
      </Row> */}
                        <Row justify="center" style={{ marginBottom: 50, marginTop: 100 }}>
                            <div
                                className='address-card-heading heading'>{window.location.pathname == '/get-in-touch' ? 'Our ' : 'Meet Us '} <div className='highlight'>{window.location.pathname == '/get-in-touch' ? 'Offices' : 'Here'}</div></div>
                        </Row>





                        <div className="image-container">
                            <img src={map} alt="Our Locations" width="611" height="438" className='responsive-img' />


                            {/* Corporate Office  */}
                           {/*  <div className="dot" style={{ top: '44.3%', left: '69.5%' }} id='location-0' onMouseEnter={() => { setLocation(0); setReset(!reset) }}
                                onMouseLeave={() => setReset(!reset)}></div>
                            <div className='dot-selected ' id='location-selected-0' style={{ top: '45%', left: '69.87%' }}  >
                                <div className="dot" style={{ top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}> </div>
                            </div>
                            <div id='location-card-0' className="location-card" style={{
                                top: '44%', left: '69.5%', position: 'absolute',
                                boxShadow: '0px 0px 12px 0px rgba(0, 0, 0, 0.11)',
                                backgroundColor: ' rgba(255, 255, 255, 0.6)',
                                borderRadius: '4px',
                                padding: 15,
                                transform: 'translate(5%,5%)',
                                cursor: 'pointer', display: 'none', flexDirection: 'column', gap: '5px'
                            }} onClick={() => locationRedirect(0)}  >
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <span>Corporate Office</span>
                                    <img src={leftIcon} alt='geofence' style={{ width: '14px', height: '14px' }}></img>
                                </div>
                                <div className='location-meta-data'>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: '4px', alignItems: 'flex-start' }}>
                                        <img src={mapIcon} alt='map' style={{ marginTop: '5px' }}></img>
                                        <span>#780, VCR Cornerstone, 3rd Floor, 19th Main, HSR Layout Sector 1, Bangalore, Karnataka-560102</span>

                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: '4px', alignItems: 'center' }}>
                                        <img alt='brigosha' src={callIcon}></img>
                                        <span>+91-9538946543</span>
                                    </div>
                                </div>
                            </div> */}

                            {/* Development Center */}

                            <div className="dot" style={{ top: '44.4%', left: '68.9%' }} id='location-1' onMouseEnter={() => { setLocation(1); setReset(!reset) }}
                                onMouseLeave={() => setReset(!reset)}></div>
                            <div className='dot-selected' id='location-selected-1' style={{ top: '45.09%', left: '69.25%' }}>
                                <div className="dot" style={{ top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}> </div>
                            </div>
                            <div id='location-card-1' className="location-card" style={{


                                top: '44.4%', left: '68.9%', position: 'absolute',
                                boxShadow: '0px 0px 12px 0px rgba(0, 0, 0, 0.11)',
                                backgroundColor: ' rgba(255, 255, 255, 0.6)',
                                borderRadius: '4px',
                                padding: 15,
                                transform: 'translate(-100%,-100%)',
                                cursor: 'pointer', display: 'none', flexDirection: 'column', gap: '5px'
                            }} onClick={() => locationRedirect(1)}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap: '3px' }}>
                                    <span>brigosha HQ</span>
                                    <img alt='development center' src={leftIcon} style={{ width: '14px', height: '14px' }}></img>
                                </div>
                                <div className='location-meta-data'>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: '4px', alignItems: 'flex-start' }}>
                                        <img alt='hr solutions' src={mapIcon} style={{ marginTop: '5px' }}></img>
                                        <span>1023, Siri Shubham, 24th Main Rd, 1st Sector, HSR Layout, Bengaluru, Karnataka 560102</span>

                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: '4px', alignItems: 'center' }}>
                                        <img alt='lidar' src={callIcon}></img>
                                        <span>+91-7876903467</span>
                                    </div>
                                </div>
                            </div>
                            {/* sydney office */}
                            <div className="dot" style={{ top: '80.4%', left: '92.9%' }} id='location-4' onMouseEnter={() => { setLocation(4); setReset(!reset) }}
                                onMouseLeave={() => setReset(!reset)}></div>
                            <div className='dot-selected' id='location-selected-4' style={{ top: '81.70%', left: '93.5%' }}>
                                <div className="dot" style={{ top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}> </div>
                            </div>
                            <div id='location-card-4' className="location-card" style={{


                                top: '80.4%', left: '92.9%', position: 'absolute',
                                boxShadow: '0px 0px 12px 0px rgba(0, 0, 0, 0.11)',
                                backgroundColor: ' rgba(255, 255, 255, 0.6)',
                                borderRadius: '4px',
                                padding: 15,
                                transform: 'translate(-100%,-100%)',
                                cursor: 'pointer', display: 'none', flexDirection: 'column', gap: '5px'
                            }} onClick={() => locationRedirect(4)}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap: '3px' }}>
                                    <span>Sydney Contact</span>
                                    <img alt='development center' src={leftIcon} style={{ width: '14px', height: '14px' }}></img>
                                </div>
                                <div className='location-meta-data'>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: '4px', alignItems: 'flex-start' }}>
                                        <img alt='hr solutions' src={personMan} style={{ marginTop: '7px' ,marginLeft:-3, height:15, width:15, color:"rgba(0, 134, 255, 1)"}}></img>
                                        <span style={{fontSize:13}}> Tarun Srivastava <br></br><span style={{fontSize:10.5}}>Vice president - Software Solutions Australia</span> 
                                        <br>
                                        </br>
                                        <a style={{fontSize:12}} >tarun.srivastava@brigosha.com</a>
                                        </span>

                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: '4px', alignItems: 'center' }}>
                                        <img alt='sydney' src={callIcon}></img>
                                        <span>+61470654990</span>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="dot" style={{top: '48%', left: '70%'}} id='location-2'  onMouseEnter={() => {setLocation(2);setReset(!reset)}}
                  onMouseLeave={() => setReset(!reset)}></div>
     <div className='dot-selected' id='location-selected-2' style={{top: '48.68%', left: '70.365%'}}>
     <div className="dot" style={{top:'50%',left:'50%',transform:'translate(-50%,-50%)'}}> </div>
     </div>
     <div   id='location-card-2' style={{top: '48%', left: '70%',position:'absolute',  
  boxShadow:'0px 0px 12px 0px rgba(0, 0, 0, 0.11)',
  backgroundColor:' rgba(255, 255, 255, 0.6)',
  borderRadius: '4px',
  padding:15,
  transform:'translate(5%,5%)',
  cursor: 'pointer',display:'none',flexDirection:'column',gap:'5px'}} onClick={()=>locationRedirect(4)}>
      <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
      <span>BANGALORE</span>
      <img src={leftIcon} style={{width:'14px',height:'14px'}}></img>
      </div>
          <div className='location-meta-data'>     
      <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start',gap:'4px',alignItems:'flex-start'}}>
      <img src={mapIcon} style={{marginTop:'5px'}}></img>
      <span>1145, Midland Avenue, Unit 9, Scarborough,Ontario M1k 4H2</span>
     
      </div>
      <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start',gap:'4px',alignItems:'center'}}>
            <img src={callIcon}></img>
          <span>+91-7876903468</span>
      </div>
      </div>
     </div> */}
                            {/* 
  
 
      <div className="dot" style={{top: '45.8%', left: '68.9%'}} id='location-2'  onMouseEnter={() => {setLocation(2);setReset(!reset)}}
                  onMouseLeave={() => setReset(!reset)}></div>
     <div className='dot-selected ' id='location-selected-2' style={{top: '46.5%', left: '69.25%'}}>
     <div className="dot" style={{top:'50%',left:'50%',transform:'translate(-50%,-50%)'}}> </div>
     </div>
     <div   id='location-card-2' className="location-card" style={{top: '45.8%', left: '68.9%',position:'absolute',  
  boxShadow:'0px 0px 12px 0px rgba(0, 0, 0, 0.11)',
  backgroundColor:' rgba(255, 255, 255, 0.6)',
  borderRadius: '4px',
  padding:15,
  transform:'translate(-100%,0%)',
  cursor: 'pointer',display:'none',flexDirection:'column',gap:'5px'}} onClick={()=>locationRedirect(2)}>
      <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center', gap: '3px'}}>
      <span>Hustlehub TechPark</span>
      <img src={leftIcon} style={{width:'14px',height:'14px'}}></img>
      </div>
      <div className='location-meta-data'>     
      <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start',gap:'4px',alignItems:'flex-start'}}>
      <img src={mapIcon} style={{marginTop:'5px'}}></img>
      <span>Hustlehub Tech Park , 27th Main Road , Sector 2, HSR Layout, Bengaluru, Karnataka 560102</span>
     
      </div>
      <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start',gap:'4px',alignItems:'center'}}>
            <img src={callIcon}></img>
          <span>+91-8805571152</span>
      </div>
      </div>
     </div> */}



                            <div className="dot " id='location-2' style={{ top: '25.37%', left: '19.36%' }} onMouseEnter={() => { setLocation(2); setReset(!reset) }}
                                onMouseLeave={() => setReset(!reset)}></div>

                            <div className='dot-selected ' id='location-selected-2' style={{ top: '26.05%', left: '19.63%' }}>
                                <div className="dot" style={{ top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}> </div>
                            </div>
                            <div id='location-card-2' className="location-card" style={{
                                top: '26%', left: '20%', position: 'absolute',
                                boxShadow: '0px 0px 12px 0px rgba(0, 0, 0, 0.11)',
                                backgroundColor: ' rgba(255, 255, 255, 0.6)',
                                borderRadius: '4px',
                                padding: 15,
                                transform: 'translate(0%,-100%)',
                                cursor: 'pointer', display: 'none', flexDirection: 'column', gap: '5px',
                            }} onClick={() => locationRedirect(2)}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap: '3px' }}>
                                    <span>Canada Office</span>
                                    <img alt='mobile application development' src={leftIcon} style={{ width: '14px', height: '14px' }}></img>
                                </div>
                                <div className='location-meta-data'>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: '4px', alignItems: 'flex-start' }}>
                                        <img alt='web application developemt' src={mapIcon} style={{ marginTop: '5px' }}></img>
                                        <span>1145, Midland Avenue, Unit 9, Scarborough, Ontario M1k 4H2</span>


                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: '4px', alignItems: 'center' }}>
                                        <img alt='hybrid application development' src={callIcon}></img>
                                        <span>+91-7869678764</span>
                                    </div>
                                </div>
                            </div>




                            <div className="dot " id='location-3' style={{ top: '28.6%', left: '19.66%' }} onMouseEnter={() => { setLocation(3); setReset(!reset) }}
                                onMouseLeave={() => setReset(!reset)}></div>

                            <div className='dot-selected ' id='location-selected-3' style={{ top: '29.4%', left: '20%' }}>
                                <div className="dot" style={{ top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}> </div>
                            </div>
                            <div id='location-card-3' className="location-card" style={{
                                top: '29%', left: '20%', position: 'absolute',
                                boxShadow: '0px 0px 12px 0px rgba(0, 0, 0, 0.11)',
                                backgroundColor: ' rgba(255, 255, 255, 0.6)',
                                borderRadius: '4px',
                                padding: 15,
                                transform: 'translate(3%,3%)',
                                cursor: 'pointer', display: 'none', flexDirection: 'column', gap: '5px',
                            }} onClick={() => locationRedirect(3)}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap: '3px' }}>
                                    <span>Newark Office</span>
                                    <img alt='geofence' src={leftIcon} style={{ width: '14px', height: '14px' }}></img>
                                </div>
                                <div className='location-meta-data'>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: '4px', alignItems: 'flex-start' }}>
                                        <img alt='map' src={mapIcon} style={{ marginTop: '5px' }}></img>
                                        <span>112 Capitol Trail Suite A, Newark Delaware, 19711</span>

                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: '4px', alignItems: 'center' }}>
                                        <img alt='7869678764' src={callIcon}></img>
                                        <span>+91-7869678764</span>
                                    </div>
                                </div>
                            </div>



                        </div>
                    </div>
                </div>

            </div>

        )
}


const mapStateToProps = state => {
    const {
        locations
    } = state.we;

    return { locations }
}

const mapDispatchToProps = dispatch => ({
    getLocations: (successCallback) => dispatch(getLocations(successCallback)),
    submitContactForm: (params, callback, successCallback) => dispatch(submitContactForm(params, callback, successCallback))
})


export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);