import { Button, Input } from "antd";
import Dragger from 'antd/lib/upload/Dragger';
import styled from "styled-components";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

export const VacancyText = styled.div`
    letter-spacing: 0.25rem;
    font-weight: 600;
    font-size: calc(1vw + 3px);
    text-transform: uppercase;
    font-family: 'Sofia Sans', sans-serif;

    @media screen and (max-width: 767px){
        margin-left: -2vw;
        margin-right: -2vw;
        margin-top: 50px;
        font-size: calc(1vw + 10px);
    }
`

// export const ColorText = styled.div`
//     background: linear-gradient(90deg, #FF231A 0%, #FF26DF 87.27%);
//     -webkit-background-clip: text;
//     -webkit-text-fill-color: transparent;
//     background-clip: text;
//     text-fill-color: transparent;
// `

export const CareerImg = styled.img`
    width: 90%;

    @media screen and (min-width: 2000px){
        width: 60%;
    }
`

export const VacancyBody = styled.div`
    font-weight: 400;
    font-size: calc(1vw + 5px);
    margin-top: 24px;
    font-family: 'Inter', sans-serif;
    max-width:900px;
    text-align:center;

    @media screen and (max-width: 767px){
        margin-left: -2vw;
        margin-right: -2vw;
        font-size: calc(1vw + 10px);
    }
`

export const ExploreButton = styled(Button)`
    border: 1px solid #0086FF;
    border-radius: 4px;
    margin-top: 24px;
    padding: 2vw 4vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: calc(1vw + 5px);
    font-family: 'Sofia Sans', sans-serif;
    background-color: #0086FF !important;
    color:white !important;
    gap:10px;
    z-index:1;
    box-shadow: 0 20px 40px 0 rgba(35, 100, 210, 0.2);
    :hover{
       
        background-color: #0086FF !important;
        color:white !important;
        box-shadow: 0 20px 5px -10px rgba(0, 0, 0, 0.4);
        transform: translateY(1px);
     
      
}
    @media screen and (max-width: 767px){
        margin-left: -2vw;
        margin-right: -2vw;
        font-size: calc(1vw + 10px);
        padding: 4vw 6vw;
    }
`

export const PerksText = styled.div`
    letter-spacing: 0.25rem;
    font-weight: 600;
    font-size: calc(1vw + 3px);
    font-family: 'Inter', sans-serif;
    text-transform: uppercase;
    width:250px;
    text-align:center;
    @media screen and (max-width: 767px){
        margin-left: -2vw;
        margin-right: -2vw;
        margin-top: 50px;
        font-size: calc(1vw + 10px);
    }
`

export const Heading = styled.div`  
    font-weight: 700;
    font-size: calc(2vw + 15px);  
    font-family: 'Sofia Sans', sans-serif;  
    margin-top: 24px;
    position:relative;
    z-index:1;
    
    
    gap:4px;

    @media screen and (max-width: 767px){
        margin-left: -2vw;
        margin-right: -2vw;
        font-size: calc(2vw + 20px);
    }
`


// export const ColorText2 = styled.span`
//     background: linear-gradient(90deg, #0086FF 17.71%, #0D0EFF 100%);
//     -webkit-background-clip: text;
//     -webkit-text-fill-color: transparent;
//     background-clip: text;
//     text-fill-color: transparent;
// `

export const PerksBody = styled.div`
    font-weight: 400;
    font-size: calc(1vw + 3px);
    margin-top: 24px;
    text-align: center;
    font-family: 'Inter', sans-serif;
    width:75%;
   
    

    @media screen and (max-width: 767px){
        margin-left: -2vw;
        margin-right: -2vw;
        font-size: calc(1vw + 10px);
     
    }
`

export const PolicyCard = styled.div`
    width: 85%;
    margin-top: 50px;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    

    img{
        width: 20%;
    }

    @media screen and (max-width: 767px){
        width: 90%;

        img{
            width: 40%;
        }
    }

    @media screen and (max-width: 575px){
        width: 100%;

        img{
            width: 20%;
        }
    }
`

export const PolicyCardHeading = styled.div`  
    font-weight: 700;
    font-size: calc(1vw + 10px);    
    margin-top: 24px;
    font-family: 'Sofia Sans', sans-serif;
    text-align:center;

    @media screen and (max-width: 767px){
        font-size: calc(2vw + 10px);
    }
`

export const PolicyCardBody = styled.div`
    font-weight: 400;
    font-size: calc(1vw + 5px);
    margin-top: 24px;
    font-family: 'Inter', sans-serif;
    text-align:center;

    @media screen and (max-width: 767px){
        font-size: calc(1vw + 10px);
    }
`

export const JdCard = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border: 1.8px solid #efefef;
    height: 200px;
    background: #F2F9FF;
    width: 370px;
    padding: 8px 25px;
    border-radius: 20px;
    // box-shadow: 2px 2px 16px rgba(0, 0, 0, 0.1);
    &:hover,
    &:focus {
        cursor: pointer;
        
        box-shadow: 0 5px 10px 0 #aac3e1;
    }
    @media(max-width:800px){
        width:280px;
    }
      @media(max-width:400px){
        width:260px;
    }
`

export const JdCardHeading = styled.div`  
    font-weight: 700;
    width:100%;
  
   min-height:75px;
 padding-top:30px;
    font-size: 22px;    
      display: block;
  display: -webkit-box;
  max-width: 60vw;
 z-index:2;
  margin: 0 auto;
  margin-top:10px;
 
  line-height: 1;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

   font-family: 'Sofia Sans', sans-serif;

  

      
 

    @media screen and (max-width: 767px){
        font-size: calc(1vw + 16px);
           min-height:70px;
           max-width: 100vw;
           
    }
`

export const JdCardBody = styled.div`
    font-weight: 400;
    font-size: calc(1vw + 2px);
   
    display:flex;
    font-family: 'Inter', sans-serif;
   
    width:300px;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
 .location-tag{
    font-size:calc(1vw+10px);
 }

    @media screen and (max-width: 800px){
      
        width:250px;
         .location-tag{
        font-size:calc(1vw + 10px);
      }
    }
`
export const JdAddress = styled.div`
    font-weight: 400;
    font-size: calc(1vw + 5px);
    margin-top: 0px;
    display:flex;
    word-break: break-word;
    font-family: 'Inter', sans-serif;
    max-width:600px;
 
   
  

    @media screen and (max-width: 800px){
        font-size: calc(1vw + 12px);
       margin-top:0px;
    }
`

export const ViewMoreButton = styled(Button)`
    border: 1px solid #0086FF;
    border-radius: 4px;
    margin-top: 40px;
    font-size: calc(1vw + 6px);
    font-family: 'Sofia Sans', sans-serif;
    font-weight: 700;
    padding: 2vw 4vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0086FF !important;
    color:white !important;
    gap:10px;
    box-shadow: 0 20px 40px 0 rgba(35, 100, 210, 0.2);
    :hover{
    
            background-color: #0086FF !important;
            color:white !important;
            box-shadow: 0 20px 5px -10px rgba(0, 0, 0, 0.4);
            transform: translateY(1px);
         
    }

    @media screen and (max-width: 767px){
        padding: 4vw 6vw;
        font-size: calc(1vw + 10px);
    }
`

export const AllJobsText = styled.div`
    letter-spacing: 0.25rem;
    font-weight: 600;
    font-size: calc(1vw + 3px);
    text-transform: uppercase;
    font-family: 'Inter', sans-serif;

    @media screen and (max-width: 767px){
        margin-top: 50px;
        font-size: calc(1vw + 10px);
    }
`

export const FormInput = styled(Input)`
    font-size: calc(1vw + 3px);
    padding: 1vw 1vw;
    min-width: 50vw;
    height:60px;
    font-family: 'Sofia Sans', sans-serif;
    align-slef:center;

    @media screen and (max-width: 767px){
        font-size: calc(1vw + 6px);
        padding: 1vw 1vw;
     width: 80vw;
    }

    @media screen and (max-width: 575px){
        font-size: calc(1vw + 8px);
        padding: 2vw 2vw;
    }
`

export const PhoneInputArea = styled(PhoneInput)`
    &.react-tel-input .form-control {
        position: relative;
        font-size: calc(1vw + 3px);
        font-family: 'Sofia Sans', sans-serif;
        letter-spacing: .01rem;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        padding-left: 48px;
        margin-left: 0;
        background: #FFFFFF;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        line-height: 25px;
          height:60px;
     width: 50vw;
        outline: none;

        @media screen and (max-width: 767px){
            font-size: calc(1vw + 6px);
           width: 80vw;
        }

        @media screen and (max-width: 575px){
            font-size: calc(1vw + 8px);
        }
    }
`

export const SubmitButton = styled(Button)`
  width: 50vw;   
    height: 50px;
    background: transparent;
    color: #0086FF;
    font-weight: 700;
    margin-bottom: 140px;
    font-size: calc(1vw + 3px); 
    padding: 2vw 1vw;
    border: 1px solid #0086FF;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Sofia Sans', sans-serif;
    background-color: #0086FF !important;
    color:white !important;
    gap:10px;
    box-shadow: 0 20px 40px 0 rgba(35, 100, 210, 0.2);

    @media screen and (max-width: 767px){
        font-size: calc(1vw + 6px);
        padding: 2vw 2vw;
     width: 80vw;
    }

    @media screen and (max-width: 575px){
        font-size: calc(1vw + 8px);
        padding: 2vw 2vw;
    }
`

export const ApplyButton = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2vw 3vw;
    background: transparent;
    color: #0086FF;
    font-weight: 700;
    border: 1px solid #0086FF;
    margin-bottom: 40px;
    font-size: calc(1vw + 6px);
    font-family: 'Sofia Sans', sans-serif;
    background-color: #0086FF !important;
    color:white !important;
    gap:10px;
    box-shadow: 0 20px 40px 0 rgba(35, 100, 210, 0.2);
  
    :hover{
       
        background-color: #0086FF !important;
        color:white !important;
        box-shadow: 0 20px 5px -10px rgba(0, 0, 0, 0.4);
        transform: translateY(1px);
     
}

`

export const FileUpload = styled(Dragger)`
    font-size: calc(1vw + 3px);
    width: 100%;
    font-family: 'Sofia Sans', sans-serif;

      
    @media screen and (max-width: 767px){
        font-size: calc(1vw + 6px);

        & .ant-upload-btn{
            width: 80vw;
        }
    }

    @media screen and (max-width: 575px){
        font-size: calc(1vw + 8px);
    }
`

export const JobSearch = styled(Input)`
    width: 50vw; 
    padding: 1vw 2vw;
    margin-top: 20px;
    height: 48;
    font-size: calc(1vw + 6px);
    font-family: 'Sofia Sans', sans-serif;
    .ant-input{
        font-size:24px;
    }
    

    @media screen and (max-width: 767px){
        font-size: calc(1vw + 15px);
        padding: 1vw 1vw;
        width: 60vw;
    }

    @media screen and (max-width: 575px){
        font-size: calc(1vw + 15px);
        padding: 2vw 2vw;
    }
`