import WeContent from '../pages/we/weContent'
import OurStory from '../pages/we/subpages/ourStory/ourStory';
import Teams from '../pages/we/subpages/teams/teams';
import Joinus from '../pages/we/subpages/joinUs/joinUs';
import ContactUs from '../pages/we/subpages/contactUs/contactUs';

import ApplyNow from '../pages/we/subpages/joinUs/applyNow';
import SafetySystem from '../pages/technology/subpages/Mobility/Automotive/safetysystem';
import Infotainment from '../pages/technology/subpages/Mobility/Automotive/infotainment';
import Jobs from '../pages/we/subpages/joinUs/jobs';
import JobDescription from '../pages/we/subpages/joinUs/jobDescription';
import Submitsuccess from '../pages/we/subpages/contactUs/submitSuccess';
import ApplicationSuccess from '../pages/we/subpages/joinUs/submitSuccess';
import TechContent from '../pages/technology/technology';
import Mobility from '../pages/technology/subpages/Mobility/mobility';
import Connectivity from '../pages/technology/subpages/Connectivity/connectivity';

import SteeringSolution from '../pages/technology/subpages/Mobility/Automotive/steeringsolution';
import DriverAssistance from '../pages/technology/subpages/Mobility/Automotive/driverassistance';
import Adas from '../pages/technology/subpages/Mobility/Automotive/adas';
import AllEventsGallery from '../pages/we/subpages/teams/AllEventsGallery/allEventsGallery';
import EventGallery from '../pages/we/subpages/teams/EventGallery/eventGallery';
import AllEvents from '../pages/we/subpages/teams/AllEvents/allevents';
import NotFound from '../pages/technology/pagenotfound';
import Blogs from '../pages/we/subpages/teams/Blogs/blogs';
import AllBlogs from '../pages/we/subpages/teams/Blogs/allBlogs';
import BlogsDescription from '../pages/we/subpages/teams/Blogs/blogDescription';
import Terms from '../pages/we/subpages/terms/terms';
import PrivacyPolicy from '../pages/we/subpages/privacy/privacy'
import YCA from '../pages/technology/subpages/Connectivity/BCS-research/yca';
import BOTG from '../pages/technology/subpages/Connectivity/BCS-research/botg';
import OffHighway from '../pages/technology/subpages/Connectivity/BCS-research/off-highway';
import Autosar from '../pages/technology/subpages/Mobility/Automotive/autosar';
import JoinUsWrapper from '../pages/we/subpages/joinUs/joinUsWrapper';
import LidarResearch from '../pages/technology/subpages/Mobility/LidarPage/LIidar-research';
import Conference from '../pages/technology/subpages/Connectivity/BCS-research/conference';
import Broadcast from '../pages/technology/subpages/Connectivity/BCS-research/broadcast';
import Transcoding from '../pages/technology/subpages/Connectivity/BCS-research/transcoding';

export const routes = [
    { path:'/', element: <WeContent/>, 
      children: [
        {path: '/', element: <OurStory />},
          {path: '/our-story/gallery', element: <AllEventsGallery />},
          {path: '/our-story/eventGallery/:eventId', element: <EventGallery/>},
          {path: '/our-story/events', element: <AllEvents/>},
          {path: '/our-story/blogs', element: <Blogs/>},
          {path: '/our-story/allBlogs', element: <AllBlogs/>},
          {path: '/our-story/blog/:blogId', element: <BlogsDescription/>},
          { path:'/our-story/privacy-policy', element: <PrivacyPolicy/>},
          { path:'/our-story/terms-conditions', element: <Terms/>},
      
        {path: '/teams', element: <Teams />},
        {path: '/join-us', element: <JoinUsWrapper />,
          children: [
            {path:'',element:<Joinus/>},
            {path: 'jobs' , element: <Jobs/>},
            {path: 'application-form/:jobId', element: <ApplyNow/>},
            {path: 'job-description/:jobId' , element: <JobDescription/>},
            {path: 'application-sent' , element: <ApplicationSuccess/>}
          ]
        },
        {path: '/get-in-touch', element: <ContactUs />,
          children: [
            {path: 'submit-success' , element: <Submitsuccess/>},
          ]
        },
      ]
    },
    { path:'/technology', element: <TechContent/>,
    children: [
      {path: '', element: <Mobility/>},
          {path: 'mobility-solutions/safety-system' , element: <SafetySystem/>},
          {path: 'mobility-solutions/steering-solution', element: <SteeringSolution/>},
          {path: 'mobility-solutions/driver-assistance' , element: <DriverAssistance/>},
          {path: 'mobility-solutions/infotainment' , element: <Infotainment/>},
          {path: 'mobility-solutions/ADAS', element: <Adas/>},
          {path: 'mobility-solutions/LiDAR', element: <LidarResearch/>},
          {path: 'mobility-solutions/technology-behind', element: <Autosar/>},
          {path: 'connected-solutions', element: <Connectivity/>},
          {path:'connected-solutions/in-house-broadcast',element:<Broadcast/>},
          {path:'connected-solutions/in-house-conference',element:<Conference/>},
          {path:'connected-solutions/video-transcoding',element:<Transcoding/>},
          {path:'connected-solutions/YCA',element:<YCA/>},
          {path:'connected-solutions/BOTG',element:<BOTG/>},
          {path:'connected-solutions/Off-Highway-Power-Train-Remote-Diagnostics',element:<OffHighway/>}
        
        
       
     
    
    ]
   
    },

  {
    path:'*',element: <NotFound/>
  }
  
]

{/* <Routes>
            <Route exact path = '/' element = {<WeContent/>}>
              <Route exact path = '/x' element = {<OurStory/>}/>
              <Route exact path = '/teams' element = {<Teams/>}/>
              <Route exact path = '/join-us' element = {<Joinus/>}/>
              <Route exact path = '/contact-us' element = {<ContactUs/>}/>
              <Route exact path = '/jobs' element = {<Jobs/>}/>
              <Route exact path = '/application-f orm' element = {<ApplyNow/>}/>

            </Route>
            <Route path = '/iot' element = {<Iot/>}>
                <Route path = '/iot/passive-safety' element = {<PassiveSafety/>}/>
                <Route path = '/iot/active-safety' element = {<ActiveSafety/>}/>
                <Route path = '/iot/transmission-unit' element = {<TransmissionUnit/>}/>
                <Route path = '/iot/body-electronics' element = {<BodyElectronics/>}/>
                <Route path = '/iot/steering-solutions' element = {<SteeringSolutions/>}/>
                <Route path = '/iot/infotainment' element = {<Infotainment/>}/>
              </Route>
              <Route path = '/automotive' element = {<Automotive/>}>
                <Route path = '/automotive/gotel' element = {<Gotel/>}/>
                <Route path = '/automotive/dhruba' element = {<Dhruba/>}/>
                <Route path = '/automotive/botg' element = {<Botg/>}/>
                <Route path = '/automotive/off-highway-power-train-remote-diagnostics' element = {<Diagnostics/>}/>
              </Route>
          </Routes> */}