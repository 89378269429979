import Api from '../../../services/Api'
import Axios from 'axios';
export const SET_CURRENT_JOBS_PAGE = "SET_CURRENT_JOBS_PAGE";
export const SET_JOBS_SUMMARY = "SET_JOBS_SUMMARY";
export const SET_SEARCH = "SET_SEARCH";
export const SET_JOBS = "SET_JOBS";
export const SET_SELECTED_JOB = "SET_SELECTED_JOB";
export const SET_FEATURED_EVENTS="SET_FEATURED_EVENTS";
export const SET_EVENTS="SET_EVENTS";
export const SET_SELECTED_EVENT="SET_SELECTED_EVENT";
export const SET_LOCATIONS="SET_LOCATIONS";
export const SET_FEATURED_BLOGS="SET_FEATURED_BLOGS";
export const SET_MAGAZINES="SET_MAGAZINES";
export const SET_BLOGS="SET_BLOGS"
export const SET_SELECTED_BLOG="SET_SELECTED_BLOG";
export const SET_ALL_BLOGS="SET_ALL_BLOGS";
export const SET_SUGGESTED_BLOGS="SET_SUGGESTED_BLOGS";
export const SET_GALLERY="SET_GALLERY";
const headers={
  "ngrok-skip-browser-warning": "hey",
}
export const setCurrentPage = (page) => ({
    type: SET_CURRENT_JOBS_PAGE,
    page
})

export const setSearch = (val) => ({
    type: SET_SEARCH,
    val
})

export const setJobsSummary = (data) => ({
    type: SET_JOBS_SUMMARY,
    data
})
export const setFeaturedEvents=(data)=>({
  type:SET_FEATURED_EVENTS,
  data
})
export const setFeaturedBlogs=(data)=>({
  type:SET_FEATURED_BLOGS,
  data
})
export const setEvents=(data)=>({
  type:SET_EVENTS,
  data
})
export const setJobs = (data) => ({
  type: SET_JOBS,
  data
})
export const setSelectedJob = (data) => ({
  type: SET_SELECTED_JOB,
  data
})
export const setSelectedEvent = (data) => ({
  type: SET_SELECTED_EVENT,
  data
})
export const setLocations=(data)=>({
  type:SET_LOCATIONS,
  data
})
export const setMagazines=(data)=>({
  type:SET_MAGAZINES,
  data
})
export const setBlogs=(data)=>({
  type:SET_BLOGS,
  data
})
export const setSelectedBlog = (data) => ({
  type: SET_SELECTED_BLOG,
  data
})
export const setAllBlogs=(data)=>({
  type:SET_ALL_BLOGS,
  data
})
export const setSuggestedBlogs=(data)=>({
  type:SET_SUGGESTED_BLOGS,
  data
})
export const setGallery=(data)=>({
  type:SET_GALLERY,
  data
})


export const fetchJobsSummary = (callback) => (dispatch,getState) => {
  const allJobsData= getState().we?.allJobs;
    try{Axios.get('https://loginV2.brigosha.com/hiring-poc/jobsData',{
        headers: headers
      })
      .then((response,error) => {
        callback();
        if (response) {
          if(!allJobsData|| JSON.stringify(response) !== allJobsData) 
            dispatch(setJobsSummary(response.data))
        }
      })}
      catch(e){
        callback();
      }
  };

 export const fetchJobs = (callback) => (dispatch, getState) => {
      const params = {
        search: getState().we?.search,
        page: getState().we?.currentPage,
        limit: 24
      }
    Axios.get('https://loginV2.brigosha.com/hiring-poc/allJobs',{
        headers: headers,params:params
      }).then((response) => {
        callback();
        if (response) {
            dispatch(setJobs(response.data))
        }
      });
  };
  export const fetchBlogs = (params,successCallback) => (dispatch, getState) => {
    const allBlogsData=getState().we?.allBlogs;

    Api.get("/public-apis/allBlogs").params(params).send((response, err) => {
              
      if(response){
         
          if (!allBlogsData || JSON.stringify(response) !== allBlogsData) {
          
          dispatch(setAllBlogs(response))
          }
          successCallback()
      }
  
  });
};

  export const getJob=(params,successCallback)=>(dispatch,getState)=>{
    const currentJob=getState().we?.currentJob;
    Axios.get('https://loginV2.brigosha.com/hiring-publicapi/job-description',{
      params:params,
        headers: headers
      }).then((response) => {
  
        if (response ) {
          if(response.data?.show?.type!=='error'){

          if(! currentJob|| JSON.stringify(response) !==  currentJob) 
            dispatch(setSelectedJob(response.data))
          }
        }
        successCallback();
      });
  }
export const submitContactForm = (params, callback, successCallback)  => () => {
    Axios.post('https://loginV2.brigosha.com/hiring-poc/contact-us',params,{
        headers: headers
      })
        .then((response) => {
            console.log(response)
            callback();
            if (response  && response?.data?.show?.type === "success") {
              
             successCallback()
            }
        });

    callback();
};

export const submitApplicationForm = (params, callback, successCallback)  => () => {
     
        console.log(params)
        Axios.post('https://loginV2.brigosha.com/hiring-poc/applyForJob', params,{headers:{'Content-Type':'multipart/form-data',  "ngrok-skip-browser-warning": "69420",}}).then((response)=>{
            console.log(response)    
            if (response) {
                  
                       
                        if (response  && response?.data?.show?.type === "success") {
              
                            successCallback()
                           }
                       
                    
                   
                }
                callback(); 
        }) 
};

export const getFeaturedEvents=(successCallback)=>(dispatch,getState)=>{
      const featuredEventsData= getState().we?.featuredEvents;
      Api.get("/public-apis/allEventOfWebsite").send((response, err) => {
              
        if(response){
           
            if (!featuredEventsData || JSON.stringify(response) !== featuredEventsData) {
            
            dispatch(setFeaturedEvents(response))
            }
            successCallback()
        }
    
    });
    };
export const getFeaturedBlogs=(successCallback)=>(dispatch,getState)=>{
      const featuredBlogsData= getState().we?.featuredBlogs;
      Api.get("/public-apis/randomBlog").send((response, err) => {
              
        if(response){
           
            if (!featuredBlogsData || JSON.stringify(response) !== featuredBlogsData) {
            
            dispatch(setFeaturedBlogs(response))
            }
            successCallback()
        }
    
    });
    };
export const getAllMagazines=(successCallback)=>(dispatch,getState)=>{
      const magazinesData= getState().we?.magazinesData;
      Api.get("/public-apis/allEmagazine").send((response, err) => {
              
        if(response){
           
            if (!magazinesData || JSON.stringify(response) !== magazinesData) {
            
            dispatch(setMagazines(response))
            }
            successCallback()
        }
    
    });
    };
export const getAllEvents=(successCallback)=>(dispatch,getState)=>{
      const eventsData= getState().we?.events;
      Api.get("/public-apis/allEvents").send((response, err) => {
              
        if(response){
           
            if (!eventsData || JSON.stringify(response) !== eventsData) {
            
            dispatch(setEvents(response))
            }
            successCallback()
        }
    
    });
    };
 export const getAllBlogs=(successCallback)=>(dispatch,getState)=>{
      const blogsData= getState().we?.blogs;
      Api.get("/public-apis/allBlogs").send((response, err) => {
              
        if(response){
           
            if (!blogsData || JSON.stringify(response?.allBlogs) !== blogsData) {
            
            dispatch(setBlogs(response?.allBlogs))
            }
            successCallback()
        }
    
    });
    };
 export const getBlog=(params,successCallback)=>(dispatch,getState)=>{
      const blogData= getState().we?.selectedBlog;
      Api.get("/public-apis/blog").params(params).send((response, err) => {
              
        if(response){
           
            if (!blogData || JSON.stringify(response) !== blogData) {
            
            dispatch(setSelectedBlog(response))
            }
            successCallback()
        }
    
    });
    };
export const getEvent=(params,successCallback)=>(dispatch,getState)=>{
      const eventData= getState().we?.selectedEvent;
      Api.get("/public-apis/eventData").params(params).send((response, err) => {
              
        if(response){
           
            if (!eventData || JSON.stringify(response) !== eventData) {
            
            dispatch(setSelectedEvent(response))
            }
            successCallback()
        }
    
    });
    };

export const getLocations=(successCallback)=>(dispatch,getState)=>{
      const loctionsData= getState().we?.locations;
      Api.get("/public-apis/allLocations").send((response, err) => {
              
        if(response){
           
            if (!loctionsData || JSON.stringify(response) !== loctionsData) {
            
            dispatch(setLocations(response))
            }
            successCallback()
        }
    
    });
    };

export const getSuggestedBlogs=(params,successCallback)=>(dispatch,getState)=>{
      const suggestedBlogsData= getState().we?.suggestedBlogs;
      Api.get("/public-apis/blogsByTag").params(params).send((response, err) => {
              
        if(response){
           
            if (!suggestedBlogsData || JSON.stringify(response) !== suggestedBlogsData) {
            
            dispatch(setSuggestedBlogs(response))
            }
            successCallback()
        }
    
    });
    };
 export const getGallery=(successCallback)=>(dispatch,getState)=>{
      const galleryData= getState().we?.gallery;
      Api.get("/public-apis/galleryDataForWebsite").send((response, err) => {
              
        if(response){
           
            if (!galleryData || JSON.stringify(response) !== galleryData) {
            
            dispatch(setGallery(response))
            }
            successCallback()
        }
    
    });
    };