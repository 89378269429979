
import React,{useState,useEffect} from 'react';
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';
import { Row, Col, Button } from 'antd';
import Img from './icons/steering-img.svg';
import { Link } from 'react-router-dom';
import { ReactComponent as Back } from '../../../../../assets/back-icon.svg';
import bg2 from '../../../../../assets/bg2.svg'
import { ReactComponent as Arrow } from '../../../../we/subpages/ourStory/PotentialCard/Vector.svg';
import { getSuggestedBlogs } from '../../../../we/redux/action';
import { connect } from 'react-redux';
import icon1 from './icons/PS-1.svg';
import icon2 from './icons/PS-2.svg';
import icon3 from './icons/PS-3.svg';
import icon4 from './icons/EPS-1.svg';
import icon5 from './icons/EPS-2.svg';
import icon6 from './icons/EPS-3.svg';


function SteeringSolution({suggestedBlogs,getSuggestedBlogs}) {

    const handleBackBtn=()=>{
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        window.history.back();
    }
    const [loader,setLoader]=useState(false);
  
    useEffect(()=>{


      let params={
        tag:['MOBILITY','STEERING SOLUTION']
      }
    if(!suggestedBlogs||suggestedBlogs.length==0){
       
      setLoader(true)
}
  getSuggestedBlogs(params,()=>{
      setLoader(false)
  })
    },[])
    return (
        <React.Fragment>
             <div   style={{
      background:`url(${bg2}) center no-repeat`,backgroundSize:'100%',
      backgroundPositionY:'100%',
      backgroundColor:'#F3F8FD',
      }}>   
        < SteeringSolutionContainer>
       
            <div style={{'display':'flex','alignItems':'center','justifyContent':'flex-start',position:'relative',gap:'10px', paddingRight: '8vw',
      paddingLeft: '8vw'}}>
            <Back className='back-btn' onClick={handleBackBtn} />
       
            </div>
            <div className='SteeringSolution-header heading'>Steering <div className='highlight' >Solution</div></div>
           
            <br/>
            <BigCard {...Obj1}/>
          
            {loader ? <div  style={{'minHeight':'280px','display':'flex',justifyContent:'center','alignItems':'center',flexDirection:'column'}}>
                <LoadingOutlined style={{fontSize: "3em"}}/> 
                 
                </div> : suggestedBlogs?.length==0? 
                null:  
                <>   
                <Row gutter={10} style={{marginTop:'50px'}}  >
                <span style={{float:'left',fontSize:'23px',fontFamily:'Sofia Sans, sans-serif',paddingRight:'40px'}}>Also Read : </span>
              
                 
               
              </Row>
              <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'center',flexWrap:'wrap',gap:'18px', marginTop: '10px'}}>
                 {suggestedBlogs?.map(blog=>{
                    return  <Col >
                    <Card  {...blog}/>
                </Col>
                  })}
              
              </div></>
            }
        </ SteeringSolutionContainer>
        </div>
        </React.Fragment>
    )
}

const BigCard=({
    btnType,
    headline,
    description,
    buttonLabel,
    img,
    alt,
    imgStart,
})=>{
  const scrollToTop=()=>{
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  
  }
    return(
        <>
        
      <CardStyle
      wrap={true}
    >
        {/* <Col 
        xs={{span: 24, order: 2}} 
        sm={{span: 24, order: 2}} 
        md={{span: 12, order:  1}} 
        lg={{span: 12, order:  1}} 
        xl={{span: 12, order:  1}}
        xxl={{span: 12, order:  1}}
        className= 'card-text-container left'
        gutter = {20}
        >
           <h3 className='content card-heading'>Power Steering</h3>
            <p className='card-content content'>
            Power steering is a system that assists drivers in maneuvering their vehicles. It includes components
            like a hydraulic piston, pump, rotary control valve, and fluid lines.
            </p>
            <ul className='card-content content' style={{paddingLeft:'18px'}}>
              <li>Pump: Moves fluid to create hydraulic pressure.</li>
              <li>Rotary Control Valve: Regulates fluid flow based on the driver’s steering input.</li>
              <li>Hydraulic Piston: Applies force to steer the car, making it easier for the driver.</li>
            </ul>
            <h3 className=' content card-heading'>Electric Power Steering (EPS)</h3>
            <p className='card-content content'>Electric power steering uses electric assistance instead of hydraulic power.</p>
            <ul className='card-content content' style={{paddingLeft:'18px'}}>
              <li>Sensing the Road: EPS provides better road feedback for the driver.</li>
              <li>Control and Steering: EPS improves steering precision and control.</li>
              <li>Integration with ADAS: EPS enables integration with advanced driver assistance systems.</li>
            </ul>
            <p className='card-content content'>EPS is widely used in various vehicles, offering reliable steering performance in different driving
situations.</p>
<p className='card-content content'>
These steering solutions, including power steering and electric power steering, enhance handling and
ease of control for drivers.
</p>
            <div 
              className='button-holder'
              style={{justifyContent: 'flex-end',width:'172px'}}
            >
              <Link to={`/technology/adas`} onClick={  scrollToTop }> 
                <Button 
                  type = {btnType} 
                  className='card-button'
                  style ={{
                    color:  '#0086FF',
                    backgroundColor:  'transparent'
                  }}
                >
                  {buttonLabel}
                </Button>
              </Link>
            </div>
        </Col>

        <Col 
        xs={{span: 24, order: 1}}  
        sm={{span: 24, order: 1}}  
        md={{span: 12, order:  2}}
        lg={{span: 12, order:  2}}
        xl={{span: 12, order:  2}}
        xxl={{span: 12, order: 2}}
        className='content'
        style={{'display':'flex',justifyContent:'center',alignItems:'flex-start'}}
        >
          {
            img ? <img src={img} alt={alt} className='card-image' /> : null
          }  
         
        </Col> */}


        <div className='wrapper-div'>
        <div className='image-div-mobile'>
          <img src={img} alt={alt} className='card-image' />
          </div>
          <div className='content-1'>
          <h3 className='content card-heading'>Power Steering</h3>
            <p className='card-content content li-card-head-text' >
            Power steering is a system that assists drivers in maneuvering their vehicles. It includes components
            like a hydraulic piston, pump, rotary control valve, and fluid lines.
            </p>
            <ul className='card-content content li-cards' style={{listStyle:'none'}}>
              <li ><img alt='Power steering' src={icon1} className='icon-box'></img>
              <span className='icon-text'>
              <b>Pump</b> 
              Moves fluid to create hydraulic pressure.
              </span>
              </li>
              <li> <img alt='Rotary Control Valve' src={icon2} className='icon-box'></img>
              <span className='icon-text'>
              <b>Rotary Control Valve</b>
               Regulates fluid flow based on the driver’s steering input.
              </span>
              </li>
              <li> <img src={icon3} alt='Hydraulic Piston' className='icon-box'></img>
              <span className='icon-text'>
              <b>Hydraulic Piston</b>
              Applies force to steer the car, making it easier for the driver.
              </span>
              </li>
            </ul>
          </div>
          <div className='image-div'>
          <img src={img} alt={alt} className='card-image' />
          </div>
          <div className='content-2'>

          <h3 className=' content card-heading'>Electric Power Steering (EPS)</h3>
            <p className='card-content content li-card-head-text' style={{textAlign:'right'}}>Electric power steering uses electric assistance instead of hydraulic power.</p>
            <ul className='card-content content li-cards' style={{textAlign:'right',listStyle:'none'}}>
              <li>  
                 <span className='icon-text'>
                <b>Sensing the Road</b>
                EPS provides better road feedback for the driver.
                </span>
                <img  alt='Electric Power Steering' src={icon4} className='icon-box'></img></li>
              <li> 
              <span className='icon-text'>
                <b>Control and Steering</b>
                EPS improves steering precision and control.
                </span>
                <img alt='Integration with ADAS' src={icon5} className='icon-box'></img></li>
              <li>
              <span className='icon-text'>
                <b>Integration with ADAS</b> 
                EPS enables integration with advanced driver assistance systems.
                </span>
                <img alt='3D Lidar technology' src={icon6} className='icon-box'></img></li>
            </ul>
          </div>
        </div>
        <div style={{display:'flex',justifyContent:'center',width:'100%',flexDirection:'column',textAlign:'center',alignItems:'center',marginTop:'40px'}}>
        <p className='card-content content'>EPS is widely used in various vehicles, offering reliable steering performance in different driving
situations.</p>
<p className='card-content content' style={{fontWeight:500}}>
These steering solutions, including power steering and electric power steering, enhance handling and
ease of control for drivers.
</p>
            
        </div>
    </CardStyle>
    </>
    )
}

const CardStyle = styled(Row)`
  padding-top: 50px;
  text-align:left;
  padding-right: 8vw;
  padding-left: 8vw;
  .card-text-container{
    height: auto;
    display: flex;
    
    flex-direction: column;
   
  }
  .card-button:hover{
       
    background-color: #0086FF !important;
    color:white !important;
 }
  


  

  .card-image:hover{
    transform: scale(1.01);
    transition: all 0.3s ease-out;
  }

  .left{
    text-align: left;
  }

  .right{
    text-align: right;
  }

  .card-image{
    width: 80%;
    transition: all 0.3s ease-out;
    margin-bottom:30px;
  }

  .button-holder{
    width: 100%;

    
  }

  .card-content{
    font-size: calc(1vw + 3px);
    font-weight: 400;
    text-align:left;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
    font-family: 'Inter', sans-serif;
  }

  .card-heading {
    width: 100%;
    margin-bottom: 24px;
    font-size: calc(1vw + 6px);
    font-weight: 700;
    font-family: 'Sofia Sans', sans-serif;
  }
  
  .card-button{
    margin-top: 20px;
    display:flex;
    justify-content:flex-start;
    align-items: center;
    font-size: calc(1vw + 3px);;
    border-color: #0086FF;
    border-radius: 4px;
    font-weight: 700;
    padding: 2vw 4vw;
  }

  .card-button:hover{
    transform: scale(1.02);
    transition: all 0.3s ease-out;
  }

.wrapper-div{
  display:flex;
  flex-direction:row;
  justify-content:space-between;

}
.content-1,.content-2{
  max-width:33%;
}

.content-2{
  text-align:right !important;
}

.image-div{
  display:flex;
  justify-content:center;
}
.image-div-mobile{
  display:none;
}
.icon-box{
  width:80px;
  height:80px;
  background:white;
  padding:10px;
  border-radius:4px;

  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.11);
}
.li-cards li{
  display:flex;
  flex-direction:row;
  gap:20px;
  margin-top:20px;
  align-items:center;
}
 .icon-text{
  display:flex;
  flex-direction:column;
 }
.li-card-head-text{
  height:140px;
}
  @media screen and (min-width: 2000px){
    .card-image{
      width: 75%;
    }
  }

  @media screen and (max-width: 767px){

    .card-content{
      font-size: calc(1vw + 10px);
      text-align: left !important;
    }

    .card-heading{
      text-align: left !important;
      font-size: calc(1vw + 15px);
    }

    .button-holder{
      justify-content: flex-start !important;
    }

    .card-button{
      padding: 3vw 6vw;
      font-size: calc(1vw + 6px);
    }
    .wrapper-div{
      flex-direction:column;
    }
    .image-div{
      display:none;
    }
    .image-div-mobile{
      display:flex;
      justify-content:center;
    }
    .content-1,.content-2{
      max-width:100%;
    }
    .content-2{
      margin-top:30px;
    }
    .content-2 .li-cards li{

      display:flex;
      flex-direction:row-reverse;
     justify-content:flex-end;
    }
    .li-card-head-text{
     height: auto;
    }
  }

  @media screen and (max-width: 425px){
    .card-button{
      padding: 4vw 6vw;
    }

    .card-image{
      width: 90%;
    }
  }
`

const  SteeringSolutionContainer = styled.div`
    height: auto;
    background-color: transparent;
    padding-top: 40px;
    // padding-right: 8vw;
    // padding-left: 8vw;
    text-align: center;
    padding-bottom: 80px;
    min-height:70vh;
    .back-btn {
      
        cursor:pointer;
      
    }
 
    .SteeringSolution-header{
        font-size: calc(2vw + 15px);
        font-weight: 700;
        position:relative;
        z-index:2;
        display:flex;
        gap:7px;
        justify-content:center;
        align-items:center;
    }

    .SteeringSolution-top{
        padding-top: 20px;
        font-size: calc(1vw + 5px);
        font-weight: 400;
    }

    @media screen and (max-width: 767px){
        // padding-right: 8vw;
        // padding-left: 8vw;

        .back-btn>svg{
          width:25px;
          height:25px;
        }
       .SteeringSolution-header{
                font-size: calc(2vw + 20px);
          
                }

        .SteeringSolution-top{
            font-size: calc(1vw + 10px);
        }
    }
`

const Card = ({ title, locationPath,id }) => {
  return(
      <CardStyle1>
        



          <div class = "card">
          <Link to={`/our-story/blog/blogId=${id}`}>
  <img src={locationPath} alt="Event-Img" />
  <div class="card-content">
    <h2 >
    {title}
   
   
   </h2>
   <br/>
   <h3>  READ BLOG <Arrow></Arrow></h3>
  
    
  </div>
  </Link>
</div>

      </CardStyle1>
  )
}

  

const CardStyle1 = styled.div`
   max-width:350px;
  
   width:100%;
    margin-bottom: 10px;

    transition: transform 0.5s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
   max-height:270px;
   height:100%;
   

   .card {
    width: 100%;
    height: 100%;
    // border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    background-color: rgba(0,0,0,0.6);
    
    
  }
  .card img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.7;
    transition: opacity 0.2s ease-out;
  }
  .card h2 {
    position: absolute;
    inset: auto auto 50px 30px;
    margin: 0;
    transition: inset 0.3s 0.3s ease-out;
    font-family: 'Sofia Sans', sans-serif;
    font-weight: normal;
    text-transform: uppercase;
    color: #FFFFFF;
 
    text-align: left;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 240px;
 
  }
  .card h3 {
    position: absolute;
    inset: auto auto 20px 30px;
    margin: 0;
    transition: inset 0.3s 0.3s ease-out;
    font-family: 'Sofia Sans', sans-serif;
    font-weight: normal;
    text-transform: uppercase;
    color:#FFFFFF;
    width: 70%;
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis; 
    text-align:left;
    font-size:14px;
  }
 

    &:hover{
        transform: scale(1.02);
         box-shadow: 0 10px 20px 0  rgba(0,0,0,0.6);
    }

   



    .position{
        display: block;
        font-size: calc(1vw + 3px);
        margin-bottom: 10px;
        font-weight: 400;
    }
    @media screen and (max-width: 2400px){
    
    
      width:350px;
      height:250px;

      .card h2{
        width: 300px;
      }
    }
    @media screen and (max-width: 1850px){
    
      width:350px;
      height:250px;

      .card h2{
        width: 280px;
      }
      
    }
    @media screen and (max-width: 1230px){
      width:350px;
      height:250px;

      .card h2{
        width: 200px;
      }
    }
    @media screen and (max-width: 728px){

      width:80vw;
      height:280px;


        .title{
            font-size: calc(1vw + 10px);
        }

        .position{
            font-size: calc(1vw + 8px);
        }
        .card h2{
          width: 300px;
          font-size:14px;
        }
    }
    @media screen and (max-width: 425px){
     
      width:84vw;
      height:200px;
    img{
       
        height:250px;
        width:230px;
       
    }
    .event-head {
        
        width:230px;
        height:230px;
    }
    .card h2{
      width: 200px;
    }
    .title.heading{
        height:43px !important;
    }
    .glass-button {
        padding: 8px 20px;
        height:43px;
    }


    }
    @media screen and (max-width: 320px){
      width:260px;
      height:200px;
      .card h2{
        width: 200px;
      }
     img{
        
         height:230px;
         width:210px;
        
     }
     .event-head {
         
         width:210px;
         height:210px;
     }
     .glass-button {
        padding: 6px 18px;
        height:40px;
    }
    .title.heading{
        height:40px !important;
    }
 
     }
`


const mapStateToProps = state => {
  const {
      suggestedBlogs
  }  = state.we;

  return {suggestedBlogs}
}

const mapDispatchToProps =dispatch =>  ({
  getSuggestedBlogs:(params,successCallback)=>dispatch(getSuggestedBlogs(params,successCallback)),

})


export default connect(mapStateToProps, mapDispatchToProps)( SteeringSolution);





const Obj1 = {
    topLine: '',
    btnType: 'outline',
    headline: '',
    description: ` `,
    buttonLabel: '',
    img: Img,
    alt: 'steering-image',
    imgStart: ''
}

