import { Button, Result } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const scrollToTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}

const ApplicationSuccess = () => {
    const navigate = useNavigate();
    return (
        <div>
            <Result
                status="success"
                title="Successfully Submitted!"
                subTitle="Your job application has been submitted successfully. We will get back to you shortly!"
                extra={[
                    <Button onClick={() => {scrollToTop(); navigate('/');}} key={1}>Back to Home</Button>,
                ]}
            />
        </div>
    );
}

export default ApplicationSuccess;
