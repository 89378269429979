
import React ,{useEffect} from 'react';
import styled from 'styled-components';
import { Row, Col, Button } from 'antd';
import parse from 'html-react-parser';
import Img from './icons/yca-banner.svg'
import { ReactComponent as Back } from './icons/backIcon.svg';
import Img1 from './icons/Attendance-Authentication.svg';
import Img2 from './icons/Resource_Management.svg';
import Img3 from './icons/Project_Management.svg';
import { Link } from 'react-router-dom';
import bg2 from '../../../../we/subpages/ourStory/ourCulture/BG.svg'

function YCA() {
  
    const handleBackBtn=()=>{
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        window.history.back();
    }
    useEffect(()=>{
      document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
    },[])
    return (
        <React.Fragment>
          
          <div   style={{
      background:`url(${bg2}) center no-repeat`,backgroundSize:'cover'
  
      }}> 
          
        < YCAContainer>
      


            <div style={{'display':'flex','alignItems':'center','justifyContent':'center',position:'relative'}}>
           <Back className='back-btn' onClick={handleBackBtn} />
          {/* <div className='YCA-header heading'> <div className='highlight'>YCA</div></div> */}
           
           
            </div>
            <br/>
            <BigCard {...Obj1}/>
            <br/>
            <br/>
            <div className='YCA-header heading'><div className='highlight'>Enterprise Solutions</div> at it’s very Best</div>
            <br/>
           
         
            <BigCard {...Obj2}/>
            <BigCard {...Obj3}/>
            <BigCard {...Obj4}/>
            <br/>
            <br/>
            <br/>
            <br/>
            <div className='YCA-header heading' style={{fontSize:'28px'}}>COMING SOON .<div className='highlight'>.</div>.</div>
            <br/>
            <br/>
        </ YCAContainer>
        </div> 
        </React.Fragment>
    )
}

const BigCard=({
    btnType,
    buttonLabel,
    img,
    alt,
    headline,
    description,
    link,
    imgStart
 
})=>{

    const scrollToTop=()=>{
        document.body.scrollTop = 0; // For Safari
          document.documentElement.scrollTop = 0;
    }  
    return(
        <>
        <CardStyle
        wrap={true}
      >
          <Col 
          xs={{span: 24, order: 2}} 
          sm={{span: 24, order: 2}} 
          md={{span: 12, order: imgStart === 'start' ? 2 : 1}} 
          lg={{span: 12, order: imgStart === 'start' ? 2 : 1}} 
          xl={{span: 12, order: imgStart === 'start' ? 2 : 1}}
          xxl={{span: 12, order: imgStart === 'start' ? 2 : 1}}
          className={imgStart === 'start' ? 'card-text-container left' : 'card-text-container left'}
          gutter = {20}
          >
            <h2 className='card-heading'>{parse(headline)}</h2>
         
            <p className='card-content content'>
          {description}
            </p>
            {
                link.length!==0? <div 
                className='button-holder'
                style={{justifyContent: 'flex-start' }}
              >
                  <a href={`${link}`} > 
                    <Button 
                      type = {btnType} 
                      className='card-button'
                      style ={{
                        color:  'white' ,
                        backgroundColor: 'transparent',
                        
                      }}
                    >
                      {buttonLabel}
                    </Button>
                  </a>
              
                
              </div>:null}

        
            
          </Col>
  
          <Col 
          xs={{span: 24, order: 1}}  
          sm={{span: 24, order: 1}}  
          md={{ span: 12, order: imgStart === 'start' ? 1 : 2 }}
          lg={{ span: 12, order: imgStart === 'start' ? 1 : 2 }}
          xl={{ span: 12, order: imgStart === 'start' ? 1 : 2 }}
          xxl={{ span: 12, order: imgStart === 'start' ? 1 : 2 }}
          className='content'
          style={{'display':'flex',justifyContent:'center'}}
          >
            {
              img ? <img src={img} alt={alt} className='card-image' /> : null
            } 
           
          </Col>
      </CardStyle>
     
    </>
    )
}
const  YCAContainer = styled.div`
    height: auto;
    background-color: transparent;
    padding-top: 40px;
    padding-right: 8vw;
    padding-left: 8vw;
    text-align: center;
    padding-bottom: 80px;
 
    .back-btn {
        position:absolute;
        left:0;
        cursor:pointer;
      
    }
    .back-btn svg{
        width:30px;
        height:30px;
    }
    .YCA-header{
        font-size: calc(2vw + 15px);
        font-weight: 700;
        position:relative;
        z-index:2;
   
    }

    .YCA-top{
        padding-top: 20px;
        font-size: calc(1vw + 5px);
        font-weight: 400;
    }
    .YCA-icon{
      display:flex;
      flex-direction:column;
      align-items:center;
      background-color:rgba(229, 245, 255, 1);
      width:170px;
      height:154px;
      justify-content:center;
      border-radius:14px;
     
      
    }
    .YCA-icon span{
      font-size: calc(1vw + 5px) ;
      font-family: 'Inter', sans-serif;
      font-weight:700 !important;
    }
    .YCA-card{
      display:flex;
      flex-direction:row;
      align-items:center;
    
      max-width:600px;
   justify-content:flex-start;
      gap:30px;
    }
    .YCA-card-info{
      width:400px;
      font-size: calc(1vw + 5px);
      font-family: 'Inter', sans-serif;
      font-weight:500;
      text-align:left;
    }

    @media screen and (max-width: 767px){
        padding-right: 8vw;
        padding-left: 8vw;

        .YCA-header{
            font-size: calc(2vw + 20px);
        }

        .YCA-top{
            font-size: calc(1vw + 10px);
        }
        .YCA-card-info,  .YCA-icon span{
          font-size: calc(1vw + 10px);
        }
        .YCA-card{
          max-width:80vw;
        }
    }
`

const CardStyle = styled(Row)`
  padding-top: 50px;
  text-align:left;

  .card-text-container{
    height: auto;
    display: flex;
    
    flex-direction: column;
   
  }
  

  .card-image:hover{
    transform: scale(1.01);
    transition: all 0.3s ease-out;
  }

  .left{
    text-align: left;
  }

  .right{
    text-align: right;
  }

  .card-image{
    width: 80%;
    transition: all 0.3s ease-out;
  }

  .button-holder{
    width: 100%;

    
  }

  .card-content{
    font-size: calc(1vw + 5px);
    font-weight: 400;
    text-align:left;
    font-family: 'Inter', sans-serif;
    word-wrap: break-word;
  }

  .card-heading {
    width: 100%;
    margin-bottom: 24px;
    font-size: calc(2vw + 1px);
    font-weight: 700;
    font-family: 'Sofia Sans', sans-serif;
  }
  
  .card-button{
    margin-top: 20px;
    display:flex;
    justify-content:flex-start;
    align-items: center;
    font-size: calc(1vw + 3px);;
    border-color: #0086FF;
    border-radius: 4px;
    font-weight: 700;
    padding: 2vw 4vw;
    background-color: #0086FF !important;
    color:white !important;
    box-shadow: 0 20px 40px 0 rgba(35, 100, 210, 0.2);
  }

  .card-button:hover{
    background-color: #0086FF !important;
    color:white !important;
    box-shadow: 0 20px 5px -10px rgba(0, 0, 0, 0.4);
     transform: translateY(1px);
  }
 

  @media screen and (min-width: 2000px){
    .card-image{
      width: 75%;
    }
  }

  @media screen and (max-width: 767px){

    .card-content{
      font-size: calc(1vw + 10px);
      text-align: left !important;
    }

    .card-heading{
      text-align: left !important;
      font-size: calc(1vw + 15px);
    }

    .button-holder{
      justify-content: flex-start !important;
    }

    .card-button{
      padding: 3vw 6vw;
      font-size: calc(1vw + 6px);
    }
  }

  @media screen and (max-width: 425px){
    .card-button{
      padding: 4vw 6vw;
    }

    .card-image{
      width: 90%;
    }
  }
`
export default  YCA;



const Obj1 = {
    topLine: '',
    btnType: '',
    headline:`<span className="YCA-header">Embark On A <div className='highlight' style="display:unset">Revolutionary Voyage With YCA</div></span>`,
    buttonLabel: 'REQUEST A DEMO NOW',
    img: Img,
    description:"  Introducing [Your Company] App, The ultimate enterprise solution! Streamline attendance, leave, and project management effortlessly. Get real-time updates on your team’s status, approve requests on the go, and generate custom reports.",
    link:'mailto:info@brigosha.com',
    alt: 'yca-image',
    imgStart: ''
}


const Obj4 = {
    topLine: '',
    btnType: '',
    headline:`Attendance Management`,
    buttonLabel: '',
    description:`With YCA's time and attendance tracking feature, effortlessly monitor and record attendance, manage leaves, and generate reports for streamlined workforce management.`,
    img: Img1,
    link:'',
    alt: 'yca-image',
    imgStart: 'start'
}
const Obj2 = {
    topLine: '',
    btnType: '',
    headline:`Resource Management`,
    buttonLabel: '',
    description:`YCA's resource allocation feature empowers you to effectively assign and manage resources, including personnel, equipment, and materials for optimal project utilization.`,
    img: Img2,
    link:'',
    alt: 'yca-image',
    imgStart: 'start'
}

const Obj3 = {
    topLine: '',
    btnType: '',
    headline:`Project Management`,
    buttonLabel: '',
    description:`YCA's task coordination feature enables seamless organization and tracking of tasks, ensuring efficient project execution.`,
    img: Img3,
    link:'',
    alt: 'yca-image',
    imgStart: ''
}