import React from 'react';
import styled from 'styled-components';
import Card from '../../../card'

import Img from './connectivity-banner.svg';

function TrueConnectivity() {
    return (
        <TrueConnectivityContainer>
            {/* <div className='trueConnectivity-header heading'>Explore The {window.innerWidth>360?null:'True '}<div className='gradient4' >{window.innerWidth>360?'True Connectivity':'Connectivity'}<div className='polygon4' ></div></div></div>
             */}
            <br/>
            <Card {...Obj1}/>
           
        </TrueConnectivityContainer>
    )
}

const TrueConnectivityContainer = styled.div`
    height: auto;
    background-color: transaprent;
    padding-top: 40px;
    padding-right: 10vw;
    padding-left: 10vw;
    text-align: center;
  

    .trueConnectivity-header{
        font-size: calc(2vw + 15px);
        font-weight: 700;
        position:relative;
        z-index:2;
    }

    .trueConnectivity-top{
        padding-top: 20px;
        font-size: calc(1vw + 5px);
        font-weight: 400;
    }

    @media screen and (max-width: 767px){
        padding-right: 8vw;
        padding-left: 8vw;

        .trueConnectivity-header{
            font-size: calc(2vw + 20px);
        }

        .trueConnectivity-top{
            font-size: calc(1vw + 10px);
        }
    }
`

export default TrueConnectivity;



const Obj1 = {
    topLine: '',
    btnType: 'outline',
    headline: `<div style="font-size: calc(2vw + 15px)">Power Of <div className='highlight'>Connectivity</div></div>`,
    description: `The rapid advancement of technology has revolutionized connectivity, allowing people to
    connect and collaborate. The Internet of Things and connected devices have redefined autonomy,
    creating robust ecosystems that enable seamless sharing of information. Connectivity remains
    vital, driving innovation, technology, and knowledge, with evolving concepts shaping the way
    we connect and do business.`,
    buttonLabel: '',
    img: Img,
    alt: 'true-connectivity-image',
    imgStart: '',
    link:'' ,
    height:80
}
