import React from 'react';
import styled from 'styled-components';
import Card from './Card';
import Automotive from './mobilityExpertise.svg'
import Iot from './connectivityExpertise.svg';
import bg from './BG.svg'

function Expertise() {
    return (
        <ExpertiseContainer>
            {/* <div className='expertise-header heading'>Our <div className='gradient2'> <span className='highlight' ></span> Expertise<div  className='polygon2' ></div></div></div> */}
            <div className='expertise-header heading'>Our <span className='highlight' >Expertise</span> </div>
            <p className='expertise-top content grey'>
            Usage of AI & Intelligent machines to produce high quality solutions that maximize human potential.
            </p>
            <br/>
            <Card {...Obj1}/>
            <Card {...Obj2}/>
            <br/>
            <br/>
            <br/>
            <br/>
            <div className='expertise-header heading'>Work, <span className='highlight' >Fun & Frolic</span> </div>
            <p className='expertise-top content grey'>
            People rarely succeed unless they have fun in what they are doing and we understand that. Experience our vibrant, free-spirited culture wherein our associates are our heartbeat! We not only encourage a ‘have-fun-while-working’ culture here, but also conduct a range of programs and activities to foster personal growth as well as camaraderie among associates. From wellness initiatives to skill-building workshops, brigosha is your compass towards success and fulfillment.
            </p>
            
        </ExpertiseContainer>
    )
}

const ExpertiseContainer = styled.div`
    height: auto;
    background-color: #FFF;
    padding-top: 50px;
    padding-right: 10vw;
    padding-left: 10vw;
    text-align: center;
    // padding-bottom: 40px;
    background-color:transparent !important;
//     background:url(${bg}) center no-repeat;
// background-size:cover;
// background-position:100% -90%;

    .expertise-header{
        font-size: calc(2vw + 15px);
        font-weight: 700;
        position:relative;
        z-index:2;
        font-family: 'Sofia Sans', sans-serif;
        color:#1e375a;
    }

    .expertise-top{
        padding-top: 20px;
        font-size: calc(1vw + 5px);
        font-weight: 400;
        font-family: 'Inter', sans-serif;
    }

    @media screen and (max-width: 767px){
        padding-right: 8vw;
        padding-left: 8vw;

        .expertise-header{
            font-size: calc(2vw + 20px);
        }

        .expertise-top{
            font-size: calc(1vw + 10px);
        }
    }
`

export default Expertise;


const Obj1 = {
    topLine: '',
    btnType: 'primary',
    headline: 'Mobility',
    description: 'Embrace the future of mobility with brigosha. Discover our expertise in autonomous driving, connected technologies, and cutting-edge mobility solutions. Experience the revolution firsthand and drive towards a smarter and more efficient world.',
    buttonLabel: 'READ MORE',
    img: Automotive,
    alt: 'automotive-image',
    imgStart: '',
    link:'technology'
}

const Obj2 = {
    topLine: '',
    btnType: 'outline',
    headline: 'Connectivity',
    description: 'We specialize in creating seamless connections through IoT, cloud integration, and advanced networking solutions. Unlock the true potential of connected technologies for a complete remodeling of your digital landscape.',
    buttonLabel: 'READ MORE',
    img: Iot,
    alt: 'iot-image',
    imgStart: 'start',
    link:'technology/connected-solutions'
}
