import React from 'react';
import Iot from './iot/iot';
import TrueConnectivity from './connectivity/trueconnectivity';
import TechStack from './techstack/techstack';
import ProductDevelopment from './productdevelopment/product';
import ApplicationDevelopment from './application/application';
import bg1 from '../../../we/subpages/ourStory/PotentialCard/potentialBG.svg'
import bg2 from '../../../../assets/bg2.svg'
import BCS from './BCS/bcs';
import BCSResearch from './BCS-research/bcs-research';
import { Outlet } from 'react-router-dom';
function Connectivity() {
   const paths = [
      '/technology/connected-solutions/YCA',
      '/technology/connected-solutions/BOTG', 
      '/technology/connected-solutions/off-highway',
      
  ]

   return (

      paths.includes(window.location.pathname) ? 
      <Outlet/> :
      
      <React.Fragment>
          <div style={{
       background: `url(${bg1}) top no-repeat`,
       backgroundSize: '100%',
       overflow: 'hidden',
     width:'100%',
backgroundColor:'#F3F8FD',
      backgroundPositionY:'-20vh'
     
         }} className='background-image'>
        <TrueConnectivity/>
        <div   style={{
    background:`url(${bg2}) center no-repeat`,backgroundSize:'cover',
   
    


    }}>
      <TechStack/>
          <Iot/>
          
          </div>
        
        </div>
 
    
       
      
        <BCS/>
   
        <BCSResearch/>
        
        <div   style={{
    background:`url(${bg2}) center no-repeat`,backgroundSize:'100%',
    backgroundPositionY:'100%',
    backgroundColor:'#F3F8FD',
   }}>


        <ProductDevelopment/>
        {/* <ApplicationDevelopment/> */}
      </div>
         
       
    
      </React.Fragment>
   )
}

export default Connectivity;

