import { LoadingOutlined, SearchOutlined ,CloseCircleOutlined} from '@ant-design/icons';
import { Col, Input, Row } from 'antd';
import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../../../components/pagination';
import { setCurrentPage, setSearch, fetchJobs } from '../../redux/action';
import {jds, jdCard} from './joinUs';
import { Heading, AllJobsText, JobSearch } from './styles';
import NoJob from './nojob.gif'
import './styles.css'
import bg2 from '../ourStory/ourCulture/BG.svg'
import { ReactComponent as Back } from '../../../../assets/back-icon.svg';
function Jobs({
    setCurrentPage, currentPage, 
    fetchJobs, allJobs,
    search, setSearch, jobsMeta, 

}) {

    const navigate = useNavigate();
    const [pageLoader, setPageLoader] = useState(false);
    

    useEffect(() => {
        setSearch('');
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
        setCurrentPage(1);
        setPageLoader(true);
        fetchJobs(() => setPageLoader(false));
     
    }, []);

    const handleSearch = (val) => {
        setSearch(val);
        if(val.length >= 1){
            setCurrentPage(1)
            setPageLoader(true);
            fetchJobs(() => setPageLoader(false));
        }else if(val.length === 0){
            setCurrentPage(1)
            setPageLoader(true);
            fetchJobs(() => setPageLoader(false));
        }
    }

    const handlePageChange = page => {
        setCurrentPage(page);
        setPageLoader(true);
        fetchJobs(() => setPageLoader(false));
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    const handleClick = (data) => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        navigate(`/join-us/job-description/jobId=${data.id}`)
       
    }
    const handleBackBtn=()=>{
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        window.history.back();
       
    }
  return (
    <div   style={{
        background:`url(${bg2}) center no-repeat`,backgroundSize:'cover'
    
        }}>
    <div style={{padding: '40px 8vw',minHeight:'70vh'}}>  
    <div style={{'display':'flex','alignItems':'center','justifyContent':'flex-start',position:'relative',gap:'10px',width:'100%',maxWidth:'100vw'}}>
           <Back className='back-btn' onClick={handleBackBtn} />

            
            </div>
            <Row align='center'>
            <Heading style={{marginTop:0,textAlign:'center'}}><span style={{minWidth:'200px',textAlign:'center'}}>Search Jobs At </span><div className='highlight' >brigosha</div></Heading>
            </Row>
            <Row align='center'>
                <JobSearch 
                    prefix={<SearchOutlined style={{marginRight:'10px',}} />} 
                    suffix={<CloseCircleOutlined  onClick={()=>handleSearch('')}/>}
                    placeholder="Search"
                    onChange={(e) => handleSearch(e.target.value)}
                    value={search} 
                />
            </Row>
            {pageLoader ? 
                <div style={{height: "70vh", display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <LoadingOutlined style={{fontSize: "3em"}}/> 
                </div>
            :
            <>
            <Row>
                <AllJobsText className='content darkblue' style={{marginTop: 40}}>ALL JOBS({jobsMeta.totalItems})</AllJobsText>   
            </Row>
            {allJobs?.length===0?
                <div style={{'display':'flex','flexDirection':'column',justifyContent:'center',alignItems:'center',minHeight:'50vh'}}>
                 <img  src={NoJob} width={250} height={250}/>
                 <span><b>No Job Found! Hold Tight! </b></span>
                 <span><b>Your favorite job role is on its way soon.</b></span>
                 </div>
            :<>
            <Row style={{marginTop: 20, marginBottom: 60,display:'flex',justifyContent:'center',alignItems:'center'}}>
         
            <div className='jd-grid'>    
                
                       { allJobs.map((jd, index) => jdCard(jd, index, handleClick)) }
                        
                        
                        </div>
             
            </Row>
            <Row justify="space-between" >
            
                <Col span={24}>
                    <Pagination total={jobsMeta.totalItems} pageSize={24} length={jobsMeta.itemCount} currentPage={currentPage} showTotal onPageChange={(page) => handlePageChange(page)}/>
                </Col>
            </Row></>
            }
        </>}
    </div>
    </div>
  )
}


const mapStateToProps = state => {
    const {
        currentPage, allJobs, search, jobsMeta
    }  = state.we;

    return {currentPage, allJobs, search, jobsMeta}
}

const mapDispatchToProps =dispatch =>  ({
    setCurrentPage: (val) => dispatch(setCurrentPage(val)),
    setSearch: (val) => dispatch(setSearch(val)),
    fetchJobs: (callback) => dispatch(fetchJobs(callback)),
   
})


export default connect(mapStateToProps, mapDispatchToProps)(Jobs);
