import React, { useState,useEffect } from 'react';
import styled from 'styled-components';
import { Row, Col, Button } from 'antd';
import { Link } from 'react-router-dom';
import {ReactComponent as Arrow} from './svgs/Vector.svg'
import Img1 from '../Automotive/svgs/safety.svg'
import Img2 from '../Automotive/svgs/steering.svg'
import Img3 from '../Automotive/svgs/driving.svg'
import Img4 from '../Automotive/svgs/infotainment.svg'
import Img5 from '../Automotive/svgs/autosar-icon.svg'
import img1 from '../Automotive/svgs/CAN-bus.svg'
import img2 from '../Automotive/svgs/UDS.svg'
import img3 from '../Automotive/svgs/V-model.svg'
import img4 from '../Automotive/svgs/SDLC.svg'


function Automotive() {
    const scrollToTop = () => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
       
    }
    const length=Obj1?.length;
    useEffect(() => {
      
         const mount = setInterval(() =>{
            setActive((prev) => {
                 if(prev == length-1){
                     return prev-(length-1);
                 }
                 else{
                     return prev+1;
                 }
             })
         }, 3000)
 
         return () => clearInterval(mount);
     
     }, [])
    const [active,setActive]=useState(0);
    return (
        <AutomotiveContainer>
            {/* <div className='automotive-header heading'>Department {window.innerWidth>=360?null:'Of '}<div className='gradient4'>{window.innerWidth>=360?'Of Automotive World':'Automotive'}<div className='polygon4'></div></div>{window.innerWidth>=360?null:' World'}</div> */}
            <div className='automotive-header heading'><div className='highlight'>Mobility</div> World</div>
            <br/>
            <br/>
         
            {/* <div>
                <div className='card-row'> */}
 <Row justify='space-evenly'  >
              
        
                    <Col  className='team-card' >
                    <Cards {...Obj[0]} />
                </Col>
                <Col  className='team-card' >
                    <Cards {...Obj[1]}/>
                </Col>
                </Row>
                <br/>
                <Row justify='space-evenly'>
                <Col  className='team-card' >
                    <Cards {...Obj[2]}/>
                </Col>
                <Col  className='team-card' >
                    <Cards {...Obj[3]}/>
                </Col>
                </Row>
                <br/>
                <Row justify='space-evenly'>
                <Col  className='team-card' >
                    <Cards {...Obj[4]}/>
                </Col>
               
                </Row>
                 {/* </div> */}
                  {/* <Link to={`/technology/mobility-solutions/technology-behind`} onClick={
                    scrollToTop()
                  }> 
                <Button 
             
             className='card-button'
             style ={{
               color:  '#0086FF',
               backgroundColor: 'transparent'
             }}
          
           >
             EXPLORE THE TECHNOLOGY BEHIND<Arrow className='arrow'/>
           </Button>
           </Link> */}
              
                {/* <Row justify='center' style={{marginTop:'20px'}}>
                  
                 <Card  active={active} setActive={setActive} />
               
               

             
                </Row> */}
            
            {/* </div> */}
           
        </AutomotiveContainer>
    )
}

const AutomotiveContainer = styled.div`
    height: auto;
    background-color: transparent;
    padding-top: 60px;
    padding-right: 8vw;
    padding-left: 8vw;
    text-align: center;
 
  
    .automotive-header{
        font-size: calc(2vw + 15px);
        font-weight: 700;
        position:relative;
        z-index:2;
    }
    .card-button{
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
       
        font-size: calc(1vw + 3px);;
        border-color: #0086FF;
        border-radius: 4px;
        font-weight: 700;
        gap:10px;
        padding: 2vw 4vw;
        background-color: #0086FF !important;
        color:white !important;
        box-shadow: 0 20px 40px 0 rgba(35, 100, 210, 0.2);
      }
      .card-button .arrow>path{
         fill:white;
      }
    
      .card-button:hover{
         
        background-color: #0086FF !important;
        color:white !important;
        box-shadow: 0 20px 5px -10px rgba(0, 0, 0, 0.4);
         transform: translateY(1px);
     }

    .automotive-top{
        padding-top: 20px;
        font-size: calc(1vw + 5px);
        font-weight: 400;
    }
    .team-card{
        display:flex;
        justify-content:center;
    }
    .card-row{
       
        display:grid;
        grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
    justify-content: center;
    place-items: center;
        gap:10px;
      place-items:center;
    }

    @media screen and (max-width: 767px){
        padding-right: 8vw;
        padding-left: 8vw;
        .card-row{
       
            display:grid;
            grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
        justify-content: center;
        place-items: center;
            gap:10px;
          place-items:center;
        }

        .automotive-header{
            font-size: calc(2vw + 20px);
        }

        .automotive-top{
            font-size: calc(1vw + 10px);
        }
    }
`






const Cards = ({ name, img ,link,}) => {
  
    const scrollToTop = () => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
       
    }
    
    return(
        <CardStyles  >
           
            <Link to={`/technology/${link}`} onClick={scrollToTop} style={{display:'flex',flexDirection:'column',justifyContent:'space-between',width:'100%',height:'100%'}}>
            <img src={img} alt={name}  className='card-image' />
            <h1 className='title heading' >{name}</h1>
                {/* <Button 
             
                  className='card-button'
                  style ={{
                    color:  '#0086FF',
                    backgroundColor: 'transparent'
                  }}
               
                >
                  READ MORE
                </Button> */}
                </Link>
        </CardStyles>
        
    )
}

const CardStyles = styled.div`
    max-width:280px;
    max-height:300px;
    margin-bottom: 10px;
    transition: all 0.5s ease-out;
    display: flex;
    flex-direction: column;
    align-items: center;
  
    background-color:white;
    border-radius:10px;
   box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.11);
    padding:15px 15px;
    &:hover  .title {
      color:#0086FF;
    }
    &:hover  .arrow>path{
        fill:#0086FF;
    }
    
    &:hover{
        transform: scale(1.03); 
        transition: all 0.3s ease-out;
          box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.11);
    }

    // card-image:hover{
    //     transform: scale(1.02);
    // }

  
    .card-image{
 
        border-radius:10px;
         width:100% !important;
        // height:100%;
        object-fit:fill;
        margin-bottom:10px;
      
        transition: all 0.3s ease-out;
      }

    .title{
        text-align: left;
        font-size: calc(1vw + 3px);
        font-weight: 700;
        display:flex;
        flex-direction:row;
        align-items:center;
        gap:10px;
    }

    .position{
        display: block;
        font-size: calc(1vw + 3px);
        margin-bottom: 10px;
        font-weight: 400;
    }
    .card-button{
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size:  calc(1vw + 3px);
        border-color: #0086FF;
        border-radius: 4px;
        font-weight: 700;
        padding: 2vw 4vw;
      }
   .arrow{
    align-self: center;
   
   }

    @media screen and (max-width: 728px){
        max-width:250px;
        max-height:250px;
        .title{
            font-size: calc(1vw + 10px);
        }
             }
        .position{
            font-size: calc(1vw + 8px);
        }
        .card-button{
            font-size:12px;
        }
    }
    @media screen and (max-width:430px){
        .arrow{
            align-self: flex-end;
            padding-bottom: 2px;
            margin-bottom: 4px;
          
          
    }
}
`



const Obj = [
    {
        name: 'Safety Systems',
        img: Img1,
        link:'mobility-solutions/safety-system'
    },
    {
        name: 'Steering Solution',
        img: Img2,
        link:'mobility-solutions/steering-solution'
    },
    {
        name: 'Driver Assistance',
     
        img: Img3,
        link:'mobility-solutions/driver-assistance'
    },
    {
        name: 'Infotainment',
     
        img: Img4,
        link:'mobility-solutions/infotainment'
    },
    {
        name: 'Explore The Technology Behind',
     
        img: Img5,
        link:'mobility-solutions/technology-behind'
    },
]






const Card = ({active,setActive}) => {
  
    const scrollToTop = (index) => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        setActive(index);
    }
    
    return(
        <CardStyle  >
            <div className='link-name'> EXPLORE THE TECHNOLOGY BEHIND </div>
            <div className='images'>
            {Obj1?.map((ele,index)=>{
 return  <Link to={`/technology/mobility-solutions/technology-behind`} onClick={()=>{scrollToTop(index)}} className='card'>
  <img src={ele?.img} alt={'explore-more'}  className='card-image'  style={{ backgroundColor:index==active?'rgba(0, 134, 255, 1)':''}}/>
      </Link>
           })}
            </div>
          
          
        </CardStyle>
    )
}

const CardStyle = styled.div`
    width:320px;
    height:90px;
    margin-bottom: 10px;
    transition: all 0.5s ease-out;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:center;
    text-align:left;
    
    position:relative;
  
    background-color:white;
    border-radius:10px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.11);
    padding:15px 15px;
    transition: all 0.3s ease-out;
    .card,.images{
        width:100%;
        height:100%;
        display:flex;
        flex-direction:row;
        align-items: center;
        gap:10px;
        transition: all 0.3s ease-out;
      
    }
    &:hover  {
     
      transform: scale(1.01); 
      transition: all 0.3s ease-out;
      box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.11);
      background-color:rgba(0, 134, 255, 1);
     
    }
   
    .card-image:hover {
        transform: scale(1.03); 
        transition: all 0.3s ease-out;
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.11);
       
    }

    // card-image:hover{
    //     transform: scale(1.02);
    // }
    .link-name{
        opacity:0;
        position:absolute;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%);
      
        width:320px;
       
        transition: all 0.3s ease-out;
    }
 &:hover .link-name ,&:hover{
    opacity:1;
    color:white;
  

 cursor:pointer;
}
 &:hover .images{
    opacity:0;
    transition: all 0.3s ease-out;
}
  
    .card-image{
 
        border-radius:10px;
        object-fit:fill;
        padding:5px;
        align-self:center;
        width:60px;
        height:60px;
        background-color:rgba(229, 245, 255, 1);
        transition: all 0.3s ease-out;
      }
      .card-image:hover{
        background-color:rgba(0, 134, 255, 1);
      }

    .title,.link-name{
        text-align: center;
        font-size: 18px;
        font-weight: 700;
       line-height:23px;
        float:left;
        width:100%;
     margin-bottom:0px;
     color:#0086FF;
     transition: all 0.3s ease-out;
    }
    .explore{
        text-align: left;
        font-size: 16px;
        font-weight: 700;
        color:#0086FF;
        float:left;
        width:100%;
     margin-bottom:0px;
     text-decoration:underline;
    }
  

    .position{
        display: block;
        font-size: calc(1vw + 3px);
        margin-bottom: 10px;
        font-weight: 400;
    }
    .card-button{
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size:  calc(1vw + 3px);
        border-color: #0086FF;
        border-radius: 4px;
        font-weight: 700;
        padding: 2vw 4vw;
      }
   

    @media screen and (max-width: 728px){
        width:280px;
    height:92px;
        .title,.link-name{
            font-size: calc(1vw + 10px);
        }

        .position{
            font-size: calc(1vw + 8px);
        }
        .card-button{
            font-size:12px;
        }
        .row{
            gap:40px;
        }
        .card-image{
            border-radius:10px;
            object-fit:fill;
            padding:5px;
            align-self:center;
            width:50px;
            height:50px;
            background-color:rgba(229, 245, 255, 1);
          
           
          
            transition: all 0.3s ease-out;
        }
    }
`


const Obj1 = [
  
    {
        
        img: img1,
       
    },
    {
       
        img: img2,
    
    },
    {
     
        img: img3,
     
    },{
       
        img:img4,
      
    }
    
]
export default Automotive;