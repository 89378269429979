
import React ,{useEffect,useState} from 'react';
import styled from 'styled-components';
import Card from '../../../card';
import { ReactComponent as Back } from '../../../../../assets/back-icon.svg';
import Img1 from './svgs/Autosar.svg';
import Img2 from './svgs/Alt.svg';
import Img3 from './svgs/CAN-bus.svg';
import Img4 from './svgs/UDS.svg';
import Img5 from './svgs/Communication.svg'
import Img6 from './svgs/V-model.svg';
import Img7 from './svgs/SDLC.svg';
import bg2 from '../../../../we/subpages/ourStory/ourCulture/BG.svg';
import slide1 from './svgs/SDLC.svg';
import slide2 from './svgs/Verification-Validation.svg';
import slide3 from './svgs/Testing-Lifecycle.svg'
import { Row, Col, Button } from 'antd';
import {ReactComponent as ArrowLeft} from '../../../../we/subpages/teams/left.svg'
import {ReactComponent as ArrowRight} from '../../../../we/subpages/teams/right.svg'
import parse from 'html-react-parser';
function Autosar() {
    const [values, setValues] = useState(0);
    const [reset,setReset]=useState(false);
    const length=Obj.length;
    const changeValuesDown = () => {
        setValues((prev) => {
            if(prev == 0){
                return prev+length-1;
            }
            else{
                return prev-1;
            }
        })
    }

    const changeValuesUp = () => {
        setValues((prev) => {
            if(prev == length-1){
                return prev-(length-1);
            }
            else{
                return prev+1;
            }
        })
    }
    const setCustomValue=(value)=>{
        setValues(value);
    }

    useEffect(() => {
       if(!reset){
        const mount = setInterval(() =>{
            setValues((prev) => {
                if(prev == length-1){
                    return prev-(length-1);
                }
                else{
                    return prev+1;
                }
            })
        }, 5000)

        return () => clearInterval(mount);
    }
    }, [reset])
    const handleBackBtn=()=>{
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        window.history.back();
    }
    useEffect(()=>{
      document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
    },[])
    return (
        <React.Fragment>
          
          
          <div   style={{
      background:`url(${bg2}) center no-repeat`,backgroundSize:'cover'
  
      }}>   
        < AutosarContainer>
      
        <div style={{'display':'flex','alignItems':'center','justifyContent':'flex-start',position:'relative'}}>
           <Back className='back-btn' onClick={handleBackBtn} />
         
            </div>

           <div className='Autosar-header heading'> <div className='highlight'>AUTOSAR</div></div>
            
       
            <Card {...Obj1}/>
            {/* <Card {...Obj2}/> */}
            <Card {...Obj3}/>
            <Card {...Obj4}/>
            {/* <Card {...Obj5}/> */}
            <Card {...Obj6}/>
            {/* <Card {...Obj7}/> */}
            <ValuesCarousal>
                <Row style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                    <Col 
                        xs={{span: 24, order: 2}} 
                        sm={{span: 24, order: 2}} 
                        md={{span: 12, order: 1}} 
                        lg={{span: 12, order: 1}} 
                        xl={{span: 12, order: 1}} 
                        xxl={{span: 12, order: 1}}
                        className='description'
                    >
                     
                        <div className='carousal'  onMouseEnter={() => {setCustomValue(values);setReset(!reset)}}
                    onMouseLeave={() => setReset(!reset)}>
                            <span className='header heading'>{parse(Obj[values].heading)}</span>
                            <p className='paragraph content grey'>{Obj[values].description}</p>
                        </div>
                    </Col>
                    <Col 
                        xs={{span: 24, order: 1}} 
                        sm={{span: 24, order: 1}} 
                        md={{span: 12, order: 2}} 
                        lg={{span: 12, order: 2}} 
                        xl={{span: 12, order: 2}} 
                        xxl={{span: 12, order: 2}}
                        className='image-container'
                    >
                        <img src={Obj[values].img} alt='Carousal Banner' className='carousal-image'/>
                    </Col>
                </Row>   
                <Row className='arrow-button '>
                    <Col  className='lines'>
                        <span className='left-outlined' onClick={() => changeValuesDown()}>
                           <ArrowLeft/>
                        </span>

                      {Obj.map((ele,index)=>{
                      
                        return <div className={values === index ? 'blueline' : 'greyline'} onClick={()=>{setCustomValue(index);setReset(!reset)}} 
                        onMouseLeave={() => setReset(!reset)}></div>
                      })}
                        
                        
                        
                        <span className='right-outlined' onClick={() => changeValuesUp()}>
                         <ArrowRight/>
                        </span>
                    </Col>
                </Row>
            </ValuesCarousal>
        </ AutosarContainer>
        </div>
        </React.Fragment>
    )
}


const  AutosarContainer = styled.div`
    height: auto;
    background-color: transparent;
    padding-top: 40px;
    padding-right: 8vw;
    padding-left: 8vw;
    text-align: center;
    padding-bottom: 80px;
 
    .back-btn {
      
        cursor:pointer;
      
    }
    .back-btn svg{
        width:30px;
        height:30px;
    }
    .Autosar-header{
        font-size: calc(2vw + 15px);
        font-weight: 700;
        position:relative;
        z-index:2;
    }

    .Autosar-top{
        padding-top: 20px;
        font-size: calc(1vw + 5px);
        font-weight: 400;
    }
    .Autosar-icon{
      display:flex;
      flex-direction:column;
      align-items:center;
      background-color:rgba(229, 245, 255, 1);
      width:170px;
      height:154px;
      justify-content:center;
      border-radius:14px;
      padding:10px;
     
      
    }
    .Autosar-icon span{
      font-size: calc(1vw + 3px) ;
      font-family: 'Inter', sans-serif;
      font-weight:700 !important;
    }
    .Autosar-card{
      display:flex;
      flex-direction:row;
      align-items:center;
    
      max-width:600px;
   justify-content:flex-start;
      gap:30px;
    }
    .Autosar-card-info{
      width:400px;
      font-size: calc(1vw + 5px);
      font-family: 'Inter', sans-serif;
      font-weight:400;
      text-align:left;
      color: #002546 !important;
    }

    @media screen and (max-width: 767px){
        padding-right: 8vw;
        padding-left: 8vw;

        .Autosar-header{
            font-size: calc(2vw + 20px);
        }

        .Autosar-top{
            font-size: calc(1vw + 10px);
        }
        .Autosar-card-info,  .Autosar-icon span{
          font-size: calc(1vw + 10px);
        }
        .Autosar-card{
          max-width:80vw;
        }
    }
`
const Obj1 = {
  topLine: '',
  btnType: 'outline',
  headline: ``,
  description: "brigosha is a leading player in the field of autosar-based systems software development. Utilizing the power of AUTOSAR, we deliver cutting-edge solutions for seamless integration and efficient communication in automotive systems. With our expertise in BSW (Base Software) development, MCAL (Microcontroller Abstraction Layer) software, and customized SWC (Software Component) development, we ensure optimal resource utilization and stay at the forefront of automotive innovation. Trust brigosha for your autosar-based software needs.",
  buttonLabel: '',
  img: Img1,
  alt: 'autosar-image',
  imgStart: '',
  link:'' ,
  height:80
}

// const Obj2 = {
//   topLine: '',
//   btnType: 'outline',
//   headline: ` <div className='highlight'>Alt:</div> Bridging Communication Gaps with Advanced Technology`,
//   description: "At brigosha, we utilize cutting-edge technologies to develop alternative communication solutions. Our software harnesses the power of Artificial Intelligence and Natural Language Processing to facilitate seamless interactions across diverse platforms, ensuring clear and efficient communication for all. Experience the future of connectivity with brigosha Alt!",
//   buttonLabel: '',
//   img: Img2,
//   alt: 'alt-image',
//   imgStart: 'start',
//   link:'' ,
//   height:80
// }
const Obj3 = {
  topLine: '',
  btnType: 'outline',
  headline: `<div className='highlight'>CAN:</div> Driving Connectivity, Empowering Efficiency`,
  description: "At brigosha, we harness the power of Controller Area Network (CAN) technology to enable reliable and robust communication among electronic components in automotive systems. By streamlining data transmission, our solutions enhance vehicle performance, safety, and efficiency. Trust brigosha to power your journey with unparalleled connectivity and exceptional results through CAN technology.",
  buttonLabel: '',
  img: Img3,
  alt: 'can-image',
  imgStart: 'start',
  link:'' ,
  height:80
}
const Obj4 = {
  topLine: '',
  btnType: 'outline',
  headline: `<div className='highlight'>UDS:</div> Unlocking Diagnostic Precision,
  Unleashing Reliability`,
  description: "brigosha harnesses the power of Unified Diagnostic Services (UDS) to provide comprehensive diagnostic capabilities for automotive systems. Our seamless integration with onboard systems enables efficient fault detection, troubleshooting, and maintenance. Rely on brigosha UDS for optimal performance, reduced downtime, and unmatched reliability in your automotive journey.",
  buttonLabel: '',
  img: Img4,
  alt: 'uds-image',
  imgStart: '',
  link:'' ,
  height:80
}
// const Obj5 = {
//   topLine: '',
//   btnType: 'outline',
//   headline: `<div className='highlight'>Communication Software:</div> Bridging Boundaries, Connecting Possibilities`,
//   description: "brigosha’s innovative communication software breaks down barriers, fostering seamless collaboration and information exchange. Our cutting-edge solutions empower organizations to communicate effortlessly across teams and departments, optimizing productivity and driving innovation. Unleash the potential of your business with brigosha Communication Software.",
//   buttonLabel: '',
//   img: Img5,
//   alt: 'software-image',
//   imgStart: '',
//   link:'' ,
//   height:80
// }
const Obj6 = {
  topLine: '',
  btnType: 'outline',
  headline: `<div className='highlight'>V-Model:</div> Ensuring Precision,
  Enhancing Efficiency`,
  description: "brigosha embraces the V-Model approach, meticulously integrating verification and validation processes throughout our software development lifecycle. By aligning requirements, testing, and development stages, we deliver high-quality solutions, mitigate risks, and optimize project timelines. Trust brigosha’s expertise in utilizing the V-Model for impeccable precision and efficiency in software  development, ensuring successful project outcomes.",
  buttonLabel: '',
  img: Img6,
  alt: 'v-model-image',
  imgStart: 'start',
  link:'' ,
  height:80
}
// const Obj7 = {
//   topLine: '',
//   btnType: 'outline',
//   headline: `<div className='highlight'>SDLC:</div> Powering Transformative
//   Solutions, Inspiring Success`,
//   description: "brigosha’s Software Development Life Cycle (SDLC) methodology is the foundation of our exceptional software solutions. From conception to deployment, our systematic approach ensures rigorous planning, efficient development, comprehensive testing, and continuous improvement. Embrace brigosha SDLC to drive transformative solutions and achieve unparalleled success.",
//   buttonLabel: '',
//   img: Img7,
//   alt: 'sdlc-image',
//   imgStart: '',
//   link:'' ,
//   height:80
// }

const ValuesCarousal = styled.div`
text-align:left; 
margin-top:40px;

    .title{
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 0.25rem;
        font-size: calc(1vw + 3px);
        font-family: 'Sofia Sans', sans-serif;
    }
    .lines{
        display:flex;
        flex-direction:row;
        align-items:center;
        gap:10px;
       
    }
    .blueline{
        width:60px;
        height:6px;
        background-color:#0086FF;
        border-radius:3px;
        cursor:pointer;
    }
    .greyline{
        width:20px;
        height:6px; 
        background-color:#9D9D9D;
        border-radius:3px;
        cursor:pointer;
    }

    .header{
        font-weight: 700;
        font-size: calc(2vw + 5px);
        display: block;
        margin-bottom: 20px;
        font-family: 'Sofia Sans', sans-serif;
    }

    .paragraph{
        font-weight: 400;
        font-size: calc(1vw + 5px);
        font-family: 'Inter', sans-serif;
    }

    .carousal-image{
        width: 400px;
        height:400px;
        padding-left:20px;
    }

    .carousal{
        margin-top: 50px
    }

    .image-container{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width:50%;
        height:50%;
    }

    .arrow-button{
        padding-bottom: 50px;
        font-size: calc(1vw + 8px);
        font-weight: 600;
        font-family: 'Sofia Sans', sans-serif;
        margin-top:20px;

       
    }

    .left-outlined>svg, .right-outlined>svg{
        display: flex;
        // margin-left: 10px;
        // margin-right: 30px;
        cursor: pointer;
        width:20px;
        height:20px;
        align-items:center;
    }

    .carousal-option{
        font-size: calc(2vw + 3px); 
        font-weight: 400;
        font-family: 'Sofia Sans', sans-serif;
    }

    .option{
        font-weight: 700;
      

    }
    .description{
      
    }



    @media screen and (min-width: 2000px){
        .carousal-image{
            width: 60%;
        }
    }

    @media screen and (max-width: 915px){
        .carousal-image{
            width: 80%;
            height: 300px;
    object-fit: fill;
        }
        .carousal-option, .description{
            // display: none;
        }
        .blueline{
            width:30px;
            height:5px;
            background-color:#0086FF;
            border-radius:3px;
            cursor:pointer;
        }
        .greyline{
            width:15px;
            height:5px; 
            background-color:#9D9D9D;
            border-radius:3px;
            cursor:pointer;
        }
        .mobile-description{
            display: flex;
            flex-direction: column;
            margin-top: 40px;
            font-size: calc(2vw + 8px);
            font-weight: 700;
            font-family: 'Sofia Sans', sans-serif;
        }

        .mobile-description .desc-header{
            display: flex;
            flex-direction: row;
            justify-content: flex-start
    
        }
        .desc-header span{
            font-family: 'Sofia Sans', sans-serif;
        }

        .mobile-description span { 
            margin-right: 10px;
        }

        .desc-body{
            font-size: calc(1vw + 10px);
            font-weight: 400;
            // padding-left: 46.38px;
            font-family: 'Sofia Sans', sans-serif;
        }
        .title{
            font-size: calc(1vw + 10px);
        }
        .image-container{
            justify-content:center;
        }
    }
    @media screen and (max-width: 770px){
        .paragraph{
          
            font-size: calc(1vw + 10px);
           
        }
        .header {
            
            font-size: calc(1vw + 15px);
        }
    }
    
`   


const Obj = [
    {
        heading: `<div className='highlight'>SDLC:</div> Powering Transformative
        Solutions, Inspiring Success`,
        description: 'brigosha leverages the Software Development Life Cycle (SDLC) methodology as the foundation of our exceptional software solutions. From conception to deployment, we follow a systematic approach that ensures rigorous planning, efficient development, comprehensive testing, and continuous improvement. Embrace brigosha SDLC to drive transformative solutions and achieve unparalleled success in software development, backed by our expertise and commitment to excellence.',
        img:slide1
    },
    {
        heading: `<div className='highlight'>Verification and Validation</div>`,
        description: 'At brigosha, we prioritize verification and validation processes to provide top-notch service. With meticulous testing and rigorous checks, we ensure brigosha delivers a seamless user experience, unmatched performance, and unwavering reliability. Trust us to validate the accuracy and quality of our services, exceeding your expectations and elevating your online presence to new heights of excellence.',
        img:slide2
    },
    {
        heading: `<div className='highlight'>Testing Lifecycle</div> for Mobility`,
        description: 'Testing Lifecycle for Mobility: Ensuring Flawless Performance at, brigosha follows a comprehensive testing lifecycle designed specifically for mobility solutions. Our process includes requirements analysis, test planning, execution, and reporting, ensuring thorough testing of functionality, compatibility, performance, and security. By meticulously assessing and validating every aspect, we guarantee to deliver a flawless mobile experience, meeting the highest standards of quality and reliability in the ever-evolving landscape of mobility. Trust us to drive your success in the mobile realm.',
        img:slide3
    }
    
 
]




export default  Autosar;





