import ContactBanner from './contact.svg'
import { Form, Input, Row, Col, message } from 'antd'
import React, { useState ,useEffect} from 'react'
import '../../contactUs/styles.css';
import { ColorText2, FormInput, FormInputArea, SubmitButton, Heading, SubHeading, ColorText1, AddressBody, ContactImg ,PhoneInputArea} from '../../contactUs/styles';
import { submitContactForm } from '../../../redux/action';
import { connect } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { FastForwardOutlined,PhoneTwoTone } from '@ant-design/icons';
import styled from 'styled-components';
import Lottie from 'react-lottie';
import animationData from './contact.json'; 
import {phone} from 'phone';
// const { TextArea } = Input;

const scrollToTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}


function ContactCard({ submitContactForm }) {


    useEffect(()=>{
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
     },[])
    const navigate = useNavigate();
 
    const initalValues = {
        name: '',
        email: '',
        phone: '',
        message: ''
    }
    const [formInput, updateFormInput] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });
    const [submitLoader, setSubmitLoader] = useState(false)
    const [isActive, setActive] = useState({name: false, phone: false, email: false});

    const handleSubmit = () => {
        setSubmitLoader(true);
        submitContactForm(formInput, () => {scrollToTop(); setSubmitLoader(false)}, successCallback )
    }

    const clearForm = () => {
        const newState = {...initalValues}
        updateFormInput(newState);
    }
    
    const successCallback =  () => {
        
        clearForm()
        navigate('/get-in-touch/submit-success')
    }


    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      };
    

    

  return (window.location.pathname === '/get-in-touch/submit-success') ?
    <Outlet/> : (
        <ContactWrapper >
    <div >
               {/* <Row >
                <Col span = {24}>
         
                    <div className='Contact-heading heading' >How Can We <span className='highlight' >Help</span> You? </div>
                    <p className='Contact-body content grey'>            
                    Please write to us for any queries & clarification.
                    </p> 
                </Col>
            </Row> */}
        <Row  style={{alignItems: 'center'}}>
            <Col 
                style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-start',flexDirection:'column'}}
                xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}
            >
                {/* <ContactImg width="100%" src={ContactBanner}  /> */}
                <div className='Contact-heading heading'  >How Can We <span className='highlight' >Help</span> You? </div>
                    <p className='Contact-body content grey'>            
                    Please write to us for any queries & clarification.
                    </p> 
                <Lottie options={defaultOptions}  />
            </Col>
            
         
             




            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className='form-col'>
                <div>
               
                {/* <Form
                        style={{marginTop: 50, padding: '0 0'}}
                        name="basic"
                       
                        initialValues={{
                            remember: true,
                        }}
                        layout='vertical'
                        onFinish={() => handleSubmit()}
                        autoComplete="off"
                    >
                        
                        <span style={{width:'100%',textAlign:'end',float:'right'}}><span style={{color:'red'}}>*</span>All fields are mandatory !</span>
                      <br/>
                        <Form.Item
                                name="name"
                                label="Name"
                               rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your username!',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value ||/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '-]+$/.test(value)) {
                                                setActive({...isActive, name: true})
                                                return Promise.resolve();
                                            }
                                            setActive({...isActive, name: false})
                                            return Promise.reject(new Error('Please enter a valid name!'));
                                        },
                                    })
                                ]}
                            >
                           
                                <FormInput placeholder='Name' value={formInput.name} onChange={e => {updateFormInput({...formInput, name: e.target.value.trim()})}}/>
                        </Form.Item>

                        <Form.Item
                                name="email"
                                label="E-mail"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your E-mail!',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
                                                setActive({...isActive, email: true})
                                                return Promise.resolve();
                                            }
                                            setActive({...isActive, email: false})
                                            return Promise.reject(new Error('Please enter a valid email!'));
                                        },
                                    })
                                ]}
                            >
                                
                                <FormInput placeholder='Email ID' value={formInput.email} onChange={e => {updateFormInput({...formInput, email: e.target.value.trim()})}}/>
                        </Form.Item>


                        <Form.Item
                                name="phone"
                                label='Phone Number'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your Mobile Number(Eg: +91 *****-*****)!',
                                    },
                                    () => (
                                        {validator(_, value) {
                                           
                                        
                                      
                                            if(value.length!==0){
        
                                                if(phone('+'+value).isValid){
                                                  
                                                    setActive({...isActive, phone: true})
                                                  
                                                    return Promise.resolve();  
                                                }else{
                                                   
                                                setActive({...isActive, phone: false});
                                                return Promise.reject(new Error('Please enter a valid Mobile number!'));
                                                }
                                                
                                            }
                                           
                                          
                                        }
                                    }),
                                ]}
                            >
                            
                                <PhoneInputArea
                                country={'in'}
                                value={formInput?.phone}
                                autoFormat={FastForwardOutlined}
                                
                                onChange={phone => {
                                    
                                   
                                    updateFormInput({...formInput, phone: phone})}}
                                placeholder="Mobile Number (Eg: +91 *****-*****)"
                              
                                />
                            </Form.Item>


                            <Form.Item
                                name="message"
                                label="Message"
                                rules={[
                                    {
                                    required: true,
                                    message: 'Please type a message!'
                                    }
                                ]}
                            >
                            
                                <FormInputArea placeholder='Message(Max 250 Characters)'value={formInput?.message} onChange={e => {updateFormInput({...formInput, message: e.target.value})}} maxLength={250}/>
                        </Form.Item>
                        
                        <Form.Item
                            wrapperCol={{offset: 0}}
                        >
                            <SubmitButton 
                           
                            type="primary" 
                            htmlType="submit" 
                            loading={submitLoader}
                            
                            disabled={!formInput.name || !formInput.email || !formInput.message || !formInput.phone || !isActive.email || !isActive.name || !isActive.phone}
                            >
                                SUBMIT
                            </SubmitButton>
                        </Form.Item>
                     
                    </Form> */}

                </div>
                <div  className='contact-wrapper' style={{display:'flex',alignItems:'center',justifyContent:'center',margin:'0 auto',background:'#fff'}}>
                    <Form
                        style={{ padding: '0 0',background:'rgba(255,255,255,0.2)',textAlign:'center'}}
                        name="basic"
                       className='contact-form'
                        initialValues={{
                            remember: true,
                        }}
                        layout='vertical'
                        onFinish={() => handleSubmit()}
                        autoComplete="off"
                    >
                        

                      <br/>
                        <Form.Item
                                name="name"
                                label="Name"
                               rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your name!',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value ||/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '-]+$/.test(value)) {
                                                setActive({...isActive, name: true})
                                                return Promise.resolve();
                                            }
                                            setActive({...isActive, name: false})
                                            return Promise.reject(new Error('Please enter a valid name!'));
                                        },
                                    })
                                ]}
                            >
                           
                                <FormInput placeholder='Name' value={formInput.name} onChange={e => {updateFormInput({...formInput, name: e.target.value.trim()})}}/>
                        </Form.Item>

                        <Form.Item
                                name="email"
                                label="E-mail"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your E-mail!',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
                                                setActive({...isActive, email: true})
                                                return Promise.resolve();
                                            }
                                            setActive({...isActive, email: false})
                                            return Promise.reject(new Error('Please enter a valid email!'));
                                        },
                                    })
                                ]}
                            >
                                
                                <FormInput placeholder='Email ID' value={formInput.email} onChange={e => {updateFormInput({...formInput, email: e.target.value.trim()})}}/>
                        </Form.Item>


                        <Form.Item
                                name="phone"
                                label='Phone Number'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your Mobile Number(Eg: +91 *****-*****)!',
                                    },
                                    () => (
                                        {validator(_, value) {
                                           
                                        
                                      
                                            if(value.length!==0){
        
                                                if(phone('+'+value).isValid){
                                                  
                                                    setActive({...isActive, phone: true})
                                                  
                                                    return Promise.resolve();  
                                                }else{
                                                   
                                                setActive({...isActive, phone: false});
                                                return Promise.reject(new Error('Please enter a valid Mobile number!'));
                                                }
                                                
                                            }
                                           
                                          
                                        }
                                    }),
                                ]}
                            >
                            
                                <PhoneInputArea
                                country={'in'}
                                value={formInput?.phone}
                                autoFormat={FastForwardOutlined}
                                
                                onChange={phone => {
                                    
                                   
                                    updateFormInput({...formInput, phone: phone})}}
                                placeholder="Mobile Number (Eg: +91 *****-*****)"
                              
                                />
                            </Form.Item>


                            <Form.Item
                                name="message"
                                label="Message"
                                rules={[
                                    {
                                    required: true,
                                    message: 'Please type a message!'
                                    }
                                ]}
                            >
                            
                                <FormInputArea placeholder='Message(Max 250 Characters)'value={formInput?.message} onChange={e => {updateFormInput({...formInput, message: e.target.value})}} maxLength={250}/>
                        </Form.Item>
                        
                        <Form.Item
                            wrapperCol={{offset: 0}}
                        >
                            <SubmitButton 
                           
                            type="primary" 
                            htmlType="submit" 
                            loading={submitLoader}
                            
                            disabled={!formInput.name || !formInput.email || !formInput.message || !formInput.phone || !isActive.email || !isActive.name || !isActive.phone}
                            >
                                SUBMIT
                            </SubmitButton>
                        </Form.Item>
                        <span style={{width:'100%',textAlign:'end',float:'right'}}><span style={{color:'red'}}>*</span>All fields are mandatory</span>  
                    </Form>

                    {/* <div    style={{marginTop: 50, padding: '0 0',background:'rgba(43, 43, 43, 1)',width:'266px',textAlign:'center',height:"271px",position:'absolute',left:'60%',borderRadius:'4px',boxShadow:' 15px 16px 30px 0px rgba(0, 0, 0, 0.11)',display:'flex',flexDirection:'column',
alignItems:'center',color:'white',justifyContent:'space-around'}} className='black-box'> 
                    <div    style={{marginTop: 50, padding: '0 0',background:'rgba(43, 43, 43, 1)',width:'50px',textAlign:'center',height:"50px",position:'absolute',left:'-10%',top:'-18.5%',borderRadius:'100px',boxShadow:' 15px 16px 30px 0px rgba(0, 0, 0, 0.11)'}}> 
</div>
             <div> <span style={{fontWeight:700}}>Contact Us</span></div>             
           <div style={{display:'flex',justifyContent:'center',flexDirection:'column',color:'rgba(255, 255, 255, 1)',gap:'5px'}}> <span>+919538946543</span>
                 <span>info@brigosha.com</span>
                 <span>Bengaluru, Karnataka</span></div>
                <div style={{display:'flex',justifyContent:'center',flexDirection:'row',color:'rgba(255, 255, 255, 1)',gap:'10px'}}>
                    <a href = 'https://twitter.com/Brigosha' target="_blank" rel="noopener noreferrer"><img src={twitter} style={{cursor:'pointer'}}></img></a>
                    <a href = 'https://www.linkedin.com/company/brigosha-technologies-pvt-lmt/about/' target="_blank" rel="noopener noreferrer"><img src={linkedIn} style={{cursor:'pointer'}}></img></a>
                    <a href = 'https://www.youtube.com/@BRIGOSHA' target="_blank" rel="noopener noreferrer"><img src={youtube} style={{cursor:'pointer'}}></img></a> 
                </div>
                    </div> */}
                    </div>
            </Col>
        </Row>
       
       
       
    </div>
   </ContactWrapper>
  )
}


const mapStateToProps = state => {
    const {
        
    }  = state.we;

    return {}
}

const mapDispatchToProps =dispatch =>  ({
    submitContactForm: (params, callback, successCallback) => dispatch(submitContactForm(params, callback, successCallback)),
})


export default connect(mapStateToProps, mapDispatchToProps)(ContactCard);

const ContactWrapper = styled.div`
    padding-top: 80px;
    padding-bottom:80px;
   
    // padding-left: 10vw;
    // padding-right: 10vw;
   
 
   
    .Contact-bold-text{
        letter-spacing: 0.25rem;
        text-transform: uppercase;
        font-weight: 600;
        font-size: calc(1vw + 3px);
        text-align: center;
        font-family: 'Sofia Sans', sans-serif;
        align-slef:center;
    }
    .Contact-div{
        font-size:18px;
    }
    .content-div{
        position:absolute;
        left:50%;
        top:50%;
        transform:translate(-50%,-50%);
        position:relative;
       
    }

    .Contact-heading{
        text-align: center;
        font-size: calc(2vw + 15px);
        font-weight: 700;
        margin-top: 30px;
        position:relative;
        z-index:2;
        font-family: 'Sofia Sans', sans-serif;
        padding-left: 10vw;
    }

    .Contact-body{
        text-align: center;
        font-weight: 400;
        font-size: calc(1vw + 5px);
        font-family: 'Inter', sans-serif;
        padding-left: 10vw;
    }



    

 




    @media screen and (max-width: 767px){
        padding-top:40px;
        // padding-left: 10vw;
        padding-right: 10vw;

        .Contact-bold-text{
            font-size: calc(1vw + 10px);
        }

        .Contact-heading{
            font-size: calc(2vw + 20px);
            padding-left: 10vw;
            text-align:center;
            width:100%;
        }
        
        .Contact-body{
            padding-left: 10vw;
            text-align:center;
            font-size: calc(1vw + 10px);
            width:100%;
        }
      
   
       .form-col{
        padding-left: 10vw;
       }
        img{
            width: 90%;
        } 

       

       
    }

   

`
